import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private filterData$ = new BehaviorSubject<any>({})
  selectedFilters$ = this.filterData$.asObservable()

  private searchText$ = new BehaviorSubject<string>('')
  currentSearch$ = this.searchText$.asObservable()

  private siteName$ = new BehaviorSubject<string>('')
  currentSite$ = this.siteName$.asObservable()
  
  constructor() { }

  setFilterData(filterData: any) {
    this.filterData$.next(filterData)
  }

  clearSearch(newValue: string) {
    this.searchText$.next(newValue)
  }

  clearSiteName(newValue: string) {
    this.siteName$.next(newValue)
  }

  resetFilterData(filterData: any) {
    filterData.searchClicked = false
    filterData.siteId = ''
    filterData.siteName = ''
    filterData.wearerId = ''
    filterData.wearerName = ''
    filterData.dateDto = {}
    filterData.startDate = ''
    filterData.endDate = ''
    filterData.searchClicked = false
    filterData.search = ''
    filterData.wearerName = ''
    filterData.cleared = true
    console.log('resetting filter from service: ', filterData)
    this.filterData$.next(filterData)
  }
}

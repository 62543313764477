import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AlertRuleProfileComponent } from './alert-rule-profile.component'

import { ManageAlertRulesComponent } from './components/manage-alert-rules/manage-alert-rules.component'
import { CreateAlertRuleComponent } from './components/create-alert-rule/create-alert-rule.component';

export const ALERT_PROFILE_ROUTES: Routes = [
  { 
    path: '', 
    component: AlertRuleProfileComponent,
    children: [
      {
        path: 'manage-alert-rules',
        component: ManageAlertRulesComponent
      },
      {
        path: 'create-alert-rule',
        component: CreateAlertRuleComponent
      },
      {
        path: 'edit-alert-rule',
        component: CreateAlertRuleComponent
      },
      {
        path: '',
        redirectTo: 'manage-alert-rules',
        pathMatch: 'full'
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(ALERT_PROFILE_ROUTES)],
  exports: [RouterModule]
})
export class AlertRuleProfileRoutingModule { }

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { DeviceApiBuilderService } from 'src/app/shared/api-builders/device-api-builder.service'
import { DeviceConfig } from 'src/app/shared/models/device-config.model'
import { Device } from 'src/app/shared/models/device.model'

@Injectable()
export class DeviceService {
    constructor(private http: HttpClient,
        private deviceApiBuilder: DeviceApiBuilderService) {

    }

    prepareDeviceConfigList(deviceConfig: DeviceConfig): Array<any> {
        let settingList: Array<any> = []
        let configMap = DeviceConfig.createConfigMap()
        let index = 0
        for (const [key, value] of Object.entries(deviceConfig)) {
            if (key !== 'id' && key !== 'isDeleted' && configMap.has(key)) {
                
                const configObject: any = {
                    configKey: key,
                    value: value,
                    ...configMap.get(key)
                }
                settingList.push(configObject)
            }
            index++
        }
        return settingList
    }

    getDeviceLogicalId() {
        return this.http.get(this.deviceApiBuilder.getDeviceIdUrl)
    }

    createDevice(deviceObject: Device) {
        return this.http.post(this.deviceApiBuilder.createDeviceUrl, deviceObject)
    }

    getDeviceById(deviceId: number) {
        const getDeviceByIdUrl = this.deviceApiBuilder.getDeviceByIdUrl(deviceId)
        return this.http.get(getDeviceByIdUrl)
    }

    updateDevice(deviceId: number, deviceObject: Device) {
        const updateDeviceUrl = this.deviceApiBuilder.updateDeviceUrl(deviceId)
        return this.http.put(updateDeviceUrl, deviceObject)
    }

    getAllDevices(requestData: any) {
        const getAllDevicesUrl = this.deviceApiBuilder.getAllDevicesUrl(requestData)
        return this.http.get(getAllDevicesUrl)
    }

    getDecommissionedDevices(requestData: any) {
        const getDecommissionedDevicesUrl = this.deviceApiBuilder.includeDecommissionedDevicesUrl(requestData)
        return this.http.get(getDecommissionedDevicesUrl)
    }

    getUnassignedDevices() {}

    getUnprovisionedDevicesUrl() {}

    getProvisionedDevicesUrl() {}
}
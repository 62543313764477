import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from 'src/app/iot/services/admin.service';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  changePasswordModel: any = {}
  defaultModel: any = {}
  notificationsEnabled: boolean = false
  userId: number
  @ViewChild('changePasswordForm', {static: true}) changePasswordForm!: NgForm

  constructor(private adminService: AdminService, private toastr: ToastrService) {
    this.changePasswordModel = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
    this.defaultModel = cloneDeep(this.changePasswordModel)
    this.userId = parseInt(localStorage.getItem('user-id')!)
  }

  ngOnInit(): void {
    this.getUserNotificationStatus(this.userId)      
  }

  getUserNotificationStatus(userId: number) {
    this.adminService.getUserNotificationStatus(userId).subscribe({
      next: (response: any) => {
        this.notificationsEnabled = response.status
      }, 
      error: (err: any) => {

      }
    })
  }

  toggleNotifications(event: any) {
    // console.log('switch: ', this.notificationsEnabled)
    this.saveNotificationStatus()
  }

  saveNotificationStatus() {
    this.adminService.saveNotifications(this.userId, this.notificationsEnabled).subscribe({
      next: (response: any) => {
        // console.log('notification put response: ', response)
        this.toastr.success(response.message)
      },
      error: (error: any) => {

      }
    })
  }

  saveUserSettings() {
    if (this.changePasswordForm.invalid) {
      return
    }
    if(this.changePasswordModel.newPassword!=this.changePasswordModel.confirmPassword){
      return
    }
    const requestData = {
      userId: this.userId, 
      // isNotificationsEnabled: this.notificationsEnabled, 
      settingsData: this.changePasswordModel
    }

    this.adminService.changePassword(requestData).subscribe({
      next: (response: any) => {
        this.changePasswordForm.resetForm(this.defaultModel)
        this.getUserNotificationStatus(this.userId)
        this.toastr.success('User settings changed')
      },
      error: (err: any) => {

      }
    })
  }


}

<!--Main Wrapper-->
<div class="container card-wrapper">
    
    <!--Loader-->
    <!-- <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading> -->
    
    <!--Card-->
    <div class="auth-card">
        
        <!--Card Header-->
        <div class="auth-card-header">
            <img src="../../../assets/images/Compedica_Dark_Tagline.svg" alt="">
            <!-- <h4>HP1 Technologies</h4> -->
        </div>

        <!--Form Container-->
        <div class="auth-form-container">
            <h5>Welcome</h5>
            <p class="">Please create a new password</p>
            
            <!--Form-->
            <form class="pt-3" #resetPasswordForm="ngForm" (ngSubmit)="resetPassword(resetPasswordForm)">
                <div class="mb-3">
                    <input autocomplete="new-password" 
                        placeholder="Password"
                        type="password" 
                        pattern="^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$"
                        minlength="4"
                        class="p-inputtext-sm w-100"
                        name="password"
                        #password="ngModel"
                        [(ngModel)]="resetPasswordDto.newPassword"
                        pInputText 
                        required>
                    <div class="error-block" *ngIf="password.invalid && (resetPasswordForm.submitted || password.dirty || password.touched)">
                        <span *ngIf="password.errors?.['required']">Password is required</span>
                    </div> 
                    <div class="error-block" *ngIf="(resetPasswordForm.submitted || password.touched)">
                        <span *ngIf="password.errors?.['pattern']">
                            Password must be 8 characters long and must have at least 1 uppercase character, 1 or more numbers, and 1 or more special character
                        </span>
                    </div>
                </div>

                <div class="mb-3">
                    <input autocomplete="new-password" 
                        placeholder="Confirm Password"
                        type="password"  
                        class="p-inputtext-sm w-100"
                        name="confirmPassword"
                        #confirmPassword="ngModel"
                        [(ngModel)]="resetPasswordDto.confirmPassword"
                        pInputText 
                        required>
                    <div class="error-block" *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                        <span *ngIf="confirmPassword.errors?.['required']">Confirm password is required</span>
                    </div> 
                    <div class="error-block" *ngIf="(confirmPassword.touched || confirmPassword.dirty) && (resetPasswordForm.submitted && (resetPasswordDto.confirmPassword !== '' && resetPasswordDto.confirmPassword!=resetPasswordDto.newPassword))">
                        <span>Passwords do not match</span>
                    </div>
                </div>

                <button pButton pRipple type="submit" [disabled]="false" label="Submit" class="submit-button p-button-raised p-button-sm w-50"></button>
            </form>
        </div>
    </div>
</div>




import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { NgForm } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { SiteService } from 'src/app/iot/services/site.service'
import { countries } from 'src/app/shared/country-data-store'
import { Country } from 'src/app/shared/models/country.model'
import { Site } from 'src/app/shared/models/site.model'
import * as moment from 'moment-timezone'
import { LOADER_CONFIG } from 'src/app/shared/global-constants'
import { LoaderService } from 'src/app/iot/services/loader.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-hospital-details',
  templateUrl: './hospital-details.component.html',
  styleUrls: ['./hospital-details.component.scss']
})
export class HospitalDetailsComponent implements OnInit, OnDestroy {
  loading: boolean = false
  siteModel: Site
  disableSave: boolean = true
  editSiteId: string = ''
  isEditMode: boolean = false
  countries: Array<Country> = countries
  timeZones: Array<any> = []
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG
  @ViewChild('siteDetailsForm', {static: true}) siteDetailsForm!: NgForm

  constructor(private siteService: SiteService,
    private loaderService: LoaderService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute) {
    this.siteModel = new Site()
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })
  }

  ngOnInit(): void {
    this.editSiteId = this.activatedRoute.snapshot.queryParamMap.get("id")!;
    if (this.editSiteId) {
      this.isEditMode = true
      this.getSiteDetails(parseInt(this.editSiteId))
    }
  }

  getSiteDetails(siteId: number) {
    this.siteService.getSiteById(siteId).subscribe({
      next: (response: any) => {
        let country = response.DATA.country
        let timezone = response.DATA.timeZone
        let code = ''
        for (let index = 0; index < this.countries.length; index++) {
          if (country == this.countries[index]['name']) {
            code = this.countries[index]['code']
          }
          
        }
        
        this.getTimeZonesWithOffset(code)
        this.siteModel = response.DATA
        this.siteModel.timeZone = timezone
        for (let i = 0; i < this.timeZones.length; i++) {
          if (timezone === this.timeZones[i]['timezone']) {
            this.siteModel.offSet = this.timeZones[i]['offset']
          }
        } 
      }, 
      error: (err: any) => {
      }
    })
  }

  saveSite() {
    if(this.siteDetailsForm.invalid) {
      return
    }
    if (this.isEditMode) {
      this.updateSite(this.siteDetailsForm, parseInt(this.editSiteId), this.siteModel)
    } else {
      this.createSite(this.siteDetailsForm)
    }
  }

  createSite(form: NgForm) {
    this.siteService.createSite(this.siteModel).subscribe({
      next: (response: any) => {
        this.router.navigate(["iot/sites/manage-sites"])
      },
      error: (err: any) => {

      }
    })
  }

  updateSite(form: NgForm, siteId: number, siteObject: Site) {
    if (form.invalid) {
      return
    }
    this.siteService.updateSite(siteId, siteObject).subscribe({
      next: (response: any) => {
        this.router.navigate(["iot/sites/manage-sites"])
      },
      error: (err: any) => {

      }
    })
  }

  onCountryChange(event: any) {
    let countryName = event.target.value
    let countryCode = ''
    for (let index = 0; index < this.countries.length; index++) {
      if (countryName == this.countries[index]['name']) {
        // this.siteModel.country = this.countries[index]['name']
        countryCode = this.countries[index]['code']
      }
      
    }
    this.getTimeZonesWithOffset(countryCode)
  }

  selectTimeZone(event: any) {
    
    let value = event.target.value
    for (let index = 0; index < this.timeZones.length; index++) {
      if (value == this.timeZones[index]['timezone']) {
        
        this.siteModel.offSet = this.timeZones[index]['offset']
      }
    }
  }

  getTimeZonesWithOffset(countryCode: string) {
    const timezones = moment.tz.zonesForCountry(countryCode)
    const timezonesWithOffset = timezones.map((timezone: any) => {
      const now = Date.now();
      const offsetMinutes = moment.tz.zone(timezone)?.utcOffset(now)!
      const offsetHours = Math.abs(offsetMinutes / 60);
      const offsetSign = offsetMinutes >= 0 ? '-' : '+';
      const offsetFormatted = `UTC${offsetSign}${Math.floor(offsetHours).toString().padStart(2, '0')}:${(Math.abs(offsetMinutes) % 60).toString().padStart(2, '0')}`;
      return { timezone, offset: offsetFormatted };
    });
    this.timeZones = timezonesWithOffset
  }

  siteModelChanged(event: any) {}

  ngOnDestroy(): void {

  }
}

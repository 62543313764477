<div class="hospitals-wrapper">
    <!--Button group-->
    <div class="btn-group mb-3">
        <button type="button" class="btn btn-outline-primary"
            [routerLink]="['manage-sites']"
            routerLinkActive="active">
            Manage
        </button>
        <button *ngIf="!editRoute && (userRole != 'ROLE_SITE_MANAGER')" type="button" class="btn btn-outline-primary"
            [routerLink]="['create-site']"
            routerLinkActive="active">
            Create
        </button>
        <button *ngIf="editRoute" type="button" class="btn btn-outline-primary"
            [ngClass]="editRoute ? 'active' : ''">
            Edit
        </button>
    </div>
    
    <!--Router Outlet-->
    <div class="">
        <router-outlet></router-outlet>
    </div>
</div>

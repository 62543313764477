export class Mobile {
    androidDeviceId: string
    manufacturer: string
    model: string
    osName: string
    osVersion: string

    constructor () {
        this.androidDeviceId = ''
        this.manufacturer = ''
        this.model = ''
        this.osName = ''
        this.osVersion = ''
    }
}
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CreatePatientService } from 'src/app/iot/services/create-patient.service'
import { LoaderService } from 'src/app/iot/services/loader.service';
import { TOASTR_CONFIG, LOADER_CONFIG } from 'src/app/shared/global-constants'

@Component({
  selector: 'app-assign-device',
  templateUrl: './assign-device.component.html',
  styleUrls: ['./assign-device.component.scss']
})
export class AssignDeviceComponent implements OnInit, OnDestroy {
  btnStatus: string = 'save'
  wearerDevice: string = ''
  wearerId: string = ''
  isEditMode: boolean = false
  deviceList: Array<any> = []
  disableSave: boolean = true
  deviceId: string = ''
  siteId: string = ''
  loading: boolean = false
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG

  @ViewChild('deviceForm', {static: false}) deviceForm!: NgForm

  constructor(private createPatientService: CreatePatientService,
    private router: Router,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute) {
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })

  }

  ngOnInit(): void {    
    this.wearerId = this.activatedRoute.snapshot.queryParamMap.get('id')!
    if (this.wearerId) {
      this.isEditMode = true
      let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      if(wearerData.deviceId) {
        this.getWearerDevice(parseInt(this.wearerId))
      }
    } else {
      // let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      // this.siteId = wearerData.siteId;
    }
    this.getAssignableDevices()  
  }

  getWearerDevice(weareId: number) {
    this.createPatientService.getWearerDevice(weareId).subscribe({
      next: (response: any) => {
        
        this.deviceList.push(response.DATA)
        this.wearerDevice = response.DATA.deviceId
      },
      error: (error: any) => {

      }
    })
  }

  getAssignableDevices() {
    let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
    let userId = localStorage.getItem('user-id')!
    let siteId = wearerData.siteId
    this.createPatientService.getAssignableDevices(parseInt(userId), parseInt(siteId)).subscribe({
      next: (response: any) => {
        if(this.deviceList.length == 0) {
          this.deviceList = response.DATA
        } else {
          response.DATA.forEach((device: any) => {
            this.deviceList.push(device)
          });
        }
      },
      error: (error: any) => {

      }
    })

  }

  selectDevice(event: any) {
    this.disableSave = (event.target.value == '') ? true : false
    this.deviceId = event.target.value
  }

  saveAssignedDevice() {
    if (this.isEditMode) {
      let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      if (wearerData.deviceId) {
        this.updateWearerDevice(parseInt(this.wearerId), this.deviceId)
      } else {
        this.assignDevice()
      }
    } else {
      this.assignDevice()
    }
  }

  assignDevice() {
    if (this.deviceForm.invalid) {
      this.toastr.error('Device ID is mandatory')
      return
    }
    var wearerData = JSON.parse(localStorage.getItem("wearerData")!);
    this.wearerId = wearerData.id!
    if(!this.deviceId){
      this.toastr.error('Please select a device')
      return
    }
    this.createPatientService.assignDeviceToWearer(parseInt(this.wearerId), this.deviceId).subscribe({
      next: (response: any) => {
        this.toastr.success('Device Assigned', '', TOASTR_CONFIG)
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(["iot/wearers/manage-wearers"])
        })
      },
      error: (error: any) => {}
    })
  }

  updateWearerDevice(wearerId: number, deviceId: string) {
    if (!this.deviceId && !this.wearerDevice) {
      this.toastr.error('Device ID is mandatory')
      return
    }
    if (!deviceId && this.wearerDevice) {
      deviceId = this.wearerDevice
    }
    this.createPatientService.updateWearerDevice(deviceId, wearerId).subscribe({
      next: (response: any) => {
        this.toastr.success('Wearer updated', '', TOASTR_CONFIG)
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(["iot/wearers/manage-wearers"])
        })
      },
      error: (error: any) => {}
    })
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }
}

import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot } from "@angular/router"
import { map } from "rxjs"
import { UserService } from "src/app/iot/services/user.service"

export const authGuard = (next: ActivatedRouteSnapshot) => {
    const service = inject(UserService);
    return service
        .isUserLoggedIn().pipe(
            map((isLoggedIn: boolean) => {
                if (isLoggedIn) {
                    return true;
                } else {
                    //   const loginUrlTree = this.router.parseUrl('/login');
                    //   this.showLoginRequiredToast(); // Display a toast when isLoggedIn is false
                    return createUrlTreeFromSnapshot(next, ['/', 'login']);
                }
            })
        )
    // .pipe(map((isLoggedIn) => isLoggedIn ? true : createUrlTreeFromSnapshot(next, ['/', 'login'])));
};
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginModel } from './login.model';

import { ToastrService } from 'ngx-toastr';
import { LoginService } from './login.service'
import { UserService } from 'src/app/iot/services/user.service';
import { LOADER_CONFIG, TOASTR_CONFIG } from 'src/app/shared/global-constants';
// import {Country} from '@angular-material-extensions/select-country'; 

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginObject: LoginModel = new LoginModel()
  loading: boolean = false;
  loaderConfig = LOADER_CONFIG

  @ViewChild('loginForm', { static: false }) form!: NgForm;
  constructor(private router: Router,
    private toastrService: ToastrService,
    private userService: UserService,
    private loginService: LoginService) {

  }

  ngOnInit(): void {
    localStorage.clear()

  }

  loginUser(form: NgForm) {
    this.loading = true
    this.loginService.signIn(this.loginObject).subscribe({
      next: (response: any) => {
        this.loading = false
        this.toastrService.success('Login successful', '', TOASTR_CONFIG)
        this.userService.setUserLoggedInStatus(true)
        this.userService.setUserData(response.User)
        this.router.navigate(['iot'])
      },
      error: (error: any) => {
        this.loading = false
      }
    })
  }
}

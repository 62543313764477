import {
  PressureLineChartOptions,
  PressureBarChartOptions,
  PressureHeatmapOptions,
  StackedColumnChartOptions
} from '../chart-options'

export const pressureLineChartOptions: PressureLineChartOptions = {
  series: [
    {
      name: "CH1-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH1-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH1-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH1-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH1-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25]
    },
    {
      name: "CH2-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH2-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH2-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH2-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH2-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25]
    },

    {
      name: "CH3-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH3-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH3-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH3-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH3-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25]
    },

    {
      name: "CH4-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH4-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH4-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH4-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH4-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25]
    },
    {
      name: "CH5-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH5-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH5-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH5-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH5-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25, 30, 20, 10, 20, 15, 10, 0, 40, 25]
    }
  ],
  chart: {
    height: 400,
    type: "line",
    toolbar: {
      show: true
    }
  },
  options: {
    colors: ['#0000FF', '#4000BF', '#800080', '#BF0040', '#FF0000']
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: "smooth",
    width: 3
  },
  title: {
    text: "",
    align: "left"
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5
    }
  },
  xaxis: {
    categories: [
      "6:OO AM",
      "6:15 AM",
      "6:3O AM",
      "6:45 AM",
      "7:OO AM",
      "7:15 AM",
      "7:3O AM",
      "7:45 AM",
      "8:OO AM",
      "8:15 AM",
      "8:30 AM",
      "8:45 AM",
      "9:OO AM",
      "9:15 AM",
      "9:30 AM",
      "9:45 AM",
      "10:OO AM",
      "10:15 AM",
      "10:30 AM",
      "10:45 AM",
      "11:OO AM",
      "11:15 AM",
      "11:30 AM",
      "11:45 AM",
      "12:OO PM",
      "12:15 PM",
      "12:30 PM",
      "12:45 PM",
      "1:OO PM",
      "1:15 PM",
      "1:30 PM",
      "1:45 PM",
      "2:OO PM",
      "2:15 PM",
      "2:30 PM",
      "2:45 PM"
    ]
  }
}

export const oneWeekPressureLineOptions: PressureLineChartOptions = {
  series: [
    {
      name: "CH1-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60]
    },
    {
      name: 'CH1-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20]
    },
    {
      name: 'CH1-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15]
    },
    {
      name: 'CH1-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5,]
    },
    {
      name: 'CH1-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0,]
    },
    {
      name: "CH2-Zone 1",
      data: [82, 49, 22, 73, 36, 15, 50]
    },
    {
      name: 'CH2-Zone 2',
      data: [15, 6, 63, 17, 4, 41, 11]
    },
    {
      name: 'CH2-Zone 3',
      data: [1, 7, 2, 2, 28, 38, 20]
    },
    {
      name: 'CH2-Zone 4',
      data: [1, 13, 7, 4, 28, 5, 17,]
    },
    {
      name: 'CH2-Zone 5',
      data: [1, 16, 2, 1, 1, 1, 1]
    },
    {
      name: "CH3-Zone 1",
      data: [27,74,	39,	20,	38,	67,	52]
    },
    {
      name: 'CH3-Zone 2',
      data: [19,	4,	11,	50,	1,	20,	15]
    },
    {
      name: 'CH3-Zone 3',
      data: [40, 13, 21, 28, 11, 3, 16]
    },
    {
      name: 'CH3-Zone 4',
      data: [1,	1,	22,	1,	13,	4,	16]
    },
    {
      name: 'CH3-Zone 5',
      data: [6,	7,	3, 1,	10,	3,	1]
    },
    {
      name: "CH4-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60]
    },
    {
      name: 'CH4-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20]
    },
    {
      name: 'CH4-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15]
    },
    {
      name: 'CH4-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5,]
    },
    {
      name: 'CH4-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0,]
    },
    {
      name: "CH5-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60]
    },
    {
      name: 'CH5-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20]
    },
    {
      name: 'CH5-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15]
    },
    {
      name: 'CH5-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5,]
    },
    {
      name: 'CH5-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0,]
    }
  ],
  chart: {
    height: 400,
    type: "line",
    toolbar: {
      show: true
    },
    // zoom: {
    //   enabled: false
    // }
  },
  options: {
    colors: ['#0000FF', '#4000BF', '#800080', '#BF0040', '#FF0000']
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: "smooth",
    width: 3
  },
  title: {
    text: "",
    align: "left"
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5
    }
  },
  xaxis: {
    categories: [
      "01-09-2023",
      "02-09-2023",
      "03-09-2023",
      "04-09-2023",
      "05-09-2023",
      "06-09-2023",
      "07-09-2023"
    ]
  }
}

export const pressureBarChartOptions: StackedColumnChartOptions = {
  series: [
    {
      name: "CH1-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH1-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH1-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH1-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH1-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30]
    }, 
    {
      name: "CH2-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH2-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH2-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH2-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH2-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30]
    },
    {
      name: "CH3-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH3-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH3-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH3-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH3-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30]
    },
    {
      name: "CH4-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH4-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH4-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH4-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH4-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30]
    },
    {
      name: "CH5-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18, 10, 20, 30, 20, 40, 20, 60, 15, 18]
    },
    {
      name: 'CH5-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22, 10, 10, 20, 20, 15, 20, 20, 15, 22]
    },
    {
      name: 'CH5-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15, 20, 20, 15, 20, 15, 25, 15, 15, 15]
    },
    {
      name: 'CH5-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15, 30, 30, 25, 20, 15, 25, 5, 15, 15]
    },
    {
      name: 'CH5-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30, 30, 20, 10, 20, 15, 10, 0, 40, 30]
    }

  ],
  chart: {
    type: "bar",
    height: 450,
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: true
    }
  },
  options: {
    colors: ['#0000FF', '#4000BF', '#800080', '#BF0040', '#FF0000']
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "60%"
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: [
      "6:OO AM",
      "6:15 AM",
      "6:3O AM",
      "6:45 AM",
      "7:OO AM",
      "7:15 AM",
      "7:3O AM",
      "7:45 AM",
      "8:OO AM",
      "8:15 AM",
      "8:30 AM",
      "8:45 AM",
      "9:OO AM",
      "9:15 AM",
      "9:30 AM",
      "9:45 AM",
      "10:OO AM",
      "10:15 AM",
      "10:30 AM",
      "10:45 AM",
      "11:OO AM",
      "11:15 AM",
      "11:30 AM",
      "11:45 AM",
      "12:OO PM",
      "12:15 PM",
      "12:30 PM",
      "12:45 PM",
      "1:OO PM",
      "1:15 PM",
      "1:30 PM",
      "1:45 PM",
      "2:OO PM",
      "2:15 PM",
      "2:30 PM",
      "2:45 PM"
    ]
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4
  },
  legend: {
    position: "right",
    offsetY: 40
  },
  fill: {
    opacity: 1
  }
}

export const oneWeekPressureBarOptions: StackedColumnChartOptions = {
  series: [
    {
      name: "CH1-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60]
    },
    {
      name: 'CH1-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20]
    },
    {
      name: 'CH1-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15]
    },
    {
      name: 'CH1-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5,]
    },
    {
      name: 'CH1-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0,]
    },
    {
      name: "CH2-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60]
    },
    {
      name: 'CH2-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20]
    },
    {
      name: 'CH2-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15]
    },
    {
      name: 'CH2-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5,]
    },
    {
      name: 'CH2-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0,]
    },
    {
      name: "CH3-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60]
    },
    {
      name: 'CH3-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20]
    },
    {
      name: 'CH3-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15]
    },
    {
      name: 'CH3-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5,]
    },
    {
      name: 'CH3-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0,]
    },
    {
      name: "CH4-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60]
    },
    {
      name: 'CH4-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20]
    },
    {
      name: 'CH4-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15]
    },
    {
      name: 'CH4-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5,]
    },
    {
      name: 'CH4-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0,]
    },
    {
      name: "CH5-Zone 1",
      data: [10, 20, 30, 20, 40, 20, 60]
    },
    {
      name: 'CH5-Zone 2',
      data: [10, 10, 20, 20, 15, 20, 20]
    },
    {
      name: 'CH5-Zone 3',
      data: [20, 20, 15, 20, 15, 25, 15]
    },
    {
      name: 'CH5-Zone 4',
      data: [30, 30, 25, 20, 15, 25, 5,]
    },
    {
      name: 'CH5-Zone 5',
      data: [30, 20, 10, 20, 15, 10, 0,]
    }

  ],
  chart: {
    type: "bar",
    height: 450,
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: true
    },
    zoom: {
      enabled: true
    }
  },
  options: {
    colors: ['#0000FF', '#4000BF', '#800080', '#BF0040', '#FF0000']
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "25%"
    }
  },
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: [
      "01-09-2023",
      "02-09-2023",
      "03-09-2023",
      "04-09-2023",
      "05-09-2023",
      "06-09-2023",
      "07-09-2023"
    ]
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 4
  },
  legend: {
    position: "right",
    offsetY: 40
  },
  fill: {
    opacity: 1
  }
}
<div class="unallocated-wrapper" *ngIf="userRole != 'ROLE_SITE_MANAGER'">
    <div class="d-flex flex-row justify-content-start">
        <div class="col">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" name="checkbox" [(ngModel)]="includeDecommissioned"
                    (change)="includeDecommissionedDevice(includeDecommissioned)">
                <label class="form-check-label" for="flexCheckDefault">
                    Include Decommissioned
                </label>
            </div>
        </div>
    </div>
    <!--Unallocated Device List-->
    <div class="mt-3">
        <div>
            <table class="table table-striped table-bordered text-center">
                <caption>List of unallocated devices</caption>
                <thead>
                    <tr>
                        <!-- <th>ID</th> -->
                        <th>Device Id</th>
                        <th>Date Created</th>
                        <th>Status</th>
                        <th class="w-20">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let device of unallocatedDevices; let i = index">
                        <td>{{device.deviceId}}</td>
                        <td>{{device.dateCreated | date:'dd/MM/yyyy, hh:mm a'}}</td>
                        <td>{{device.isActive ? 'Active' : 'Inactive'}}</td>
                        <td *ngIf="userRole!='ROLE_SITE_MANAGER'">
                            <select *ngIf="device.isActive" class="form-select form-select-sm" #unallocatedDropdown
                                name="unallocatedAction" (change)="selectAction($event, device, i)">
                                <option value="">Select Action</option>
                                <option value="edit">Edit</option>
                                <option value="allocate">Allocate</option>
                                <option value="decommission">Decomission</option>
                            </select>
                            <span *ngIf="!device.isActive">Device is decomissioned</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="paging">
        <button pButton pRipple type="button" style="float: right;" label="Add" icon="pi pi-plus" (click)="showModal()"
            class="login-button p-button-raised p-button-sm">
        </button>
        <pagination [totalItems]="totalItems" previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;"
            lastText="&raquo;" [maxSize]="10" [itemsPerPage]="10" [boundaryLinks]="true" [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)">
        </pagination>
    </div>
</div>
<!--Create/Edit Device Modal-->
<div class="modal fade" bsModal #addDeviceModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{modalTitle}}</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="addDeviceModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form #createDeviceForm="ngForm" (ngSubmit)="saveDevice()">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 m-auto">
                            <div class="row mb-3">
                                <label for="deviceId" class="col-sm-3 col-form-label text-end">Device Id</label>
                                <div class="col-sm-4">
                                    <input type="text" disabled class="form-control form-select-sm" id="deviceId"
                                        [(ngModel)]="deviceModel.deviceId" #deviceId="ngModel" name="deviceId">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-12">
                                    <form #configForm="ngForm" (ngSubmit)="setConfigValue(configForm)">
                                        <table class="table table-striped table-bordered text-center">
                                            <thead>
                                                <tr>
                                                    <th class="w-50">Key</th>
                                                    <th>Value</th>
                                                    <th class="col-md-2">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let setting of settings; let i=index">
                                                    <!-- Key -->
                                                    <td class="text-start">
                                                        {{setting.configKey}} {{setting.unit}}
                                                        <!-- <br>
                                                        EI: {{editIndex}} EC: {{isEditConfig}} CK: {{currentActiveKey}}
                                                        <br>
                                                        S.CK: {{setting.configKey}} MO: {{deviceConfigModel[setting.configKey]}}
                                                        <br>
                                                        S.V: {{setting.value}} SD: {{showDropdown}} -->
                                                    </td>
                                                    <!-- Value -->
                                                    <td [ngClass]="isEditConfig ? 'text-start': ''">
                                                        <span
                                                            *ngIf="editIndex != i && setting.configKey!='eventThreshold'">
                                                            {{setting.value}}
                                                        </span>
                                                        <span
                                                            *ngIf="editIndex != i && setting.configKey=='eventThreshold'">
                                                            {{setting.value+'%'}} {{eventThreshold+'mv'}}
                                                        </span>
                                                        <!-- Editor -->
                                                        <div
                                                            *ngIf="isEditConfig && currentActiveKey==setting.configKey">
                                                            <!--Inline table input-->
                                                            <input *ngIf="!showDropdown"
                                                                class="table-input form-control form-control-sm w-50"
                                                                required type="number" [min]="setting.min"
                                                                [max]="setting.max" name="value"
                                                                [(ngModel)]="deviceConfigModel[setting.configKey]"
                                                                #value="ngModel">

                                                            <!-- SD card boolean -->
                                                            <select
                                                                *ngIf="currentActiveKey=='sdCardWriteBoolean' && showDropdown"
                                                                class="form-select form-select-sm w-50"
                                                                style="display: inline;" name="value"
                                                                [(ngModel)]="deviceConfigModel[setting.configKey]"
                                                                #value="ngModel">
                                                                <option value="true" selected>True</option>
                                                                <option value="false">False</option>
                                                            </select>

                                                            <!-- Shin unit boolean -->
                                                            <select
                                                                *ngIf="currentActiveKey=='shinUnitAttachedBoolean' && showDropdown"
                                                                class="form-select form-select-sm w-50"
                                                                style="display: inline;" name="value"
                                                                [(ngModel)]="deviceConfigModel[setting.configKey]"
                                                                #value="ngModel">
                                                                <option value="true">True</option>
                                                                <option value="false" selected>False</option>
                                                            </select>
                                                            <!-- Demo Mode Boolean -->
                                                            <select
                                                                *ngIf="currentActiveKey=='demoMode' && showDropdown"
                                                                class="form-select form-select-sm w-50"
                                                                style="display: inline;" name="value"
                                                                [(ngModel)]="deviceConfigModel[setting.configKey]"
                                                                #value="ngModel">
                                                                <option value="true">True</option>
                                                                <option value="false" selected>False</option>
                                                            </select>
                                                            <!--Save Icon Button-->
                                                            <!-- [disabled]="configForm.invalid" -->
                                                            <button type="submit" class="btn btn-sm mx-3">
                                                                <i class="bi bi-check-circle"></i>
                                                            </button>
                                                            <span *ngIf="setting.configKey=='eventThreshold'">
                                                                {{eventThreshold}}
                                                            </span>
                                                        </div>
                                                        <!--For edit mode-->
                                                    </td>
                                                    <!-- Action -->
                                                    <td>
                                                        <button [disabled]="isEditConfig" type="button"
                                                            class="btn btn-sm mx-3">
                                                            <i class="bi bi-pencil-square cursor-pointer"
                                                                (click)="editConfig(setting.configKey, i)">
                                                            </i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="error-block text-center">
                                            <span *ngIf="invalidConfig">
                                                <!-- This is an error message -->
                                                {{errorMessage}}
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                        class="login-button p-button-raised p-button-secondary p-button-sm">
                    </button>
                    <button pButton pRipple type="submit" label="Save" icon="pi pi-check"
                        class="login-button p-button-raised p-button-sm">
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Allocated Device Modal -->
<div class="modal fade" bsModal #allocateModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Allocate Device</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="allocateModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <label for="site" class="col-sm-5 col-form-label">Allocate Site</label>
                    <div class="col-sm-7">
                        <select class="form-control form-select form-select-sm custom-form-control" name="site"
                            [(ngModel)]="siteId" #site="ngModel" (change)="selectSite($event)">
                            <option value="0" [selected]="true">Select Site</option>
                            <option *ngFor="let site of siteList" value="{{site.id}}">
                                {{site.name}}
                            </option>
                        </select>
                        <div class="error-block" *ngIf="showError">
                            <span>(63a1.1)Site name is mandatory</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Save" icon="pi pi-check" (click)="allocateDevice()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Decommission Device Modal -->
<div class="modal fade" bsModal #decommissionModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Decommission Device</h4>
                <button type="button" class="btn close pull-right" aria-label="Close"
                    (click)="decommissionModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to decommission this device ?</p>
                <h6>Warning:</h6>
                <p>
                    If this device is decomissioned, it will no longer be able to be synced,
                    and will need re-provisioning after being assigned to another wearer.
                </p>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Save" icon="pi pi-check" (click)="decommissionDevice()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CreateSiteService } from 'src/app/iot/services/create-site.service'


@Component({
  selector: 'app-hospital-header',
  templateUrl: './hospital-header.component.html',
  styleUrls: ['./hospital-header.component.scss']
})
export class HospitalHeaderComponent implements OnInit {

  siteStatus: boolean = true
  sitePassed: boolean = false

  mobileStatus: boolean = false
  mobilePresent: boolean = false

  currentActiveTab: string = 'site-details'
  currentButtonStatus: string = 'save'
  newHospitalAddedSubscription: any

  title: string = 'Site Details'
  siteId: any = ''
  isEditMode: boolean = false
  siteData: any = {}

  constructor(private createSiteService: CreateSiteService,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.siteId = this.activatedRoute.snapshot.queryParamMap.get('id')
    if (this.siteId) {
      this.isEditMode = true
    } else {
      this.isEditMode = false
    }

    this.newHospitalAddedSubscription = this.createSiteService.hospitalAdded$.subscribe(newHospitalData => {
      this.currentActiveTab = newHospitalData.activeTabName;
      this.currentButtonStatus = newHospitalData.buttonStatus;
      // this.currentUpdatedData = newSiteData.updatedData;
      this.prepareCurrentActiveTab(this.currentActiveTab, this.currentButtonStatus);
    })    
  }

  prepareCurrentActiveTab(tabName: string, btnStatus: string) {
    if (tabName == 'site-details' && btnStatus == 'saveNext') {
      this.sitePassed = true
      this.currentActiveTab = 'mobiles'
      this.mobileStatus = true
      this.title = 'Mobile Devices'
    } else if (tabName == 'mobiles' && btnStatus == 'saveNext') {

    }
  }

  currentTab(tabName: string) {
    this.currentActiveTab = tabName
    if (tabName == 'site-details') {
      this.title = 'Site Details'
    } else if (tabName == 'mobiles') {
      this.title = 'Mobile Devices'
    }
  }

}

export class DeviceConfig {
    id: number
    bootOnThreshold: number
    bootOffThreshold: number
    bootWakeUpThreshold: number
    bootWakeUpDuration: number
    bootLowPowerThreshold: number
    bootLowPowerDuration: number
    bootLowPowerHysteresis: number
    timeSliceInterval: number
    zone1MaxPercentage: number
    zone2MaxPercentage: number
    zone3MaxPercentage: number
    zone4MaxPercentage: number
    // maxPressureLoad: number
    //
    channel1MaxPressureLoad: number
    channel2MaxPressureLoad: number
    channel3MaxPressureLoad: number
    channel4MaxPressureLoad: number
    channel5MaxPressureLoad: number
    //
    bleConnectionTimeout: number
    bleAdvertisingInterval: number
    sdCardWriteBoolean: boolean
    shinUnitAttachedBoolean: boolean
    demoMode: boolean
    constructor() {
        this.id = 0
        this.bootOnThreshold = 5
        this.bootOffThreshold = 5
        this.bootWakeUpThreshold = 10
        this.bootWakeUpDuration = 2
        this.bootLowPowerThreshold = 2
        this.bootLowPowerDuration = 30000
        this.bootLowPowerHysteresis = 0
        this.timeSliceInterval = 15
        this.zone1MaxPercentage = 20
        this.zone2MaxPercentage = 40
        this.zone3MaxPercentage = 60
        this.zone4MaxPercentage = 80
        // this.maxPressureLoad = 710
        // New Settings
        this.channel1MaxPressureLoad = 710
        this.channel2MaxPressureLoad = 710
        this.channel3MaxPressureLoad = 710
        this.channel4MaxPressureLoad = 710
        this.channel5MaxPressureLoad = 710
        //
        this.bleConnectionTimeout = 30
        this.bleAdvertisingInterval = 3000
        this.sdCardWriteBoolean = false
        this.shinUnitAttachedBoolean = true
        this.demoMode = false
    }

    static createConfigMap() {
        const configMap = new Map()
        configMap.set('bootOnThreshold', { unit: '(%)', min: 0, max: 100 })
        configMap.set('bootOffThreshold', { unit: '(%)', min: 0, max: 100 })
        configMap.set('bootWakeUpThreshold', { unit: '(Each unit represents 31.25mg)', min: 1, max: 255 })
        configMap.set('bootWakeUpDuration', { unit: '(Each unit represents 40ms)', min: 1, max: 8 })
        configMap.set('bootLowPowerThreshold', { unit: '(Each unit represents 8mg)', min: 1, max: 255 })
        configMap.set('bootLowPowerDuration', { unit: '(Each unit represents 10ms)', min: 1, max: 65535 })
        configMap.set('bootLowPowerHysteresis', { unit: '(0 = 0mg, 1 = 24mg, 2 = 48mg)', min: 0, max: 3 })
        configMap.set('timeSliceInterval', { unit: '(minutes)', min: 5, max: 15 })
        configMap.set('zone1MaxPercentage', { unit: '(%)', min: 0, max: 100 })
        configMap.set('zone2MaxPercentage', { unit: '(%)', min: 0, max: 100 })
        configMap.set('zone3MaxPercentage', { unit: '(%)', min: 0, max: 100 })
        configMap.set('zone4MaxPercentage', { unit: '(%)', min: 0, max: 100 })
        // configMap.set('maxPressureLoad', { unit: '(mV)', min: 0, max: 2475 })
        //
        configMap.set('channel1MaxPressureLoad', { unit: '(mV)', min: 0, max: 2475 })
        configMap.set('channel2MaxPressureLoad', { unit: '(mV)', min: 0, max: 2475 })
        configMap.set('channel3MaxPressureLoad', { unit: '(mV)', min: 0, max: 2475 })
        configMap.set('channel4MaxPressureLoad', { unit: '(mV)', min: 0, max: 2475 })
        configMap.set('channel5MaxPressureLoad', { unit: '(mV)', min: 0, max: 2475 })
        //
        configMap.set('bleConnectionTimeout', { unit: '(s)', min: 1, max: 60 })
        configMap.set('bleAdvertisingInterval', { unit: '(ms)', min: 10, max: 10000 })
        configMap.set('sdCardWriteBoolean', { unit: '(0 = off, 1 = on)', min: 0, max: 1 })
        configMap.set('shinUnitAttachedBoolean', { unit: '(0 = off, 1 = on)', min: 0, max: 1 })
        configMap.set('demoMode', { unit: '(0 = off, 1 = on)', min: 0, max: 1 })
        // console.log('config Map: ', configMap)
        return configMap
    }
}
import { Component } from '@angular/core';

@Component({
  selector: 'app-create-hospitals',
  templateUrl: './create-hospitals.component.html',
  styleUrls: ['./create-hospitals.component.scss']
})
export class CreateHospitalsComponent {

}

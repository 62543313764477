import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ModalDirective } from 'ngx-bootstrap/modal'
import { ActivityService } from 'src/app/iot/services/activity.service'
import { SiteService } from 'src/app/iot/services/site.service'
import * as _ from 'lodash'
import { ToastrService } from 'ngx-toastr'
import { WearerService } from 'src/app/iot/services/wearer.service'
import { LoaderService } from 'src/app/iot/services/loader.service'
import { LOADER_CONFIG } from 'src/app/shared/global-constants'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnDestroy {
  totalItems: number = 0
  pageIndex: number = 1
  pageSize: number = 10
  currentPage: number = 1
  allChecked: boolean = false
  openChecked: boolean = false
  pendingChecked: boolean = false
  closeChecked: boolean = false
  userId!: string
  userRole!: string
  siteId: string = '0'
  wearerId: string = '0'
  alertType: string = ''
  filterData: any = {}
  status: Array<string> = []
  siteList: Array<any> = []
  wearerList: Array<any> = []
  isFilterApplied: boolean = false
  alertList: Array<any> = []
  alertId!: number 
  alertNotes: Array<any> = []
  totalAlertNotes: number = 0
  currentNote: number = 1
  alertData: any = {}
  alertNoteObj: any = {}
  prevoiusNotes: number = 0
  isAlreadyClosed: boolean = false
  addNote: boolean = false
  noteText: string = ''
  loading: boolean = false
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG

  @ViewChild('alertModal', {static: false}) alertModal!: ModalDirective

  constructor(private siteService: SiteService,
    private wearerService: WearerService,
    private loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService, 
    private activityService: ActivityService) {
    this.userId = localStorage.getItem('user-id')!
    this.filterData = {
      userId: this.userId,
      pageIndex: 1,
      pageSize: 10,
      status: 'all'
    }

    this.alertData.alertStatus = ''
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })
  }

  ngOnInit(): void {
      this.userRole = localStorage.getItem('user-role')!
      this.allChecked = true
      if (this.userRole != 'ROLE_SITE_MANAGER') {
        this.getAssociatedSites()
      }
      let siteId = ''
      let alertType = ''
      let wearerId = ''
      this.activatedRoute.params.subscribe((params: any) => {
        siteId = params.siteId
        wearerId = params.wearerId
        alertType = params.alertType
      });
      if (siteId && wearerId && alertType) {
        this.siteId = siteId
        this.getSiteWearers(parseInt(this.userId), parseInt(siteId))
        setTimeout(() => {
          this.alertType = alertType
          this.wearerId = wearerId
          this.filterData.siteId = siteId
          this.filterData.wearerId = wearerId
          this.filterData.alertType = alertType 
          this.filterAlerts(this.filterData)
          this.isFilterApplied = true;
        }, 800);
        return;
      }
      this.getAllAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
      
  }

  getAssociatedSites() {
    this.siteService.getAllSites(parseInt(this.userId)).subscribe({
      next: (response: any) => {
        this.siteList = response.DATA
      },
      error: (error: any) => {

      }
    })
  }

  getSiteWearers(userId: number, siteId: number) {
    this.wearerService.getAllWearersBySite(siteId).subscribe({
      next: (response: any) => {
        this.wearerList = response.DATA
      },
      error: (error: any) => {

      }
    })
  }

  getAllAlerts(userId: number, pageIndex: number, pageSize: number) {
    this.activityService.getAlertsData(userId, pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.alertList = response.DATA
        this.totalItems = response.total
      },
      error: (error: any) => {

      }
    })
  }

  filterAlerts(filterData: any) {
    this.activityService.filterAlertsData(filterData).subscribe({
      next: (response: any) => {
        this.alertList = response.DATA
        this.totalItems = response.total
      },
      error: (error: any) => {}
    })
  }

  getAlertDetails(userId: number, alertId: number) {
    this.activityService.getAlertById(userId, alertId).subscribe({
      next: (response: any) => {
        this.alertData = response.AlertDetails
        this.alertNotes = this.alertData.alertNotes
        this.prevoiusNotes = this.alertNotes.length
        this.totalAlertNotes = this.alertNotes.length
        this.isAlreadyClosed = (this.alertData.alertStatus == 'CLOSED') ? true : false;
        this.alertNoteObj = this.alertNotes[0]
        this.alertModal.show()
      },
      error: (error: any) => {

      }
    })
  }

  showModal(event: any, alert: any, i: any) {
    this.alertId = parseInt(alert.id)
    this.getAlertDetails(parseInt(this.userId), this.alertId)
    // this.alertModal.show()
  }

  hideModal() {
    this.alertModal.hide()
  }

  onHidden() {
    // this.addNote = false
    // this.currentNote = 1
    // this.totalAlertNotes = 0
  }

  pageChanged(event: any) {
    if(this.isFilterApplied) {
      this.filterData.pageIndex = event.page
      this.filterAlerts(this.filterData)
    } else {
      this.pageIndex = event.page
      this.getAllAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
    }
  }

  checkStatus(event: any) {
    // this.isFilterApplied = true
    
    let value = event.target.value;
    let checked = event.target.checked
    if (checked) {
      this.isFilterApplied = true
      if (value == 'all') {
        this.filterData.status = value
        this.openChecked = false
        this.pendingChecked = false
        this.closeChecked = false
        if (this.siteId != '0') {
          this.filterData.siteId = this.siteId
        }
        if (this.alertType != '') {
          this.filterData.alertType = this.alertType
        }
        this.filterAlerts(this.filterData)
        return;
      } else {
        this.allChecked = false
        this.status.push(value)
      }
    } else {
      this.isFilterApplied = false
      if (value == 'all') {
        this.getAllAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
      } else {
        if (this.status.length > 0) {
          for(let i = 0; i < this.status.length; i++){
            if (this.status[i] == value) {
              this.status.splice(i, 1)
            }
          }
        }  
      }
    }
    if (this.status.length > 1) {
      this.filterData.status = this.status.join(',')
      this.filterAlerts(this.filterData)
    }else if (this.status.length == 1) {
      this.filterData.status = this.status[0]
      this.filterAlerts(this.filterData)
    } else if (this.status.length == 0) {
      
      this.filterData.status = 'all'
      if (this.filterData.siteId || this.filterData.alertType || this.filterData.status) {
        this.filterAlerts(this.filterData)
      } else {
        this.getAllAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
      }
    }
  }
  
  addNewAlertNote() {
    this.addNote = true
  }

  discardAlertNote() {
    this.addNote = false
    this.noteText = ''
  }

  saveAlertNote() {
    let requestData = {
      'note': this.noteText,
      'alertId': this.alertData.id,
      'userId': this.userId
    }
    if (this.noteText == '') {
      this.toastr.error('Alert note can not be blank')
      // this.loading = false
      return;
    }

    this.activityService.addAlertNote(requestData).subscribe({
      next: (resposne: any) => {
        this.alertNotes.push(resposne.DATA)
        this.alertNoteObj = this.alertNotes[0]
        this.totalAlertNotes = this.alertNotes.length
        this.addNote = false
        this.noteText = '';
        if (this.isFilterApplied) {
          this.filterAlerts(this.filterData)
        } else {
          this.getAllAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
        }
      },
      error: (error: any) => {

      }
    })



  }

  changeNote(event: any) {
    this.currentNote = event.page
    this.alertNoteObj =  this.alertNotes[parseInt(event.page) - 1]
  }

  editAlert() {
    this.totalAlertNotes = this.alertData.alertNotes.length
    let status = this.alertData.alertStatus
    if (status == '') {
      if (this.totalAlertNotes == this.prevoiusNotes) {
        this.toastr.error('Please select status')
        return
      }
    }
    if (status != 'CLOSED') {

    }
    if (status == 'CLOSED' && !this.isAlreadyClosed) {
      if (this.totalAlertNotes == this.prevoiusNotes) {
        this.toastr.error('Please add an alert note')
        // this.loading = false
        return
      }
    }

    this.activityService.editAlert(this.alertId, this.alertData).subscribe({
      next: (response: any) => {
        this.alertModal.hide()
        if (this.isFilterApplied) {
          this.filterAlerts(this.filterData)
        } else {
          this.getAllAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
        }
      },
      error: (error: any) => {

      }
    })
  }

  changeSite(event: any) {
    this.siteId = event.target.value
    this.filterData.siteId = this.siteId
    this.isFilterApplied = true
    
    if (this.currentPage != 1) {
      this.currentPage = 1
      this.filterData.pageIndex = 1
    }
    
    this.getSiteWearers(parseInt(this.userId), parseInt(this.siteId))
    this.filterAlerts(this.filterData)
  }

  changeWearer(event: any) {
    this.wearerId = event.target.value
    this.filterData.wearerId = this.wearerId
    this.isFilterApplied = true
    
    if (this.currentPage != 1) {
      this.currentPage = 1
      this.filterData.pageIndex = 1
    }
    

    this.filterAlerts(this.filterData)
  }

  changeAlertType(event: any) {
    let value = event.target.value
    if (value != 'all') {
      this.filterData.alertType = value
    } else {
      this.filterData.alertType = 'all'
    }
    this.isFilterApplied = true
    if (this.currentPage != 1) {
      this.currentPage = 1
      this.filterData.pageIndex = 1
    }
    this.filterAlerts(this.filterData)
  }

  clearFilters() {
    this.filterData = {
      userId: this.userId,
      pageIndex: 1,
      pageSize: 10,
      status: 'all'
    }
    this.pageIndex = 1
    this.allChecked = false
    this.openChecked = false
    this.pendingChecked = false
    this.closeChecked = false
    this.siteId = '0'
    this.wearerId = '0'
    this.alertType = ''
    this.status = []
    this.isFilterApplied = false
    this.router.navigate(['iot/activity/alerts'])
    this.getAllAlerts(parseInt(this.userId), this.pageIndex, this.pageSize);
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }
}

<!--Main Wrapper-->
<div class="container card-wrapper">
    
    <!--Loader-->
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    
    <!--Card-->
    <div class="auth-card">
        
        <!--Card Header-->
        <div class="auth-card-header">
            <img src="../../../assets/images/Compedica_Dark_Tagline.svg" alt="">
        </div>

        <!--Form Container-->
        <div class="auth-form-container">
            <h5>Welcome</h5>
            <p class="">Please login to access your account</p>
            
            <!--Form-->
            <form #loginForm="ngForm" (ngSubmit)="loginUser(loginForm)">
                <div class="mb-5">
                    <input autocomplete="off" 
                        placeholder="Email"
                        type="text" 
                        id="email"
                        class="p-inputtext-sm w-100" 
                        name="loginEmail"
                        #loginEmail="ngModel" 
                        [(ngModel)]="loginObject.email"
                        pattern="[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                        pInputText 
                        required>
                    <div class="error-block" *ngIf="loginEmail.invalid && (loginEmail.dirty || loginEmail.touched)">
                        <span *ngIf="loginEmail.errors?.['required']">Email is required</span>
                        <span *ngIf="!loginEmail.errors?.['required'] && loginEmail.errors?.['pattern']" [ngClass]="{}">Email is invalid</span>
                    </div>
                </div>

                <div class="mb-3">
                    <input autocomplete="new-password" 
                        placeholder="Password"
                        type="password" 
                        id="password" 
                        class="p-inputtext-sm w-100"
                        name="loginPassword"
                        #loginPassword="ngModel"
                        [(ngModel)]="loginObject.password"
                        pInputText 
                        required>
                    <div class="error-block" *ngIf="loginPassword.invalid && (loginPassword.dirty || loginPassword.touched)">
                        <span *ngIf="loginPassword.errors?.['required']">Password is required</span>
                    </div> 
                </div>

                <div class="mb-3 forgot-password">
                    <a [routerLink]="['/forgot-password']">Forgot Password ?</a>
                    <span style="float: right;">Stage v1.0</span>
                </div>

                <!-- <mat-select-country appearance="outline" 
                    label="Country"
                    (onCountrySelected)="onCountrySelected($event)">
                </mat-select-country> -->

                <button pButton pRipple type="submit" [disabled]="!loginForm.valid" label="Sign In" class="submit-button p-button-raised p-button-sm w-50"></button>
            </form>
        </div>
    </div>
</div>




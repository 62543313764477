import { TemperatureBarChartOptions, TemperatureLineChartOptions } from '../chart-options'




export const temperatureBarChartOptions: TemperatureBarChartOptions = {
    series: [
        {
            name: "Wearer 1",
            data: [15, 18, 25, 18, 11, 22, 17, 29, 23, 33, 30, 21, 15, 18, 25, 18, 11, 22, 17, 29, 23, 33, 30, 21, 15, 18, 25, 18, 11, 22, 17, 29, 23, 33, 30, 21]
        }
    ],
    chart: {
        type: "bar",
        height: 450
    },
    options: {
        colors: ['#0000FF'],

    },
    legend: {
        show: true
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: "45%",
            // endingShape: "rounded"
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
    },
    xaxis: {
        categories: [
            "6:OO AM",
            "6:15 AM",
            "6:3O AM",
            "6:45 AM",
            "7:OO AM",
            "7:15 AM",
            "7:3O AM",
            "7:45 AM",
            "8:OO AM",
            "8:15 AM",
            "8:30 AM",
            "8:45 AM",
            "9:OO AM",
            "9:15 AM",
            "9:30 AM",
            "9:45 AM",
            "10:OO AM",
            "10:15 AM",
            "10:30 AM",
            "10:45 AM",
            "11:OO AM",
            "11:15 AM",
            "11:30 AM",
            "11:45 AM",
            "12:OO PM",
            "12:15 PM",
            "12:30 PM",
            "12:45 PM",
            "1:OO PM",
            "1:15 PM",
            "1:30 PM",
            "1:45 PM",
            "2:OO PM",
            "2:15 PM",
            "2:30 PM",
            "2:45 PM"
        ]
    },
    yaxis: {
        title: {
            text: "Temperature(\u00B0C)"
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + "\u00B0C";
            }
        }
    }

}

export const oneWeekTemperatureBarOptions: TemperatureBarChartOptions = {
    series: [
        {
            name: "Wearer 1 Min. Temp",
            data: [15, 18, 25, 18, 11, 22, 17]
        },
        {
            name: "Wearer 1 Max. Temp",
            data: [25, 28, 35, 28, 21, 32, 27]
        }
    ],
    chart: {
        type: "bar",
        height: 450
    },
    options: {
        colors: ['#0000FF', '#FF0000']
    },
    legend: {
        show: true
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: "45%",
            // endingShape: "rounded"
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
    },
    xaxis: {
        categories: [
            "01-09-2023",
            "02-09-2023",
            "03-09-2023",
            "04-09-2023",
            "05-09-2023",
            "06-09-2023",
            "07-09-2023",
        ]
    },
    yaxis: {
        title: {
            text: "Temperature(\u00B0C)"
        }
    },
    fill: {
        opacity: 1
    },
    tooltip: {
        y: {
            formatter: function (val) {
                return val + "\u00B0C";
            }
        }
    }
}

export const temperatureLineChartOptions: TemperatureLineChartOptions = {
    series: [
        {
            name: "Wearer 1",
            data: [15, 18, 25, 18, 11, 22, 17, 29, 23, 33, 30, 21, 15, 18, 25, 18, 11, 22, 17, 29, 23, 33, 30, 21, 15, 18, 25, 18, 11, 22, 17, 29, 23, 33, 30, 21]
        }
    ],
    chart: {
        height: 350,
        type: "line",
        zoom: {
            enabled: false
        }
    },
    options: {
        colors: ['#0000FF']
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: "smooth",
        width: 3
    },
    title: {
        text: "",
        align: "left"
    },
    grid: {
        row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
        }
    },
    xaxis: {
        categories: [
            "6:OO AM",
            "6:15 AM",
            "6:3O AM",
            "6:45 AM",
            "7:OO AM",
            "7:15 AM",
            "7:3O AM",
            "7:45 AM",
            "8:OO AM",
            "8:15 AM",
            "8:30 AM",
            "8:45 AM",
            "9:OO AM",
            "9:15 AM",
            "9:30 AM",
            "9:45 AM",
            "10:OO AM",
            "10:15 AM",
            "10:30 AM",
            "10:45 AM",
            "11:OO AM",
            "11:15 AM",
            "11:30 AM",
            "11:45 AM",
            "12:OO PM",
            "12:15 PM",
            "12:30 PM",
            "12:45 PM",
            "1:OO PM",
            "1:15 PM",
            "1:30 PM",
            "1:45 PM",
            "2:OO PM",
            "2:15 PM",
            "2:30 PM",
            "2:45 PM"
        ]
    }
}

export const oneWeekTemperatureLineOptions: TemperatureLineChartOptions = {
    series: [
        {
            name: "Wearer 1 Min. Temp",
            data: [15, 18, 25, 18, 11, 22, 17]
        },
        {
            name: "Wearer 1 Max. Temp",
            data: [25, 28, 35, 28, 21, 32, 27]
        },
    ],
    chart: {
        height: 350,
        type: "line",
        zoom: {
            enabled: false
        }
    },
    options: {
        colors: ['#0000FF', '#FF0000']
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: "smooth",
        width: 3
    },
    title: {
        text: "",
        align: "left"
    },
    grid: {
        row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
        }
    },
    xaxis: {
        categories: [
            "01-09-2023",
            "02-09-2023",
            "03-09-2023",
            "04-09-2023",
            "05-09-2023",
            "06-09-2023",
            "07-09-2023",
        ]
    }
}
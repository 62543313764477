<!--Header-->
<app-header></app-header>
<!--Common Wrapper-->
<div class="container" style="margin-bottom: 50px; margin-top: 118px;">
    <!--Breadcrumbs Wrapper-->
    <!-- <div class="breadcrumb-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Library</a></li>
              <li class="breadcrumb-item active" aria-current="page">Data</li>
            </ol>
          </nav>
    </div> -->
    <router-outlet></router-outlet>
</div>
<!--Footer-->
<app-footer></app-footer>

import { Component, OnInit, OnDestroy } from '@angular/core'
import { LOADER_CONFIG, alertTypes} from '../../../shared/global-constants'
import { ActivityService } from '../../services/activity.service'
import { Router } from '@angular/router'
import { Subscription, interval } from 'rxjs'
import { LoaderService } from '../../services/loader.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  loading: boolean = false
  loaderConfig = LOADER_CONFIG
  alertList: Array<any> = alertTypes
  totalActivityAlerts: number = 100
  totalDeviceSyncAlerts: number = 50
  totalLowBatteryAlerts: number = 120
  totalFaultAlerts: number = 80
  currentActivityPage: number = 1
  currentDeviceSyncPage: number = 1
  currentLowBatteryPage: number = 1
  currentFaultPage: number = 1
  activityAlertList: Array<any> = []
  deviceSyncAlertList: Array<any> = []
  lowBatteryAlertList: Array<any> = []
  faultAlertList: Array<any> = []
  userId!: string
  pageIndex: number = 1
  pageSize: number = 10
  currentSection: string = 'Activity Alerts'
  loadingSubscription!: Subscription
  intervalSubscription!: Subscription

  constructor (private activityService: ActivityService,
    private router: Router, 
    private loaderService: LoaderService) {
      this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
        this.loading = isLoading
      })
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('user-id')!
    
    this.intervalSubscription = interval(1000*60*5).subscribe(() => {
      if(this.currentActivityPage != 1 || this.currentDeviceSyncPage != 1 || this.currentLowBatteryPage != 1 || this.currentFaultPage != 1) {
        if(this.currentSection == 'Activity Alerts' && this.currentActivityPage != 1) {
          this.activityPageChanged(this.currentActivityPage)
          this.getAllDeviceSyncAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.getAllLowBatteryAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.getAllFaultAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
        } else if(this.currentSection == 'Device Sync Alerts' && this.currentDeviceSyncPage != 1) {
          this.getAllActivityAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.deviceSyncPageChanged(this.currentDeviceSyncPage)
          this.getAllLowBatteryAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.getAllFaultAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
        } else if(this.currentSection == 'Low Battery Alerts' && this.currentLowBatteryPage != 1) {
          this.getAllActivityAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.getAllDeviceSyncAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.lowBatteryPageChanged(this.currentLowBatteryPage)
          this.getAllFaultAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
        } else if(this.currentSection == 'Faults Alerts' && this.currentFaultPage != 1) {
          this.getAllActivityAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.getAllDeviceSyncAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.getAllLowBatteryAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
          this.faultsPageChanged(this.currentFaultPage)
        }
      } else {
        if(this.currentActivityPage == 1) {
          this.getAllActivityAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
        }
        if(this.currentDeviceSyncPage == 1) {
          this.getAllDeviceSyncAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
        }
        if(this.currentLowBatteryPage == 1) {
          this.getAllLowBatteryAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
        }
        if(this.currentFaultPage == 1) {
          this.getAllFaultAlerts(parseInt(this.userId), this.pageIndex, this.pageSize) 
        }
      }
      
      
    })
    if(this.currentActivityPage == 1) {
      this.getAllActivityAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
    }
    if(this.currentDeviceSyncPage == 1) {
      this.getAllDeviceSyncAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
    }
    if(this.currentLowBatteryPage == 1) {
      this.getAllLowBatteryAlerts(parseInt(this.userId), this.pageIndex, this.pageSize)
    }
    if(this.currentFaultPage == 1) {
      this.getAllFaultAlerts(parseInt(this.userId), this.pageIndex, this.pageSize) 
    }
  }

  getAllActivityAlerts(userId: number, pageIndex: number, pageSize: number) {
    this.activityService.getAllActivityAlerts(userId, pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.activityAlertList = response.DATA
        this.totalActivityAlerts = response.total
        this.updateAlertCount(this.totalActivityAlerts, 'Activity Alerts')
        setTimeout(() => {
          this.currentActivityPage = pageIndex
        }, 5)
      },
      error: () => {}
    })
  }

  getAllDeviceSyncAlerts(userId: number, pageIndex: number, pageSize: number) {
    this.activityService.getAllDeviceSyncAlerts(userId, pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.deviceSyncAlertList = response.DATA
        this.totalDeviceSyncAlerts = response.total
        this.updateAlertCount(this.totalDeviceSyncAlerts, 'Device Sync Alerts')
        setTimeout(() => {
          this.currentDeviceSyncPage = pageIndex
        }, 5)
      },
      error: () => {}
    })
  }

  getAllLowBatteryAlerts(userId: number, pageIndex: number, pageSize: number) {
    this.activityService.getAllLowBatteryAlerts(userId, pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.lowBatteryAlertList = response.DATA
        this.totalLowBatteryAlerts = response.total
        this.updateAlertCount(this.totalLowBatteryAlerts, 'Low Battery Alerts')
        setTimeout(() => {
          this.currentLowBatteryPage = pageIndex
        }, 5)
      },
      error: () => {}
    })
  }

  getAllFaultAlerts(userId: number, pageIndex: number, pageSize: number) {
    this.activityService.getAllFaultAlerts(userId, pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.faultAlertList = response.DATA
        this.totalFaultAlerts = response.total
        this.updateAlertCount(this.totalFaultAlerts, 'Fault Alerts')
        setTimeout(() => {
          this.currentFaultPage = pageIndex
        }, 5)
      },
      error: () => {}
    })
  } 
  
  activityPageChanged(event: any) {
    this.currentActivityPage = event
    this.getAllActivityAlerts(parseInt(this.userId), event, this.pageSize)
  }
  
  deviceSyncPageChanged(event: any) {
    this.currentDeviceSyncPage = event
    this.getAllDeviceSyncAlerts(parseInt(this.userId), event, this.pageSize)
  }
  
  lowBatteryPageChanged(event: any) {
    this.currentLowBatteryPage = event
    this.getAllLowBatteryAlerts(parseInt(this.userId), event, this.pageSize)
  }
  
  faultsPageChanged(event: any) {
    this.currentFaultPage = event
    this.getAllFaultAlerts(parseInt(this.userId), event, this.pageSize)
  }

  accordionClick(event: any, currentSection: string) {
    event.stopPropagation();
    this.currentSection = currentSection
  }

  navigateToAlerts(alertData: any) {
    const navigationExtras = {
      queryParams: {
        siteId: alertData.siteId,
        wearerId: alertData.wearerId,
        alertType: alertData.alertType
      }
    }
    // this.router.navigate(['iot/activity/alerts'], navigationExtras);
    this.router.navigate(['iot/activity/alerts', {siteId: alertData.siteId, wearerId: alertData.wearerId, alertType: alertData.alertType}])
  }

  updateAlertCount(alertCount: number, alertType: string) {
    for (let index = 0; index < alertTypes.length; index++) {
      if (alertType == alertTypes[index]['type']) {
        alertTypes[index]['count'] = alertCount
      }
    }
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
    this.intervalSubscription.unsubscribe()
  }

}

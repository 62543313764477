import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'iot-app';

  constructor(private primeNgConfig: PrimeNGConfig) {

  }

  ngOnInit(): void {
    this.primeNgConfig.ripple = true
    this.getTimeZone()
  }

  getTimeZone() {
    const date = new Date()
    const timezoneOffsetInMinutes = date.getTimezoneOffset();
    const timezone = this.formatTimezoneOffset(timezoneOffsetInMinutes);
    
    const timezoneName = Intl.DateTimeFormat(undefined, { timeZoneName: 'long' }).resolvedOptions().timeZone;
    // console.log("timezone: ", timezone)
    // console.log("timezoneName: ", timezoneName)
    localStorage.setItem('timezone', timezone)
    localStorage.setItem('timezoneName', timezoneName)
    return timezone
  }

  private formatTimezoneOffset(offset: number): string {
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const sign = offset < 0 ? '+' : '-';
    return `${sign}${this.padZero(hours)}:${this.padZero(minutes)}`;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}

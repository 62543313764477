import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators'
import { DatePipe } from '@angular/common'
import { ActivityApiBuilderService } from "src/app/shared/api-builders/activity-api-builder.service"

@Injectable()
export class ActivityService {
    timeZone: string = localStorage.getItem('timezone')!
    datePipe: DatePipe = new DatePipe('en-US', this.timeZone)
    constructor(private http: HttpClient,
        private activityApiBuilder: ActivityApiBuilderService) {

    }

    getWearerActivity(wearerId: number, pageIndex: number, pageSize: number) {
        const getWearerActivityUrl = this.activityApiBuilder.getWearerActivityUrl(wearerId, pageIndex, pageSize)
        return this.http.get(getWearerActivityUrl).pipe(map((response: any) => {
            let activityData = response.DATA
            activityData.forEach((activity: any) => {
                activity.average = parseFloat(activity.average.toFixed(2));

                let channelWeightsList = activity.channelWeights;
                channelWeightsList.forEach((channel: any) => {
                    let channelNumber = channel.channelNumber;
                    let percentage = parseFloat(channel.percentage.toFixed(2));
                    switch (channelNumber) {
                        case 1:
                            activity.channel1Percentage = percentage;
                            break;
                        case 2:
                            activity.channel2Percentage = percentage;
                            break;
                        case 3:
                            activity.channel3Percentage = percentage;
                            break;
                        case 4:
                            activity.channel4Percentage = percentage;
                            break;
                        case 5:
                            activity.channel5Percentage = percentage;
                            break;
                        default:
                            break;
                    }
                })
            })
            return response
        }))
    }

    getTimeSliceData(userId: number, pageIndex: number, pageSize: number) {
        const getTimeSliceDataUrl = this.activityApiBuilder.getTimeSliceDataUrl(userId, pageIndex, pageSize)
        return this.http.get(getTimeSliceDataUrl).pipe(map((response: any) => {
            let timeSliceData = this.augmentTimeSliceData(response.DATA)
            response.DATA = timeSliceData
            return response
        }))
    }

    filterTimeSliceData(filterData: any) {
        const filterTimeSliceDataUrl = this.activityApiBuilder.filterTimeSliceDataUrl(filterData)
        return this.http.get(filterTimeSliceDataUrl).pipe(map((response: any) => {
            let filteredTimeSliceData = this.augmentTimeSliceData(response.DATA)
            response.DATA = filteredTimeSliceData
            return response
        }))
    }

    getTimeSliceDataCSV(requestData: any) {
        const timeSliceDataCSVUrl = this.activityApiBuilder.getTimeSliceDataForCSVUrl(requestData)
        return this.http.get(timeSliceDataCSVUrl).pipe(map((response: any) => {
            let timeSliceData = response.DATA
            timeSliceData.forEach((item: any, index: number) => {
                // console.log('timesliceData -> forEach counter: ', index)
                item.channels.sort((a: any, b: any) => a.channelNumber - b.channelNumber)
                item.ch1zone1 = item.channels[0]['zoneOneCounter']
                item.ch1zone2 = item.channels[0]['zoneTwoCounter']
                item.ch1zone3 = item.channels[0]['zoneThreeCounter']
                item.ch1zone4 = item.channels[0]['zoneFourCounter']
                item.ch1zone5 = item.channels[0]['zoneFiveCounter']

                item.ch2zone1 = item.channels[1]['zoneOneCounter']
                item.ch2zone2 = item.channels[1]['zoneTwoCounter']
                item.ch2zone3 = item.channels[1]['zoneThreeCounter']
                item.ch2zone4 = item.channels[1]['zoneFourCounter']
                item.ch2zone5 = item.channels[1]['zoneFiveCounter']

                item.ch3zone1 = item.channels[2]['zoneOneCounter']
                item.ch3zone2 = item.channels[2]['zoneTwoCounter']
                item.ch3zone3 = item.channels[2]['zoneThreeCounter']
                item.ch3zone4 = item.channels[2]['zoneFourCounter']
                item.ch3zone5 = item.channels[2]['zoneFiveCounter']

                item.ch4zone1 = item.channels[3]['zoneOneCounter']
                item.ch4zone2 = item.channels[3]['zoneTwoCounter']
                item.ch4zone3 = item.channels[3]['zoneThreeCounter']
                item.ch4zone4 = item.channels[3]['zoneFourCounter']
                item.ch4zone5 = item.channels[3]['zoneFiveCounter']

                item.ch5zone1 = item.channels[4]['zoneOneCounter']
                item.ch5zone2 = item.channels[4]['zoneTwoCounter']
                item.ch5zone3 = item.channels[4]['zoneThreeCounter']
                item.ch5zone4 = item.channels[4]['zoneFourCounter']
                item.ch5zone5 = item.channels[4]['zoneFiveCounter']
            });
            let csvData: Array<any> = []
            for (let index = 0; index < timeSliceData.length; index++) {
                let csvDataObject: any = {}
                csvDataObject.id = timeSliceData[index]['id']
                csvDataObject.batterPercentage = timeSliceData[index]['batteryLevelInPercentage']
                csvDataObject.suBatteryPercentage = timeSliceData[index]['shinUnitBatteryLevel']
                csvDataObject.deviceId = timeSliceData[index]['deviceId']

                csvDataObject.ch1zone1 = timeSliceData[index]['ch1zone1']
                csvDataObject.ch1zone2 = timeSliceData[index]['ch1zone2']
                csvDataObject.ch1zone3 = timeSliceData[index]['ch1zone3']
                csvDataObject.ch1zone4 = timeSliceData[index]['ch1zone4']
                csvDataObject.ch1zone5 = timeSliceData[index]['ch1zone5']

                csvDataObject.ch2zone1 = timeSliceData[index]['ch2zone1']
                csvDataObject.ch2zone2 = timeSliceData[index]['ch2zone2']
                csvDataObject.ch2zone3 = timeSliceData[index]['ch2zone3']
                csvDataObject.ch2zone4 = timeSliceData[index]['ch2zone4']
                csvDataObject.ch2zone5 = timeSliceData[index]['ch2zone5']

                csvDataObject.ch3zone1 = timeSliceData[index]['ch3zone1']
                csvDataObject.ch3zone2 = timeSliceData[index]['ch3zone2']
                csvDataObject.ch3zone3 = timeSliceData[index]['ch3zone3']
                csvDataObject.ch3zone4 = timeSliceData[index]['ch3zone4']
                csvDataObject.ch3zone5 = timeSliceData[index]['ch3zone5']

                csvDataObject.ch4zone1 = timeSliceData[index]['ch4zone1']
                csvDataObject.ch4zone2 = timeSliceData[index]['ch4zone2']
                csvDataObject.ch4zone3 = timeSliceData[index]['ch4zone3']
                csvDataObject.ch4zone4 = timeSliceData[index]['ch4zone4']
                csvDataObject.ch4zone5 = timeSliceData[index]['ch4zone5']

                csvDataObject.ch5zone1 = timeSliceData[index]['ch5zone1']
                csvDataObject.ch5zone2 = timeSliceData[index]['ch5zone2']
                csvDataObject.ch5zone3 = timeSliceData[index]['ch5zone3']
                csvDataObject.ch5zone4 = timeSliceData[index]['ch5zone4']
                csvDataObject.ch5zone5 = timeSliceData[index]['ch5zone5']

                csvDataObject.stepCount = timeSliceData[index]['stepCount']
                csvDataObject.shinUnitOperationTime = timeSliceData[index]['shinUnitOperationTime']
                csvDataObject.temperature = timeSliceData[index]['temperature']
                csvDataObject.humidity = timeSliceData[index]['humidity']
                csvDataObject.siteId = timeSliceData[index]['site']['id']
                csvDataObject.siteName = timeSliceData[index]['site']['name']
                csvDataObject.wearerId = timeSliceData[index]['wearer']['id']
                csvDataObject.wearerName = `${timeSliceData[index]['wearer']['firstName']} ${timeSliceData[index]['wearer']['lastName']}`
                csvDataObject.timeSliceInterval = timeSliceData[index]['timeSliceDuration']
                let tsStart = timeSliceData[index]['timeSliceStart']
                let tsEnd = timeSliceData[index]['timeSliceEnd']
                let tz = localStorage.getItem('timezone')!
                // console.log('tz: ', tz)
                csvDataObject.timeSliceStart = this.datePipe.transform((tsStart*1000), 'dd/MM/yyyy, hh:mm a', tz)
                csvDataObject.timesliceEnd = this.datePipe.transform((tsEnd*1000), 'dd/MM/yyyy, hh:mm a', tz)
                // csvDataObject.timeSliceStart = timeSliceData[index]['timeSliceStart']
                // csvDataObject.timesliceEnd = timeSliceData[index]['timeSliceEnd']
                csvData.push(csvDataObject)
            }
            response.csvData = csvData
            // console.log('final response: ', response)
            return response
        }))
    }

    getAlertsData(userId: number, pageIndex: number, pageSize: number) {
        const getAlertsDataUrl = this.activityApiBuilder.getAlertsDataUrl(userId, pageIndex, pageSize)
        return this.http.get(getAlertsDataUrl).pipe(map((response: any) => {
            let alertList = response.DATA
            alertList.forEach((alert: any) => {
                if (alert.alertType === 'LOW_BATTERY') {
                    alert.alertRule = `${alert.alertRule} (${alert.batteryLocation})`
                    // if (alert.batteryLocation === 'Shin') {
                    // } else {

                    // }
                }
                
            });
            return response
        }))
    }

    filterAlertsData(filterData: any) {
        const filterAlertsUrl = this.activityApiBuilder.fiterAlertsDataUrl(filterData)
        return this.http.get(filterAlertsUrl).pipe(map((response: any) => {
            // let alertList = response.DATA
            response.DATA.forEach((alert: any) => {
                if (alert.alertType === 'LOW_BATTERY') {
                    alert.alertRule = `${alert.alertRule} (${alert.batteryLocation})`
                }
            });
            return response
        }))
    }

    getAlertById(userId: number, alertId: number) {
        const getAlertByIdUrl = this.activityApiBuilder.getAlertByIdUrl(userId, alertId)
        return this.http.get(getAlertByIdUrl)
    }

    addAlertNote(requestData: any) {
        const addAlertNoteUrl = this.activityApiBuilder.addAlertNoteUrl
        return this.http.post(addAlertNoteUrl, requestData)
    }

    editAlert(alertId: number, requestData: any) {
        const editAlertUrl = this.activityApiBuilder.editAlertUrl(alertId)
        return this.http.put(editAlertUrl, requestData)
    }

    augmentTimeSliceData(timeSliceData: Array<any>) {
        timeSliceData.forEach((timeSlice: any) => {
            timeSlice.average = parseFloat(timeSlice.average.toFixed(2));
            timeSlice.timeSliceStart = timeSlice.timeSliceStart * 1000
            timeSlice.timeSliceEnd = timeSlice.timeSliceEnd * 1000
            let channelWeightsList = timeSlice.channelWeights;
            channelWeightsList.forEach((channel: any) => {
                let channelNumber = channel.channelNumber;
                let percentage = parseFloat(channel.percentage.toFixed(2));
                switch (channelNumber) {
                    case 1:
                        timeSlice.channel1Percentage = percentage;
                        break;
                    case 2:
                        timeSlice.channel2Percentage = percentage;
                        break;
                    case 3:
                        timeSlice.channel3Percentage = percentage;
                        break;
                    case 4:
                        timeSlice.channel4Percentage = percentage;
                        break;
                    case 5:
                        timeSlice.channel5Percentage = percentage;
                        break;
                    default:
                        break;
                }
            })
        })
        // console.log('augmented data: ', timeSliceData)
        return timeSliceData
    }

    getAllActivityAlerts(userId: number, pageIndex: number, pageSize: number) {
        const getActivityAlertsUrl = this.activityApiBuilder.getActivityAlertsUrl(userId, pageIndex, pageSize)
        return this.http.get(getActivityAlertsUrl)
    }

    getAllDeviceSyncAlerts(userId: number, pageIndex: number, pageSize: number) {
        const getDeviceSyncAlertsUrl = this.activityApiBuilder.getDeviceSyncAlertsUrl(userId, pageIndex, pageSize)
        return this.http.get(getDeviceSyncAlertsUrl)
    }

    getAllLowBatteryAlerts(userId: number, pageIndex: number, pageSize: number) {
        const getLowBatteryAlertsUrl = this.activityApiBuilder.getLowBatteryAlertsUrl(userId, pageIndex, pageSize)
        return this.http.get(getLowBatteryAlertsUrl).pipe(map((response: any) => {
            let lowBatteryAlertList = response.DATA
            lowBatteryAlertList.forEach((alert: any) => {
                if (alert.batteryLocation != 'Shin') {
                    alert.batteryLocation = 'Boot'
                }
            });
            return response
        }))
    }

    getAllFaultAlerts(userId: number, pageIndex: number, pageSize: number) {
        const getFaultAlertsUrl = this.activityApiBuilder.getFaultAlertsUrl(userId, pageIndex, pageSize)
        return this.http.get(getFaultAlertsUrl)
    }
}
import { Injectable, QueryList, ElementRef } from "@angular/core";

@Injectable()
export class UtilService {
    constructor() { }

    resetDropdowns(queryList: QueryList<ElementRef>) {
        queryList.filter((item: ElementRef, index) => {
            if (item.nativeElement.value != '') {
                item.nativeElement.value = ""
                return true;
            } else {
                return false;
            }
        })
    }

    convertObjectToList(data: any) {

    }

}
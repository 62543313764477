<div class="manage-hospital-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="row">
        <!--Filter-->
        <div class="col-md-3" >
            <app-filters [filterType]="'sites'" *ngIf="currentUser !== 'ROLE_SITE_MANAGER'"></app-filters>
        </div>
        
        <!--Hospitals-->
        <div class="col-md-9" [ngClass]="currentUser == 'ROLE_SITE_MANAGER' ? 'col-md-12' : 'col-md-9'">
            <div class="table-wrapper">
                <table class="table table-striped table-bordered text-center">
                    <caption>List of sites</caption>
                    <thead>
                        <tr>
                            <th>Site ID</th>
                            <th>Site Name</th>
                            <th>Location</th>
                            <th>Time Zone</th>
                            <th>Create Date/Time</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let site of siteData">
                            <td>{{site.id}}</td>
                            <td>{{site.name}}</td>
                            <td>{{site.town}}, {{site.country}}</td>
                            <td>{{site.offSet}}</td>
                            <td>{{site.dateCreated | date:'dd/MM/yyyy, hh:mm a'}}</td>
                            <td>
                                <select #siteDropdown class="form-select form-select-sm" (change)="selectAction(site, $event)">
                                    <option value="" selected>Select action</option>
                                    <option value="edit">Edit</option>
                                    <option value="delete" *ngIf="currentUser != 'ROLE_SITE_MANAGER'">Delete</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="paging">
                    <pagination 
                        [totalItems]="totalItems" 
                        previousText="&lsaquo;" 
                        nextText="&rsaquo;" 
                        firstText="&laquo;"
                        lastText="&raquo;" 
                        [maxSize]="10" 
                        [itemsPerPage]="10" 
                        [boundaryLinks]="true"
                        [(ngModel)]="currentPage" 
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
                <!-- <app-filters></app-filters> -->
            </div>
        </div>
    </div>
</div>

<!-- Delete Site Modal -->
<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Site</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="deleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete this site ?</p>
                <h6>Warning:</h6>
                <p>
                    If site is deleted then it and it's associated wearers and devices will no 
                    longer be able to be used again, however existing timeslice and alerts data 
                    for the site will still be available.
                </p>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="deleteModal.hide()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" (click)="deleteSite(siteId)"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>

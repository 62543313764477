import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { DatePipe } from '@angular/common';
import { SiteService } from 'src/app/iot/services/site.service';
import { WearerService } from 'src/app/iot/services/wearer.service';
import { Subscription } from 'rxjs';
import { getFullYear } from 'ngx-bootstrap/chronos';
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Input() filterType: string = ''
  @Input() wearerType?: string = ''
  filterData: any = {}
  startDate: string = ''
  endDate: string = ''
  siteName: string = ''
  siteId: string = ''
  wearerName: string = ''
  wearerId: string = '0'
  dateDto: any = {}
  pipe = new DatePipe('en-US')
  searchClicked: boolean = false
  filterCleared: boolean = false
  userId: number
  userRole: string
  siteList: Array<any> = []
  wearerList: Array<any> = []
  wearerNameSubscription!: Subscription
  siteNameSubscription!: Subscription
  maxDate: Date = new Date()
  minEndDate!: Date
  startDateValue: any
  endDateValue: any

  constructor(private fitlerService: FilterService, 
    private siteService: SiteService, 
    private wearerService: WearerService) {
    this.userId = parseInt(localStorage.getItem('user-id')!)
    this.userRole = localStorage.getItem('role')!
    this.filterData.managerId = this.userId
    this.filterData.pageIndex = 1
    this.filterData.pageSize = 10
    this.filterData.fromPagination = false
    let currentYear = new Date().getFullYear()

    this.dateDto.startDateValue = new Date(currentYear, 0, 1)
    
    this.minEndDate = new Date(this.dateDto.startDateValue)
    // this.dateDto.endDateValue = this.pipe.transform(new Date(), 'dd-MM-yyyy')
    this.dateDto.endDateValue = new Date() 
  }

  ngOnInit(): void {
    this.wearerNameSubscription = this.fitlerService.currentSearch$.subscribe((value: string) => {
      this.wearerName = value
    })

    this.siteNameSubscription = this.fitlerService.currentSite$.subscribe((value: string) => {
      this.siteId = value
    })
    if (this.userRole != 'ROLE_SITE_MANAGER') {
      this.getSites(this.userId)
    } else {
      this.getSites(this.userId)
    }
  }

  getSites(userId: number) {
    this.siteService.getAllSites(userId).subscribe({
      next: (response: any) => {
        this.siteList = response.DATA
      },
      error: (error: any) => {

      }
    })
  } 

  getWearersBySite(userId: number, siteId: number) {
    this.wearerService.getAllWearersBySite(siteId).subscribe({
      next: (response: any) => {
        this.wearerList = response.DATA
      },
      error: (error: any) => {

      }
    })
  }

  selectSite(event: any) {
    let siteId = event.target.value
    this.filterData.siteId = siteId
    this.filterData.wearerId = '0'
    if (this.filterData.pageIndex != 1) {
      this.filterData.pageIndex = 1
    }
    this.getWearersBySite(this.userId, parseInt(siteId))
  }

  selectWearer(event: any) {
    let weareId = event.target.value
    this.filterData.wearerId = weareId
  }

  setFilterData() {
    this.searchClicked = true
    
    this.filterData.filterType = this.filterType
    
    this.filterData.startDate = this.pipe.transform(this.dateDto.startDateValue, 'yyyy-MM-dd')
    this.filterData.endDate = this.pipe.transform(this.dateDto.endDateValue, 'yyyy-MM-dd')
    this.filterData.searchClicked = true
    this.filterData.cleared = false
    this.filterData.timeZoneName = localStorage.getItem('timezoneName')
    this.filterData.fromPagination = false
    if (this.filterType == 'sites') { 
      this.filterData.search = this.siteName
    } else if (this.filterType == 'wearers') {
      this.filterData.wearerType = this.wearerType
      this.filterData.isAssigned = (this.wearerType == 'assigned') ? true : false
      this.filterData.wearerName = this.wearerName
      // this.filterData.siteName = this.siteName
      this.filterData.siteId = this.siteId
    } else if (this.filterType == 'activity') {
      this.filterData.siteId = this.siteId
      this.filterData.wearerId = this.wearerId
    }
    
    this.fitlerService.setFilterData(this.filterData)
  }

  resetFilter() {
    this.searchClicked = false
    this.siteId = ''
    this.siteName = ''
    this.wearerId = '0'
    this.wearerName = ''
    this.dateDto = {}
    this.startDate = ''
    this.endDate = ''
    this.filterData.searchClicked = false
    this.filterData.search = ''
    this.filterData.wearerName = ''
    this.filterData.cleared = true
    this.wearerList = []
    
    this.fitlerService.setFilterData(this.filterData)
  }

  initFilterData() {
    this.filterData.search = ''
    this.filterData.siteId = ''
    this.filterData.wearerId = ''
    this.filterData.startDate
    this.filterData.endDate
  }

  filterByDate() {
    if (this.dateDto.startDateValue) {
      // this.csvRequestData.startDate = this.datePipe.transform(this.dateDto.startDateValue, 'yyyy-MM-dd')
    }
    if (this.dateDto.endDateValue) {
      // this.csvRequestData.endDate = this.datePipe.transform(this.dateDto.endDateValue, 'yyyy-MM-dd')
    }
  }

  onValueChangeStart(event: any) {
    this.startDateValue = this.pipe.transform(this.dateDto.startDateValue, 'yyyy-MM-dd') 
    // this.requestData.startDate = this.startDateValue  
    // this.dateDto.startDateValue = this.startDateValue
    
  }

  onValueChangeEnd(event: any) {
    this.endDateValue = this.pipe.transform(this.dateDto.endDateValue, 'yyyy-MM-dd')
    // this.requestData.endDate = this.endDateValue
    // this.dateDto.endDateValue = this.endDateValue
  }

  ngOnDestroy() {
    this.wearerNameSubscription.unsubscribe()
    this.siteNameSubscription.unsubscribe()
    this.filterData.searchClicked = false
    this.filterData.cleared = true
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AdminService } from 'src/app/iot/services/admin.service';
import { SiteService } from 'src/app/iot/services/site.service';
import { AngularCsv } from 'angular7-csv-compat-16/dist/Angular-csv'
import { TOASTR_CONFIG, adminReportCSVOptions } from 'src/app/shared/global-constants';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-performance-reports',
  templateUrl: './performance-reports.component.html',
  styleUrls: ['./performance-reports.component.scss']
})
export class PerformanceReportsComponent implements OnInit {
  totalItems: number = 0
  currentPage: number = 1
  //@ts-ignore
  minDate: Date
  search: boolean = false
  maxDate: Date = new Date()
  startDateValue: any
  endDateValue: any
  reportType: string = ''
  showReports: boolean = false
  userId!: number
  pageIndex: number = 1
  pageSize: number = 10
  siteId: string = ''
  siteList: Array<any> = [] 
  alertList: Array<any> = []
  requestData: any = {}
  showDatePickers: boolean = false
  exportType: string = 'all'
  csvRequestData: any = {}
  dateDto: any = {}
  maxAllowedDate: Date = new Date()
  datePipe: DatePipe = new DatePipe('en-US')
  userRole!: string
  selectedSite: string = ''
  @ViewChild('csvModal', { static: false }) csvModal!: ModalDirective

  constructor(private adminService: AdminService,
    private toastr: ToastrService, 
    private siteService: SiteService) {
    this.userId = parseInt(localStorage.getItem('user-id')!)
    this.userRole = localStorage.getItem('role')!
    this.requestData.userId = this.userId
    this.requestData.pageIndex = this.pageIndex
    this.requestData.pageSize = this.pageSize
    this.requestData.reportType = this.reportType
    if (this.userRole == 'ROLE_SITE_MANAGER') {
      this.requestData.siteId = this.selectedSite
    } else {
      this.requestData.siteId = this.siteId
    }
    this.requestData.startDate = this.startDateValue
    this.requestData.endDate = this.endDateValue
  }

  ngOnInit(): void {
    this.getAllSites()  
  }

  getAllSites() {
    this.siteService.getAllSites(this.userId).subscribe({
      next: (response: any) => {
        this.siteList = response.DATA
        if (this.siteList.length==1 && this.userRole == 'ROLE_SITE_MANAGER') {
          this.selectedSite = this.siteList[0].id
          this.requestData.siteId = this.selectedSite
          this.csvRequestData.siteId = this.selectedSite
        }
      },
      error: (error: any) => {

      }
    })
  }

  getAdminReport(requestData: any) {
    // const requestData = {
    //   userId: this.userId,
    //   pageIndex: this.pageIndex,
    //   pageSize: this.pageSize,
    //   reportType: this.reportType,
    //   siteId: this.siteId,
    //   startDate: this.startDateValue,
    //   endDate: this.endDateValue
    // }
    
    this.adminService.getAdminReports(requestData).subscribe({
      next: (response: any) => {
        this.alertList = response.DATA
        this.totalItems = response.total
      },
      error: (error: any) => {

      }
    })
    
  }

  selectReportType(event: any) {
    const value = event.target.value
    if (value == 'open') {
      this.reportType = 'open'
    } else if (value == 'closed') {
      this.reportType = 'closed'
    }
    this.requestData.reportType = this.reportType
  }

  selectSite(event: any) {
    let siteId = event.target.value
    if (siteId != '') {
      this.requestData.siteId = siteId
      this.csvRequestData.siteId = siteId
    }
  }

  onRadioChange() {
    if (this.exportType == 'selected') {
      this.showDatePickers = true
    } else {
      this.showDatePickers = false
    }
  }

  filterByDate() {
    if (this.dateDto.startDateValue) {
      this.csvRequestData.startDate = this.datePipe.transform(this.dateDto.startDateValue, 'yyyy-MM-dd')
    }
    if (this.dateDto.endDateValue) {
      this.csvRequestData.endDate = this.datePipe.transform(this.dateDto.endDateValue, 'yyyy-MM-dd')
    }
  }

  onValueChangeStart(event: any) {
    this.startDateValue = this.datePipe.transform(this.dateDto.startDateValue, 'yyyy-MM-dd') 
    this.requestData.startDate = this.startDateValue  
  }

  onValueChangeEnd(event: any) {
    this.endDateValue = this.datePipe.transform(this.dateDto.endDateValue, 'yyyy-MM-dd')
    this.requestData.endDate = this.endDateValue
  }

  showModal() {
    this.csvModal.show()
  }

  hideModal() {
    this.csvModal.hide()
  }

  onHidden() {
    this.showDatePickers = false
  }

  downloadCSV() {}

  exportToCSV() {
    const requestData = {
      userId: this.userId,
      startDate: this.startDateValue,
      endDate: this.endDateValue,
      reportType: this.reportType,
      siteId: this.csvRequestData.siteId
    }

    this.adminService.getAdminReportsCSVData(requestData).subscribe({
      next: (response: any) => {
        
        if (response.DATA.length == 0) {
          this.toastr.warning('No alerts found', '', TOASTR_CONFIG)
          this.csvModal.hide()  
          return
        }
        for(let i = 1; i <= response.maxNotes; i++) {
          adminReportCSVOptions.headers.push(`Alert Note`, `Alert Note Date`, `Added By`)
        }
        if (this.reportType == 'open') {
          new AngularCsv(response.csvData, 'Open Alerts', adminReportCSVOptions)
        } else {
          new AngularCsv(response.csvData, 'Closed Alert', adminReportCSVOptions)
        }
      },
      error: (error: any) => {

      } 
    })
  }

  showReport() {
    this.getAdminReport(this.requestData)
    this.showReports = true
  }

  goBack() {
    this.reportType = ''
    if (this.userRole == 'ROLE_SITE_MANAGER') {
      this.selectSite = this.siteList[0].id
    } else {
      this.selectedSite = ''
    }
    
    this.showReports = false
    this.dateDto = {}
  }

  pageChanged(event: any) {
    this.requestData.pageIndex = event.page
    this.getAdminReport(this.requestData)
  }
}

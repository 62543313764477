import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HospitalsComponent } from './hospitals.component';
import { ManageHospitalsComponent } from './components/manage-hospitals/manage-hospitals.component';
import { CreateHospitalsComponent } from './components/create-hospitals/create-hospitals.component';

export const HOSPITAL_ROUTES: Routes = [
  { 
    path: '', 
    component: HospitalsComponent,
    children: [
      {
        path: 'manage-sites',
        component: ManageHospitalsComponent
      },
      {
        path: 'create-site',
        component: CreateHospitalsComponent
      },
      {
        path: 'edit-site',
        component: CreateHospitalsComponent
      },
      {
        path: '',
        redirectTo: 'manage-sites',
        pathMatch: 'full'
      } 
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(HOSPITAL_ROUTES)],
  exports: [RouterModule]
})
export class HospitalsRoutingModule { }

<div class="reports-wrapper">
    <div class="search-form p-3 m-auto" *ngIf="!showReports">
        <form>
            <!-- <div class="container"></div> -->
            <div class="form-title mb-3">
                <h6 class="text-center">Performance Report</h6>
            </div>
            <!--Report Name-->
            <div class="row mb-3">
                <div class="col-sm-3 col-form-label">Report Name</div>
                <div class="col-sm-9">
                    <select class="form-select form-select-sm" (change)="selectReportType($event)">
                        <option value="">Select report type</option>
                        <option value="open">Open Alerts Report</option>
                        <option value="closed">Closed Alerts Report</option>
                    </select>
                </div>
            </div>
            <!--Site-->
            <div class="row mb-3">
                <div class="col-sm-3 col-form-label">Site</div>
                <div class="col-sm-9">
                    <select class="form-select form-select-sm"
                        [disabled]="userRole=='ROLE_SITE_MANAGER'" 
                        [(ngModel)]="selectedSite"
                        name="site"
                        #siteId="ngModel"
                        (change)="selectSite($event)">
                        <option value="" selected>Select site</option>
                        <option value={{site.id}} *ngFor="let site of siteList; let i=index">{{site.name}}</option>
                    </select>
                </div>
            </div>
            <!--Start Date-->
            <div class="row mb-3">
                <div class="col-sm-3 col-form-label">Start Date</div>
                <div class="col-sm-9">
                    <div class="input-group">
                        <input type="text" class="form-control"
                            name="startDate"
                            [placeholder]="'DD-MM-YYYY'"
                            [maxDate]="maxDate"
                            #dp="bsDatepicker"
                            bsDatepicker
                            [(ngModel)]="dateDto.startDateValue"
                            (ngModelChange)="onValueChangeStart($event)"
                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"/>
                        <button class="btn btn-date text-white" type="button" (click)="dp.toggle()">
                            <i class="bi bi-calendar-check-fill"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!--End Date-->
            <div class="row mb-3">
                <div class="col-sm-3 col-form-label">End Date</div>
                <div class="col-sm-9">
                    <div class="input-group">
                        <input type="text" class="form-control"
                            name="endDate"
                            [placeholder]="'DD-MM-YYYY'"
                            [minDate]="dateDto.startDateValue"
                            [maxDate]="maxDate"
                            #dp1="bsDatepicker"
                            bsDatepicker
                            [(ngModel)]="dateDto.endDateValue"
                            (ngModelChange)="onValueChangeEnd($event)"
                            [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"/>
                        <button class="btn btn-date text-white" type="button" (click)="dp1.toggle()">
                            <i class="bi bi-calendar-check-fill"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="" style="display: flex; align-items: end; justify-content: end;">
                <button pButton pRipple 
                    type="button" 
                    [disabled]="reportType == ''"
                    (click)="showReport()" 
                    style="float: right;" 
                    label="Search" 
                    icon="pi pi-search"
                    class="p-button-raised p-button-sm">
                </button>        
            </div>
        </form>
    </div>
    <div class="mt-3">
        <!-- Closed Alerts Table -->
        <table class="table table-striped table-bordered text-center" *ngIf="reportType=='closed' && showReports">
            <caption>List of closed alerts</caption>
            <thead>
                <tr>
                    <th>Site</th>
                    <th>Wearer</th>
                    <th>Device Id</th>
                    <th>Alert Type</th>
                    <th>Alert Rule</th>
                    <th>Alert Date</th>
                    <!-- <th>Alert Value %</th> -->
                    <th>Time to Close</th>
                    <th>User</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alert of alertList">
                    <td>{{alert.siteName}}</td>
                    <td>{{alert.wearerName}}</td>
                    <td>{{alert.deviceId}}</td>
                    <td>{{alert.alertType}}</td>
                    <td>{{alert.alertRule}}</td>
                    <td>{{alert.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                    <!-- <td></td> -->
                    <td>{{alert.timeToClose}}</td>
                    <td>{{alert.userName}}</td>
                </tr>
            </tbody>
        </table>

        <!-- Open Alerts Table -->
        <table class="table table-striped table-bordered text-center" *ngIf="reportType=='open' && showReports">
            <caption>List of open alerts</caption>
            <thead>
                <tr>
                    <th>Site</th>
                    <th>Wearer</th>
                    <th>Device Id</th>
                    <th>Alert Type</th>
                    <th>Alert Rule/Fault</th>
                    <th>Alert Date</th>
                    <!-- <th>Alert Value %</th> -->
                    <th>Status</th>
                    <th>Elapsed Time</th>
                    <th>Last User</th>
                    <th>Last Note Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alert of alertList">
                    <td>{{alert.siteName}}</td>
                    <td>{{alert.wearerName}}</td>
                    <td>{{alert.deviceId}}</td>
                    <td>{{alert.alertType}}</td>
                    <td>{{alert.alertRule}}</td>
                    <td>{{alert.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                    <!-- <td></td> -->
                    <td>{{alert.alertStatus}}</td>
                    <td>{{alert.timeToClose}}</td>
                    <td>{{alert.userName}}</td>
                    <td>{{alert?.lastNote?.createdOn | date:'dd/MM/yyyy, hh:mm a'}}</td>
                </tr>
            </tbody>
        </table>
        <div class="paging" *ngIf="showReports">
            <button pButton pRipple type="button" 
                style="float: right; margin-left: 15px;" label="Go Back" icon=""
                (click)="goBack()" class="login-button p-button-raised p-button-sm">
            </button>
            <button pButton pRipple type="button" 
                style="float: right;" label="Download CSV" icon="pi pi-download"
                (click)="exportToCSV()" class="login-button p-button-raised p-button-sm">
            </button>
            

            <pagination 
                [totalItems]="totalItems" 
                previousText="&lsaquo;" 
                nextText="&rsaquo;" 
                firstText="&laquo;"
                lastText="&raquo;" 
                [maxSize]="10" 
                [itemsPerPage]="10" 
                [boundaryLinks]="true"
                [(ngModel)]="currentPage" 
                (pageChanged)="pageChanged($event)">
            </pagination>
        </div>
    </div>
</div>

<!-- CSV Modal -->
<div class="modal fade" bsModal #csvModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Export to CSV</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Select data to export</p>
                <div class="container">
                    <div class="row mb-3">
                        <div class="form-check">
                            <input class="form-check-input" 
                                type="radio"
                                name="exportCSV"
                                value="all"
                                [(ngModel)]="exportType"
                                (change)="onRadioChange()"
                                id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Export all data
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" 
                                type="radio" 
                                name="exportCSV"
                                value="selected" 
                                [(ngModel)]="exportType"
                                (change)="onRadioChange()"
                                id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Export data between selected dates
                            </label>
                          </div>
                          
                    </div>
                    <div class="row mb-3" *ngIf="showDatePickers">
                        <!-- Date pickers -->
                        <div class="col-6">
                            <label for="">Start Date</label>
                            <div class="col-6 input-group">
                                <input type="text" 
                                    class="form-control form-control-sm" 
                                    #dp="bsDatepicker" bsDatepicker
                                    [(ngModel)]="dateDto.startDateValue" 
                                    name="startDateValue"
                                    [bsConfig]="{ isAnimated: true , dateInputFormat: 'DD-MM-YYYY', maxDate: maxAllowedDate, showWeekNumbers: false }"
                                    [placeholder]="'DD-MM-YYYY'"  
                                    (ngModelChange)="filterByDate()"  
                                    (bsValueChange)="onValueChangeStart($event)">
                                    <button class="btn btn-sm btn-date text-white" type="button" (click)="dp.toggle()">
                                        <i class="bi bi-calendar-check-fill"></i>
                                    </button>
                            </div>
                        </div>
                        <div class="col-6">
                            <label for="">End Date</label>
                            <div class="col-12 input-group">
                                <input type="text" class="form-control form-control-sm" 
                                    #dp1="bsDatepicker" bsDatepicker
                                    [(ngModel)]="dateDto.endDateValue" 
                                    name="endDateValue"
                                    [bsConfig]="{ isAnimated: true ,  dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                    [placeholder]="'DD-MM-YYYY'"
                                    (ngModelChange)="filterByDate()" 
                                    (bsValueChange)="onValueChangeEnd($event)">
                                    <button class="btn btn-sm btn-date text-white" type="button" (click)="dp1.toggle()">
                                        <i class="bi bi-calendar-check-fill"></i>
                                    </button>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Export" icon="pi pi-check" (click)="exportToCSV()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>
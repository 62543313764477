import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development'

@Injectable({ providedIn: 'root' })
export class SitesApiBuilderService {

  public serverUrl: string = `${environment.host}/api/v1`

  constructor() { }

  // Get all sites having managerId = null
  getAvailableSitesUrl = `${this.serverUrl}/site/getsites`

  // Get all sites having managerId != null
  getAssociatedSitesUrl = `${this.serverUrl}/site/getAssociatedSites`

  // Get all sites
  getAllSitesUrl = (userId: number) => `${this.serverUrl}/site/all/${userId}`

  getManagerSitesUrl = (managerId: number) => `${this.serverUrl}/site/all/${managerId}`

  // Get all sites - paginated
  getPaginatedSitesUrl = (requestData: any) => {
    const {managerId, pageIndex, pageSize} = requestData
    return `${this.serverUrl}/site/getPaginated/${managerId}/${pageIndex}/${pageSize}`
  }

  createSiteUrl = `${this.serverUrl}/site/add`

  updateSiteUrl = (siteId: number) => `${this.serverUrl}/site/update/${siteId}`

  getSiteByIdUrl = (siteId: number) => `${this.serverUrl}/site/getSite/${siteId}`

  deleteSiteUrl = (siteId: number) => `${this.serverUrl}/site/delete/${siteId}`

  public filterSites = (filterObject: any) => {
    //http://localhost:8080/api/v1/site/filterSites/1/1/10?type=all&name=oo&dateFrom=2022-01-01&dateTo=2022-10-25
    const {managerId, pageIndex, pageSize, type='all', search, startDate, endDate, timeZoneName} = filterObject
    let filterSiteUrl = `${this.serverUrl}/site/filterSites/${managerId}/${pageIndex}/${pageSize}/?`;
    
    const queryParams = []
    
    if (search && search != '') {
      queryParams.push(`name=${search}`)
      // filterSiteUrl = `${this.serverUrl}/site/filterSites/${managerId}/${pageIndex}/${pageSize}/?type=${type}&name=${search}&dateFrom=${startDate}&dateTo=${endDate}`
    }

    if(startDate && endDate) {
      queryParams.push(`dateFrom=${startDate}&dateTo=${endDate}`)
    }
    
    if (timeZoneName) {
      queryParams.push(`timeZone=${timeZoneName}`)
    } 
    filterSiteUrl += queryParams.join('&')
    
    return filterSiteUrl
  }

}

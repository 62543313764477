import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts'
import { PieChartOptions } from '../graph/chart-options' 
import { 
  pressurePieChartOptionsC1, 
  pressurePieChartOptionsC2, 
  pressurePieChartOptionsC3,pressurePieChartOptionsC4 } from './mock-data/pressure'


@Component({
  selector: 'app-graph-demo',
  templateUrl: './graph-demo.component.html',
  styleUrls: ['./graph-demo.component.scss']
})
export class GraphDemoComponent implements OnInit {

  showBar: boolean = false
  showLine: boolean = false
  showHeatmap: boolean = false
  showPie: boolean = true
  selectedInterval: number = 1
  selectedParameter: string = 'pressure'
  
  @ViewChild("pie1", { static: true }) pieChart1!: ChartComponent;

  @ViewChild("pie2", { static: true }) pieChart2!: ChartComponent;

  @ViewChild("pie3", { static: true }) pieChar3!: ChartComponent;

  @ViewChild("pie4", { static: true }) pieChart4!: ChartComponent;

  @ViewChild("pie5", { static: true }) pieChart5!: ChartComponent;

  constructor() {

  }

  ngOnInit(): void {
    
  }

  changeParameter(event: any) {

  }

  selectTimeInterval(value: any) {

  }

  switchGraph(event: any) {
    // if (event.target.value == 'bar') {
    //   this.showBar = true
    //   this.showLine = false
    //   this.showHeatmap = false
    // } else if (event.target.value == 'line') {
    //   this.showLine = true
    //   this.showBar = false
    //   this.showHeatmap = false
    // } else {
    //   this.showHeatmap = true
    //   this.showLine = false
    //   this.showBar = false
    // }
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss']
})
export class TemperatureComponent {
  totalItems: number = 100
  currentPage: number = 1
  dateDto: any = {}

  constructor() {}

  filterByDate() {}

  onValueChangeStart(event: any) {}

  onValueChangeEnd(event: any) {}

  pageChanged(event: any) {}
}

import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'
import { CreatePatientService } from 'src/app/iot/services/create-patient.service'
import { LoaderService } from 'src/app/iot/services/loader.service'
import { LOADER_CONFIG } from 'src/app/shared/global-constants'

@Component({
  selector: 'app-patient-header',
  templateUrl: './patient-header.component.html',
  styleUrls: ['./patient-header.component.scss']
})
export class PatientHeaderComponent implements OnInit, OnDestroy {
  
  patientStatus: boolean = true
  patientPassed: boolean = false

  prescriptionPassed: boolean = false
  prescriptionStatus: boolean = false
  prescriptionPresent: boolean = false

  alertProfileStatus: boolean = false
  alertProfilePassed: boolean = false
  alertProfilePresent: boolean = false

  messageRoutingPassed: boolean = false
  messageRoutingStatus: boolean = false
  messageRoutingPresent: boolean = false

  mobileDevicesPassed: boolean = false
  mobileDevicesStatus: boolean = false
  mobileDevicesPresent: boolean = false

  deviceStatus: boolean = false
  devicePassed:boolean = false
  devicePresent: boolean = false

  currentActiveTab: string = 'patient-details'
  currentButtonStatus: string = 'save'
  newPatientAddedSubscription: any

  title: string = 'Wearer Details'
  isEditMode: boolean = false
  wearerId: any = ''
  wearerData: any = {}
  isAssignable: boolean = false
  loading: boolean = false
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG
  
  constructor(private createPatientService: CreatePatientService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute) {
      this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
        this.loading = isLoading
      })
  }

  ngOnInit(): void {
    this.wearerId = this.activatedRoute.snapshot.queryParamMap.get('id')
    if (this.wearerId) {
      this.isEditMode = true
      this.getWearerDetails(this.wearerId)
    }
    
    this.newPatientAddedSubscription = this.createPatientService.patientAdded$.subscribe(newPatientData => {
      this.currentActiveTab = newPatientData.activeTabName;
      this.currentButtonStatus = newPatientData.buttonStatus;
      this.prepareCurrentActiveTab(this.currentActiveTab, this.currentButtonStatus);
    })
  }

  getWearerDetails(wearerId: number) {
    this.createPatientService.getWearerById(wearerId).subscribe({
      next: (response: any) => {
        localStorage.setItem('wearerData', JSON.stringify(response.DATA))
        this.wearerData = response.DATA
        // Check for id
        if (this.wearerData.id) {
          this.patientPassed = true
        }
        
        //Check prescription
        if(this.wearerData.prescriptionId) {
          this.prescriptionPresent = true
          this.prescriptionPassed = true
        }

        // Check for associated alert rule profile
        if (this.wearerData.alertRuleProfileId) {
          this.alertProfilePresent = true
          this.alertProfilePassed = true
        }

        // Check for message routing
        if(this.wearerData.notificationChannelId) {
          this.messageRoutingPresent = true
          this.messageRoutingPassed = true
        }

        // Check for associated alert profiles
        if (this.wearerData.mobilesIds && this.wearerData.mobilesIds.length != 0) {
          this.mobileDevicesPresent = true
          this.mobileDevicesPassed = true
        }

        // Check for assigned device
        if (this.wearerData.deviceId) {
          this.devicePresent = true
          this.devicePassed = true
        }

        if (this.wearerData.assignable) {
          this.isAssignable = true
        } else {
          this.isAssignable = false
        }
      },
      error: (error: any) => {

      }
    })
  }


  currentTab(tabName: string) {
    this.currentActiveTab = tabName
    if (tabName == 'patient-details') {
      this.title = 'Wearer Details'
    } else if(tabName == 'wearer-prescription') {
      this.title = 'Prescription Details'
    } else if (tabName == 'patient-alert-profile') {
      this.title = 'Wearer Alert Rule Profile'
    } else if (tabName == 'message-routing') {
      this.title = 'Message Routing'
    } else if (tabName == 'mobile-devices') {
      this.title = 'Mobile Devices'
    } else if (tabName == 'assign-device') {
      this.title = 'Assign Device'
    }
  }

  prepareCurrentActiveTab(tabName: string, btnStatus: string) {
    if (tabName == 'patient-details' && btnStatus == 'saveNext') {
      this.patientPassed = true
      this.currentActiveTab = 'wearer-prescription'
      this.prescriptionStatus = true
      this.title = 'Prescription Details'
    } else if (tabName == 'wearer-prescription' && btnStatus == 'saveNext') {
      this.prescriptionPassed = true
      this.currentActiveTab = 'patient-alert-profile'
      this.alertProfileStatus = true
      this.title = 'Wearer Alert Rule Profile'
    } else if (tabName == 'patient-alert-profile' && btnStatus == 'saveNext') {
      this.alertProfilePassed = true
      this.currentActiveTab = 'message-routing'
      this.messageRoutingStatus = true
      this.title = 'Message Routing'
      this.alertProfilePresent = true
    } else if (tabName == 'message-routing' && btnStatus == 'saveNext') {
      this.messageRoutingPassed = true
      this.currentActiveTab = 'mobile-devices'
      // this.deviceStatus = true
      this.mobileDevicesStatus = true
      // this.alertProfilePresent = true
      this.title = 'Mobile Devices'
      this.messageRoutingPresent = true
    } else if (tabName == 'mobile-devices' && btnStatus == 'saveNext') {
      this.mobileDevicesPassed = true
      this.currentActiveTab = 'assign-device'
      this.deviceStatus = true
      this.title = 'Assign Devices'
    } else if (tabName == 'assign-device' && btnStatus == 'saveNext') {
      this.mobileDevicesPassed = true
      this.messageRoutingPresent = true
      this.devicePresent = true
    }
  }

  ngOnDestroy(): void {
      this.loadingSubscription.unsubscribe()
  }
  
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreatePatientService } from 'src/app/iot/services/create-patient.service';

@Component({
  selector: 'app-create-patient',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.scss']
})

export class CreatePatientComponent implements OnInit {

  wearerId: string = ''
  isEditMode: boolean = false

  constructor(private activatedRoute: ActivatedRoute, 
    private createWearerService: CreatePatientService) {

  }

  ngOnInit(): void {}
}

import { Component, ViewChild, OnInit } from '@angular/core'
import { NgForm } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-mobile-details',
  templateUrl: './mobile-details.component.html',
  styleUrls: ['./mobile-details.component.scss']
})
export class MobileDetailsComponent {

  isModalShown: boolean = false;
  @ViewChild("mobileModal", { static: false })
  //@ts-ignore
  mobileModal: ModalDirective
  mobileDto: any = {}
  mobileList: Array<any> = []
  editModileData: boolean = false

  constructor() {

  }

  showMobileModal() {
    this.isModalShown = true
    // this.mobileModal.show()
  }

  addMobilesToSite() {}

  hideModal() {
    this.mobileModal.hide()

  }

  onHidden() {
    this.isModalShown = false
  }

  addMobile(form: NgForm) {

  }

  updateMobile() {}
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { ActivityService } from 'src/app/iot/services/activity.service';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/iot/services/loader.service';
import { LOADER_CONFIG } from 'src/app/shared/global-constants';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-wearer-activity',
  templateUrl: './wearer-activity.component.html',
  styleUrls: ['./wearer-activity.component.scss'],
  providers: [DialogService]
})
export class WearerActivityComponent implements OnInit, OnDestroy {
  wearerId!: string
  wearerName!: string
  pageIndex: number
  pageSize: number
  totalItems: number
  currentPage: number
  activityList: Array<any> = []
  loading: boolean = false
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG

  constructor(private router: Router, private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private activityService: ActivityService) {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.totalItems = 0;
    this.currentPage = 1;
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })
  }

  ngOnInit(): void {
    this.wearerId = this.activatedRoute.snapshot.paramMap.get('wearerId')!
    this.getWearerActivity(parseInt(this.wearerId), this.pageIndex, this.pageSize)
  }

  getWearerActivity (wearerId: number, pageIndex: number, pageSize: number) {
    this.activityService.getWearerActivity(wearerId, pageIndex, pageSize).subscribe({
      next: (resposnse: any) => {
        this.activityList = resposnse.DATA
        this.totalItems = resposnse.total
        for (const activity of this.activityList) {
          // milliseconds
          const startMilliseconds = activity.timeSliceStart * 1000;
          const endMilliseconds = activity.timeSliceEnd * 1000;
    
          // Create Date objects
          const startDate = new Date(startMilliseconds);
          const endDate = new Date(endMilliseconds);
    
          // Format dates using DatePipe
          activity.timeSliceStart = this.datePipe.transform(startDate, 'dd/MM/yyyy, hh:mm a')
          activity.timeSliceEnd = this.datePipe.transform(endDate, 'dd/MM/yyyy, hh:mm a')
          
        }
          
        
        this.wearerName = `${this.activityList[0].wearer.firstName} ${this.activityList[0].wearer.lastName}` 
      },
      error: (error: any) => {

      }
    })
  }

  pageChanged(event: any) {
    this.getWearerActivity(parseInt(this.wearerId), event.page, this.pageSize)
  }

  routeToWearers() {
    this.router.navigate(['iot/wearers/manage-wearers'])
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }  
}

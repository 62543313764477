<div class="wearer-alert-wrapper">
  <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
  <div class="row m-3">
        <form #alertProfileForm="ngForm" (ngSubmit)="saveAlertProfile()">
            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required-label">Alert Profile</label>
                <div class="col-sm-4">
                    <select class="form-select form-select-sm" required 
                        (change)="selectAlertRuleProfile($event)" 
                        name="alertType"
                        [(ngModel)]="alertType"
                        #alertProfileType="ngModel">
                        <option value="" selected>Select alert profile type</option>
                        <option value="custom">Custom</option>
                        <option value="pre" disabled>-----Pre Configured-----</option>
                      <option *ngFor="let alert of preConfiguredAlertProfiles" value={{alert.id}}>
                        {{alert.name}}
                      </option>
                    </select>
                    <div class="error-block p-2" *ngIf="alertProfileType.invalid && (alertProfileType.dirty || alertProfileType.touched)">
                      <span *ngIf="alertProfileType.errors?.['required']">Please select alertProfileType</span>
                    </div>
                  </div>
            </div>
            <div class="row mb-3" *ngIf="isCustomSelected">
                <label for="" class="col-sm-3 col-form-label text-end required-label">Name</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" required
                    name="alertName"
                    [(ngModel)]="alertProfileModel.name" 
                    #alertName="ngModel">
                    <div class="error-block p-2" *ngIf="alertName.invalid && (alertName.dirty || alertName.touched)">
                      <span *ngIf="alertName.errors?.['required']">Alert rule profile name is required</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="isCustomSelected">
                <label for="" class="col-sm-3 col-form-label text-end required-label">Description</label>
                <div class="col-sm-4">
                  <input type="text" class="form-control form-control-sm" required
                    name="details"
                    [(ngModel)]="alertProfileModel.details" 
                    #details="ngModel">
                    <div class="error-block p-2" *ngIf="details.invalid && (details.dirty || details.touched)">
                      <span *ngIf="details.errors?.['required']">Alert rule profile details are required</span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-12">
                    <div class="table-wrapper">
                        <table class="table table-striped table-bordered text-center">
                          <thead>
                            <tr>
                              <th>Alert Type</th>
                              <th>Alert Rule</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td [ngClass]="isCustomSelected ? 'alert-type' : 'pre-configured'" class="" (click)="showModal('deviceSync')">
                                Device Sync
                              </td>
                              <td>No sync within {{alertProfileModel.deviceSync}} days of assignment</td>
                            </tr>
                            <tr>
                              <td [ngClass]="isCustomSelected ? 'alert-type' : 'pre-configured'" class="" (click)="showModal('lowBattery')">
                                Low Battery
                              </td>
                              <td>Remaining charge less that {{alertProfileModel.lowBattery}} %</td>
                            </tr>
                          </tbody>
                        </table>
                        <p *ngIf="!isPrescriptionPresent">Prescription is not available for this wearer, please create via prescription details screen</p>
                        <p *ngIf="isPrescriptionPresent">Alert rule populated from prescription details (edited via prescription details screen)</p>
                        <table class="table table-striped table-bordered text-center" *ngIf="isPrescriptionPresent && wearerPrescription">
                          <thead>
                            <tr>
                              <th>Alert Type</th>
                              <th>Alert Rule</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>STEP_TARGET</td>
                              <td>Less than {{wearerPrescription.targetStepsPerDay}} steps per day</td>
                            </tr>
                            <tr>
                              <td>TARGET_USE</td>
                              <td>Less than {{wearerPrescription.targetPeriodInMinutes}} minutes per day</td>
                            </tr>
                            <tr>
                              <td>MAXIMUM_USE</td>
                              <td>Not more than {{wearerPrescription.maximumHoursOfUsage}} hours per day</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                </div>
            </div>

            <div class="btn-wrapper" style="display: flex; align-items: center; justify-content: center;">
              <button pButton pRipple 
                [disabled]="!alertProfileForm.valid || disableSave"
                icon="pi pi-save" 
                label="Save"
                (click)="btnStatus = 'save'" 
                class="p-button-raised p-button-sm mx-2">
              </button>
              <button pButton pRipple
                [disabled]="!alertProfileForm.valid" 
                (click)="btnStatus = 'saveNext'"
                icon="pi pi-angle-double-right" 
                label="Save & Next" 
                class="p-button-raised p-button-sm mx-2">
              </button>
            </div>
        </form>
    </div>
</div>

<!--Alert Rule Profile Modal-->
<div [config]="{backdrop: 'static'}" 
  (onHidden)="onHidden()" bsModal #alertModal="bs-modal"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog custom-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-capitalize">
          Alert Rule: {{modalTitle}}
        </h4>
        <button type="button" class="btn close" aria-label="Close" (click)="alertModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!--Device Sync-->
      <div class="modal-body" *ngIf="modalTitle =='Device Sync'">
        <div class="row mb-3">
            <div class="col-sm-5 text-end">
                No sync within
            </div>
            <div class="col-sm-2">
                <input type="number" class="form-control form-control-sm" 
                    name="deviceSync"
                    min="2"
                    max="10"
                    [(ngModel)]="deviceSyncValue"
                    #deviceSync="ngModel" required>
            </div>
            <div class="col-sm-5">
                days of assignment
            </div>
        </div>
        <div class="row">
          <div class="col-sm-5"></div>
          <div class="col-sm-7">
            <div class="error-block" *ngIf="showError && alertRule=='deviceSync' && !invalidDeviceSync">
              <span>Device sync is mandatory(21c1.1)</span>
            </div>
            <div class="error-block" *ngIf="showError && alertRule=='deviceSync' && invalidDeviceSync">
              <span>Device sync is outside valid range(21c1.2)</span>
            </div>
          </div>
        </div>
      </div>
      
      <!--Low Battery-->
      <div class="modal-body" *ngIf="modalTitle =='Low Battery'">
        <div class="row mb-3">
          <div class="col-sm-5">
            Remaining charge less than
          </div>
          <div class="col-sm-2">
            <input type="number" class="form-control form-control-sm" 
                name="lowBattery"
                min="10"
                max="20"
                [(ngModel)]="lowBatteryValue"
                #lowBattery="ngModel" required>
          </div>
          <div class="col-sm-5">%</div>
        </div>

        <div class="row">
          <div class="col-sm-5"></div>
          <div class="col-sm-7">
            <div class="error-block" *ngIf="showError && alertRule=='lowBattery' && !invalidLowBattery">
              <span>Low battery is mandatory(21c2.1)</span>
            </div>
            <div class="error-block" *ngIf="showError && alertRule=='lowBattery' && invalidLowBattery">
              <span>Low battery is outside valid range(21c2.2)</span>
            </div>
          </div>
        </div>
        
      </div>
      <div class="modal-footer py-1">
        <button pButton pRipple 
          type="button" 
          (click)="hideModal()"
          label="Cancel" 
          icon="pi pi-times"
          class="login-button p-button-raised p-button-secondary p-button-sm">
        </button>
        <button pButton pRipple 
          type="button"
          (click)="updateValues()"
          label="Save" 
          icon="pi pi-check"
          class="login-button p-button-raised p-button-sm">
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation Modal -->
<div [config]="{backdrop: 'static'}" 
  (onHidden)="onHidden()"
  bsModal
  #confirmationModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name">
  <div class="modal-dialog custom-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-capitalize">
          Edit Pre-Configured Alert Rule Profile
        </h4>
        <button type="button" class="btn close" aria-label="Close" (click)="confirmationModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <p>Are you sure you want to edit a pre-configured alert rule profile ?</p>
        </div>
        
      </div>
      <div class="modal-footer py-1">
        <button pButton pRipple 
          type="button"
          (click)="editPreCon()" 
          icon="pi pi-save" 
          label="OK" 
          class="p-button-raised p-button-sm mx-2">
        </button>
        <button pButton pRipple 
          type="button" 
          (click)="hideModal()"
          icon="pi pi-angle-double-right" 
          label="Cancel" 
          class="p-button-raised p-button-sm mx-2">
        </button>
      </div>
    </div>
  </div>
</div>
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { MatSelectCountryModule } from '@angular-material-extensions/select-country'

import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { LoginComponent } from './core/login/login.component';
import { SignupComponent } from './core/signup/signup.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { IotModule } from './iot/iot.module';


import { LoginService } from './core/login/login.service';
import { UserService } from './iot/services/user.service';
import { AuthService } from './iot/services/auth.service'
import { AuthApiBuilderService } from './shared/api-builders/auth-api-builder.service'; 

import { ApiInterceptor, HttpStatus } from './shared/interceptors/api.interceptor';
import { LoggingInterceptor } from './shared/interceptors/logging.interceptor';
import { LoaderService } from './iot/services/loader.service';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    // MatSelectCountryModule.forRoot('en'),
    InputTextModule,
    ButtonModule,
    RippleModule,
    AppRoutingModule,
    IotModule
  ],
  providers: [
    HttpStatus,
    {
      provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true
    },
    LoginService,
    AuthService,
    UserService,
    LoaderService, 
    AuthApiBuilderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

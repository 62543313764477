import { Component } from '@angular/core';

@Component({
  selector: 'app-humidity',
  templateUrl: './humidity.component.html',
  styleUrls: ['./humidity.component.scss']
})
export class HumidityComponent {
  totalItems: number = 100
  currentPage: number = 1
  dateDto: any = {}

  constructor() {}

  filterByDate() {}

  onValueChangeStart(event: any) {}

  onValueChangeEnd(event: any) {}

  pageChanged(event: any) {}
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SitesApiBuilderService } from 'src/app/shared/api-builders/sites-api-builder.service';
import { Site } from 'src/app/shared/models/site.model';

@Injectable()
export class SiteService {

    constructor(private http: HttpClient,
        private siteApiBuilder: SitesApiBuilderService) {
    }

    createSite(siteObject: Site) {
        const createSiteUrl = this.siteApiBuilder.createSiteUrl
        return this.http.post(createSiteUrl, siteObject)
    }

    getSiteById(siteId: number): Observable<any> {
        const getSiteByIdUrl = this.siteApiBuilder.getSiteByIdUrl(siteId)
        return this.http.get(getSiteByIdUrl)
    }

    updateSite(siteId: number, siteObject: Site) {
        const updateSiteUrl = this.siteApiBuilder.updateSiteUrl(siteId)
        return this.http.put(updateSiteUrl, siteObject)
    }

    deleteSite(siteId: number) {
        const deleteSiteUrl = this.siteApiBuilder.deleteSiteUrl(siteId);
        return this.http.delete(deleteSiteUrl)
    }

    getAvailableSites() {
        return this.http.get(this.siteApiBuilder.getAvailableSitesUrl)
    }

    getAssociatedSites() {
    
    }

    getAllSites(userId: number) {
        const getAllSitesUrl = this.siteApiBuilder.getAllSitesUrl(userId)
        return this.http.get(getAllSitesUrl)
    }

    getManagerSites(managerId: number) {
        const getManagerSitesUrl = this.siteApiBuilder.getManagerSitesUrl(managerId)
        return this.http.get(getManagerSitesUrl)
    }



    getAllPaginatedSites(requestData: any) {
        const getAllPaginatedSitesUrl = this.siteApiBuilder.getPaginatedSitesUrl(requestData)
        return this.http.get(getAllPaginatedSitesUrl)
    }

    filterSites(filterData: any) {
        const filterSitesUrl = this.siteApiBuilder.filterSites(filterData)
        return this.http.get(filterSitesUrl)
    }
}
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AlertRuleApiBuilderService } from "src/app/shared/api-builders/alert-profile-api-builder.service";
import { AlertRuleProfile } from "src/app/shared/models/alert-rule-profile.model";


@Injectable()
export class AlertRuleProfileService {
    
    constructor(private http: HttpClient, 
        private alertRuleApiBuilder: AlertRuleApiBuilderService) {

    }

    createAlertRuleProfile(alerRuleProfileData: AlertRuleProfile, type: string) {
        const createAlertProfileUrl = this.alertRuleApiBuilder.createAlertRuleProfileUrl(type)
        return this.http.post(createAlertProfileUrl, alerRuleProfileData)
    }

    getAllPreconfiguredAlertProfiles() {
        return this.http.get(this.alertRuleApiBuilder.getAllPreConfiguredAlertProfilesUrl)
    }

    getAllAlertProfiles(type: string, pageIndex: number, pageSize: number) {
        const getAllAlertProfilesUrl = this.alertRuleApiBuilder.getAlertRuleProfilesUrl(type, pageIndex, pageSize)
        return this.http.get(getAllAlertProfilesUrl)
    }

    getAlertProfileById(alertProfileId: number) {
        const getAlertProfileByIdUrl = this.alertRuleApiBuilder.getAlertRuleProfileByIdUrl(alertProfileId)
        return this.http.get(getAlertProfileByIdUrl)
    }

    updateAlertProfile(alertProfileId: number, alerRuleProfileData: AlertRuleProfile) {
        const updateAlertProfileUrl = this.alertRuleApiBuilder.editAlertRuleProfileUrl(alertProfileId)
        return this.http.put(updateAlertProfileUrl, alerRuleProfileData)
    }

    deleteAlertProfile(alertProfileId: number) {
        const deleteAlertProfileUrl = this.alertRuleApiBuilder.deleteAlertRuleProfileUrl(alertProfileId);
        return this.http.delete(deleteAlertProfileUrl)
    }
}
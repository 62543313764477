import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment.development'
@Injectable({providedIn: 'root'})
export class AuthApiBuilderService {

  public serverUrl: string = `${environment.host}/api/v1`

  constructor() { }

  public login = `${this.serverUrl}/auth/signin`
  
  forgotPassword = `${this.serverUrl}/auth/forgotpassword`

  resetPasswordUrl = (token: string) => `${this.serverUrl}/auth/resetpassword?token=${token}`
}

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms';

import { NgxLoadingModule } from 'ngx-loading'

import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'

import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AlertRuleProfileRoutingModule } from './alert-rule-profile-routing.module';
import { AlertRuleProfileComponent } from './alert-rule-profile.component';
import { ManageAlertRulesComponent } from './components/manage-alert-rules/manage-alert-rules.component';
import { CreateAlertRuleComponent } from './components/create-alert-rule/create-alert-rule.component';
import { AlertRuleApiBuilderService } from 'src/app/shared/api-builders/alert-profile-api-builder.service';
import { AlertRuleProfileService } from '../../services/alert-rule-profile.service';


@NgModule({
  declarations: [
    AlertRuleProfileComponent,
    ManageAlertRulesComponent,
    CreateAlertRuleComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    RippleModule,
    PaginationModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ModalModule.forRoot(),
    FormsModule,
    AlertRuleProfileRoutingModule
  ],
  providers: [
    AlertRuleApiBuilderService,
    AlertRuleProfileService
  ]
})
export class AlertRuleProfileModule { }

export class AlertRuleProfile {
    id: number| null
    name: string
    details: string 
    alertProfileType: string  
    lowBattery: number = 10
    deviceSync: number = 2

    constructor() {
        this.id = null
        this.name = ''
        this.details = ''
        this.alertProfileType = ''
        this.lowBattery = 10
        this.deviceSync = 2
    }
}

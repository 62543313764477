import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, createUrlTreeFromSnapshot, Router } from "@angular/router"
import { map } from "rxjs"
import { UserService } from "src/app/iot/services/user.service"

export const adminGuard = (next: ActivatedRouteSnapshot, router: Router) => {
    const service = inject(UserService);
    const userRole = service.getUserRole()
    if(userRole == 'ROLE_ROOT_ADMIN' || userRole == 'ROLE_SERVICE_ADMIN') {
        return true
    } else {
        return createUrlTreeFromSnapshot(next, ['/iot','administration','devices', 'unassigned'])
        // return router.createUrlTree(['/', 'iot', 'administration', 'devices'])
    }

    // return service
    //     .isUserLoggedIn().pipe(
    //         map((isLoggedIn: boolean) => {
    //             if (isLoggedIn) {
    //                 return true;
    //             } else {
    //                 return createUrlTreeFromSnapshot(next, ['/', 'login']);
    //             }
    //         })
    //     )
}
import { PressureHeatmapOptions } from '../chart-options'

export class PressureHeatmap {
    pressureHeatmapOptions: PressureHeatmapOptions;
    pressureHeatmapZones: PressureHeatmapOptions
    oneWeekPressureHM: PressureHeatmapOptions;
    oneWeekHMZones: PressureHeatmapOptions
    zoneView: boolean = false
    constructor() {
        this.pressureHeatmapOptions = {
            series: [
                {
                    name: "6:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "6:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "6:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "6:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "7:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "7:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "7:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "7:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "8:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "8:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "8:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "8:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "9:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "9:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "9:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "9:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "10:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "10:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "10:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "10:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "11:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "11:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "11:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "11:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "12:00 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "12:15 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "12:30 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "12:45 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "1:00 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "1:15 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "1:30 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "1:45 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "2:00 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "2:15 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "2:30 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "2:45 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                }
            ],
            // colors: [],
            chart: {
                height: 600,
                width: 900,
                type: "heatmap"
            },
            plotOptions: {

                heatmap: {
                    shadeIntensity: 0.5,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 24,
                                name: "less than 25%",
                                color: "#128FD9"
                            },
                            {
                                from: 25,
                                to: 50,
                                name: "25% to 50%",
                                color: "#00A100"
                            },
                            {
                                from: 51,
                                to: 75,
                                name: "50% to 75%",
                                color: "#FFB200"
                            },
                            {
                                from: 76,
                                to: 100,
                                name: "more than 75%",
                                color: "#FF0000"
                            }
                        ]
                    },

                }
            },
            stroke: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            title: {
                text: ""
            }
        }

        this.pressureHeatmapZones = {
            series: [
                {
                    name: "6:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "6:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "6:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "6:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "7:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "7:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "7:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "7:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "8:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "8:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "8:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "8:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "9:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "9:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "9:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "9:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "10:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "10:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "10:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "10:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "11:00 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "11:15 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "11:30 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "11:45 AM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "12:00 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "12:15 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "12:30 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "12:45 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "1:00 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "1:15 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "1:30 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "1:45 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "2:00 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "2:15 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "2:30 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "2:45 PM",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                }
            ],
            // colors: [],
            chart: {
                height: 600,
                width: 900,
                type: "heatmap"
            },
            plotOptions: {

                heatmap: {
                    shadeIntensity: 0.5,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 24,
                                name: "less than 25%",
                                color: "#128FD9"
                            },
                            {
                                from: 25,
                                to: 50,
                                name: "25% to 50%",
                                color: "#00A100"
                            },
                            {
                                from: 51,
                                to: 75,
                                name: "50% to 75%",
                                color: "#FFB200"
                            },
                            {
                                from: 76,
                                to: 100,
                                name: "more than 75%",
                                color: "#FF0000"
                            }
                        ]
                    },

                }
            },
            stroke: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            title: {
                text: ""
            }
        }

        this.oneWeekPressureHM = {
            series: [
                {
                    name: "01-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "02-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "03-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "04-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "05-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "06-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                },
                {
                    name: "07-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, false)
                }
            ],
            // colors: [],
            chart: {
                height: 400,
                width: 900,
                type: "heatmap"
            },
            plotOptions: {

                heatmap: {
                    shadeIntensity: 0.5,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 24,
                                name: "less than 25%",
                                color: "#128FD9"
                            },
                            {
                                from: 25,
                                to: 50,
                                name: "25% to 50%",
                                color: "#00A100"
                            },
                            {
                                from: 51,
                                to: 75,
                                name: "50% to 75%",
                                color: "#FFB200"
                            },
                            {
                                from: 76,
                                to: 100,
                                name: "more than 75%",
                                color: "#FF0000"
                            }
                        ]
                    },

                }
            },
            stroke: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            title: {
                text: ""
            }
        }

        this.oneWeekHMZones = {
            series: [
                {
                    name: "01-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "02-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "03-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "04-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "05-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "06-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                },
                {
                    name: "07-09-2023",
                    data: this.generateData(5, { min: 0, max: 100 }, true)
                }
            ],
            // colors: [],
            chart: {
                height: 400,
                width: 900,
                type: "heatmap",
                toolbar: {
                    show: true
                }
            },
            plotOptions: {

                heatmap: {
                    shadeIntensity: 0.5,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 24,
                                name: "less than 25%",
                                color: "#128FD9"
                            },
                            {
                                from: 25,
                                to: 50,
                                name: "25% to 50%",
                                color: "#00A100"
                            },
                            {
                                from: 51,
                                to: 75,
                                name: "50% to 75%",
                                color: "#FFB200"
                            },
                            {
                                from: 76,
                                to: 100,
                                name: "more than 75%",
                                color: "#FF0000"
                            }
                        ]
                    },

                }
            },
            stroke: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            title: {
                text: ""
            }
        }
    }

    prepareData(zones: boolean): PressureHeatmapOptions {
        
        if (zones) {
            return this.pressureHeatmapZones
        } else {
            return this.pressureHeatmapOptions
        }
    }

    prepareOneWeekData(zones: boolean): PressureHeatmapOptions {
        if (zones) {
            return this.oneWeekHMZones
        } else {
            return this.oneWeekPressureHM
        }
    }

    generateData(count: any, yRange: any, zoneEnabled: boolean) {
        let x = ''
        let series = []; 
        for (let i = 1; i <= 5; i++) {
            for (let j = 1; j <= 5; j++) {
                if (zoneEnabled) {
                    x = "Ch" + j + " Zone" + i
                } else {
                    x = "Ch" + i + " Zone" + j
                }
                let y = Math.floor(Math.random() * (yRange.max - yRange.min + 1)) + yRange.min
                series.push({ x: x, y: y })
            }
        }
        return series
    }
}
<div class="prescription-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="container">
        <form #prescriptionForm="ngForm" (ngSubmit)="saveWearerPrescription(prescriptionForm)">
            <div class="row mb-3">
                <label for="" class="col-sm-6 col-form-label text-end required">
                    Target period of use per day (in minutes)
                </label>
                <div class="col-sm-4">
                    <input type="number" 
                        class="form-control form-control-sm"   
                        min="0"
                        max="1440"
                        name="targetUsage"
                        (change)="prescriptionModelChanged()"
                        [(ngModel)]="wearerPrescription.targetPeriodInMinutes"
                        #targetUsage="ngModel" 
                        required>
                    <div class="error-block" *ngIf="targetUsage.invalid && (prescriptionForm.submitted || targetUsage.dirty || targetUsage.touched)">
                        <span *ngIf="targetUsage.errors?.['required']">Target usage is mandatory</span>
                    </div>
                    <div class="error-block" *ngIf="targetUsage.invalid && (prescriptionForm.submitted || targetUsage.dirty || targetUsage.touched)">
                        <span *ngIf="targetUsage.errors?.['min']">
                            (21b.1) {{wearerPrescription.targetPeriodInMinutes}} outside valid range
                        </span>
                    </div>
                    <div class="error-block" *ngIf="targetUsage.invalid && (prescriptionForm.submitted || targetUsage.dirty || targetUsage.touched)">
                        <span *ngIf="targetUsage.errors?.['max']">
                            (21b.1) {{wearerPrescription.targetPeriodInMinutes}} outside valid range
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="" class="col-sm-6 col-form-label text-end required">
                    Target steps per day
                </label>
                <div class="col-sm-4">
                    <input type="number" 
                        class="form-control form-control-sm" 
                        min="0"
                        max="65535"  
                        name="targetSteps"
                        (change)="prescriptionModelChanged()"
                        [(ngModel)]="wearerPrescription.targetStepsPerDay"
                        #targetSteps="ngModel" 
                        required>
                    <div class="error-block" *ngIf="targetSteps.invalid && (prescriptionForm.submitted || targetSteps.dirty || targetSteps.touched)">
                        <span *ngIf="targetSteps.errors?.['required']">Target steps are mandatory</span>
                    </div>
                    <div class="error-block" *ngIf="targetSteps.invalid && (prescriptionForm.submitted || targetSteps.dirty || targetSteps.touched)">
                        <span *ngIf="targetSteps.errors?.['min']">
                            (21b.1) {{wearerPrescription.targetStepsPerDay}} outside valid range
                        </span>
                    </div>
                    <div class="error-block" *ngIf="targetSteps.invalid && (prescriptionForm.submitted || targetSteps.dirty || targetSteps.touched)">
                        <span *ngIf="targetSteps.errors?.['max']">
                            (21b.1) {{wearerPrescription.targetStepsPerDay}} outside valid range
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="" class="col-sm-6 col-form-label text-end required">
                    Maximum hours of use
                </label>
                <div class="col-sm-4">
                    <input type="number" 
                        class="form-control form-control-sm"   
                        min="0"
                        max="24"
                        name="maxHours"
                        (change)="prescriptionModelChanged()"
                        [(ngModel)]="wearerPrescription.maximumHoursOfUsage"
                        #maxHours="ngModel" 
                        required>
                    <div class="error-block" *ngIf="maxHours.invalid && (prescriptionForm.submitted || maxHours.dirty || maxHours.touched)">
                        <span *ngIf="maxHours.errors?.['required']">Max hours are required</span>
                    </div>
                    <div class="error-block" *ngIf="maxHours.invalid && (prescriptionForm.submitted || maxHours.dirty || maxHours.touched)">
                        <span *ngIf="maxHours.errors?.['min']">
                            (21b.1) {{wearerPrescription.maximumHoursOfUsage}} outside valid range
                        </span>
                    </div>
                    <div class="error-block" *ngIf="maxHours.invalid && (prescriptionForm.submitted || maxHours.dirty || maxHours.touched)">
                        <span *ngIf="maxHours.errors?.['max']">
                            (21b.1) {{wearerPrescription.maximumHoursOfUsage}} outside valid range
                        </span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="" class="col-sm-6 col-form-label text-end required">
                    Language
                </label>
                <div class="col-sm-4">
                    <select class="form-select form-select-sm" 
                        name="language"
                        (change)="prescriptionModelChanged()"
                        [(ngModel)]="wearerPrescription.language"
                        #language="ngModel" 
                        required>
                        <option value="">Select language</option>
                        <option value="0">English</option>
                        <option value="1">Spanish</option>
                        <option value="2">German</option>
                        <option value="3">Gulf Arabic</option>
                        <option value="4">French</option>
                    </select>
                    <div class="error-block" *ngIf="language.invalid && (prescriptionForm.submitted || language.dirty || language.touched)">
                        <span *ngIf="language.errors?.['required']">Language is required</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="" class="col-sm-6 col-form-label text-end required">
                    Verbosity Level
                </label>
                <div class="col-sm-4">
                    <select class="form-select form-select-sm" 
                        name="verbosity"
                        (change)="prescriptionModelChanged()"
                        [(ngModel)]="wearerPrescription.verbosityLevel"
                        #verbosity="ngModel" 
                        required>
                        <option value="">Selecte verbosity level</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                    </select>
                    <div class="error-block" *ngIf="verbosity.invalid && (prescriptionForm.submitted || verbosity.dirty || verbosity.touched)">
                        <span *ngIf="verbosity.errors?.['required']">Verbosity level is required</span>
                    </div>
                </div>
            </div>
            <div class="btn-wrapper" style="display: flex; align-items: center; justify-content: center;">
                <button pButton pRipple 
                    [disabled]="false"
                    (click)="btnStatus='save'"
                    icon="pi pi-save" 
                    label="Save" 
                    class="p-button-raised p-button-sm mx-2">
                </button>
                <button pButton pRipple
                    [disabled]="false"  
                    (click)="btnStatus='saveNext'"
                    icon="pi pi-angle-double-right" 
                    label="Save & Next" 
                    class="p-button-raised p-button-sm mx-2">
                </button>
            </div>
        </form>
    </div>
</div>
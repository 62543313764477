import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/iot/services/auth.service';
import { TOASTR_CONFIG } from 'src/app/shared/global-constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordDto: any = {}
  loading: boolean = false
  constructor(private authService: AuthService, 
    private router: Router, 
    private toastr: ToastrService) {

  }

  ngOnInit(): void {
    
  }

  forgotPassword(form: NgForm) {
    this.authService.forgotPassword(this.forgotPasswordDto).subscribe({
      next: (success: any) => {
        this.toastr.success('Password reset mail sent successfully', '', TOASTR_CONFIG)
        this.router.navigate(['/'])
      },
      error: (err: any) => {
        form.reset();
      }
    })
  }
}

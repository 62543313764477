import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development'
@Injectable({
  providedIn: 'root'
})
export class DeviceApiBuilderService {

  public serverUrl: string = `${environment.host}/api/v1`
  constructor() { }

  getDeviceIdUrl = `${this.serverUrl}/device/getid`

  createDeviceUrl = `${this.serverUrl}/device/add`

  getDeviceByIdUrl = (deviceId: number) => `${this.serverUrl}/device/getDevice/${deviceId}`

  updateDeviceUrl = (deviceId: number) => `${this.serverUrl}/device/editdevice/${deviceId}`

  deleteDeviceUrl = (deviceId: number) => `${this.serverUrl}/device/deletedevice/${deviceId}`

  getAllDevicesUrl = (requestData: any) => {
    const {isAllocated, isAssigned, isProvisioned, pageIndex, pageSize, userId, siteId} = requestData
    let allDevicesUrl = `${this.serverUrl}/device/allDevices/${isAllocated}/${isAssigned}/${isProvisioned}/${pageIndex}/${pageSize}/${userId}?`     
    const queryParams: any = []
    if (siteId && siteId != '') {
      queryParams.push(`siteId=${siteId}`)
    }
    allDevicesUrl += queryParams.join('&')
    return allDevicesUrl

  }

  includeDecommissionedDevicesUrl = (requestData: any) => {
    const {userId, type, pageIndex, pageSize, siteId = ''} = requestData

    const queryParams = new URLSearchParams({siteId: siteId || ''}).toString();

    return `${this.serverUrl}/device/includeDecommissionedDevices/${type}/${userId}/${pageIndex}/${pageSize}?${queryParams}`
  }

  getUnassignedDevicesUrl = ``

  getUnprovisionedDevicesUrl = ``

  getProvisionedDevicesUrl = ``
    
}

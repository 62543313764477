import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthApiBuilderService } from "src/app/shared/api-builders/auth-api-builder.service";

@Injectable()
export class AuthService {

    constructor(public authApiBuilder: AuthApiBuilderService, public httpClient: HttpClient) { }

    signIn(loginData: any) {
        return this.httpClient.post(this.authApiBuilder.login, loginData)
    }

    forgotPassword(forgotPasswordData: any) {
        return this.httpClient.post(this.authApiBuilder.forgotPassword , forgotPasswordData);
    }

    resetPassword(token: any, resetData: any) {
        const resetPasswordUrl = this.authApiBuilder.resetPasswordUrl(token);
        return this.httpClient.put(resetPasswordUrl, resetData)
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'

import { PaginationModule } from 'ngx-bootstrap/pagination' 
import { NgxLoadingModule } from 'ngx-loading'

import { IotRoutingModule } from './iot-routing.module';
import { IotComponent } from './iot.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';



import { AdministrationModule } from './modules/administration/administration.module';
import { ActivityModule } from './modules/activity/activity.module';
import { AlertRuleProfileModule } from './modules/alert-rule-profile/alert-rule-profile.module';
import { HospitalsModule } from './modules/hospitals/hospitals.module';
import { PatientsModule } from './modules/patients/patients.module';



@NgModule({
  declarations: [
    IotComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PaginationModule,
    NgxLoadingModule.forRoot({}),
    IotRoutingModule,
    AdministrationModule,
    ActivityModule,
    AlertRuleProfileModule,
    HospitalsModule,
    PatientsModule
  ],
  exports: []
})
export class IotModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { FiltersComponent } from './components/filters/filters.component';
import { FormsModule } from '@angular/forms';
import { UtilService } from './services/util.service';


@NgModule({
  declarations: [FiltersComponent],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [FiltersComponent],
  providers: [
    UtilService
  ]
})
export class SharedModule { }

import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core'
import { ActivityService } from 'src/app/iot/services/activity.service'
import { FilterService } from 'src/app/shared/services/filter.service'
import { Subscription } from 'rxjs'
import { ModalDirective } from 'ngx-bootstrap/modal'
import { DatePipe } from '@angular/common'
import { AngularCsv } from 'angular7-csv-compat-16/dist/Angular-csv'
import { LOADER_CONFIG, timeSliceCSVOptions } from 'src/app/shared/global-constants'
import { LoaderService } from 'src/app/iot/services/loader.service'

@Component({
  selector: 'app-pressure',
  templateUrl: './pressure.component.html',
  styleUrls: ['./pressure.component.scss']
})
export class PressureComponent implements OnInit, OnDestroy, OnChanges {
  totalItems: number = 0
  currentPage: number = 1
  dateDto: any = {}
  pageIndex: number = 1
  pageSize: number = 10
  userId!: number
  userRole!: string
  filterData: any = {}
  filterSubscription!: Subscription
  timeSliceData: Array<any> = []
  exportType: string = 'all'
  csvRequestData: any = {}
  showDatePickers: boolean = false
  maxAllowedDate: Date = new Date()
  datePipe: DatePipe = new DatePipe('en-US')
  loading: boolean = false
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG
  filterApplied: boolean = false

  @ViewChild('csvModal', { static: false }) csvModal!: ModalDirective

  constructor(private activityService: ActivityService,
    private cd: ChangeDetectorRef,
    private loaderService: LoaderService, 
    private filterService: FilterService) {
    this.userId = parseInt(localStorage.getItem('user-id')!)
    this.userRole = localStorage.getItem('role')!
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })

  }

  ngOnInit(): void {
    this.getTimeSliceData(this.userId, this.pageIndex, this.pageSize)
    this.filterSubscription = this.filterService.selectedFilters$.subscribe({
      next: (filterData: any) => {
        this.filterData = filterData
        if (filterData.searchClicked) {
          this.filterApplied = true
          this.filterActivities(this.filterData)
        } else if (this.filterData.cleared) {
          this.filterApplied = false
          this.currentPage = this.pageIndex
          this.getTimeSliceData(this.userId, this.pageIndex, this.pageSize)
        }
      },
      error: (error: any) => {

      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes: ', changes)
  }

  getTimeSliceData(userId: number, pageIndex: number, pageSize: number) {
    console.log('filter applied: ', this.filterApplied)
    // if (!this.filterApplied) {
    //   pageIndex = 1
    // }
    this.activityService.getTimeSliceData(userId, pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.timeSliceData = response.DATA
        this.totalItems = response.total
      },
      error: (error: any) => { }
    })
  }

  filterByDate() {
    if (this.dateDto.startDateValue) {
      this.csvRequestData.startDate = this.datePipe.transform(this.dateDto.startDateValue, 'yyyy-MM-dd')
    }
    if (this.dateDto.endDateValue) {
      this.csvRequestData.endDate = this.datePipe.transform(this.dateDto.endDateValue, 'yyyy-MM-dd')
    }
  }

  onValueChangeStart(event: any) {
    
  }

  onValueChangeEnd(event: any) {
    
  }

  filterActivities(requestData: any) {
    if (requestData.siteId == '0' || requestData.siteId == '') {
      delete requestData.siteId
    }
    if (requestData.wearerId == '0' || requestData.wearerId == '') {
      delete requestData.wearerId
    }
    console.log('search cliked: ', requestData.searchClicked, 'page index: ', requestData.pageIndex)
    // requestData.pageIndex = this.pageIndex
    // requestData.pageSize = this.pageSize
    // this.currentPage = requestData.pageIndex
    if (requestData.searchClicked && !requestData.fromPagination) {
      requestData.pageIndex = 1
      this.currentPage = 1
    } else if (requestData.fromPagination) {
      this.currentPage = requestData.pageIndex
    }
    this.activityService.filterTimeSliceData(requestData).subscribe({
      next: (response: any) => {
        this.timeSliceData = response.DATA
        this.totalItems = response.total
        this.cd.detectChanges()
      },
      error: (error: any) => {

      }
    })
  }

  pageChanged(event: any) {
    console.log('page changed event: ', event)
    console.log('current page: ', this.currentPage)
    
    if (this.filterData.siteId && this.filterData.siteId != '0' && this.filterData.searchClicked) {
      this.filterData.pageIndex = event.page
      this.filterActivities(this.filterData)
    } else if ((this.filterData.siteId && this.filterData.siteId != '0') && (this.filterData.wearerId && this.filterData.wearerId != '0')) {
      this.filterData.pageIndex = event.page
      this.filterActivities(this.filterData)
    } else if (this.filterData.startDate && this.filterData.endDate && this.filterData.searchClicked){
      this.filterData.pageIndex = event.page
      this.filterActivities(this.filterData)
    } else {
      this.getTimeSliceData(this.userId, event.page, this.pageSize)
    }
  }

  handlePageChange(event: any) {
    console.log('handlePageChange: ', event, typeof(event))
    console.log('handlePageChange current page: ', this.currentPage)
    this.filterData.fromPagination = true
    if (this.filterData.siteId && this.filterData.siteId != '0' && this.filterData.searchClicked) {
      this.filterData.pageIndex = event.page
      this.filterActivities(this.filterData)
    } else if ((this.filterData.siteId && this.filterData.siteId != '0') && (this.filterData.wearerId && this.filterData.wearerId != '0')) {
      this.filterData.pageIndex = event.page
      this.filterActivities(this.filterData)
    } else if (this.filterData.startDate && this.filterData.endDate && this.filterData.searchClicked){
      this.filterData.pageIndex = event.page
      this.filterActivities(this.filterData)
    } else {
      this.getTimeSliceData(this.userId, event.page, this.pageSize)
    }
  }

  showCSVModal() {
    this.csvModal.show()
  }

  onHidden() {
    this.showDatePickers = false
  }

  hideModal() {
    this.csvModal.hide()
  }

  onRadioChange() {
    
    if (this.exportType == 'selected') {
      this.showDatePickers = true
    } else {
      this.showDatePickers = false
    }
  }

  exportToCSV() {
    const requestData = {
      userId: this.userId,
      siteId: this.filterData.siteId,
      wearerId: this.filterData.wearerId,
      startDate: this.filterData.startDate,
      endDate:  this.filterData.endDate,
    }

    this.activityService.getTimeSliceDataCSV(requestData).subscribe({
      next: (response: any) => {
        new AngularCsv(response.csvData, 'Activity Data', timeSliceCSVOptions)
      },
      error: (error: any) => { }
    })
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe()
    this.loadingSubscription.unsubscribe()
  }
}

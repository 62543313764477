<div class="allocated-wrapper">
    <div class="d-flex flex-row justify-content-start">
        <div *ngIf="userRole != 'ROLE_SITE_MANAGER'" class="col-md-2 col-xs-4 px-0 pb-2">
            <select class="form-control form-select form-select-sm custom-form-control" (change)="filterBySite($event)">
                <option value="0" selected>Select Site</option>
                <option *ngFor="let site of siteList" value="{{ site.id }}">
                    {{site.name}}
                </option>
            </select>
        </div>

        <div class="col pt-1 mx-5">
            <div class="form-check">
                <input class="form-check-input" 
                    type="checkbox" 
                    name="checkbox"
                    [(ngModel)]="includeDecommissioned" 
                    (change)="includeDecommissionedDevices(includeDecommissioned)">
                <label class="form-check-label" for="flexCheckDefault">
                    Include Decommissioned
                </label>
            </div>
            <!-- <label class="container-checkbox align-middle">Include Decomissioned
                <input type="checkbox" name="unAssigned"/>
                <span class="checkmark"></span>
            </label> -->
        </div>
    </div>
    <!--Allocated Device List-->
    <div class="mt-3">
        <div>
            <table class="table table-striped table-bordered text-center">
                <caption>List of unassigned devices</caption>
                <thead>
                    <tr>
                        <th class="w-20">Device Id</th>
                        <th>Site</th>
                        <th class="w-20">Created Date/Time</th>
                        <th>Status</th>
                        <th class="w-20">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let device of unassignedDevices; let i = index">
                        <td>{{device.deviceId}}</td>
                        <td>{{device.siteName}}</td>
                        <td>{{device.dateCreated | date:'dd/MM/yyyy, hh:mm a'}}</td>
                        <td>{{device.isActive ? 'Active':'Inactive'}}</td>
                        <td>
                            <select *ngIf="device.isActive" class="form-select form-select-sm"
                            #unassignedDropdown 
                            (change)="selectAction($event, device, i)"
                            name="unallocatedAction">
                                <option selected value="">Select an action</option>
                                <option value="edit">Edit</option>
                                <option value="assign">Assign</option>
                                <option value="decommission">Decommission</option>
                            </select>
                            <span *ngIf="!device.isActive">Device is decomissioned</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="paging mt-3">
        <pagination 
            [totalItems]="totalItems" 
            previousText="&lsaquo;" 
            nextText="&rsaquo;" 
            firstText="&laquo;"
            lastText="&raquo;" 
            [maxSize]="10" 
            [itemsPerPage]="10" 
            [boundaryLinks]="true"
            [(ngModel)]="currentPage" 
            (pageChanged)="pageChanged($event)">
        </pagination>
    </div>
</div>

<!--Edit Device Modal-->
<div class="modal fade" 
    bsModal #updateDeviceModal="bs-modal" 
    [config]="{backdrop: 'static'}" 
    tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Create Device</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="updateDeviceModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form #updateDeviceForm="ngForm" (ngSubmit)="saveDevice(updateDeviceForm)">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12 m-auto">
                            <div class="row mb-3">
                                <label for="deviceId" class="col-sm-3 col-form-label text-end">Device Id</label>
                                <div class="col-sm-4">
                                    <input type="text" 
                                        disabled 
                                        class="form-control form-select-sm" 
                                        id="deviceId" 
                                        [(ngModel)]="deviceModel.deviceId"
                                        #deviceId="ngModel" 
                                        name="deviceId">
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-12">
                                    <form #configForm="ngForm" (ngSubmit)="setConfigValue(configForm)">
                                        <table class="table table-striped table-bordered text-center">
                                            <thead>
                                                <tr>
                                                  <th class="w-50">Key</th>
                                                  <th>Value</th>
                                                  <th class="col-md-2">Actions</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                <tr *ngFor="let setting of settings; let i=index">
                                                    <td class="text-start">{{setting.configKey}} {{setting.unit}}</td>
                                                    <td [ngClass]="isEditConfig ? 'text-start': ''">
                                                      <span *ngIf="editIndex != i && setting.configKey!='eventThreshold'">
                                                        {{setting.value}}
                                                      </span>
                                                      <span *ngIf="editIndex != i && setting.configKey=='eventThreshold'">
                                                        {{setting.value+'%'}} {{eventThreshold+'mv'}}
                                                      </span>
                                                      <div *ngIf="isEditConfig && currentActiveKey==setting.configKey">
                                                        <!--Inline table input-->
                                                        <input *ngIf="!showDropdown" class="table-input form-control form-control-sm w-50" required 
                                                            style="display: inline;"  
                                                            type="number"
                                                            [min]="setting.min"
                                                            [max]="setting.max" 
                                                            name="value" 
                                                            [(ngModel)]="deviceConfigModel[setting.configKey]"
                                                            #value="ngModel">
                                                        <!-- SD Card Boolean -->
                                                        <select *ngIf="currentActiveKey=='sdCardWriteBoolean' && showDropdown" 
                                                            class="form-select form-select-sm w-50" 
                                                            style="display: inline;"
                                                            name="value"
                                                            [(ngModel)]="deviceConfigModel[setting.configKey]" #value="ngModel">
                                                            <option value="true" selected>True</option>
                                                            <option value="false">False</option>
                                                        </select>
                                                        
                                                        <!-- Shin Unit Attached Boolean -->
                                                        <select *ngIf="currentActiveKey=='shinUnitAttachedBoolean' && showDropdown" 
                                                            class="form-select form-select-sm w-50"
                                                            style="display: inline;" 
                                                            name="value"
                                                            [(ngModel)]="deviceConfigModel[setting.configKey]" #value="ngModel">
                                                            <option value="true">True</option>
                                                            <option value="false" selected>False</option>
                                                        </select>

                                                        <!-- Demo Mode Boolean -->
                                                        <select
                                                            *ngIf="currentActiveKey=='demoMode' && showDropdown"
                                                            class="form-select form-select-sm w-50"
                                                            style="display: inline;" name="value"
                                                            [(ngModel)]="deviceConfigModel[setting.configKey]"
                                                            #value="ngModel">
                                                            <option value="true">True</option>
                                                            <option value="false" selected>False</option>
                                                        </select>
                                                        <!--Save Icon Button-->
                                                        <button type="submit" class="btn btn-sm mx-3">
                                                          <i class="bi bi-check-circle"></i>
                                                        </button>
                                                        <span *ngIf="setting.configKey=='eventThreshold'">
                                                          {{eventThreshold}}
                                                        </span>
                                                      </div>
                                                      <!--For edit mode-->
                                                    </td>
                                                    <td>
                                                        <button [disabled]="isEditConfig" type="button" class="btn btn-sm mx-3">
                                                            <i class="bi bi-pencil-square cursor-pointer"
                                                                (click)="editConfig(setting.configKey, i)">
                                                            </i>
                                                        </button>
                                                    </td>
                                                  </tr>
                                              </tbody>
                                        </table>
                                        <div class="error-block text-center">
                                            <span *ngIf="invalidConfig">
                                                <!-- This is an error message -->
                                              {{errorMessage}}
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                        class="login-button p-button-raised p-button-secondary p-button-sm">
                    </button>
                    <button pButton pRipple type="submit" label="Save" icon="pi pi-check"
                        class="login-button p-button-raised p-button-sm">
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!-- Assign Device Modal -->
<div class="modal fade" bsModal #assignModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Assign Device</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="assignModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <label for="site" class="col-sm-5 col-form-label">Assign device to wearer</label>
                    <div class="col-sm-7">
                        <select class="form-control form-select form-select-sm custom-form-control"
                            name="wearerId"
                            [(ngModel)]="wearerId">
                        <option value="" [selected]="true">Select wearer</option>
                        <option *ngFor="let wearer of wearerList" value="{{wearer.id}}">
                            {{wearer.firstName}} {{wearer.lastName}}
                        </option>
                        </select>
                        <div class="error-block" *ngIf="showError">
                            <span>(63b1.1)Wearer is mandatory</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="assignModal.hide()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Save" icon="pi pi-check" (click)="assignDevice()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Decommission Device Modal -->
<div class="modal fade" bsModal #decommissionModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Decommission Device</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="decommissionModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to decommission this device ?</p>
                <h6>Warning:</h6>
                <p>
                    If this device is decomissioned, it will no longer be able to be synced, 
                    and will need re-provisioning after being assigned to another wearer.
                </p>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="decommissionModal.hide()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Save" icon="pi pi-check" (click)="decommissionDevice()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>
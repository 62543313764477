<div class="filter-wrapper">
    <!-- <i class="bi bi-arrow-left-circle"></i> -->
    <div class="container">
        <div class="row mb-3">
            <div class="col-3">
                <h6>Filters</h6>
            </div>
            <div class="col-9" style="display: flex; justify-content: end;">
                <button type="button" class="btn btn-sm btn-primary mx-2" (click)="setFilterData()">
                    Search
                </button>
                <button type="button" class="btn btn-sm btn-secondary" (click)="resetFilter()">
                    Clear
                </button>
            </div>
        </div>
        <!-- Select site -->
        <div *ngIf="userRole != 'ROLE_SITE_MANAGER'">
            <div class="row mb-3" *ngIf="filterType == 'activity'">
                <div class="col-12">
                    <select name="" class="form-select form-select-sm" 
                        (change)="selectSite($event)"
                        [(ngModel)]="siteId">
                        <option value="" selected>Select site</option>
                        <option value={{site.id}} *ngFor="let site of siteList; let i=index">{{site.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <!-- Select wearer -->
        <div *ngIf="userRole != 'ROLE_SITE_MANAGER'">
            <div class="row mb-3" *ngIf="filterType == 'activity'">
                <div class="col-12">
                    <select name="" class="form-select form-select-sm" [(ngModel)]="wearerId"
                        (change)="selectWearer($event)">
                        <option value="0" selected>Select wearer</option>
                        <option value={{wearer.id}} *ngFor="let wearer of wearerList; let i=index">{{wearer.firstName}} {{wearer.lastName}}</option>
                    </select>
                </div>
            </div>
        </div>
        <!-- Site Name -->
        <div class="row mb-3" *ngIf="filterType == 'sites'">
            <div class="col-12">
                <input type="text" 
                    class="form-control form-control-sm" 
                    placeholder="Search by site name ..."
                    name="site"
                    [(ngModel)]="siteName"
                    #site="ngModel">
            </div>
        </div>
        <!-- Wearer Name -->
        <div class="row mb-3" *ngIf="filterType == 'wearers'">
            <div class="col-12">
                <input type="text" 
                    class="form-control form-control-sm" 
                    placeholder="Search wearer by name ..."
                    name="wearer"
                    [(ngModel)]="wearerName"
                    #wearer="ngModel">
            </div>
        </div>
        <div class="row mb-3">
            <span *ngIf="filterType == 'sites'">Created between</span>
            <span *ngIf="filterType == 'wearers' && wearerType == 'assigned'">Assigned between</span>
            <span *ngIf="filterType == 'activity'">Timeslice Between</span>
        </div>
        <!--Start Date-->
        <div class="row mb-3" *ngIf="(filterType == 'wearers' && wearerType == 'assigned') || (filterType == 'sites') || (filterType == 'activity')">
            <label for="">Start Date</label>
            <div class="col-12 input-group">
                <input type="text" 
                    class="form-control form-control-sm" 
                    name="startDateValue"
                    [placeholder]="'DD-MM-YYYY'"
                    [maxDate]="maxDate"
                    #dp="bsDatepicker" 
                    bsDatepicker
                    [(ngModel)]="dateDto.startDateValue" 
                    (ngModelChange)="filterByDate()"
                    [bsConfig]="{ isAnimated: true , dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                    (bsValueChange)="onValueChangeStart($event)">
                    <button class="btn btn-sm btn-date text-white" type="button" (click)="dp.toggle()">
                        <i class="bi bi-calendar-check-fill"></i>
                    </button>
            </div>
        </div>
        <!--End Date-->
        <div class="row mb-3" *ngIf="(filterType == 'wearers' && wearerType == 'assigned') || (filterType == 'sites') || (filterType == 'activity')">
            <label for="">End Date</label>
            <div class="col-12 input-group">
                <input type="text" class="form-control form-control-sm" 
                    name="endDateValue"
                    [placeholder]="'DD-MM-YYYY'"
                    [minDate]="dateDto.startDateValue"
                    [maxDate]="maxDate"
                    #dp1="bsDatepicker"
                    bsDatepicker
                    [(ngModel)]="dateDto.endDateValue" 
                    (ngModelChange)="filterByDate()" 
                    (bsValueChange)="onValueChangeEnd($event)"
                    [bsConfig]="{ isAnimated: true ,  dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}">
                    <button class="btn btn-sm btn-date text-white" type="button" (click)="dp1.toggle()">
                        <i class="bi bi-calendar-check-fill"></i>
                    </button>
            </div>
        </div>
        <!-- Sites -->
        <div class="row" *ngIf="filterType == 'wearers'">
            <div class="col-12">
                <select name="" class="form-select form-select-sm" [(ngModel)]="siteId">
                    <option value="" selected>Select site</option>
                    <option value={{site.id}} *ngFor="let site of siteList; let i=index">{{site.name}}</option>
                </select>
            </div>
        </div>
    </div>
</div>

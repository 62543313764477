import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'

import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';

import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'
import { InputTextModule } from 'primeng/inputtext'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { InputSwitchModule } from 'primeng/inputswitch' 
import { DropdownModule } from 'primeng/dropdown'

import { PaginationModule } from 'ngx-bootstrap/pagination'
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker' 



import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationComponent } from './administration.component';
import { UsersComponent } from './components/users/users.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { DevicesComponent } from './components/devices/devices.component';
import { AllocatedDevicesComponent } from './components/devices/allocated-devices/allocated-devices.component';
import { UnallocatedDevicesComponent } from './components/devices/unallocated-devices/unallocated-devices.component'; 
import { UnprovisionedDevicesComponent } from './components/devices/unprovisioned-devices/unprovisioned-devices.component';
import { ReportsComponent } from './components/reports/reports.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { PerformanceReportsComponent } from './components/performance-reports/performance-reports.component';
import { UnassignedDevicesComponent } from './components/devices/unassigned-devices/unassigned-devices.component';
import { ProvisionedDevicesComponent } from './components/devices/provisioned-devices/provisioned-devices.component';

import { AdminService } from '../../services/admin.service';
import { DeviceService } from '../../services/device.service';
import { DeviceApiBuilderService } from 'src/app/shared/api-builders/device-api-builder.service';



@NgModule({
  declarations: [
    AdministrationComponent,
    UsersComponent,
    SettingsComponent,
    CreateUserComponent,
    DevicesComponent,
    AllocatedDevicesComponent,
    UnallocatedDevicesComponent,
    UnprovisionedDevicesComponent,
    ReportsComponent,
    AlertsComponent,
    PerformanceReportsComponent,
    UnassignedDevicesComponent,
    ProvisionedDevicesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    ButtonModule,
    RippleModule,
    InputTextModule,
    InputTextareaModule,
    InputSwitchModule,
    DropdownModule,
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AdministrationRoutingModule
  ],
  providers: [
    AdminService,
    DeviceApiBuilderService,
    DeviceService

  ]
})
export class AdministrationModule { }

<div class="humiduty-wrapper">
    <div class="row">
        <!-- Filters -->
        <div class="col-md-3">
            <div class="filter-wrapper">
                <div class="container">
                    <div class="row mb-3">
                        <div class="col-3">
                            <h6>Filters</h6>
                        </div>
                        <div class="col-9" style="display: flex; justify-content: end;">

                            <button type="button" class="btn btn-sm btn-primary mx-2">Search</button>
                            <button type="button" class="btn btn-sm btn-secondary">Clear</button>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <select class="form-select form-select-sm">
                                <option value="0" selected>Select site</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <select class="form-select form-select-sm">
                                <option value="0" selected>Select wearer</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <span>Created between</span>
                    </div>

                    <div class="row mb-3">
                        <label for="">Start Date</label>
                        <div class="col-12 input-group">
                            <input type="text" class="form-control form-control-sm" #dp="bsDatepicker" bsDatepicker
                                [(ngModel)]="dateDto.startDateValue" name="startDateValue"
                                [bsConfig]="{ isAnimated: true , dateInputFormat: 'YYYY-MM-DD' }"
                                [placeholder]="'YYYY-MM-DD'" (ngModelChange)="filterByDate()"
                                (bsValueChange)="onValueChangeStart($event)">
                            <button class="btn btn-sm btn-date text-white" type="button" (click)="dp.toggle()">
                                <i class="bi bi-calendar-check-fill"></i>
                            </button>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <label for="">End Date</label>
                        <div class="col-12 input-group">
                            <input type="text" class="form-control form-control-sm" #dp1="bsDatepicker" bsDatepicker
                                [(ngModel)]="dateDto.endDateValue" name="endDateValue"
                                [bsConfig]="{ isAnimated: true ,  dateInputFormat: 'YYYY-MM-DD' }"
                                [placeholder]="'YYYY-MM-DD'" (ngModelChange)="filterByDate()"
                                (bsValueChange)="onValueChangeEnd($event)">
                            <button class="btn btn-sm btn-date text-white" type="button" (click)="dp1.toggle()">
                                <i class="bi bi-calendar-check-fill"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Table -->
        <div class="col-md-9">
            <div class="table-wrapper">
                <table class="table table-striped table-bordered text-center">
                    <thead>
                        <tr>
                            <th>Date/Time</th>
                            <th>Site</th>
                            <th>Wearer</th>
                            <th>Device</th>
                            <th>Min Threshold</th>
                            <th>Max Threshold</th>
                            <th>Mean Humidity</th>
                            <th>Timeslice Interval</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild, 
  ViewChildren, 
  QueryList, 
  ElementRef
} from '@angular/core'
import { NgForm } from '@angular/forms'
import * as _ from 'lodash'
import { ModalDirective } from 'ngx-bootstrap/modal'
import { CreateUser, GetUser, Role } from 'src/app/shared/models/user.model'
import { AdminService } from 'src/app/iot/services/admin.service'
import { UtilService } from 'src/app/shared/services/util.service'
import { LOADER_CONFIG, TOASTR_CONFIG } from 'src/app/shared/global-constants'
import { LoaderService } from 'src/app/iot/services/loader.service'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { SiteService } from 'src/app/iot/services/site.service'
import { countries } from 'src/app/shared/country-data-store'
import { Country } from 'src/app/shared/models/country.model'


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  loading: boolean = false
  loaderConfig = LOADER_CONFIG
  totalItems: number = 0
  currentPage: number = 1
  pageSize: number = 10
  user: CreateUser
  userModel: CreateUser
  roleList: Array<Role> = []
  roles: Array<string> = []
  userList: Array<GetUser> = []
  isSiteManager: boolean = false
  siteList: Array<any> = []
  allSites: Array<any> = []
  editMode: boolean = false
  activeUser: boolean = false
  currentRowData!: GetUser
  userAction: string = ''
  userId: string = ''
  loadingSubscription: Subscription
  countries: Array<Country> = countries

  @ViewChild('addUserForm', { static: false }) userForm!: NgForm
  @ViewChild('addUserModal', { static: false }) addUserModal!: ModalDirective
  @ViewChild('activateModal', { static: false }) activateModal!: ModalDirective
  @ViewChild('deleteModal', { static: false }) deleteModal!: ModalDirective
  @ViewChildren('usersDropDown') usersDropDown!: QueryList<ElementRef>
  @ViewChild('userRole') userRoleDropDown!: ElementRef
  constructor(private adminService: AdminService,
    private siteService: SiteService,
    public loaderService: LoaderService,
    private toastr: ToastrService,
    private router: Router,
    private utilService: UtilService) {
    this.userId = localStorage.getItem('user-id')!
    this.user = new CreateUser()
    this.userModel = _.cloneDeep(this.user)
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })
  }

  ngOnInit(): void {
    this.userModel.role = null
    this.getRoles()
    this.getAvailableSites()
    this.getAllSites(parseInt(this.userId))
    this.getAllUsers(this.currentPage, this.pageSize)
  }

  getRoles() {
    this.adminService.getUserRoles().subscribe({
      next: (response: any) => {
        
        this.roleList = response.filteredRoleList
        this.roles = response.augmentedRoleList
      },
      error: (err: any) => {
      }
    })
  }

  getAvailableSites() {
    this.siteService.getAvailableSites().subscribe({
      next: (response: any) => {
        this.siteList = response.DATA
      },
      error: () => {
      }
    })
  }

  getAllSites(userId: number) {
    this.siteService.getAllSites(userId).subscribe({
      next: (response: any) => {
        this.allSites = response.DATA
        localStorage.setItem('allSites', JSON.stringify(this.allSites))
      },
      error: (error: any) => {

      }
    })
  }


  getAllUsers(pageIndex: number, pageSize: number) {
    this.adminService.getAllUsers(pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.userList = response.Users
        this.totalItems = response.total
        
        for (let i = 0; i < this.userList.length; i++) {
          // console.log('user ID: ', this.userList[i].userId);
        }
      },
      error: (error: any) => {
      }
    })
  }

  showModal() {
    this.userModel.userRole = ''
    this.addUserModal.show()
  }

  hideModal() {
    switch (this.userAction) {
      case 'activate':
        this.activateModal.hide()
        break;

      case 'deactivate':
        this.activateModal.show()
        break

      case '':
      case 'edit':
        this.addUserModal.hide()
        break

      case 'delete':
        this.deleteModal.hide()
        break
      default:
        break;
    }
  }

  onHidden() {
    this.getAvailableSites()
    this.userModel = _.cloneDeep(this.user)
    this.userForm.resetForm(this.userModel)
    
    this.editMode = false
    this.isSiteManager = false
    this.utilService.resetDropdowns(this.usersDropDown)
  }

  selectRole(event: any) {
    
    const value = event.target.value
    const currentRole = this.roleList.filter((item) => item.description === value)[0]
    this.userModel.role = currentRole
    if (value === 'Site Manager') {
      this.isSiteManager = true
    } else {
      this.isSiteManager = false
    }
    
  }

  selectSite(event: any) {
    this.userModel.site = { id: event.target.value }
    this.userModel.siteId = event.target.value
  }

  selectUserAction(event: any, user: GetUser) {
    const action = event.target.value
    this.userAction = action
    this.currentRowData = user
    const userId = user.userId
    this.activeUser = (user.status == 'Active') ? true : false
    
    if (action == 'activate') {
      this.activateModal.show()
    } else if (action == 'deactivate') {
      this.activateModal.show()
    } else if (action == 'edit') {
      this.editMode = true
      this.getUserData(userId)
    } else if (action == 'delete') {
      this.deleteModal.show()
    }
  }

  saveUserData(form: NgForm) {
    if (!this.editMode) {
      this.addUser(this.userForm)
    } else {
      this.editUser(this.userForm)
    }
  }

  addUser(form: NgForm) {
    if (form.invalid) {
      return
    }
    if (!this.isSiteManager) {
      delete this.userModel.site
    }
    this.userModel.username = this.userModel.email
    this.adminService.createUser(this.userModel).subscribe({
      next: (response: any) => {
        this.toastr.success('User Created', '', TOASTR_CONFIG)
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(['iot/administration/users']);
        });
        this.addUserModal.hide()
      },
      error: (err: any) => {

      }
    })
  }

  editUser(form: NgForm) {
    if (form.invalid) {
      return
    }
    // delete this.userModel.userRole
    // delete this.userModel.userSite
    const userId = this.currentRowData.userId
    
    this.adminService.editUser(userId, this.userModel).subscribe({
      next: (response: any) => {
        
        this.toastr.success('User Updated', '', TOASTR_CONFIG)
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(['iot/administration/users']);
        });
        this.addUserModal.hide()

      },
      error: (error: any) => {

      }
    })
  }

  prepareSiteList(managerId: any, sitesList: Array<any>): Array<any> {
    
    sitesList = sitesList.filter((item: any) => {

      if (item.managerId === managerId || !item.managerId) {
        return item
      }
    })
    return sitesList
  }

  getUserData(userId: any) {

    this.adminService.getUserById(userId).subscribe({
      next: (response: any) => {
        
        let role = response.Users.role.authority
        this.userModel = response.Users
        this.userModel.userRole = response.Users.role.description
        this.isSiteManager = (this.userModel.userRole === 'Site Manager') ? true : false
        // this.siteList.push(this.userModel.site)
        
        if (role === 'ROLE_SITE_MANAGER') {
          this.userModel.userSite = (response.Users.site.id).toString()
          this.userModel.site = { id: response.Users.site.id }
          let managerId = response.Users.id
          this.siteList = this.prepareSiteList(managerId, JSON.parse(localStorage.getItem('allSites')!))
          
        }
        
        this.addUserModal.show()
      },
      error: () => { }
    })
  }

  updateUserStatus() {
    const userId = this.currentRowData.userId
    this.adminService.updateUserStatus(userId).subscribe({
      next: (response: any) => {
        this.toastr.success(response.message, '', TOASTR_CONFIG)
        this.activateModal.hide()
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(['iot/administration/users']);
        });
      },
      error: (error: any) => {
        
      }
    })
  }

  deleteUser() {
    const userId = this.currentRowData.userId
    this.adminService.deleteUser(userId).subscribe({
      next: (response: any) => {
        this.toastr.success('User Deleted', '', TOASTR_CONFIG)
        this.deleteModal.hide()
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(['iot/administration/users']);
        });
      },
      error: (error: any) => {
        this.deleteModal.hide()
      }
    })
  }

  restrictInput(event: any) {
    // const key = event.keyCode || event.which;
    // // Allow only numerical input (key codes 48 to 57 are for numbers 0-9)
    // if (key < 48 || key > 57) {
    //   event.preventDefault();
    // }
    
  }

  pageChanged(event: any): void {
    const page = event.page
    this.getAllUsers(page, this.pageSize)
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }
}

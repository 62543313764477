import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IotComponent } from './iot.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ADMIN_ROUTES } from './modules/administration/administration-routing.module';
import { ACTIVITY_ROUTES } from './modules/activity/activity-routing.module';
import { PATIENT_ROUTES } from './modules/patients/patients-routing.module';
import { ALERT_PROFILE_ROUTES } from './modules/alert-rule-profile/alert-rule-profile-routing.module';
import { HOSPITAL_ROUTES } from './modules/hospitals/hospitals-routing.module';
import { authGuard } from '../shared/guards/auth-guard';


export const IOT_ROUTES: Routes = [
  { 
    path: 'iot', 
    component: IotComponent, 
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authGuard]
      },
      {
        path: 'administration',
        children: ADMIN_ROUTES,
        canActivate: [authGuard]
      },
      {
        path: 'activity',
        children: ACTIVITY_ROUTES,
        canActivate: [authGuard]
      },
      {
        path: 'sites',
        children: HOSPITAL_ROUTES,
        canActivate: [authGuard]
      },
      {
        path: 'alert-rule-profiles',
        children: ALERT_PROFILE_ROUTES,
        canActivate: [authGuard]
      },
      {
        path: 'wearers',
        children: PATIENT_ROUTES,
        canActivate: [authGuard]
      }
    ]
  },
  
];

@NgModule({
  imports: [
    RouterModule.forChild(IOT_ROUTES)],
  exports: [RouterModule]
})
export class IotRoutingModule { }

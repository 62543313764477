import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit {
  currentTab: string = 'allocated'
  userRole: string
  userId: string

  constructor() {
    this.userId = localStorage.getItem('user-id')!
    this.userRole = localStorage.getItem('role')!
  }
  ngOnInit(): void {
    if (this.userRole == 'ROLE_SITE_MANAGER') {
      this.currentTab = 'unassigned'
      this.changeTab(this.currentTab)
    } else {
      this.currentTab = 'allocated'
    }
  }

  changeTab(tabName: string) {
    this.currentTab = tabName;
  }

}

<div class="patients-wrapper">
  <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="row">
        <!--Filters-->
        <div class="col-md-3">
            <app-filters [filterType]="'wearers'" [wearerType]="tabType"></app-filters>
        </div>

        <!--Patients List-->
        <div class="col-md-9">
            <!--Button group-->
            <div class="btn-group mb-3">
                <button type="button" class="btn btn-outline-primary"
                    [class.btn-active]="tabType == 'assigned'"
                    (click)="changeTab('assigned')">
                    Assigned
                </button>
                <button type="button" class="btn btn-outline-primary"
                    [class.btn-active]="tabType == 'unassigned'"
                    (click)="changeTab('unassigned')">
                    Unassigned
                </button>
            </div>
            <div class="table-wrapper">
                <div *ngIf="tabType == 'assigned'">
                    <table class="table table-striped table-bordered text-center">
                        <caption>List of assigned wearers</caption>
                        <thead>
                            <tr>
                              <th>Wearer</th>
                              <th>Site</th>
                              <th>Device</th>
                              <th>Assigned Date/Time</th>
                              <th>Last Sync Date/Time</th>
                              <th><i class="bi bi-battery-half"></i></th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let patient of assignedWearers; let i=index">
                                <td>
                                    <a class="" [routerLink]="['../wearer-activity', patient.id]">
                                        {{patient.firstName}} {{patient.lastName}}
                                    </a>
                                </td>
                                <td>{{patient.siteName}}</td>
                                <td>{{patient.deviceId}}</td>
                                <td>{{patient.assignedDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{patient.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{patient.batteryStrength}} %</td>
                                <td>
                                  <select #assignedDropdown name="" class="form-select form-select-sm"
                                    (change)="selectAssignedAction(patient, $event)">
                                    <option value="">Select</option>
                                    <option value="edit">Edit</option>
                                    <option value="unassign">Unassign</option>
                                    <!-- <option value="delete" *ngIf="userRole!='ROLE_SITE_MANAGER'">Delete</option> -->
                                  </select>
                                </td>
                            </tr>
                          </tbody>
                    </table>
                    <div class="paging">
                        <pagination 
                            [totalItems]="totalItems" 
                            previousText="&lsaquo;" 
                            nextText="&rsaquo;" 
                            firstText="&laquo;"
                            lastText="&raquo;"
                            [maxSize]="10" 
                            [itemsPerPage]="10" 
                            [boundaryLinks]="true"
                            [(ngModel)]="currentPage" 
                            (pageChanged)="pageChanged($event)">
                        </pagination>
                    </div>
                </div>
                <div *ngIf="tabType == 'unassigned'">
                    <table class="table table-striped table-bordered text-center">
                        <caption>List of unassigned wearers</caption>
                        <thead>
                            <tr>
                              <th>Wearer</th>
                              <th>Site</th>
                              <th class="w-25">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let patient of unassignedWearers; let i=index">
                                <td>
                                    <a class="" [routerLink]="['../wearer-activity', patient.id]">
                                        {{patient.firstName}} {{patient.lastName}}
                                    </a>
                                </td>
                                <td>{{patient.siteName}}</td>
                                <td>
                                    <select #unassignedDropdown name="" class="form-select form-select-sm"
                                        (change)="selectUnassignedAction(patient, $event)">
                                        <option value="">Select</option>
                                        <option value="edit">Edit</option>
                                        <option value="assign" [disabled]="!patient.assignable" >Assign</option>
                                        <option value="delete" *ngIf="userRole!='ROLE_SITE_MANAGER'">Delete</option>
                                    </select>
                                </td>
                            </tr>
                          </tbody>
                    </table>
                    <div class="paging">
                        <pagination 
                            [totalItems]="totalItems" 
                            previousText="&lsaquo;" 
                            nextText="&rsaquo;" 
                            firstText="&laquo;"
                            lastText="&raquo;" 
                            [maxSize]="10" 
                            [itemsPerPage]="10" 
                            [boundaryLinks]="true"
                            [(ngModel)]="currentPage" 
                            (pageChanged)="pageChanged($event)">
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Assign Wearer Modal-->
<div [config]="{backdrop: 'static'}" (onHidden)="onHidden()"
  bsModal #assignModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-capitalize">
          Assign Device to {{wearerObject.firstName}} {{wearerObject.lastName}}
        </h4>
        <button type="button" class="btn close" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <label for="" class="col-sm-5 col-form-label text-end">Assign Device</label>
          <div class="col-sm-6">
            <select name="" id="" class="form-select form-select-sm" (change)="selectDevice($event)">
              <option value="" selected>Select device to assign</option>
              <option value={{device.deviceId}} *ngFor="let device of assignableDevices">{{device.deviceId}}</option>
            </select>
          </div>
        </div>

      </div>
      <div class="modal-footer py-1">
        <button pButton pRipple 
            class="login-button p-button-raised p-button-secondary p-button-sm"
            type="button" label="Cancel" icon="pi pi-times" 
            (click)="hideModal()">
        </button>
        <button pButton pRipple 
            class="login-button p-button-raised p-button-primary p-button-sm"
            type="button" label="OK" icon="pi pi-check" 
            [disabled]="disableOK"
            (click)="assignDevice(wearerObject)">
        </button>
      </div>
    </div>
  </div>
</div>

<!--Delete Wearer Modal-->
<div [config]="{backdrop: 'static'}" (onHidden)="onHidden()"
  bsModal #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-capitalize">
          Delete Wearer
        </h4>
        <button type="button" class="btn close" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <p>Are you sure, you want to delete {{wearerObject.firstName}} {{wearerObject.lastName}} ?</p>
          <h6>Warning:</h6>
          <p>
            If wearer is deleted then they will no longer be able to be used again, 
            however existing timeslice data for the wearer will still be available.
          </p>
        </div>

      </div>
      <div class="modal-footer py-1">
        <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
            class="login-button p-button-raised p-button-secondary p-button-sm">
        </button>
        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" (click)="deleteWearer(wearerObject.id)"
            class="login-button p-button-raised p-button-sm">
        </button>
      </div>
    </div>
  </div>
</div>

<!--Unassign Modal-->
<div [config]="{backdrop: 'static'}" (onHidden)="onHidden()"
  bsModal #unassignModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-capitalize">
          Unassign Wearer
        </h4>
        <button type="button" class="btn close" aria-label="Close" (click)="unassignModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <p>Are you sure, you want to unassign {{wearerObject.firstName}} {{wearerObject.lastName}} ?</p>
          <h6>Warning:</h6>
          <p>            
            If device is unassigned from the wearer then the device will be set as decomissioned.  
            It will therefore no longer be able to be synced, and will need re-provisioning after 
            being assigned to another wearer.
          </p>
        </div>

      </div>
      <div class="modal-footer py-1">
        <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="unassignModal.hide()"
            class="login-button p-button-raised p-button-secondary p-button-sm">
        </button>
        <button pButton pRipple type="button" label="Save" icon="pi pi-check" (click)="unassignWearer(wearerObject.id)"
            class="login-button p-button-raised p-button-sm">
        </button>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { AdminApiBuilderService } from 'src/app/shared/api-builders/admin-api-builder.service'
import { Role, CreateUser, GetUser } from 'src/app/shared/models/user.model'
import { DatePipe } from '@angular/common'
import * as _ from 'lodash'

@Injectable()
export class AdminService {
    timeZone: string = localStorage.getItem('timezone')!
    datePipe: DatePipe = new DatePipe('en-US', this.timeZone)
    constructor(private http: HttpClient, 
        private adminApiBuilder: AdminApiBuilderService) {

    }

    getUserRoles(): Observable<Array<Role>> {
        return this.http.get(this.adminApiBuilder.getUserRolesUrl).pipe(map((response: any) => {
            const filteredRoleList = response.roleList.filter((item: Role) => item.id === 2 || item.id === 3)
            response.filteredRoleList = filteredRoleList
            response.augmentedRoleList = filteredRoleList.map((item: any) => item = item.description)
            return response
        }))
    }

    // Get list of sites with managerId = null
    // getAvailableSites() {
    //     return this.http.get(this.adminApiBuilder.getAvailableSitesUrl)
    // }

    // Get list of sites with managerId = null & managerId != null
    // getAllSites(userId: number) {
    //     const getAllSitesUrl = this.adminApiBuilder.getAllSitesUrl(userId)
    //     return this.http.get(getAllSitesUrl)
    // }

    createUser(userData: CreateUser) {
        return this.http.post(this.adminApiBuilder.createUserUrl, userData)
    }

    getAllUsers(pageIndex: number, pageSize: number): Observable<Array<GetUser>> {
        const getAllUsersUrl = this.adminApiBuilder.getAllUsersUrl(pageIndex, pageSize)
        return this.http.get(getAllUsersUrl).pipe(map((response: any) => {
            response.Users.map((item: any) => item.userRole = item.role.description)
            return response
        }))
    }

    getUserById(userId: any) {
        const getUserByIdUrl = this.adminApiBuilder.getUserByIdUrl(userId)
        return this.http.get(getUserByIdUrl)
        // return this.http.get(getUserByIdUrl).pipe(map((response: any) => {
        //     response.Users.userRole = response.Users.role.description
        //     response.Users.userSite = response.Users.site.id
        //     return response 
        // }))
    }

    editUser(userId: any, userData: CreateUser) {
        const editUserUrl = this.adminApiBuilder.editUserUrl(userId)
        return this.http.put(editUserUrl, userData)    
    }

    updateUserStatus(userId: number) {
        const activateDeactivateUrl = this.adminApiBuilder.updateUserStatusUrl(userId)
        return this.http.put(activateDeactivateUrl, userId)
    }

    deleteUser(userId: number) {
        const deleteUserUrl = this.adminApiBuilder.deleteUserUrl(userId)
        return this.http.put(deleteUserUrl, userId)
    }

    changePassword(requestData: any) {
        const {userId, settingsData} = requestData
        const changePasswordUrl = this.adminApiBuilder.changePasswordUrl(userId)
        return this.http.put(changePasswordUrl, settingsData)
    }

    saveNotifications(userId: number, isNotificationEnabled: boolean) {
        const toggleNotificationsUrl = this.adminApiBuilder.saveNotificationsUrl(userId, isNotificationEnabled)
        return this.http.put(toggleNotificationsUrl, {})
    }

    getUserNotificationStatus(userId: number) {
        const getUserNotificationStatusUrl = this.adminApiBuilder.getNotificationStatusUrl(userId)
        return this.http.get(getUserNotificationStatusUrl)
    }

    getAdminReports(requestData: any) {
        const adminReportsUrl = this.adminApiBuilder.getAdminReportsUrl(requestData)
        return this.http.get(adminReportsUrl)
    }

    getAdminReportsCSVData(requestData: any) {
        const adminReportCSVUrl = this.adminApiBuilder.getAdminReportsCSVUrl(requestData)
        // let maxNotesCount = 0
        return this.http.get(adminReportCSVUrl).pipe(map((response: any) => {
            let reportData = response.DATA
            let csvData: Array<any> = []
            let hasNotes: boolean = false
            let maxNotesCount = 0
            for (let index = 0; index < reportData.length; index++) {
                let csvDataObject: any = {}
                csvDataObject.id = reportData[index]['id']
                csvDataObject.site = reportData[index]['siteName']
                csvDataObject.Wearer = reportData[index]['wearerName']
                csvDataObject.alertCondition = reportData[index]['alertRule']
                csvDataObject.alertStatus = reportData[index]['alertStatus']
                csvDataObject.alertType = reportData[index]['alertType']
                csvDataObject.alertValue = reportData[index]['alertValue']
                csvDataObject.alertCreationDate = this.datePipe.transform(reportData[index]['alertCreationDate'], 'dd/MM/yyyy, hh:mm a', this.timeZone) 
                csvDataObject.deviceId = reportData[index]['deviceId']
                csvDataObject.dateTime = this.datePipe.transform(reportData[index]['alertReceiveDate'],'dd/MM/yyyy, hh:mm a', this.timeZone)
                csvDataObject.updatedAt = (reportData[index]?.lastNote) ? this.datePipe.transform(reportData[index]?.lastNote?.createdOn, 'dd/MM/yyyy, hh:mm a', this.timeZone) : 'NA'
                // csvDataObject.alertNotes = reportData[index]?.alertNotes
                if (reportData[index]?.alertNotes && reportData[index]?.alertNotes.length > 0) {
                    
                    // csvDataObject.noteCount = reportData[index]?.alertNotes.length
                    hasNotes = true
                    if (reportData[index]?.alertNotes.length > maxNotesCount) {
                        maxNotesCount = reportData[index]?.alertNotes.length
                    }
                         
                    
                    let noteList = reportData[index]?.alertNotes
                    
                    noteList.forEach((note: any, index: number) => {
                        
                        const noteColumns = {
                            [`alertNote${index + 1}`]: note.note,
                            [`alertNoteDate${index + 1}`]: this.datePipe.transform(note.createdOn, 'dd/MM/yyyy, hh:mm a', this.timeZone),
                            [`addedBy${index + 1}`]: note.addedBy,
                        }
                        
                        Object.assign(csvDataObject, noteColumns)
                        // response.maxNotes = maxNotesCount
                        // maxNotesCount = 0
                    })

                }
                // csvDataObject.hasNotes = hasNotes
                csvData.push(csvDataObject)
            }
            
            response.csvData = csvData
            
            // let highest: any = _.maxBy(response.csvData, 'noteCount')
            response.maxNotes = maxNotesCount
            return response
        }))
    }

}
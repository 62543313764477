export class CreateUser {
    username: string
    name: string
    email: string
    password: string
    confirmPassword: string
    role: Role | null
    userRole?: string
    site?: any
    siteId?:number | null
    userSite?: string
    notificationEmail: string
    houseNumber: string
    street: string
    city: string
    country: string
    postCode: string
    mobNo: string
    
    constructor() {
        this.username = ''
        this.name = ''
        this.email = ''
        this.password = ''
        this.confirmPassword = ''
        this.role = null
        this.userRole = ''
        this.site = null
        this.userSite = ''
        this.notificationEmail = ''
        this.houseNumber = ''
        this.street = ''
        this.city = ''
        this.country = ''
        this.postCode = ''
        this.mobNo = ''
        this.siteId = null
    }
}

export class GetUser {
    userId: number
    name: string
    userEmail: string
    userRole: string
    status: string
    lastLogin: string

    constructor() {
        this.userId = 0
        this.name = ''
        this.userEmail = ''
        this.userRole = ''
        this.status = ''
        this.lastLogin = ''
    }
}

export class Role {
    id: number = 0
    authority: string = ''
    description: string = ''
    active: boolean = true

    constructor() {}
}
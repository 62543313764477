import { Component, ViewChild, OnInit } from '@angular/core'
import { NgForm } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { ModalDirective } from 'ngx-bootstrap/modal'
import { AlertRuleProfileService } from 'src/app/iot/services/alert-rule-profile.service'
import { AlertRuleProfile } from 'src/app/shared/models/alert-rule-profile.model'

@Component({
  selector: 'app-create-alert-rule',
  templateUrl: './create-alert-rule.component.html',
  styleUrls: ['./create-alert-rule.component.scss']
})
export class CreateAlertRuleComponent implements OnInit {
  loading: boolean = false
  editMode: boolean = false
  alertRuleType: string = 'temperature'
  modalTitle: string = ''
  alertProfileModel: AlertRuleProfile
  alertProfileId: string = ''
  deviceSyncValue: number = 2
  lowBatteryValue: number = 10
  showError: boolean = false
  invalidDeviceSync: boolean = false
  invalidLowBattery: boolean = false

  @ViewChild('alertRuleModal', { static: false }) alertRuleModal!: ModalDirective
  @ViewChild('alertProfileForm', {static: false}) alertProfileForm!: NgForm


  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertProfileService: AlertRuleProfileService) {
    this.alertProfileModel = new AlertRuleProfile()
  }
  
  ngOnInit(): void {
    this.alertProfileId = this.activatedRoute.snapshot.queryParamMap.get('id')!
    if(this.alertProfileId) {
      this.editMode = true
      this.getAlertProfileById(parseInt(this.alertProfileId))
    }
    
  }

  showModal(alertRuleType: string) {
    this.alertRuleType = alertRuleType
    if (alertRuleType == 'low battery') {
      this.modalTitle = 'Low Battery'
    } else if (alertRuleType == 'device sync') {
      this.modalTitle = 'Device Sync'
    } else if (alertRuleType == 'activity') {
      this.modalTitle = 'Activity'
    }
    this.alertRuleModal.show()
  }

  onHidden() {
    this.showError = false
    this.invalidDeviceSync = false
    this.invalidLowBattery = false
    this.deviceSyncValue = 2
    this.lowBatteryValue = 10
  }

  updateValues() {
    if (this.alertRuleType == 'device sync') {
      if (!this.deviceSyncValue) {
        this.showError = true
        this.invalidDeviceSync = false
        return
      } else if (this.deviceSyncValue < 2 || this.deviceSyncValue > 10) {
        this.showError = true
        this.invalidDeviceSync = true
        return
      } 
      else {
        this.alertProfileModel.deviceSync = this.deviceSyncValue
      }
    } else if (this.alertRuleType == 'low battery') {
      if (!this.lowBatteryValue) {
        this.showError = true
        this.invalidLowBattery = false
        return
      } else if (this.lowBatteryValue < 10 || this.lowBatteryValue > 20) {
        this.showError = true
        this.invalidLowBattery = true
        return
      } else {
        this.alertProfileModel.lowBattery = this.lowBatteryValue
      }
    }
    this.alertRuleModal.hide()
  }

  hideModal() {
    this.alertRuleModal.hide()
  }

  getAlertProfileById(alertProfileId: number) {
    this.alertProfileService.getAlertProfileById(alertProfileId).subscribe({
      next: (response: any) => {
        this.alertProfileModel = response.DATA
      }, 
      error: (error: any) => {}
    })
  }

  saveAlertProfile() {
    if (this.editMode) {
      this.updateAlertProfile(this.alertProfileForm)
    } else {
      this.createAlertProfile(this.alertProfileForm)
    }
  }

  createAlertProfile(form: NgForm) {
    if(form.invalid) {
      return
    }
    this.alertProfileService.createAlertRuleProfile(this.alertProfileModel, 'PRE_CONFIGURED').subscribe({
      next: (response: any) => {

        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(["iot/alert-rule-profiles/manage-alert-rules"]);
        });
      },
      error: (error: any) => {

      }
    })
  }

  updateAlertProfile(form: NgForm) {
    if(form.invalid) {
      return
    }
    this.alertProfileService.updateAlertProfile(parseInt(this.alertProfileId), this.alertProfileModel).subscribe({
      next: (response: any) => {
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(["iot/alert-rule-profiles/manage-alert-rules"]);
        });
      },
      error: (error: any) => {

      }
    })
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class LoaderService {
    
    private isLoading = new BehaviorSubject<boolean>(false) 

    constructor() {

    }

    show() {
        this.isLoading.next(true)
    }

    hide() {
        this.isLoading.next(false)
    }

    isLoading$(): Observable<boolean> {
        return this.isLoading.asObservable();
    }
}
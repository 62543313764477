<div id="notfound">
    <div class="notfound">
        <div class="notfound-404">
            <h1>Oops!</h1>
            <h2>404 - The page can't be found</h2>
        </div>
        <button pButton pRipple type="button" 
            label="GO TO HOMEPAGE" 
            (click)="goToHomePage()"
            class="login-button p-button-raised p-button-sm w-50">
        </button>
    </div>
</div>
import { Component } from '@angular/core';

@Component({
  selector: 'app-impacts',
  templateUrl: './impacts.component.html',
  styleUrls: ['./impacts.component.scss']
})
export class ImpactsComponent {

  totalItems: number = 100
  currentPage: number = 1
  dateDto: any = {}

  constructor() {}

  filterByDate() {}

  onValueChangeStart(event: any) {}

  onValueChangeEnd(event: any) {}

  pageChanged(event: any) {}
}

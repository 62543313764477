<div class="open-alerts-wrapper">
    <div>
        <table class="table table-striped table-bordered text-center">
            <caption>List of opent alerts</caption>
            <thead>
                <tr>
                    <th>Site</th>
                    <th>Wearer</th>
                    <th>Device Id</th>
                    <th>Alert Type</th>
                    <th>Alert Rule/Fault</th>
                    <th>Alert Date</th>
                    <th>Alert Value %</th>
                    <th>Status</th>
                    <th>Elapsed Time</th>
                    <th>Last User</th>
                    <th>Last Note Date</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="paging">
        <button pButton pRipple type="button" 
            style="float: right;" label="Download CSV" icon="pi pi-download"
            (click)="downloadCSV()" class="login-button p-button-raised p-button-sm">
        </button>
        <pagination 
            [totalItems]="totalItems" 
            previousText="&lsaquo;" 
            nextText="&rsaquo;" 
            firstText="&laquo;"
            lastText="&raquo;" 
            [maxSize]="10" 
            [itemsPerPage]="10" 
            [boundaryLinks]="true"
            [(ngModel)]="currentPage" 
            (pageChanged)="pageChanged($event)">
        </pagination>
    </div>
</div>
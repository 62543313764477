<header style="position: fixed; background-color: #FFF; width: 100%; z-index: 1000; top: 0;">
    <!--Navbar-->
    <nav id="top-nav" class="navbar navbar-expand-lg navbar-light" style="background-color: #FFF; color: black;">
        <div class="container">
            <a [routerLink]="['/iot/dashboard']" class="navbar-brand">
                <img class="logo" src="../../../../assets/images/Compedica_Dark_Tagline.svg" alt="">
            </a>
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarColor02">
                <ul class="navbar-nav ms-md-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <i class="bi bi-person-circle user-icon" style="color: #212b55; margin-right: 0;"></i>
                        <!-- <img src="https://randomuser.me/api/portraits/men/64.jpg" alt="" style="width: 28%; border-radius: 50%; margin-right: 15px; float: right; border: 2px solid #707070;"> -->
                    </li>
                    <li class="nav-item dropdown">
                        <button type="button" class="btn btn-link nav-link dropdown-toggle user-btn"
                            data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                            <span>{{userName}}</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                            <li class="user-detail">
                                <p>
                                    <strong>{{userEmail}}</strong>
                                </p>
                                <p>
                                    <strong>{{userRole}}</strong>
                                </p>
                            </li>
                            
                            <li (click)="signoutUser()" style="cursor: pointer;">
                                <a class="dropdown-item">Signout</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!--Main Navigation-->
    <nav id="main-nav" class="navbar navbar-expand-lg navbar-dark" style="background-color: #FFF;  box-shadow: 0 4px 6px -6px #222;">
        <div class="container">
            <!-- <a href="#" class="navbar-brand">HP1t</a> -->
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarColor02" aria-controls="navbarColor02" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarColor02">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">

                        <a class="nav-link" routerLinkActive="active" aria-current="page" [routerLink]="['dashboard']">
                            <i class="bi bi-bar-chart-line-fill"></i>
                            Dashboard
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" [routerLink]="['wearers']">
                            <i class="bi bi-cpu-fill"></i>
                            Wearers
                        </a>
                    </li>
                    <li class="nav-item" *ngIf="userRole!=='Site Manager'">
                        <a class="nav-link" routerLinkActive="active" [routerLink]="['alert-rule-profiles']">
                            <i class="bi bi-exclamation-circle-fill"></i>
                            Alert Rule Profiles
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" [routerLink]="['sites']">
                            <i class="bi bi-geo-alt-fill"></i>
                            Sites
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" [routerLink]="['activity']">
                            <i class="bi bi-activity"></i>
                            Activity
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLinkActive="active" [routerLink]="['administration']">
                            <i class="bi bi-people-fill"></i>
                            Administration
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
import { 
  Component, 
  OnInit,
  OnDestroy, 
  ViewChild, 
  ViewChildren, 
  ElementRef, 
  QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AlertRuleProfileService } from 'src/app/iot/services/alert-rule-profile.service';
import { LoaderService } from 'src/app/iot/services/loader.service';
import { LOADER_CONFIG } from 'src/app/shared/global-constants';
import { UtilService } from 'src/app/shared/services/util.service';
@Component({
  selector: 'app-manage-alert-rules',
  templateUrl: './manage-alert-rules.component.html',
  styleUrls: ['./manage-alert-rules.component.scss']
})
export class ManageAlertRulesComponent implements OnInit,OnDestroy {

  loading: boolean = false
  loaderConfig = LOADER_CONFIG
  totalItems: number = 0
  currentPage: number = 1
  pageIndex: number = 1
  pageSize: number = 10
  alertProfileList: Array<any> = []
  alertProfileData: any
  alertProfileId: string = ''
  userAction: string = ''
  loadingSubscription!: Subscription

  @ViewChild('deleteModal', {static: false}) deleteModal!: ModalDirective
  @ViewChild('confirmationModal', {static: false}) confirmationModal!: ModalDirective
  @ViewChildren('alertAction') alertAction!: QueryList<ElementRef>

  constructor(private router: Router, 
    private utilService: UtilService, 
    private loaderService:LoaderService,
    private alertProfileService: AlertRuleProfileService) {

      this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
        this.loading = isLoading
      })

  }

  ngOnInit(): void {
    this.getAlertRuleProfiles(this.pageIndex, this.pageSize)
  }

  getAlertRuleProfiles(pageIndex: number, pageSize: number) {
    this.alertProfileService.getAllAlertProfiles('PRE_CONFIGURED', pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.alertProfileList = response.DATA
        this.totalItems = response.total
      },
      error: (error: any) => {

      }
    })
  }

  showModal() {
    switch (this.userAction) {
      case 'edit':
        this.confirmationModal.show()
        break;
      case 'delete':
        this.deleteModal.show()
        break;
      default:
        break;
    }
  }

  hideModal() {
    switch (this.userAction) {
      case 'edit':
        this.confirmationModal.hide()
        break;
      case 'delete':
        this.deleteModal.hide()
        break;
      default:
        break;
    }
  }

  onHidden() {
    this.userAction = ''
    this.utilService.resetDropdowns(this.alertAction)
  }

  selectAction(event: any, data: any) {
    this.userAction = event.target.value
    this.alertProfileData = data
    this.alertProfileId = data.id
    if (this.userAction == 'edit') {
      this.showModal()
    } else if (this.userAction == 'delete') {
      this.showModal()
    }
  }

  editAlertProfile() {
    this.router.navigateByUrl("iot/alert-rule-profiles/manage-alert-rules", { skipLocationChange: true }).then(() => {
      this.router.navigate(["iot/alert-rule-profiles/edit-alert-rule"], {queryParams: { id: this.alertProfileData.id }});
    });
  }

  deleteAlertProfile(alertProfileID: string) {
    this.alertProfileService.deleteAlertProfile(parseInt(alertProfileID)).subscribe({
      next: (response: any) => {
        this.alertProfileList = this.alertProfileList.filter((item: any) => { 
          return item.id != parseInt(alertProfileID)
        })
        this.deleteModal.hide()
      },
      error: (error: any) => {}
    })
  }

  pageChanged(event: any) {
    this.getAlertRuleProfiles(event.page, this.pageSize)
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }
}

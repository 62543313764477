import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http'
import { Observable, finalize, tap } from 'rxjs'

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    const startTime = Date.now()
    let status: string = ''

    return next.handle(request).pipe(
      tap({
        next: (event: any) => {
          if (event instanceof HttpResponse) {
            status = 'succeeded'
          }
        },
        error: (err: any) => {
          status = 'failed'
        }
      }), 
      finalize(() => {
        const elapsedTime = Date.now() - startTime
        const message = request.method + ' ' + request.urlWithParams + ' ' + status + ' in ' + elapsedTime/1000 + ' seconds'
        // console.log('Request: ', request)
        console.log('Logger: ', message)
        this.logRequestTime(request, startTime, status)
      })
    );
  }

  private logRequestTime(request: HttpRequest<any>, startTime: number, status: string) {
    if (!request || !request.url) {
      return
    }
    const endTime: Date = new Date()
    const duration: number = (endTime.valueOf() - startTime) / 1000
    
    const dataToLog: Record<string, number | string> = {
      method: request.method,
      requestURL: request.url,
      params: request.params.toString(),
      body: request.method == 'POST' ? request.body : 'NA',
      duration: duration + 'ms',
      status: status
    }
    // console.log('Logger: ', dataToLog)
  }
}


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './core/login/login.component';
import { ForgotPasswordComponent } from './core/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './core/reset-password/reset-password.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { IotRoutingModule, IOT_ROUTES } from './iot/iot-routing.module';
import { authGuard } from './shared/guards/auth-guard' 

const APP_ROUTES: Routes = [
  { 
    path: '', 
    redirectTo: 'login', 
    pathMatch: 'full' 
  },
  { 
    path: 'login', 
    component: LoginComponent
  },
  { 
    path: 'forgot-password', 
    component: ForgotPasswordComponent 
  },
  { 
    path: 'reset-password/:token' , 
    component: ResetPasswordComponent
  },
  { 
    path: 'iot',
    children: IOT_ROUTES,
    canActivate: [authGuard] 
     
  },
  { 
    path: '**', 
    component: PageNotFoundComponent 
  }       
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES),
    IotRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms'
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { Subscription } from 'rxjs';
import { CreatePatientService } from 'src/app/iot/services/create-patient.service'
import { LoaderService } from 'src/app/iot/services/loader.service';
import { TOASTR_CONFIG, LOADER_CONFIG } from 'src/app/shared/global-constants'
import { Mobile } from 'src/app/shared/models/mobile.model'
import { Wearer } from 'src/app/shared/models/wearer.model';
import { UtilService } from 'src/app/shared/services/util.service'

@Component({
  selector: 'app-wearer-mobile-devices',
  templateUrl: './wearer-mobile-devices.component.html',
  styleUrls: ['./wearer-mobile-devices.component.scss']
})

export class WearerMobileDevicesComponent implements OnInit, OnDestroy {
  loading: boolean = false
  btnStatus: string = 'save'
  editModileData: boolean = false
  defaultMobile: Mobile
  mobileModel: Mobile
  mobileList: Array<Mobile> = []
  userAction: string = ''
  currentActiveIndex: number = 0
  isSaveAlreadyPressed: boolean = false
  isEditMode: boolean = false
  disableSave: boolean = true
  wearerId: string = ''
  wearerModel!: Wearer
  currentMobile!: Mobile
  mobileId!: number
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG

  @ViewChild('mobileModal', { static: true }) mobileModal!: ModalDirective
  @ViewChild('deleteModal', { static: true }) deleteModal!: ModalDirective
  @ViewChild('mobileForm', {static: true}) mobileForm!: NgForm
  @ViewChildren('mobileDropdown') mobileDropdown!: QueryList<ElementRef>

  constructor(private createPatientService: CreatePatientService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private toastr: ToastrService, 
    private utilService: UtilService) {
    this.defaultMobile = new Mobile()
    this.mobileModel = cloneDeep(this.defaultMobile)
    this.currentMobile = cloneDeep(this.defaultMobile)
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })
  }

  ngOnInit(): void {
    this.wearerId = this.activatedRoute.snapshot.queryParamMap.get('id')!
    if(this.wearerId) {
      this.isEditMode = true
      this.getWearerMobileDevices(parseInt(this.wearerId))
    } else {
      this.wearerId = localStorage.getItem('wearerId')!
    }
  }

  getWearerMobileDevices(wearerId: number) {
    this.createPatientService.getWearerMobileDevices(wearerId).subscribe({
      next: (response: any) => {
        this.mobileList = response.DATA
      },
      error: (error: any) => {

      }
    })
  }

  mobileAction(event: any, index: any, mobile: any) {
    let action = event.target.value
    this.currentActiveIndex = parseInt(index);
    if (action === 'edit') {
      this.editModileData = true
      this.currentMobile = cloneDeep(mobile)
      this.mobileModal.show()
    } else if (action === 'delete') {
      if (mobile.id) {
        this.mobileId = mobile.id
        this.showDeleteModal(this.mobileId)
      } else {
        this.mobileList.splice(index, 1)
      }
    }
  }

  deleteMobile() {
    this.createPatientService.deleteWearerMobile(this.mobileId).subscribe({
      next: () => {
        
        this.toastr.success('Mobile deleted', '', TOASTR_CONFIG)
        this.deleteModal.hide()
        this.mobileList = this.mobileList.filter((item: any) => item.id != this.mobileId)
      }, 
      error: () => {
      }
    }) 
  }

  routeToNextTab(btnStatus: string) {
    const addedFormStatus: any = {};
    addedFormStatus.activeTabName = "mobile-devices";
    addedFormStatus.buttonStatus = btnStatus;
    this.createPatientService.broadcastNewPatient(addedFormStatus);
  }

  showDeleteModal(mobileId: any) {
    this.deleteModal.show()
  }

  showModal() {
    this.mobileForm.resetForm(this.defaultMobile)
    this.mobileModel = this.defaultMobile
    this.mobileModal.show()
  }

  hideModal() {
    this.mobileModal.hide()
  }

  onHidden() {
    this.utilService.resetDropdowns(this.mobileDropdown)
    // this.mobileForm.resetForm()
    this.editModileData = false
  }

  addMobileData(form: NgForm) {
    let androidDeviceId = this.currentMobile.androidDeviceId;
    if (form.invalid) {
      return
    }
    this.mobileModel = cloneDeep(this.currentMobile)
    if (this.mobileList.length == 0) {
      this.mobileList.push(this.mobileModel)
      this.mobileModal.hide();
      this.mobileModel = cloneDeep(this.defaultMobile)
    } else if (!this.editModileData) {
      for (let i = 0; i <= this.mobileList.length - 1; i++) {
        if (this.mobileList[i].androidDeviceId == androidDeviceId) {
          this.toastr.error('Mobile device already exists', '', TOASTR_CONFIG)
          return
        }
      }
      let data = cloneDeep(this.currentMobile)
      this.mobileList.push(data)
      this.mobileModal.hide();
      this.mobileModel = cloneDeep(this.defaultMobile)
      return
    } else {
      for (let i = 0; i <= this.mobileList.length - 1; i++) {
        if (this.mobileList[i].androidDeviceId == androidDeviceId) {
          this.mobileList[i] = this.mobileModel;
          this.mobileModal.hide();
          this.editModileData = false;
        }
      }
    }

  }

  saveMobileData(btnStatus: string) {
    if (btnStatus === 'save') {
      // TODO: 
      /**
       * 1. Integrate create API 
       * 2. Set isSaveAlreadyPressed = true inside next block
       * 3. Store wearer id & wearer data in local storage
       */
      this.prepareRequestData()
      this.createPatientService.updateWearer(parseInt(this.wearerId), this.wearerModel).subscribe({
        next: (response: any) => {
          this.isSaveAlreadyPressed = true
          this.disableSave = true
        },
        error: (error: any) => {

        }
      })
    } else {
      if (this.isSaveAlreadyPressed) {
        this.routeToNextTab(btnStatus)
      } else {
        if(this.disableSave) {
          this.routeToNextTab(btnStatus)
        } else {
          // TODO:
          /**
           * 1. Call add wearer api
           * 2. Store wearer id & wearer data in local storage
           * 3. Call fucntion routeToNextTab()
           */
          this.prepareRequestData()
          this.createPatientService.updateWearer(parseInt(this.wearerId), this.wearerModel).subscribe({
            next: (response: any) => {
              this.routeToNextTab(btnStatus)
            },
            error: (error: any) => {

            } 
          })
        }
      }
    }
  }

  addMobile() {
    this.userAction = 'add'
    this.currentMobile = this.currentMobile = cloneDeep(this.defaultMobile)
    this.showModal()
  }

  prepareRequestData() {
    let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
    this.wearerModel = new Wearer()
    this.wearerModel.id = wearerData.id
    this.wearerModel.firstName = wearerData.firstName
    this.wearerModel.lastName = wearerData.lastName
    this.wearerModel.birthDate = wearerData.birthDate
    this.wearerModel.gender = wearerData.gender
    this.wearerModel.height = wearerData.height
    this.wearerModel.weight = wearerData.weight
    this.wearerModel.shoeSize = wearerData.shoeSize
    this.wearerModel.siteId = wearerData.siteId
    this.wearerModel.mobiles = this.mobileList
  }

  mobileModelChanged(event: any) {
    this.disableSave = false
  }

  updateWearerMobiles() {
    
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }
}

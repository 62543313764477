import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

import { ToastrModule } from 'ngx-toastr'
import { NgxLoadingModule } from 'ngx-loading'

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { PaginationModule } from 'ngx-bootstrap/pagination' 
import { ModalModule } from 'ngx-bootstrap/modal'

import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'
import { DropdownModule } from 'primeng/dropdown'

import { HospitalsRoutingModule } from './hospitals-routing.module'
import { HospitalsComponent } from './hospitals.component'
import { ManageHospitalsComponent } from './components/manage-hospitals/manage-hospitals.component'
import { CreateHospitalsComponent } from './components/create-hospitals/create-hospitals.component'
import { HospitalHeaderComponent } from './components/hospital-header/hospital-header.component'
import { HospitalDetailsComponent } from './components/hospital-details/hospital-details.component'
import { MobileDetailsComponent } from './components/mobile-details/mobile-details.component'

import { CreateSiteService } from '../../services/create-site.service'

import { ManageHospitalsService } from '../../services/manage-hospitals.service'
import { SharedModule } from 'src/app/shared/shared.module'
import { SiteService } from '../../services/site.service'
import { SitesApiBuilderService } from 'src/app/shared/api-builders/sites-api-builder.service'

@NgModule({
  declarations: [
    HospitalsComponent,
    ManageHospitalsComponent,
    CreateHospitalsComponent,
    HospitalHeaderComponent,
    HospitalDetailsComponent,
    MobileDetailsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forChild(),
    HospitalsRoutingModule
  ],
  providers: [
    CreateSiteService,
    SiteService,
    SitesApiBuilderService,
    ManageHospitalsService
  ]
})
export class HospitalsModule { }

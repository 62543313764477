import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class UserService {

  isLoggedIn: boolean = false

  constructor() { }

  isUserLoggedIn(): Observable<boolean> {
    const authToken = localStorage.getItem('authToken')
    if(authToken) {
      this.isLoggedIn = true
    } else {
      this.isLoggedIn = false
    }
    return of(this.isLoggedIn)
  }

  setUserLoggedInStatus(status: boolean) {
    this.isLoggedIn = status
  }

  setUserData(userData: any) {
    localStorage.setItem('authToken', userData['auth-token'])
    localStorage.setItem('role', userData['role'].authority)
    localStorage.setItem('name', userData['name'])
    localStorage.setItem('email', userData['email'])
    localStorage.setItem('username', userData['userName'])
    localStorage.setItem('user-id', userData['id'])
    localStorage.setItem('user-role', userData['role'].description)
  }

  getUserRole() {
    const userRole = localStorage.getItem("role")
    return userRole
  }


}

<div class="assign-device-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <form #deviceForm="ngForm" (ngSubmit)="saveAssignedDevice()">
        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end">Assign Device:</label>
            <div class="col-sm-3">
                <select class="form-select form-select-sm" (change)="selectDevice($event)"
                    name="wearerDeviceId"
                    [(ngModel)]="wearerDevice"
                    #wearerDeviceId="ngModel" required>
                    <option value="">Select device</option>
                    <option *ngFor="let device of deviceList" value={{device.deviceId}}>
                        {{device.deviceId}}
                    </option>
                </select>
                <!-- submitted: {{deviceForm.submitted}}
                wearerDevice blank {{wearerDevice == ''}} -->
                <!-- <div class="error-block" *ngIf="wearerDeviceId.invalid && ((deviceForm.submitted && wearerDevice == '') || wearerDeviceId.dirty || wearerDeviceId.touched)">
                    <span *ngIf="wearerDeviceId.errors?.['required']">
                        Device ID is required
                    </span>
                </div> -->
            </div>
        </div>
        <div class="btn-wrapper">
            <!-- <button pButton pRipple 
                type="button" 
                icon="pi pi-save" 
                label="Save" 
                (click)="assignDevice()"
                class="p-button-raised p-button-sm mx-2">
            </button> -->
            <button pButton pRipple 
                type="submit" 
                icon="pi pi-save" 
                label="Save" 
                class="p-button-raised p-button-sm mx-2">
            </button>
          </div>
    </form>
</div>
<!-- <p>assign-device works!</p>
<button type="button" class="btn btn-primary btn-sm mx-2">Next</button>
<button type="button" class="btn btn-primary btn-sm mx-2">Prev</button> -->
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdministrationComponent } from './administration.component';
import { UsersComponent } from './components/users/users.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { PerformanceReportsComponent } from './components/performance-reports/performance-reports.component';
import { DevicesComponent } from './components/devices/devices.component';
import { AllocatedDevicesComponent } from './components/devices/allocated-devices/allocated-devices.component';
import { UnallocatedDevicesComponent } from './components/devices/unallocated-devices/unallocated-devices.component';
import { UnassignedDevicesComponent } from './components/devices/unassigned-devices/unassigned-devices.component';
import { UnprovisionedDevicesComponent } from './components/devices/unprovisioned-devices/unprovisioned-devices.component';
import { ProvisionedDevicesComponent } from './components/devices/provisioned-devices/provisioned-devices.component';
import { adminGuard } from 'src/app/shared/guards/admin-guard';

export const ADMIN_ROUTES: Routes = [
  { 
    path: '', 
    component: AdministrationComponent,
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full'
      },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [adminGuard]
      },
      {
        path: 'create-user',
        component: CreateUserComponent
      },
      {
        path: 'settings',
        component: SettingsComponent
      },
      {
        path: 'performance-reports',
        component: PerformanceReportsComponent,
        // canActivate: [adminGuard]
      },
      {
        path: 'open-alerts',
        component: AlertsComponent
      },
      {
        path: 'devices',
        component: DevicesComponent,
        children: [
          {
            path: 'unallocated',
            component: UnallocatedDevicesComponent
          },
          {
            path: 'unassigned',
            component: UnassignedDevicesComponent
          },
          {
            path: 'unprovisioned',
            component: UnprovisionedDevicesComponent
          },
          {
            path: 'provisioned',
            component: ProvisionedDevicesComponent
          },
          {
            path: '',
            redirectTo: 'unallocated',
            pathMatch: 'full'
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(ADMIN_ROUTES)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }

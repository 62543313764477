import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'
import { ToastrModule } from 'ngx-toastr'

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { ModalModule } from 'ngx-bootstrap/modal'
import { PaginationModule } from 'ngx-bootstrap/pagination'
import { NgApexchartsModule } from 'ng-apexcharts';


import { ActivityRoutingModule } from './activity-routing.module';
import { ActivityComponent } from './activity.component';
import { ImpactsComponent } from './components/impacts/impacts.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { GraphComponent } from './components/graph/graph.component';
import { PressureComponent } from './components/pressure/pressure.component';
import { TemperatureComponent } from './components/temperature/temperature.component';
import { HumidityComponent } from './components/humidity/humidity.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { GraphDemoComponent } from './components/graph-demo/graph-demo.component';
import { NgxLoadingModule } from 'ngx-loading';


@NgModule({
  declarations: [
    ActivityComponent,
    ImpactsComponent,
    AlertsComponent,
    GraphComponent,
    PressureComponent,
    TemperatureComponent,
    HumidityComponent,
    GraphDemoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ToastrModule.forRoot(),
    ButtonModule,
    RippleModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    PaginationModule.forRoot(),
    NgApexchartsModule,
    ActivityRoutingModule
  ]
})
export class ActivityModule { }

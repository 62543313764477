import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { patientData } from './mock-data'
import { FilterService } from 'src/app/shared/services/filter.service';
import { WearerService } from 'src/app/iot/services/wearer.service';
import { CreatePatientService } from 'src/app/iot/services/create-patient.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { UtilService } from 'src/app/shared/services/util.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TOASTR_CONFIG, LOADER_CONFIG } from 'src/app/shared/global-constants';
import { LoaderService } from 'src/app/iot/services/loader.service';
@Component({
  selector: 'app-manage-patients',
  templateUrl: './manage-patients.component.html',
  styleUrls: ['./manage-patients.component.scss']
})
export class ManagePatientsComponent implements OnInit, OnDestroy {
  dateDto: any = {}
  tabType: string = 'assigned'
  loading: boolean = false
  userId: string = ''
  userRole: string = ''
  userAction: string = ''
  currentPage: number = 1;
  totalItems: number = 0;
  pageIndex: number = 1;
  pageSize: number = 10;
  assignedWearers: Array<any> = []
  unassignedWearers: Array<any> = []
  filterData: any = {}
  wearerObject: any = {}
  assignableDevices: Array<any> = []
  disableOK: boolean = true
  deviceId: String = ''
  filterSubcription!: Subscription
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG

  @ViewChild('deleteModal', { static: false }) deleteModal!: ModalDirective
  @ViewChild('assignModal', {static: false}) assignModal!: ModalDirective
  @ViewChild('unassignModal', {static: false}) unassignModal!: ModalDirective
  @ViewChildren('assignedDropdown') assignedDropdown!: QueryList<ElementRef>;
  @ViewChildren('unassignedDropdown') unassignedDropdown!: QueryList<ElementRef>;
 
  constructor(private router: Router,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private createPatientService: CreatePatientService,
    private utilService: UtilService,
    private wearerService: WearerService,
    private filterService: FilterService) {
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('user-id')!
    this.userRole = localStorage.getItem('role')!
    this.getAssignedWearers(parseInt(this.userId), this.pageIndex, this.pageSize)
    
    this.filterSubcription = this.filterService.selectedFilters$.subscribe({
      next: (value: any) => {
        this.filterData = value
        if (this.filterData.wearerName == '' && this.filterData.cleared) {
          this.pageIndex = 1
          if (this.tabType == 'assigned') {
            this.getAssignedWearers(parseInt(this.userId), this.pageIndex, this.pageSize)
          } else {
            this.getUnassignedWearers(parseInt(this.userId), this.pageIndex, this.pageSize)
          }
        } else if (this.filterData.searchClicked) {
          this.filterData.userId = this.userId
          // this.filterData.pageIndex = this.pageIndex
          this.filterData.pageIndex = 1
          this.filterData.pageSize = this.pageSize
          if (this.tabType == 'assigned') {
            this.filterWearers(this.filterData)  
          } else if (this.tabType == 'unassigned') {
            delete this.filterData.startDate
            delete this.filterData.endDate
            this.filterWearers(this.filterData)
          }
        }
      },
      error: (err: any) => { }
    })
  }

  getAssignedWearers(userId: number, pageIndex: number, pageSize: number) {
    this.wearerService.getAssignedWearers(userId, pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.assignedWearers = response.DATA
        this.totalItems = response.total
        
      },
      error: (err: any) => {

      }
    })
  }

  getUnassignedWearers(userId: number, pageIndex: number, pageSize: number) {
    this.wearerService.getUnassignedWearers(userId, pageIndex, pageSize).subscribe({
      next: (response: any) => {
        this.unassignedWearers = response.DATA
        this.totalItems = response.total
        
      },
      error: (err: any) => {

      }
    })
  }

  filterWearers(filterData: any) {
    if (this.tabType == 'assigned') {
      this.wearerService.filterWearers(filterData).subscribe({
        next: (response: any) => {
          this.assignedWearers = response.DATA
          this.totalItems = response.total
        },
        error: (error: any) => {}
      })
    } else if (this.tabType == 'unassigned') {
      this.wearerService.filterWearers(filterData).subscribe({
        next: (response: any) => {
          this.unassignedWearers = response.DATA
          this.totalItems = response.total
        },
        error: (error: any) => {

        }
      })
    }
  }

  pageChanged(event: any) {
    this.pageIndex = event.page
    if (this.tabType == 'assigned') {
      if (this.filterData.siteId && this.filterData.siteId != '') {
        this.filterData.pageIndex = event.page
        this.filterWearers(this.filterData)
      } else {
        this.getAssignedWearers(parseInt(this.userId), this.pageIndex, this.pageSize)
      }
      
    } else if (this.tabType == 'unassigned') {
      if (this.filterData.siteId && this.filterData.siteId != '') {
        this.filterData.pageIndex = event.page
        this.filterWearers(this.filterData)
      } else {
        this.getUnassignedWearers(parseInt(this.userId), this.pageIndex, this.pageSize)
      }
    }
  }

  changeTab(tabName: string) {
    this.tabType = tabName
    this.pageIndex = 1
    this.currentPage = 1
    
    this.filterService.resetFilterData(this.filterData)
    this.filterService.clearSearch('')
    this.filterService.clearSiteName('')
    if (this.tabType == 'assigned') {
      this.getAssignedWearers(parseInt(this.userId), this.pageIndex, this.pageSize)
    } else {
      this.getUnassignedWearers(parseInt(this.userId), this.pageIndex, this.pageSize)
    }
  }

  selectAssignedAction(wearerData: any, event: any) {
    let action = event.target.value
    
    if (action == 'edit') {
      this.router.navigateByUrl("iot/wearers/manage-wearers", { skipLocationChange: true }).then(() => {
        this.router.navigate(["iot/wearers/edit-wearer"], {
          queryParams: { id: wearerData.id }
        });
      });
    } else if (action == 'delete') {
      this.wearerObject = wearerData
      this.userAction = 'delete'
      this.showModal()
    } else if(action == 'unassign') {
      // show unassign modal
      this.wearerObject = wearerData
      this.unassignModal.show()
      // this.unassignWearer(parseInt(wearerData.id))
    }
  }

  selectUnassignedAction(wearerData: any, event: any) {
    let action = event.target.value;
    this.userAction = action;
    this.wearerObject = wearerData;
    
    if (action == 'edit') {
      this.router.navigateByUrl("iot/wearers/manage-wearers", { skipLocationChange: true }).then(() => {
        this.router.navigate(["iot/wearers/edit-wearer"], {
          queryParams: { id: wearerData.id }
        });
      });
    } else if (action == 'assign') {
      this.getAssignableDevices(this.userId, wearerData.siteId)
      this.showModal();
    }  else if (action == 'delete')  {
      this.showModal();
    }
  }

  showModal() {
    if(this.userAction == 'delete') {
      this.deleteModal.show()
    } else if (this.userAction == 'assign') {
      this.assignModal.show()
    } else if (this.userAction == 'unassign') {
      this.unassignModal.show()
    }
  }

  hideModal() {
    if (this.userAction == 'delete') {
      this.deleteModal.hide()
    } else if (this.userAction == 'assign') {
      this.assignModal.hide()
    }
  }

  onHidden() {
    if(this.tabType == 'assigned') {
      this.utilService.resetDropdowns(this.assignedDropdown)
    } else {
      this.utilService.resetDropdowns(this.unassignedDropdown)
    }
  }

  getAssignableDevices(userId: any, siteId: any) {
    this.createPatientService.getAssignableDevices(userId, siteId).subscribe({
      next: (response: any) => {
        this.assignableDevices = response.DATA
      }, 
      error: (error: any) => {
      }
    });
  }

  selectDevice(event: any) {
    this.disableOK = (event.target.value == '') ? true : false;
    this.deviceId = event.target.value;
  }

  deleteWearer(wearerId: number) {
    this.wearerService.deleteWearer(wearerId).subscribe({
      next: (response: any) => {
        
        if (this.tabType == 'assigned') {
          this.assignedWearers = this.assignedWearers.filter((wearer: any) => {
            return wearer.id != wearerId
          })
          this.deleteModal.hide()
        } else {
          this.unassignedWearers = this.unassignedWearers.filter((wearer: any) => {
            return wearer.id != wearerId
          })
          this.deleteModal.hide()
        }
      },
      error: (error: any) => {

      }
    })
  }
  
  assignDevice(wearerData: any) {
    this.wearerService.assignDevice(this.deviceId, parseInt(wearerData.id)).subscribe({
      next: (success: any) => {
        this.unassignedWearers = this.unassignedWearers.filter((wearer: any) => {
          return wearer.id != wearerData.id
        })
        this.assignModal.hide()
      },
      error: () => {
        this.assignModal.hide()
      }
    })
  }

  unassignWearer(wearerId: number) {
    this.wearerService.unassignDevice(wearerId).subscribe({
      next: (success: any) => {
        this.unassignModal.hide()
        this.toastr.success('Wearer Unassigned', '', TOASTR_CONFIG)      
        this.changeTab('assigned')
      }, 
      error: (err: any) => {
      }
    })
  }

  ngOnDestroy() {
    this.filterSubcription.unsubscribe()
    this.loadingSubscription.unsubscribe()
  }

}

<!--Admin Navigation-->
<div class="navigation mb-3">
    <ul class="nav justify-content-start">
        <li class="nav-item" *ngIf="userRole!='ROLE_SITE_MANAGER'">
            <a class="nav-link" [routerLink]="['users']" routerLinkActive="active">
                Users
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['settings']" routerLinkActive="active">
                Settings
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['performance-reports']" routerLinkActive="active">
                Reports
            </a>
        </li>
        
        <li class="nav-item">
            <a class="nav-link" [routerLink]="['devices']" routerLinkActive="active">
                Devices
            </a>
        </li>
    </ul>
</div>
<router-outlet></router-outlet>
<div class="activity-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="wearer-activity">
        <div class="mb-2 w-100">
            <div class="heading-box py-2 px-3">
                <p class="mb-0">Wearer: {{wearerName}}</p>
            </div>
        </div>
        <div class="inner-box row m-3 px-0">
            <div class="table-wrapper">
                <table class="table table-striped table-bordered text-center">
                    <caption>List of Wearer Activity</caption>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <!-- <th>Site Name</th> -->
                            <th>Time Slice Start</th>
                            <th>Time Slice End</th>
                            <th>SU Active Time</th>
                            <th>Steps</th>
                            <th>Temp</th>
                            <th>Humidity</th>
                            <th>C1</th>
                            <th>C2</th>
                            <th>C3</th>
                            <th>C4</th>
                            <th>C5</th>
                            <th>Foot</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let activity of activityList; let i=index">
                            <td>{{activity.id}}</td>
                            <td>{{activity.timeSliceStart}}</td>
                            <td>{{activity.timeSliceEnd}}</td>
                            <td>{{activity.shinUnitOperationTime}}</td>
                            <td>{{activity.stepCount}}</td>
                            <td>{{activity.temperature}}</td>
                            <td>{{activity.humidity}}</td>
                            <td>{{activity.channel1Percentage}}</td>
                            <td>{{activity.channel2Percentage}}</td>
                            <td>{{activity.channel3Percentage}}</td>
                            <td>{{activity.channel4Percentage}}</td>
                            <td>{{activity.channel5Percentage}}</td>
                            <td>{{activity.average}}</td>
                        </tr>
                    </tbody>
                </table>
                <!--Pagination widget-->
                <div class="paging">
                    <!-- <pagination [totalItems]="totalItems" 
                        previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                        [maxSize]="10"
                        [boundaryLinks]="true" 
                        [(ngModel)]="currentPage" 
                        (pageChanged)="pageChanged($event)">
                    </pagination> -->
                    <!-- <button class="btn btn-cancel" type="button" (click)="routeToWearers()">Cancel</button> -->
                    <button class="login-button p-button-raised p-button-sm" 
                        pButton 
                        pRipple 
                        type="button" 
                        style="float: right;" 
                        label="Go Back" 
                        (click)="routeToWearers()">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
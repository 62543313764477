import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment.development'

@Injectable({ providedIn: 'root' })
export class AdminApiBuilderService {

  public serverUrl: string = `${environment.host}/api/v1`

  constructor() { }

  getUserRolesUrl: string = `${this.serverUrl}/auth/roles`

  // getAvailableSitesUrl: string = `${this.serverUrl}/site/getsites`

  // getAllSitesUrl = (userId: number) => `${this.serverUrl}/site/all/${userId}`

  createUserUrl: string = `${this.serverUrl}/admin/adduser`

  getAllUsersUrl = (pageIndex: number, pageSize: number) => `${this.serverUrl}/admin/users/${pageIndex}/${pageSize}`

  getUserByIdUrl = (userId: any) => `${this.serverUrl}/admin/user/${userId}`

  editUserUrl = (userId: any) => `${this.serverUrl}/admin/edituser/${userId}`

  updateUserStatusUrl = (userId: number) => `${this.serverUrl}/admin/activatedeactivateuser/${userId}`

  deleteUserUrl = (userId: number) => `${this.serverUrl}/admin/deleteuser/${userId}`

  changePasswordUrl = (userId: number) => `${this.serverUrl}/admin/update-password/${userId}`

  saveNotificationsUrl = (userId: any, isNotificationsEnabled: boolean) => `${this.serverUrl}/admin/update-notification/${userId}/${isNotificationsEnabled}`
  
  getNotificationStatusUrl = (userId: number) => `${this.serverUrl}/auth/getUserNotificationStatus/${userId}`

  getAdminReportsUrl = (requestData: any) => {
    const {userId, pageIndex, pageSize, reportType, siteId, startDate, endDate} = requestData
    let adminReportsUrl = `${this.serverUrl}/alerts/getPaginatedPerformanceReport/${userId}/${pageIndex}/${pageSize}/${reportType}?`
    const queryParams = []
    if (siteId && siteId !== '0') {
      queryParams.push(`siteId=${siteId}`)
    }

    if (startDate && endDate) {
      queryParams.push(`dateFrom=${startDate}&dateTo=${endDate}`)
    }
    adminReportsUrl += queryParams.join('&') 
    return adminReportsUrl
  }

  getAdminReportsCSVUrl = (requestData: any) => {
    const {userId, reportType, siteId, startDate, endDate} = requestData
    let adminReportCSVUrl = `${this.serverUrl}/alerts/getNonPaginatedPerformanceReport/${userId}/${reportType}?`
    const queryParams = []
    if (siteId && siteId !== '0') {
      queryParams.push(`siteId=${siteId}`)
    }

    if (startDate && endDate) {
      queryParams.push(`dateFrom=${startDate}&dateTo=${endDate}`)
    }
    adminReportCSVUrl += queryParams.join('&') 
    return adminReportCSVUrl
  }

}

<div class="alerts-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="row">
        <!--Alert Filters-->
        <div class="col-md-2">
            <div class="filter-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="check-all"  
                                    name="all"
                                    [(ngModel)]="allChecked"
                                    #all="ngModel"
                                    value="all"
                                    (change)="checkStatus($event)">
                                <label class="form-check-label" for="check-all">
                                  All
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="check-open"  
                                  name="open"
                                  [(ngModel)]="openChecked"
                                  #open="ngModel"
                                  value="open"
                                  (change)="checkStatus($event)">
                                <label class="form-check-label" for="check-open">
                                  Open
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="check-pending"  
                                  name="pending"
                                  [(ngModel)]="pendingChecked"
                                  #pending="ngModel"
                                  value="pending"
                                  (change)="checkStatus($event)">
                                <label class="form-check-label" for="check-pending">
                                  Pending
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="check-closed"  
                                  name="closed"
                                  [(ngModel)]="closeChecked"
                                  #closed="ngModel"
                                  value="closed"
                                  (change)="checkStatus($event)">
                                <label class="form-check-label" for="check-closed">
                                  Closed
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>

        <!--Alert List-->
        <div class="col-md-10">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group mb-3">
                        <select class="form-control form-select form-select-sm"
                            (change)="changeSite($event)" 
                            name="site"
                            [(ngModel)]="siteId"
                            #site="ngModel">
                            <option value="0">Select site</option>
                            <option value={{site.id}} *ngFor="let site of siteList">{{site.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group mb-3">
                        <select class="form-control form-select form-select-sm"
                            (change)="changeWearer($event)" 
                            name="wearer"
                            [(ngModel)]="wearerId">
                            <option value="0">Select Wearer</option>
                            <option value={{wearer.id}} *ngFor="let wearer of wearerList">
                                {{wearer.firstName}} {{wearer.lastName}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group mb-3">
                        <select class="form-control form-select form-select-sm" 
                            (change)="changeAlertType($event)"
                            name="alert"
                            [(ngModel)]="alertType"
                            #alert="ngModel">
                            <option value="">Select alert type</option>
                            <option value="DEVICE_SYNC">Device Sync</option>
                            <option value="LOW_BATTERY">Low Battery</option>
                            <option value="STEP_TARGET">Target Steps</option>
                            <option value="MAXIMUM_USE">Maximun hours of usage</option>
                            <option value="TARGET_USE">Target usage</option>
                            <option value="FAULT">Fault</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group mb-3">
                        <button type="button" class="btn btn-primary btn-sm" (click)="clearFilters()">Clear</button>
                    </div>
                </div>
            </div>
            <!--Activity Table-->
            <div class="table-wrapper">
                <table class="table table-striped table-bordered text-center">
                    <caption>List of wearer activities</caption>
                    <thead>
                        <tr>
                            <th>Alert ID</th>
                            <th>Site</th>
                            <th>Wearer</th>
                            <th>Device Id</th>
                            <th>Date/Time</th>
                            <th>Alert Type</th>
                            <th>Alert Rule</th>
                            <th>Status</th>
                            <th>Last Entry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let alert of alertList; let i = index">
                            <td style="cursor: pointer;" (click)="showModal($event, alert, i)">
                                {{alert.id}}
                            </td>
                            <td>{{alert.siteName}}</td>
                            <td>{{alert.wearerName}}</td>
                            <td>{{alert.deviceId}}</td>
                            <td>{{alert.alertCreationDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                            <td>{{alert.alertType}}</td>
                            <td>{{alert.alertRule}}</td>
                            <td>{{alert.alertStatus}}</td>
                            <td>{{alert.lastEntry | date:'dd/MM/yyyy, hh:mm a'}}</td>
                        </tr>
                    </tbody>
                </table>
                <!--Pagination-->
                <div class="paging">
                    <pagination [totalItems]="totalItems" 
                        previousText="&lsaquo;" 
                        nextText="&rsaquo;" 
                        firstText="&laquo;" 
                        lastText="&raquo;" 
                        [maxSize]="10"
                        [boundaryLinks]="true" 
                        [(ngModel)]="currentPage" 
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Alert Modal-->
<div [config]="{backdrop:'static' }" (onHidden)="onHidden()" bsModal
    #alertModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-auto-name" class="modal-title pull-left text-capitalize">Alert Details</h4>
                
                <button type="button" class="btn close" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="alert-modal-wrapper">
                    <div class="row mb-3">
                        <div class="col-md-3">
                            <label for="">Alert ID</label>
                            <input type="text" class="form-control form-select-sm" 
                                [(ngModel)]="alertData.id"
                                disabled 
                                readonly>
                        </div>
                        <div class="col-md-3">
                            <label for="">Status</label>
                            <select class="form-control form-select form-select-sm" [disabled]="isAlreadyClosed"
                            [(ngModel)]="alertData.alertStatus">
                                <option disabled value="">Select status</option>
                                <option value="OPEN">Open</option>
                                <option value="PENDING">Pending</option>
                                <option value="CLOSED">Closed</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-3">
                            <label for="">Date</label>
                            <input type="text" class="form-control form-select-sm"
                                value="{{alertData.alertCreationDate | date:'dd/MM/yyyy, hh:mm a'}}" 
                                disabled 
                                readonly>
                        </div>
                        <!-- <div class="col-md-3">
                            <label for="">Time</label>
                            <input type="text" class="form-control form-select-sm" 
                            [(ngModel)]="alertData.time"
                                disabled readonly>
                        </div> -->
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <!-- *ngIf="!addNote && alertData.alertNotes.length > 0" -->
                            <div class="alert-notes-wrapper mb-3" *ngIf="!addNote && alertData?.alertNotes?.length > 0">
                                <p>{{alertNoteObj.note}}</p>
                                <p style="margin-bottom:0; font-size: 13px;">
                                    <strong>Added by:</strong>
                                    {{alertNoteObj.addedBy}}
                                </p>
                                <p style="margin-bottom:0; font-size: 13px;">
                                    <strong>Date:</strong>
                                    {{alertNoteObj.createdOn | date:'dd/MM/yyyy, hh:mm a'}}
                                </p>
                            </div>
                            <!--Add note-->
                            <div class="add-note-wrapper mb-3" *ngIf="addNote">
                                <label for="">Alert Note</label>
                                <textarea class="form-control" cols="5" rows="6" style="resize: none;"
                                    name="note"
                                    [(ngModel)]="noteText"
                                    #note="ngModel">
                                </textarea>
                                <div class="btn-wrapper mt-3">
                                    <button class="btn btn-primary mx-3" type="button" (click)="saveAlertNote()">Save</button>
                                    <button class="btn btn-secondary mx-3" type="button" (click)="discardAlertNote()">Discard</button>
                                </div>
                            </div>
                            <div class="paging" *ngIf="!addNote && totalAlertNotes > 0">
                                <!-- {{totalAlertNotes}} -->
                                <pagination [totalItems]="totalAlertNotes" 
                                    previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;" 
                                    [maxSize]="10"
                                    [boundaryLinks]="true"
                                    [itemsPerPage]="1"
                                    [(ngModel)]="currentNote" 
                                    (pageChanged)="changeNote($event)">
                                </pagination>
                            </div>
                            <div *ngIf="!addNote">
                                <button class="login-button p-button-raised p-button-sm" 
                                    pButton 
                                    pRipple 
                                    type="button" 
                                    label="Add Note" 
                                    icon="pi pi-plus"
                                    (click)="addNewAlertNote()">
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row mb-3" *ngIf="addNote">
                        <div class="col-md-12">
                            
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="modal-footer py-1">
                <!-- <button  class="btn btn-save m-1 text-white" type="button" (click)="editAlert()">OK</button> -->
                <!-- <button type="reset" class="btn btn-cancel text-white" (click)="hideModal()">Cancel</button> -->
                
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Save" icon="pi pi-check" (click)="editAlert()"
                    [disabled]="false"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>

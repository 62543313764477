import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientsComponent } from './patients.component';
import { ManagePatientsComponent } from './components/manage-patients/manage-patients.component';
import { CreatePatientComponent } from './components/create-patient/create-patient.component';
import { WearerActivityComponent } from './components/wearer-activity/wearer-activity.component';


export const PATIENT_ROUTES: Routes = [
  { 
    path: '', 
    component: PatientsComponent,
    children: [
      {
        path: 'manage-wearers',
        component: ManagePatientsComponent
      }, 
      {
        path: 'create-wearer',
        component: CreatePatientComponent
      },
      {
        path: 'edit-wearer',
        component: CreatePatientComponent
      },
      {
        path: '',
        redirectTo: 'manage-wearers',
        pathMatch: 'full'
      }
    ] 
  }, 
  {
    path: 'wearer-activity/:wearerId',
    component: WearerActivityComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(PATIENT_ROUTES)],
  exports: [RouterModule]
})
export class PatientsRoutingModule { }

import { Component } from '@angular/core';

@Component({
  selector: 'app-allocated-devices',
  templateUrl: './allocated-devices.component.html',
  styleUrls: ['./allocated-devices.component.scss']
})

export class AllocatedDevicesComponent {
  totalItems: number = 100
  currentPage: number = 1
  showBoundaryLinks: boolean = true
  filterBySite(event: any) {

  }

  pageChanged(event: any): void {
  }

}

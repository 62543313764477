<div class="mobiles-wrapper px-md-5">
  <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="table-wrapper">
        <table class="table table-striped table-bordered text-center">
            <caption>List of mobile devices</caption>
            <thead>
                <tr>
                    <th>Android Device Id</th>
                    <th>Manufacturer</th>
                    <th>Model</th>
                    <th>OS</th>
                    <th>OS Version</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let mobile of mobileList; let i=index">
                  <td>{{mobile.androidDeviceId}}</td>
                  <td>{{mobile.manufacturer}}</td>
                  <td>{{mobile.model}}</td>
                  <td>{{mobile.osName}}</td>
                  <td>{{mobile.osVersion}}</td>
                    <td>
                        <select class="form-select form-select-sm" #mobileDropdown (change)="mobileAction($event, i, mobile)">
                            <option value="">Select action</option>
                            <option value="edit">Edit</option>
                            <option value="delete">Delete</option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-5 btn-wrapper">
          <button pButton pRipple 
            type="button"
            (click)="addMobile()"  
            icon="pi pi-mobile" 
            label="Add New Mobile" 
            class="p-button-raised p-button-sm mx-2">
          </button>
          <button pButton pRipple 
            type="button"
            [disabled]="disableSave" 
            (click)="saveMobileData('save')"
            icon="pi pi-save" 
            label="Save" 
            class="p-button-raised p-button-sm mx-2">
          </button>
          <button pButton pRipple 
            type="button" 
            (click)="saveMobileData('saveNext')"
            icon="pi pi-angle-double-right" 
            label="Save & Next" 
            class="p-button-raised p-button-sm mx-2">
          </button>
        </div>
    </div>
</div>

<!--Add / Update Mobile Device Modal-->
<div [config]="{backdrop: 'static'}" (onHidden)="onHidden()"
  bsModal #mobileModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left">
          {{!editModileData ? 'Add New Mobile Device' : 'Edit Mobile'}}
        </h4>
        <button type="button" class="btn close" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form #mobileForm="ngForm" (ngSubmit)="addMobileData(mobileForm)">  
        <div class="modal-body" style="padding-left: 55px; padding-right: 55px;">
        <!--Modal body-->
            <div class="row mb-3">
                <label for="imei" class="col-sm-3 col-form-label text-end required">Android Device Id</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" minlength="15" maxlength="17" required 
                      name="imei"
                      (change)="mobileModelChanged($event)"
                      [disabled]="editModileData"
                      [(ngModel)]="currentMobile.androidDeviceId"
                      #imei="ngModel">
                    <div class="error-block" *ngIf="imei.invalid && (mobileForm.submitted || imei.dirty || imei.touched)">
                      <span *ngIf="imei.errors?.['required']">Android Device ID is required</span>
                    </div>
                    <div class="error-block" *ngIf="imei.invalid && (mobileForm.submitted || imei.dirty || imei.touched)">
                      <span *ngIf="imei.errors?.['minlength']">Android device id must be 15 digits long</span>
                    </div>
                    <div class="error-block" *ngIf="imei.invalid && (mobileForm.submitted || imei.dirty || imei.touched)">
                      <span *ngIf="imei.errors?.['maxlength']">Android device id cann't be longer than 17 digits</span>
                    </div>
                </div>
            </div>
            
            <div class="row mb-3">
                <label for="manufacturer" class="col-sm-3 col-form-label text-end required">Manufacturer</label>
                <div class="col-sm-4">
                    <select class="form-control form-select" required
                      name="manufacturer"
                      (change)="mobileModelChanged($event)"
                      [(ngModel)]="currentMobile.manufacturer"
                      #manufacturer="ngModel">
                        <option value="">Select manufacturer</option>
                        <option value="samsung">Samsung</option>
                    </select>
                    <div class="error-block" *ngIf="manufacturer.invalid && (mobileForm.submitted || manufacturer.dirty || manufacturer.touched)">
                      <span *ngIf="manufacturer.errors?.['required']">Manufacturer is required</span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="model" class="col-sm-3 col-form-label text-end required">Model</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" required
                      (change)="mobileModelChanged($event)"
                      name="model"
                      [(ngModel)]="currentMobile.model"
                      #model="ngModel">
                      <div class="error-block" *ngIf="model.invalid && (mobileForm.submitted || model.dirty || model.touched)">
                        <span *ngIf="model.errors?.['required']">Model name is required</span>
                      </div>
                </div>
            </div>
            
            <div class="row mb-3">
              <label for="os" class="col-sm-3 col-form-label text-end required">OS</label>
              <div class="col-sm-4">
                <select class="form-control form-select" required
                  name="osName"
                  (change)="mobileModelChanged($event)"
                  [(ngModel)]="currentMobile.osName"
                  #osName="ngModel">
                  <option value="">Select os</option>
                  <option value="android">Android</option>
                </select>
                <div class="error-block" *ngIf="osName.invalid && (mobileForm.submitted || osName.dirty || osName.touched)">
                  <span *ngIf="osName.errors?.['required']">Mobile OS is required</span>
                </div>
              </div>
            </div>
            
            <div class="row mb-3">
              <label for="version" class="col-sm-3 col-form-label text-end required">OS Version</label>
              <div class="col-sm-4">
                <select class="form-control form-select" required
                  name="osVersion"
                  (change)="mobileModelChanged($event)"
                  [(ngModel)]="currentMobile.osVersion"
                  #osVersion="ngModel">
                  <option value="" selected>Select os version</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                </select>
                <div class="error-block" *ngIf="osVersion.invalid && (mobileForm.submitted || osVersion.dirty || osVersion.touched)">
                  <span *ngIf="osVersion.errors?.['required']">OS version is required</span>
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
            <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                class="login-button p-button-raised p-button-secondary p-button-sm">
            </button>
            <button [disabled]="false" pButton pRipple type="submit" label="Save" icon="pi pi-check"
                class="login-button p-button-raised p-button-sm">
            </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Delete Mobile Modal -->
<div [config]="{backdrop: 'static'}" (onHidden)="onHidden()"
  bsModal #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left text-capitalize">
          Delete Mobile
        </h4>
        <button type="button" class="btn close" aria-label="Close" (click)="deleteModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row mb-3">
          <p>Are you sure, you want to delete this mobile ?</p>
          <h6>Warning:</h6>
          <p>
            If this mobile device is deleted you will not be able to use it to sync data anymore
          </p>
        </div>

      </div>
      <div class="modal-footer py-1">
        <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="deleteModal.hide()"
            class="login-button p-button-raised p-button-secondary p-button-sm">
        </button>
        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" (click)="deleteMobile()"
            class="login-button p-button-raised p-button-sm">
        </button>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/iot/services/auth.service';
import { TOASTR_CONFIG } from 'src/app/shared/global-constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordDto: any = {}
  _token: string = ''
  constructor(private router: Router,
    private authService: AuthService, 
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute) { 

  }

  ngOnInit(): void {
    this._token = this.activatedRoute.snapshot.paramMap.get('token')!   
  }

  resetPassword(form: NgForm) {
    if (form.invalid) {
      return
    }
    
    this.resetPasswordDto['token'] = this._token;
    this.authService.resetPassword(this._token, this.resetPasswordDto).subscribe({
      next: () => {
        this.toastr.success('Password reset successfully', '', TOASTR_CONFIG)
        this.router.navigate(['/login']);
      },
      error: (err: any) => {
      }
    })
  }

}
<!--Main Wrapper-->
<div class="dashboard-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <!-- <div class="row mb-3">
        <div class="col-md-3">
            <div class="graph-card">
                <div class="">
                    <div class="card-text">
                        <h6>Wearers</h6>
                        <span>397</span>
                    </div>
                    <div class="icon bg-gradient-red">
                        <i class="bi bi-person-fill-up"></i>
                    </div>
                </div>
                <div class="percentage mt-4">
                    <p>
                        <span class="green-text">
                            <i class="bi bi-arrow-up"></i>35.8%
                        </span>
                        <span class="">Since last month</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="graph-card">
                <div>
                    <div class="card-text">
                        <h6>Sites</h6>
                        <span>25</span>
                    </div>
                    <div class="icon bg-gradient-orange">
                        <i class="bi bi-geo-alt-fill"></i>
                    </div>
                </div>
                <div class="percentage mt-4">
                    <p>
                        <span class="green-text">
                            <i class="bi bi-arrow-up"></i>5.3%
                        </span>
                        <span class="">Since last month</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="graph-card">
                <div>
                    <div class="card-text">
                        <h6>Devices</h6>
                        <span>2500</span>
                    </div>
                    <div class="icon bg-gradient-green">
                        <i class="bi bi-cpu"></i>
                    </div>
                </div>
                <div class="percentage mt-4">
                    <p>
                        <span class="green-text">
                            <i class="bi bi-arrow-up"></i>5%
                        </span>
                        <span class="">Since last month</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="graph-card">
                <div>
                    <div class="card-text">
                        <h6>Impacts</h6>
                        <span>25000</span>
                    </div>
                    <div class="icon bg-gradient-blue">
                        <i class="bi bi-arrows-collapse"></i>
                    </div>
                </div>
                <div class="percentage mt-4">
                    <p>
                        <span class="green-text">
                            <i class="bi bi-arrow-up"></i>15.2%
                        </span>
                        <span class="">Since last month</span>
                    </p>
                </div>
            </div>
        </div>
    </div> -->
    <!--Accordion-->
    <div class="accordion dashboard-accordion" id="accordionExample">
        <!--Accordion Item -->
        <!-- <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" 
                    data-bs-target="#collapseOne"
                    aria-expanded="true" 
                    aria-controls="collapseOne">
                    Activity Alerts
                    <span class="badge text-bg-purple">100</span>
                </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <table class="table table-striped table-bordered text-center">
                        <caption>List of activity alerts</caption>
                        <thead>
                            <tr>
                                <th>Patient</th>
                                <th>Hospital</th>
                                <th>Time</th>
                                <th>Date</th>
                                <th>Alert Rule</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr>
                                <td>Tom Smith</td>
                                <td>Hospital 1</td>
                                <td>18:23</td>
                                <td>11/11/12</td>
                                <td>Impact Rate</td>
                                <td>3 in 2 days</td>
                            </tr>
                            <tr>
                                <td>Will Brown</td>
                                <td>Hospital 2</td>
                                <td>19:45</td>
                                <td>10/11/12</td>
                                <td>Impact Intensity</td>
                                <td>Peak 40</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Device Sync Alerts
                    <span class="badge text-bg-purple">100</span>
                </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <table class="table table-striped table-bordered text-center">
                        <caption>List of device sync alerts</caption>
                        <thead>
                            <tr>
                                <th>Device</th>
                                <th>Last Patient</th>
                                <th>Site</th>
                                <th>Time</th>
                                <th>Date</th>
                                <th>Alert Rule</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr>
                                <td>HD1</td>
                                <td>Tom Smith</td>
                                <td>Site 1</td>
                                <td>18:23</td>
                                <td>11/11/12</td>
                                <td>Device Sync</td>
                                <td>3 days</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="accordion-item mb-3">
            <h2 class="accordion-header" id="headingThree">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Low Battery Alerts
                    <span class="badge text-bg-purple">100</span>
                </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <table class="table table-striped table-bordered text-center">
                        <caption>List of battery alerts</caption>
                        <thead>
                            <tr>
                                <th>Device</th>
                                <th>Hospital</th>
                                <th>Last Sync Time</th>
                                <th>Last Sync Date</th>
                                <th><i style="font-size: 19px;" class="bi bi-battery-half"></i></th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr>
                                <td>HD1</td>
                                <td>Hospital 1</td>
                                <td>18:23</td>
                                <td>11/11/12</td>
                                <td>5 %</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->
    </div>

    <!-- Dynamic -->  
    <div style="display: none;" class="accordion dashboard-accordion" id="accordion2" *ngFor="let alert of alertList; let i = index">
        <div class="accordion-item mb-3" (click)="accordionClick($event, alert.type)">
            <h2 class="accordion-header" id="">
                <button class="accordion-button" type="button" 
                    data-bs-toggle="collapse" 
                    [attr.data-bs-target]="alert.target"
                    [attr.aria-expanded]="false" 
                    [attr.aria-controls]="alert.accId">
                    {{alert.type}}
                    <span class="badge text-bg-purple">{{alert.count}}</span>
                </button>
            </h2>
            <div [attr.id]="alert.accId" class="accordion-collapse collapse" [ngClass]="i==0 ? 'show': ''" data-bs-parent="#accordion2">
                <div class="accordion-body">
                    <table class="table table-striped table-bordered text-center">
                        <caption>List of {{alert.type}}</caption>
                        <thead>
                            <!-- Activity/Device Sync Alert Headers -->
                            <tr *ngIf="alert.type=='Activity Alerts' || alert.type=='Device Sync Alerts'">
                                <th>Wearer</th>
                                <th>Device</th>
                                <th>Site</th>
                                <th>Last Sync Date/Time</th>
                                <th>Alert Date/Time</th>
                                <th>Alert Rule</th>
                                <th>Value</th>
                            </tr>

                            <!-- Low Battery Alert Headers -->
                            <tr *ngIf="alert.type=='Low Battery Alerts'">
                                <th>Wearer</th>
                                <th>Device</th>
                                <th>Site</th>
                                <th>Last Sync Date/Time</th>
                                <th>Alert Date/Time</th>
                                <th>Alert Rule</th>
                                <th>Value</th>
                                <th>Battery Location</th>
                                <th>
                                    <i class="bi bi-battery-half"></i>
                                </th>
                            </tr>

                            <!-- Fault Alert Headers -->
                            <tr *ngIf="alert.type=='Fault Alerts'">
                                <th>Wearer</th>
                                <th>Device</th>
                                <th>Site</th>
                                <th>Last Sync Date/Time</th>
                                <th>Fault Logged Date/Time</th>
                                <th>Fault Code</th>
                                <th>Fault Description</th>
                            </tr>
                        </thead>

                        <!-- Activity Alerts -->
                        <tbody class="" *ngIf="alert.type=='Activity Alerts'">
                            <tr class="alert-row" *ngFor="let activity of activityAlertList" (click)="navigateToAlerts(activity)">
                                <td>{{activity.wearerName}}</td>
                                <td>{{activity.deviceId}}</td>
                                <td>{{activity.siteName}}</td>
                                <td>{{activity.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{activity.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{activity.alertType}}</td>
                                <td>{{activity.alertRule}}</td>
                            </tr>
                        </tbody>

                        <!-- Device Sync Alerts -->
                        <tbody *ngIf="alert.type=='Device Sync Alerts'">
                            <tr class="alert-row" *ngFor="let activity of deviceSyncAlertList" (click)="navigateToAlerts(activity)">
                                <td>{{activity.wearerName}}</td>
                                <td>{{activity.deviceId}}</td>
                                <td>{{activity.siteName}}</td>
                                <td>{{activity.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{activity.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{activity.alertType}}</td>
                                <td>{{activity.alertRule}}</td>
                            </tr>
                        </tbody>

                        <!-- Low Battery Alerts -->
                        <tbody *ngIf="alert.type=='Low Battery Alerts'">
                            <tr class="alert-row" *ngFor="let lb of lowBatteryAlertList" (click)="navigateToAlerts(lb)">
                                <td>{{lb.wearerName}}</td>
                                <td>{{lb.deviceId}}</td>
                                <td>{{lb.siteName}}</td>
                                <td>{{lb.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{lb.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{lb.alertType}}</td>
                                <td>{{lb.alertRule}}</td>
                                <td>{{lb.batteryLocation}}</td>
                                <td>{{lb.alertValue}}</td>
                            </tr>
                        </tbody>

                        <!-- Fault Alerts -->
                        <tbody *ngIf="alert.type=='Fault Alerts'">
                            <tr class="alert-row" *ngFor="let fault of faultAlertList" (click)="navigateToAlerts(fault)">
                                <td>{{fault.wearerName}}</td>
                                <td>{{fault.deviceId}}</td>
                                <td>{{fault.siteName}}</td>
                                <td>{{fault.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{fault.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{fault.alertValue}}</td>
                                <td>{{fault.alertRule}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-sm-7 paging mt-3">
                        <!-- <span *ngIf="alert.type=='Activity Alerts'">Activity pagination</span> -->
                        <pagination
                            *ngIf="alert.type=='Activity Alerts'"
                            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                            [totalItems]="totalActivityAlerts"
                            [boundaryLinks]="true"
                            [(ngModel)]="currentActivityPage"
                            (pageChanged)="activityPageChanged($event.page)"
                            [maxSize]="10">
                        </pagination>

                        <!-- <span *ngIf="alert.type=='Device Sync Alerts'">Device pagination</span> -->
                        <pagination
                            *ngIf="alert.type=='Device Sync Alerts'"
                            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                            [totalItems]="totalDeviceSyncAlerts"
                            [boundaryLinks]="true"
                            [(ngModel)]="currentDeviceSyncPage"
                            (pageChanged)="deviceSyncPageChanged($event.page)"
                            [maxSize]="10">
                        </pagination>

                        <!-- <span *ngIf="alert.type=='Low Battery Alerts'">Battery pagination</span> -->
                        <pagination
                            *ngIf="alert.type=='Low Battery Alerts'"
                            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                            [totalItems]="totalLowBatteryAlerts"
                            [boundaryLinks]="true"
                            [(ngModel)]="currentLowBatteryPage"
                            (pageChanged)="lowBatteryPageChanged($event.page)"
                            [maxSize]="10">
                        </pagination>

                        <!-- <span *ngIf="alert.type=='Fault Alerts'">Fault pagination</span> -->
                        <pagination
                            *ngIf="alert.type=='Fault Alerts'"
                            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                            [totalItems]="totalFaultAlerts"
                            [boundaryLinks]="true"
                            [(ngModel)]="currentFaultPage"
                            (pageChanged)="faultsPageChanged($event.page)"
                            [maxSize]="10">
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Dynamic 2 -->
    <div class="accordion dashboard-accordion" id="accordion3">
        <div *ngFor="let alert of alertList; let i = index" class="accordion-item mb-3">
            <h2 class="accordion-header" id="heading{{ i }}">
                <button class="accordion-button" type="button"
                    data-bs-toggle="collapse" 
                    [attr.data-bs-target]="'#collapse' + i" 
                    [attr.aria-expanded]="i === 0 ? 'true' : 'false'" 
                    [attr.aria-controls]="'collapse' + i">
                    {{alert.type}}
                    <span class="badge text-bg-purple">{{alert.count}}</span>
                </button>
            </h2>
            <div [id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'heading' + i" 
                [attr.data-bs-parent]="'#accordion3'">
                <div class="accordion-body">
                    <table class="table table-striped table-bordered text-center">
                        <caption>List of {{alert.type}}</caption>
                        <thead>
                            <!-- Activity/Device Sync Alert Headers -->
                            <tr *ngIf="alert.type=='Activity Alerts' || alert.type=='Device Sync Alerts'">
                                <th>Wearer</th>
                                <th>Device</th>
                                <th>Site</th>
                                <th>Last Sync Date/Time</th>
                                <th>Alert Date/Time</th>
                                <th>Alert Rule</th>
                                <th>Value</th>
                            </tr>

                            <!-- Low Battery Alert Headers -->
                            <tr *ngIf="alert.type=='Low Battery Alerts'">
                                <th>Wearer</th>
                                <th>Device</th>
                                <th>Site</th>
                                <th>Last Sync Date/Time</th>
                                <th>Alert Date/Time</th>
                                <th>Alert Rule</th>
                                <th>Value</th>
                                <th>Battery Location</th>
                                <th>
                                    <i class="bi bi-battery-half"></i>
                                </th>
                            </tr>

                            <!-- Fault Alert Headers -->
                            <tr *ngIf="alert.type=='Fault Alerts'">
                                <th>Wearer</th>
                                <th>Device</th>
                                <th>Site</th>
                                <th>Last Sync Date/Time</th>
                                <th>Fault Logged Date/Time</th>
                                <th>Fault Code</th>
                                <th>Fault Description</th>
                            </tr>
                        </thead>

                        <!-- Activity Alerts -->
                        <tbody class="" *ngIf="alert.type=='Activity Alerts'">
                            <tr class="alert-row" *ngFor="let activity of activityAlertList" (click)="navigateToAlerts(activity)">
                                <td>{{activity.wearerName}}</td>
                                <td>{{activity.deviceId}}</td>
                                <td>{{activity.siteName}}</td>
                                <td>{{activity.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{activity.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{activity.alertType}}</td>
                                <td>{{activity.alertRule}}</td>
                            </tr>
                        </tbody>

                        <!-- Device Sync Alerts -->
                        <tbody *ngIf="alert.type=='Device Sync Alerts'">
                            <tr class="alert-row" *ngFor="let activity of deviceSyncAlertList" (click)="navigateToAlerts(activity)">
                                <td>{{activity.wearerName}}</td>
                                <td>{{activity.deviceId}}</td>
                                <td>{{activity.siteName}}</td>
                                <td>{{activity.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{activity.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{activity.alertType}}</td>
                                <td>{{activity.alertRule}}</td>
                            </tr>
                        </tbody>

                        <!-- Low Battery Alerts -->
                        <tbody *ngIf="alert.type=='Low Battery Alerts'">
                            <tr class="alert-row" *ngFor="let lb of lowBatteryAlertList" (click)="navigateToAlerts(lb)">
                                <td>{{lb.wearerName}}</td>
                                <td>{{lb.deviceId}}</td>
                                <td>{{lb.siteName}}</td>
                                <td>{{lb.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{lb.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{lb.alertType}}</td>
                                <td>{{lb.alertRule}}</td>
                                <td>{{lb.batteryLocation}}</td>
                                <td>{{lb.alertValue}}</td>
                            </tr>
                        </tbody>

                        <!-- Fault Alerts -->
                        <tbody *ngIf="alert.type=='Fault Alerts'">
                            <tr class="alert-row" *ngFor="let fault of faultAlertList" (click)="navigateToAlerts(fault)">
                                <td>{{fault.wearerName}}</td>
                                <td>{{fault.deviceId}}</td>
                                <td>{{fault.siteName}}</td>
                                <td>{{fault.lastSyncDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{fault.alertReceiveDate | date:'dd/MM/yyyy, hh:mm a'}}</td>
                                <td>{{fault.alertValue}}</td>
                                <td>{{fault.alertRule}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="col-sm-7 paging mt-3">
                        <!-- <span *ngIf="alert.type=='Activity Alerts'">Activity pagination</span> -->
                        <pagination
                            *ngIf="alert.type=='Activity Alerts'"
                            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                            [totalItems]="totalActivityAlerts"
                            [boundaryLinks]="true"
                            [(ngModel)]="currentActivityPage"
                            (pageChanged)="activityPageChanged($event.page)"
                            [maxSize]="10">
                        </pagination>

                        <!-- <span *ngIf="alert.type=='Device Sync Alerts'">Device pagination</span> -->
                        <pagination
                            *ngIf="alert.type=='Device Sync Alerts'"
                            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                            [totalItems]="totalDeviceSyncAlerts"
                            [boundaryLinks]="true"
                            [(ngModel)]="currentDeviceSyncPage"
                            (pageChanged)="deviceSyncPageChanged($event.page)"
                            [maxSize]="10">
                        </pagination>

                        <!-- <span *ngIf="alert.type=='Low Battery Alerts'">Battery pagination</span> -->
                        <pagination
                            *ngIf="alert.type=='Low Battery Alerts'"
                            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                            [totalItems]="totalLowBatteryAlerts"
                            [boundaryLinks]="true"
                            [(ngModel)]="currentLowBatteryPage"
                            (pageChanged)="lowBatteryPageChanged($event.page)"
                            [maxSize]="10">
                        </pagination>

                        <!-- <span *ngIf="alert.type=='Fault Alerts'">Fault pagination</span> -->
                        <pagination
                            *ngIf="alert.type=='Fault Alerts'"
                            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                            [totalItems]="totalFaultAlerts"
                            [boundaryLinks]="true"
                            [(ngModel)]="currentFaultPage"
                            (pageChanged)="faultsPageChanged($event.page)"
                            [maxSize]="10">
                        </pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
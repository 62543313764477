<div class="impacts-wrapper">
    <div class="row">
        <!--Impact Filters-->
        <div class="col-md-3">
            <div class="filter-wrapper">
                <div class="container">
                    <div class="row mb-3">
                        <div class="col-3">
                            <h6>Filters</h6>
                        </div>
                        <div class="col-9" style="display: flex; justify-content: end;">
                            
                            <button type="button" class="btn btn-sm btn-primary mx-2">Search</button>
                            <button type="button" class="btn btn-sm btn-secondary">Clear</button>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <select class="form-select form-select-sm">
                                <option value="0" selected>Select site</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-12">
                            <select class="form-select form-select-sm">
                                <option value="0" selected>Select wearer</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <span>Created between</span>
                    </div>

                    <div class="row mb-3">
                        <label for="">Start Date</label>
                        <div class="col-12 input-group">
                            <input type="text" 
                                class="form-control form-control-sm" 
                                #dp="bsDatepicker" bsDatepicker
                                [(ngModel)]="dateDto.startDateValue" 
                                name="startDateValue"
                                [bsConfig]="{ isAnimated: true , dateInputFormat: 'YYYY-MM-DD' }"
                                [placeholder]="'YYYY-MM-DD'"  
                                (ngModelChange)="filterByDate()"  
                                (bsValueChange)="onValueChangeStart($event)">
                                <button class="btn btn-sm btn-date text-white" type="button" (click)="dp.toggle()">
                                    <i class="bi bi-calendar-check-fill"></i>
                                </button>
                        </div>
                    </div>
                    
                    <div class="row mb-3">
                        <label for="">End Date</label>
                        <div class="col-12 input-group">
                            <input type="text" class="form-control form-control-sm" 
                                #dp1="bsDatepicker" bsDatepicker
                                [(ngModel)]="dateDto.endDateValue" name="endDateValue"
                                [bsConfig]="{ isAnimated: true ,  dateInputFormat: 'YYYY-MM-DD' }"
                                [placeholder]="'YYYY-MM-DD'"
                                (ngModelChange)="filterByDate()" (bsValueChange)="onValueChangeEnd($event)">
                                <button class="btn btn-sm btn-date text-white" type="button" (click)="dp1.toggle()">
                                    <i class="bi bi-calendar-check-fill"></i>
                                </button>
                        </div>
                    </div>
                </div>    
            </div>
        </div>

        <!--Impacts List-->
        <div class="col-md-9">
            <div class="table-wrapper">
                <table class="table table-striped table-bordered text-center">
                    <caption>List of impacts</caption>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Site</th>
                            <th>Wearer</th>
                            <th>Device Id</th>
                            <th>Date/Time</th>
                            <th>Impact Intensity</th>
                            <th>Impact Intensity %</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>75</td>
                            <td>HP1T</td>
                            <td>Phil Phoden</td>
                            <td>HD11</td>
                            <td>22/03/2023, 02:06 PM</td>
                            <td>1070</td>
                            <td>59</td>
                        </tr>
                    </tbody>
                </table>
                <div class="paging">
                    <pagination 
                        [totalItems]="totalItems" 
                        previousText="&lsaquo;" 
                        nextText="&rsaquo;" 
                        firstText="&laquo;"
                        lastText="&raquo;" 
                        [maxSize]="10" 
                        [itemsPerPage]="10" 
                        [boundaryLinks]="true"
                        [(ngModel)]="currentPage" 
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>

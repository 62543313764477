<div class="patient-details-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="container">
        <form #wearerDetailsForm="ngForm" (ngSubmit)="saveWearerData(wearerDetailsForm)">
            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required">First Name</label>
                <div class="col-sm-6">
                    <input type="text" 
                        class="form-control form-control-sm" 
                        placeholder="First Name"
                        id="first-name" 
                        required 
                        (change)="wearerModelChanged($event)"
                        name="firstName"
                        [(ngModel)]="wearerModel.firstName" 
                        #firstName="ngModel">
                    <div class="error-block p-2" *ngIf="firstName.invalid && (wearerDetailsForm.submitted || firstName.dirty || firstName.touched)">
                        <span *ngIf="firstName.errors?.['required']">First name is required</span>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required">Last Name</label>
                <div class="col-sm-6">
                    <input type="text" 
                        class="form-control form-control-sm" 
                        placeholder="Last Name"
                        id="last-name" 
                        required 
                        (change)="wearerModelChanged($event)"
                        name="lastName"
                        [(ngModel)]="wearerModel.lastName" 
                        #lastName="ngModel">
                    <div class="error-block p-2" *ngIf="lastName.invalid && (wearerDetailsForm.submitted || lastName.dirty || lastName.touched)">
                        <span *ngIf="lastName.errors?.['required']">Last name is required</span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required">Date of Birth</label>
                <div class="col-sm-6">
                    <div class="input-group">
                        <input type="text" required
                            class="form-control form-control-sm" 
                            #dp="bsDatepicker" bsDatepicker
                            [(ngModel)]="wearerModel.birthDate"
                            [bsConfig]="{ isAnimated: true , dateInputFormat: 'DD-MM-YYYY', maxDate: maxAllowedDate, showWeekNumbers: false }" 
                            name="birthDate" 
                            #birthDate="ngModel" 
                            (ngModelChange)="filterByDate()"  
                            (bsValueChange)="onDateOfBirthChange($event)">
                        <button class="btn btn-sm btn-date text-white" type="button" (click)="dp.toggle()">
                            <i class="bi bi-calendar-check-fill"></i>
                        </button>
                    </div>
                    <div class="error-block" *ngIf="birthDate.invalid && (wearerDetailsForm.submitted || birthDate.dirty || birthDate.touched)">
                        <span *ngIf="birthDate.errors?.['required']">Date of birth is required</span>
                        <span *ngIf="birthDate.errors?.['maxDate']">Date must be less than current date</span>
                    </div>
                    
                </div>
            </div>

            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required">Gender</label>
                <div class="col-sm-6">
                    <select class="form-select form-select-sm" required
                        name="gender"
                        (ngModelChange)="wearerModelChanged($event)"
                        [(ngModel)]="wearerModel.gender"
                        #gender="ngModel">
                        <option selected value="">Select gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    <div class="error-block" *ngIf="gender.invalid && (wearerDetailsForm.submitted || gender.dirty || gender.touched)">
                        <span *ngIf="gender.errors?.['required']">Gender is required</span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required">Height (in cm.)</label>
                <div class="col-sm-6">
                    <input type="number" 
                        class="form-control form-control-sm" 
                        placeholder="Height (in cm.)" 
                        required 
                        (change)="wearerModelChanged($event)"
                        name="height"
                        [(ngModel)]="wearerModel.height" 
                        #height="ngModel">
                    <div class="error-block" *ngIf="height.invalid && (wearerDetailsForm.submitted || height.dirty || height.touched)">
                        <span *ngIf="height.errors?.['required']">Height is required</span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required">Weight (in Kg.)</label>
                <div class="col-sm-6">
                    <input type="number" 
                        class="form-control form-control-sm" 
                        placeholder="Weight (in kg.)" 
                        required 
                        (change)="wearerModelChanged($event)"
                        name="weight"
                        [(ngModel)]="wearerModel.weight" 
                        #weight="ngModel">
                    <div class="error-block" *ngIf="weight.invalid && (wearerDetailsForm.submitted || weight.dirty || weight.touched)">
                        <span *ngIf="weight.errors?.['required']">Weight is required</span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required">Shoe size (US size)</label>
                <div class="col-sm-6">
                    <select class="form-select form-select-sm" required
                        name="shoeSize"
                        (ngModelChange)="wearerModelChanged($event)"
                        [(ngModel)]="wearerModel.shoeSize"
                        #shoeSize="ngModel">
                        <option selected value="">Select shoe size(US Size)</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select>
                    <div class="error-block p-2" *ngIf="shoeSize.invalid && (wearerDetailsForm.submitted || shoeSize.dirty || shoeSize.touched)">
                        <span *ngIf="shoeSize.errors?.['required']">Shoe size is required</span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="" class="col-sm-3 col-form-label text-end required">Site</label>
                <div class="col-sm-6">
                    <select class="form-select form-select-sm" required
                        (ngModelChange)="wearerModelChanged($event)"
                        name="site"
                        [(ngModel)]="wearerModel.siteId"
                        #site="ngModel"
                        [disabled]="isEditMode">
                        <option selected value="">Select site</option>
                        <option *ngFor="let site of siteList" value={{site.id}}>{{site.name}}</option>
                    </select>
                    
                    <div class="error-block p-2" *ngIf="site.invalid && (wearerDetailsForm.submitted || site.dirty || site.touched)">
                        <span *ngIf="site.errors?.['required']">Site is required</span>
                    </div>
                </div>
            </div>

            <div class="btn-wrapper" style="display: flex; align-items: center; justify-content: center;">
                <button pButton pRipple 
                    [disabled]="false" 
                    (click)="btnStatus='save'"
                    icon="pi pi-save" 
                    label="Save" 
                    class="p-button-raised p-button-sm mx-2">
                </button>
                <button pButton pRipple 
                    [disabled]="false"
                    (click)="btnStatus='saveNext'"
                    icon="pi pi-angle-double-right" 
                    label="Save & Next" 
                    class="p-button-raised p-button-sm mx-2">
                </button>
            </div>
        </form>
    </div>
</div>


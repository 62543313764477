import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WearerApiBuilderService } from "src/app/shared/api-builders/wearer-api-builder.service";

@Injectable()
export class WearerService {
    
    constructor(private http: HttpClient, private wearerApiBuilder: WearerApiBuilderService) {

    }

    getAssignedWearers(userId: number, pageIndex: number, pageSize: number) {
        const getAssignedWearersUrl = this.wearerApiBuilder.getAssignedWearersUrl(userId, pageIndex, pageSize); 
        return this.http.get(getAssignedWearersUrl)
    }

    getUnassignedWearers(userId: number, pageIndex: number, pageSize: number) {
        const getUnassignedWearersUrl = this.wearerApiBuilder.getUnassignedWearersUrl(userId, pageIndex, pageSize);
        return this.http.get(getUnassignedWearersUrl);
    }

    getUnassignedWearersBySite(siteId: number) {
        const getUnassignedWearersBySiteUrl = this.wearerApiBuilder.getUnassignedWearersBySiteUrl(siteId)
        return this.http.get(getUnassignedWearersBySiteUrl)
    }

    getAssignedWearersBySite(userId: number, siteId: number) {
        const getAllAssignedWearersBySiteUrl = this.wearerApiBuilder.getAssignedWearersBySiteUrl(userId, siteId)
        return this.http.get(getAllAssignedWearersBySiteUrl)
    }

    getAllWearersBySite(siteId: number) {
        const getAllWearersUrl = this.wearerApiBuilder.getAllWearersUrl(siteId)
        return this.http.get(getAllWearersUrl)
    }

    filterWearers(filterData: any) {
        const filterWearersUrl = this.wearerApiBuilder.filterWearerData(filterData)
        return this.http.get(filterWearersUrl)
    }

    deleteWearer(weareId: any) {
        const deleteWearerUrl = this.wearerApiBuilder.deleteWearerUrl(weareId)
        return this.http.delete(deleteWearerUrl)
    }

    assignDevice(deviceId: String, wearerId: number) {
        const assignDeviceUrl = this.wearerApiBuilder.assignDeviceUrl(deviceId, wearerId);
        return this.http.post(assignDeviceUrl, {});
    }

    unassignDevice(wearerId: number) {
        const unassignDeviceUrl = this.wearerApiBuilder.unassignDeviceUrl(wearerId)
        return this.http.post(unassignDeviceUrl, {})
    }
} 

<div class="users-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <table class="table table-striped table-bordered mb-0">
        <caption>List of users</caption>
        <thead>
            <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Last Login</th>
                <th class="w-20">Action</th>
            </tr>
        </thead>
        <tbody class="">
            <tr *ngFor="let user of userList">
                <td>
                    {{user.name}}
                </td>
                <td>{{user.userEmail}}</td>
                <td>{{user.userRole}}</td>
                <td>{{user.status}}</td>
                <td>{{user.lastLogin}}</td>
                <td>
                    <select class="form-select form-select-sm" #usersDropDown 
                        (change)="selectUserAction($event, user)">
                        <option value="">Select Action</option>
                        <option value="activate" *ngIf="user.status == 'Inactive'">Activate</option>
                        <option value="deactivate" *ngIf="user.status == 'Active'">Deactivate</option>
                        <option value="edit">Edit</option>
                        <option value="delete">Delete</option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="paging">
        <button class="login-button p-button-raised p-button-sm" 
            pButton 
            pRipple 
            type="button" 
            style="float: right;" 
            label="Add" 
            icon="pi pi-user-plus"
            (click)="showModal()">
        </button>
        <pagination 
            [totalItems]="totalItems" 
            previousText="&lsaquo;" 
            nextText="&rsaquo;" 
            firstText="&laquo;"
            lastText="&raquo;" 
            [maxSize]="10" 
            [itemsPerPage]="10" 
            [boundaryLinks]="true" 
            [(ngModel)]="currentPage"
            (pageChanged)="pageChanged($event)">
        </pagination>
    </div>
</div>

<!--Add User Modal-->
<div class="modal fade" bsModal #addUserModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-lg">

        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{editMode ? 'Edit User' : 'Create User'}}</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="addUserModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form #addUserForm="ngForm" (ngSubmit)="saveUserData(addUserForm)">
                <div class="modal-body">
                    <div class="container">
                        <div class="row mb-3">
                            <!--Username-->
                            <div class="col-md-6">
                                <input autocomplete="new-password" placeholder="Name" tabindex="1" type="text"
                                    id="username" class="p-inputtext-sm w-100 required-field" pattern="^[A-Z][a-z]+\s[A-Z][a-z]+$"
                                    name="username" #username="ngModel" [(ngModel)]="userModel.name" pInputText
                                    required>
                                <div class="error-block"
                                    *ngIf="username.invalid && (addUserForm.submitted || username.dirty || username.touched)">
                                    <span *ngIf="username.errors?.['required']">Name is required</span>
                                    <span *ngIf="!username.errors?.['required'] && username.errors?.['pattern']">
                                        Name must have 2 words e.g. 'John Doe'
                                    </span>
                                </div>
                            </div>

                            <!--Adress Line 1-->
                            <div class="col-md-6">
                                <input type="text" placeholder="Address Line 1" tabindex="8" class="p-inputtext-sm w-100"
                                    name="houseNo" [(ngModel)]="userModel.houseNumber" #houseNo="ngModel" pInputText
                                    required>
                                <div class="error-block" *ngIf="houseNo.invalid && (addUserForm.submitted || houseNo.dirty || houseNo.touched)">
                                    <span *ngIf="houseNo.errors?.['required']">House no. is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <!--Email-->
                            <div class="col-md-6">
                                <input autocomplete="off" placeholder="Email" tabindex="2" type="text" id="email"
                                    class="p-inputtext-sm w-100" name="userEmail" #userEmail="ngModel"
                                    [(ngModel)]="userModel.email"
                                    pattern="[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                                    pInputText required>

                                <div class="error-block"
                                    *ngIf="userEmail.invalid && (addUserForm.submitted || userEmail.dirty || userEmail.touched)">
                                    <span *ngIf="userEmail.errors?.['required']">
                                        Email is required
                                    </span>
                                    <span *ngIf="!userEmail.errors?.['required'] && userEmail.errors?.['pattern']">
                                        Email is invalid
                                    </span>
                                </div>
                            </div>
                            <!-- Address Line 2 -->
                            <div class="col-md-6">
                                <input type="text" tabindex="9" placeholder="Address Line 2" class="p-inputtext-sm w-100"
                                    name="street" [(ngModel)]="userModel.street" #street="ngModel" pInputText>
                                <!-- <div class="error-block" *ngIf="houseNo.invalid && (houseNo.dirty || houseNo.touched)">
                                    <span *ngIf="houseNo.errors?.['required']">House no. is required</span>
                                </div> -->
                            </div>

                        </div>
                        <div class="row mb-3">
                            <!--Password-->
                            <div class="col-md-6" *ngIf="editMode"></div>
                            <div class="col-md-6" *ngIf="!editMode">
                                <input autocomplete="new-password" 
                                    placeholder="Password" 
                                    tabindex="3" 
                                    type="password"
                                    pattern="^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$"
                                    minlength="8" 
                                    id="password" 
                                    class="p-inputtext-sm w-100" 
                                    name="userPassword"
                                    #password="ngModel" 
                                    [(ngModel)]="userModel.password" pInputText required>

                                <div class="error-block"
                                    *ngIf="password.invalid && (addUserForm.submitted || password.touched)">
                                    <span *ngIf="password.errors?.['required']">Password is required</span>
                                    <!-- <span *ngIf="password.errors?.['minlength']">
                                        Password must be 8 characters long
                                    </span> -->
                                    <span *ngIf="password.errors?.['pattern']">
                                        Password must be 8 characters long and must have at least 1 uppercase character, 1 or more numbers, and only 1 special character
                                    </span>
                                </div>
                            </div>
                            <!-- Town/City -->
                            <div class="col-md-6">
                                <input type="text" tabindex="10" placeholder="Town/City" class="p-inputtext-sm w-100"
                                    name="city" [(ngModel)]="userModel.city" #city="ngModel" pInputText required>
                                <div class="error-block" *ngIf="city.invalid && (addUserForm.submitted || city.dirty || city.touched)">
                                    <span *ngIf="city.errors?.['required']">Town/City is required</span>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <!--Confirm Password-->
                            <div class="col-md-6" *ngIf="editMode"></div>
                            <div class="col-md-6" *ngIf="!editMode">
                                <input autocomplete="new-password" 
                                    placeholder="Confirm Password" 
                                    tabindex="4"
                                    type="password" 
                                    minlength="8" 
                                    id="confirmPassword" 
                                    class="p-inputtext-sm w-100"
                                    name="confirmPassword" 
                                    #confirmPassword="ngModel"
                                    [pattern]="userModel.password"
                                    [(ngModel)]="userModel.confirmPassword" pInputText required>
                                <!-- <div class="error-block"
                                    *ngIf="userModel.confirmPassword!=userModel.password && !confirmPassword.invalid && (addUserForm.submitted || confirmPassword.touched)">
                                    <span>Passwords do not match</span>
                                </div> -->
                                <div class="error-block" *ngIf="confirmPassword.invalid && (addUserForm.submitted || confirmPassword.touched)">
                                    <p class="my-0" *ngIf="confirmPassword.errors?.['required']">
                                        Confirm password is required
                                    </p>
                                    <p class="my-0" *ngIf="userModel.confirmPassword!==userModel.password">
                                        Passwords do not match
                                    </p>
                                </div>
                            </div>
                            <!-- Country -->
                            <!-- zip code -->
                            <div class="col-md-6">
                                <input autocomplete="new-password" 
                                    placeholder="Post Code/Zip Code"
                                    minlength="5"
                                    maxlength="7" 
                                    type="text"
                                    class="p-inputtext-sm w-100" 
                                    name="postalCode" 
                                    #postalCode="ngModel"
                                    [(ngModel)]="userModel.postCode" pInputText required>
                                <div class="error-block"
                                    *ngIf="postalCode.invalid && (addUserForm.submitted || postalCode.dirty || postalCode.touched)">
                                    <span *ngIf="postalCode.errors?.['required']">Post code is required</span>
                                </div>
                                <!-- <select name="country" class="form-select form-select-sm"
                                    [(ngModel)]="userModel.country" 
                                    #country="ngModel" 
                                    required>
                                    <option value="" disabled>Select country</option>
                                    <option *ngFor="let country of countries" value={{country.name}}>{{country.name}}</option>
                                </select>
                                <div class="error-block" *ngIf="country.invalid && (addUserForm.submitted || country.dirty || country.touched)">
                                    <span *ngIf="country.errors?.['required']">Country is required</span>
                                </div> -->
                            </div>
                        </div>
                        <div class="row mb-3">
                            <!--User Role-->
                            <div class="col-md-6">
                                <!-- <span>user role: {{userModel | json}}</span> -->
                                <select class="form-select form-select-sm" 
                                    tabindex="5" 
                                    (change)="selectRole($event)"
                                    [(ngModel)]="userModel.userRole" 
                                    #userRole="ngModel" 
                                    name="role"
                                    [disabled]="editMode" 
                                    required>
                                    <option value="" selected>Select Role</option>
                                    <option *ngFor="let role of roles" value={{role}}>{{role}}</option>
                                </select>
                                <div class="error-block"
                                    *ngIf="userRole.invalid && (addUserForm.submitted || userRole.touched)">
                                    <span *ngIf="userRole.errors?.['required']">Role is required</span>
                                </div>
                            </div>
                            <!--Postal Code-->
                            <!-- country -->
                            <div class="col-md-6">
                                <select name="country" class="form-select form-select-sm"
                                    [(ngModel)]="userModel.country" 
                                    #country="ngModel" 
                                    required>
                                    <option value="" disabled>Select country</option>
                                    <option *ngFor="let country of countries" value={{country.name}}>{{country.name}}</option>
                                </select>
                                <div class="error-block" *ngIf="country.invalid && (addUserForm.submitted || country.dirty || country.touched)">
                                    <span *ngIf="country.errors?.['required']">Country is required</span>
                                </div>
                                <!-- <input autocomplete="new-password" 
                                    placeholder="Post Code/Zip Code"
                                    minlength="5"
                                    maxlength="7" 
                                    type="text"
                                    class="p-inputtext-sm w-100" 
                                    name="postalCode" 
                                    #postalCode="ngModel"
                                    [(ngModel)]="userModel.postCode" pInputText required>
                                <div class="error-block"
                                    *ngIf="postalCode.invalid && (addUserForm.submitted || postalCode.dirty || postalCode.touched)">
                                    <span *ngIf="postalCode.errors?.['required']">Post code is required</span>
                                </div> -->
                            </div>
                        </div>
                        <div class="row mb-3">
                            <!--Site-->
                            <div class="col-md-6">
                                <select class="form-select form-select-sm" *ngIf="isSiteManager" tabindex="6"
                                    (change)="selectSite($event)"
                                    [(ngModel)]="userModel.userSite" 
                                    #site="ngModel" 
                                    name="userSite">
                                    <option value="" selected>Select Site</option>
                                    <option *ngFor="let site of siteList" value={{site.id}}>{{site.name}}</option>
                                </select>
                            </div>
                            <!--Telephone-->
                            <div class="col-md-6">
                                <input autocomplete="new-password" placeholder="Telephone" type="text" id="telephone"
                                    class="p-inputtext-sm w-100" name="phone" #userPhone="ngModel" minlength="7" maxlength="17" 
                                    (keypress)="restrictInput($event)"
                                    [(ngModel)]="userModel.mobNo" pattern="^\+[1-9]\d{1,14}$" pInputText required>
                                <div class="error-block"
                                    *ngIf="userPhone.invalid && (addUserForm.submitted || userPhone.dirty || userPhone.touched)">
                                    <p class="my-0" *ngIf="userPhone.errors?.['required']">Phone number is required</p>
                                    <p class="my-0" *ngIf="userPhone.errors?.['pattern']">Use + with country code</p>
                                    <!-- <p class="my-0" *ngIf="userPhone.errors?.['minlength']">Phone number is invalid</p> -->
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <!--Notification Email-->
                            <div class="col-md-6">
                                <input autocomplete="off" placeholder="Notification Email" tabindex="7" type="text"
                                    id="email" class="p-inputtext-sm w-100" name="notificationMail"
                                    #notificationEmail="ngModel" [(ngModel)]="userModel.notificationEmail" pInputText
                                    >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="addUserModal.hide()"
                        class="login-button p-button-raised p-button-secondary p-button-sm">
                    </button>
                    <button pButton pRipple type="submit" label="Save" icon="pi pi-check"
                        [disabled]="false"
                        class="login-button p-button-raised p-button-sm">
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!--Activate/Deactivate Modal-->
<div class="modal fade" bsModal #activateModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">{{activeUser ? 'Deactivate User' : 'Activate User'}}</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="activateModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <p *ngIf="!activeUser">Are you sure you want to activate this user ?</p>
                    <p *ngIf="activeUser">Are you sure you want to deactivate this user ?</p>
                    <h6 *ngIf="activeUser">Warning</h6>
                    <p *ngIf="activeUser">
                        Disabling this user will prevent them logging in again, 
                        however any notifications will still be sent to the user.
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="activateModal.hide()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Save" icon="pi pi-check" (click)="updateUserStatus()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>

<!--Delete User Modal-->
<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete User</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="deleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to delete this user ?</p>
                <h6>Warning:</h6>
                <p>
                    Deleting this user will prevent them logging in again, and stop all notofications to the user.
                </p>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" (click)="deleteUser()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>
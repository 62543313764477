import { Component } from '@angular/core'
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-alert-rule-profile',
  templateUrl: './alert-rule-profile.component.html',
  styleUrls: ['./alert-rule-profile.component.scss']
})
export class AlertRuleProfileComponent {
  editRoute: boolean = false

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.lastIndexOf('edit-alert-rule') != -1) {
          this.editRoute = true
        } else {
          this.editRoute = false
        }
      }
    });
  }
}

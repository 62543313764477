import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http'
import { BehaviorSubject, EMPTY, Observable, finalize, tap } from 'rxjs'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { LoaderService } from 'src/app/iot/services/loader.service'
import {jwtDecode} from 'jwt-decode'

@Injectable()
export class HttpStatus {

  private requestInFlight$: BehaviorSubject<boolean>

  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false)
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight)
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable()
  }
}

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private router: Router,
    private status: HttpStatus,
    private loaderService: LoaderService,
    private toastr: ToastrService) {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.status.setHttpStatus(true)
    // console.log('SHOW LOADER -> loadingService.show()', request.url)
    this.loaderService.show()
    const authToken = localStorage.getItem('authToken')

    if (authToken) {
      if (this.isTokenExpired(authToken)) {
        this.toastr.error("Session Expired, please login again")
        this.loaderService.hide()
        localStorage.clear()
        this.router.navigate(['login'])
        // return EMPTY
      }
      const clonedRequest = request.clone({ headers: request.headers.set("Authorization", authToken) })
      // console.log('protected API')
      return next.handle(clonedRequest).pipe(
        tap({
          next: (event: any) => {
            if (event instanceof HttpResponse) {
              if (event.body && event.body.message) {
                this.status.setHttpStatus(false)
              }
            } else {
              // console.log('EVENT: ', event)
            }
          },
          error: (err: any) => {
            // console.log('Error: ', err)
            if (err instanceof HttpErrorResponse) {
              // console.log('ERROR: ', err)
              if (err.error && err.error?.message) {
                if (err.error?.status != 403) {
                  // this.toastr.error(err.error.message)
                } else {

                }
                // this.toastr.error(err.error.message)
              } else if (err.statusText) {
                // this.toastr.error(err.statusText)
                return
              }
              this.status.setHttpStatus(false)
              if (err.error) {
                if (err.status != 403) {
                  this.toastr.error(err.error.message)
                  return
                }
                localStorage.clear()
                this.router.navigate(['login'])
                return
              }
              // if (err.status == 401 || err.status == 403) {
              //   if (err.error) {
              //     this.toastr.error(err.error.message)
              //     return
              //   }
              //   localStorage.clear()
              //   this.router.navigate(['login'])
              //   return
              // }
              if (err.status == 0) {
                this.toastr.error("Internet not available")
                return
              }
            }
          }
        }), 
        finalize(() => {
          // console.log('HIDE LOADER -> loadingService.hide()', request.url)
          this.loaderService.hide()
        })
      )
    }
    //For open APIs
    return next.handle(request).pipe(
      tap({
        next: (event: any) => {
          if (event instanceof HttpResponse) {
            if (event.body && event.body.message) {
              // this.toastr.success(event.body.message)
              // console.log('LOADER public API: ', event.body.message)
              this.status.setHttpStatus(false)
            }
          }
        },
        error: (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if(err.statusText && err.statusText === 'Unknown Error') {
              this.toastr.error(err.statusText)
            } else {
              this.toastr.error(err.error.message)
            }
            this.status.setHttpStatus(false)
            if (err.status == 401 || err.status == 403) {
              localStorage.clear();
              this.router.navigate(["login"])
            }
          }
        }
      }),
      finalize(() => {
        // console.log('HIDE LOADER -> loadingService.hide()', request.url)
        this.loaderService.hide()
      })
    );
  }

  private isTokenExpired(token: string): Boolean {
    try {
      const decodedToken: any = jwtDecode(token)
      // console.log('decodedToken: ', decodedToken) 
      if (decodedToken && decodedToken.exp) {
        const expirationTime = decodedToken.exp * 1000
        // console.log('token expired: ', Date.now() > expirationTime)
        return Date.now() > expirationTime
      } 
    } catch (error) {
      // console.error('Error decoding JWT:', error);
    }
    return true
  }
}

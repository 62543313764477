<div class="create-alert-rule-wrapper">
    <div class="title mb-2 p-2">
        <h5 *ngIf="alertProfileId != undefined">Update Alert Rule Profile</h5>
        <h5 *ngIf="alertProfileId == undefined">New Alert Rule Profile</h5>
    </div>
    <div class="form-container pt-3" style="padding-bottom: 0;">
        <form #alertProfileForm="ngForm" (ngSubmit)="saveAlertProfile()">
            <div class="container">
                <div class="row mb-3">
                    <label for="" class="col-sm-3 col-form-label text-end required-label">Name</label>
                    <div class="col-sm-9">
                        <input type="text" class="form-control form-control-sm" required
                          name="alertName"
                          [(ngModel)]="alertProfileModel.name" 
                          #alertName="ngModel">
                        <div class="error-block p-2" *ngIf="alertName.invalid && (alertProfileForm.submitted || alertName.dirty || alertName.touched)">
                          <span *ngIf="alertName.errors?.['required']">(30a.2)Alert rule profile name is mandatory</span>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                  <label for="" class="col-sm-3 col-form-label text-end required-label">Description</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control form-control-sm" required 
                      name="details"
                      [(ngModel)]="alertProfileModel.details" 
                      #details="ngModel">
                      <div class="error-block p-2" *ngIf="details.invalid && (alertProfileForm.submitted || details.dirty || details.touched)">
                        <span *ngIf="details.errors?.['required']">(30a.2)Alert rule profile details are mandatory</span>
                      </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="table-wrapper">
                            <table class="table table-striped table-bordered text-center">
                                <caption>List of alert rules</caption>
                                <thead>
                                    <tr>
                                        <th>Alert Type</th>
                                        <th>Alert Rule</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="alert-type" (click)="showModal('device sync')">
                                            Device Sync
                                        </td>
                                        <td>No sync within {{alertProfileModel.deviceSync}} days of assignment</td>
                                    </tr>
                                    <tr>
                                        <td class="alert-type" (click)="showModal('low battery')">
                                            Low Battery
                                        </td>
                                        <td>Remaining charge less that {{alertProfileModel.lowBattery}}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="mb-3 text-center">
                        <button class="login-button p-button-raised p-button-sm"
                            [disabled]="false" 
                            pButton pRipple 
                            type="submit" 
                            label="Save" 
                            icon="pi pi-check">
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" 
    bsModal #alertRuleModal="bs-modal" 
    [config]="{backdrop: 'static'}" 
    tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog" 
        [ngClass]="(alertRuleType == 'temperature' || alertRuleType == 'pressure' || alertRuleType == 'humidity' || alertRuleType == 'activity') ? 'modal-lg':'modal-md'">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Alert Rule: {{modalTitle}}</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="alertRuleModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form>
                <!-- Low Battery -->
                <div class="modal-body" *ngIf="alertRuleType == 'low battery'">
                    <div class="row mb-3">
                        <label for="" class="col-sm-5 col-form-label">
                            Remaining charge less than
                        </label>
                        <div class="col-sm-3">
                            <div class="input-group">
                                <input type="number" 
                                    min="10"
                                    max="20"
                                    class="form-control" 
                                    aria-label="" 
                                    aria-describedby="battery"
                                    name="battery"
                                    [(ngModel)]="lowBatteryValue"
                                    #battery="ngModel">
                                <span class="input-group-text" id="battery">%</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5"></div>
                        <div class="col-sm-7">
                          <div class="error-block" *ngIf="showError && alertRuleType=='low battery' && !invalidLowBattery">
                            <span>Low battery is mandatory(21c2.1)</span>
                          </div>
                          <div class="error-block" *ngIf="showError && alertRuleType=='low battery' && invalidLowBattery">
                            <span>Low battery is outside valid range(21c2.2)</span>
                          </div>
                        </div>
                      </div>
                </div>

                <!-- Device Sync -->
                <div class="modal-body" *ngIf="alertRuleType == 'device sync'">
                    <div class="row mb-3">
                        <label for="" class="col-sm-3 col-form-label">
                            No sync within
                        </label>
                        <div class="col-sm-2">
                            <input type="number" 
                                min="2"
                                max="10"
                                class="form-control form-control-sm"
                                name="deviceSync"
                                [(ngModel)]="deviceSyncValue"
                                #deviceSync="ngModel">
                        </div>
                        <label for="" class="col-sm-4 col-form-label">
                            days of assignment
                        </label>
                        
                    </div>
                    <div class="row">
                        <div class="col-sm-5"></div>
                        <div class="col-sm-7">
                          <div class="error-block" *ngIf="showError && alertRuleType=='device sync' && !invalidDeviceSync">
                            <span>Device sync is mandatory(21c1.1)</span>
                          </div>
                          <div class="error-block" *ngIf="showError && alertRuleType=='device sync' && invalidDeviceSync">
                            <span>Device sync is outside valid range(21c1.2)</span>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                        class="login-button p-button-raised p-button-secondary p-button-sm">
                    </button>
                    <button pButton pRipple type="submit" label="Save" icon="pi pi-check" (click)="updateValues()"
                        class="login-button p-button-raised p-button-sm">
                    </button>
                </div>
            </form>
        </div>
    </div>

</div>

import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { DatePipe } from '@angular/common'
import { FormControl, NgForm } from '@angular/forms'
import * as _ from 'lodash'
import { CreatePatientService } from 'src/app/iot/services/create-patient.service'
import { Wearer } from 'src/app/shared/models/wearer.model'
import { SiteService } from 'src/app/iot/services/site.service'
import { Subscription } from 'rxjs'
import { LOADER_CONFIG } from 'src/app/shared/global-constants'
import { LoaderService } from 'src/app/iot/services/loader.service'

@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit, OnDestroy {
  loading: boolean = false
  btnStatus: string = 'save'
  wearerModel: Wearer
  isEditMode: boolean = false
  disableSave: boolean = false
  isSaveAlreadyPressed: boolean = false
  pipe = new DatePipe('en-US')
  userRole: string = ''
  userId: string = ''
  siteList: Array<any> = []
  wearerId: string = ''
  wearerData: any = {}
  dateDto: any = {}
  isDateValid: boolean = true
  maxAllowedDate = new Date()
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG
  
  @ViewChild('wearerDetailsForm', {static: true}) wearerDetailsForm!: NgForm
  @ViewChild('birthDate', {static: true}) birthDate!: FormControl



  constructor(private createPatientService: CreatePatientService,
    private loaderService: LoaderService,
    private siteService: SiteService, 
    private router: Router, 
    private activatedRoute: ActivatedRoute) { 
    this.userId = localStorage.getItem('user-id')!
    this.userRole = localStorage.getItem('role')!
    this.wearerModel = new Wearer()
    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })
  }

  ngOnInit(): void {
    this.wearerId = this.activatedRoute.snapshot.queryParamMap.get("id")!
    if (this.wearerId) {
      this.isEditMode = true
      this.getWearerDetails(parseInt(this.wearerId))
    }
    
    if (this.userRole == 'ROLE_SITE_MANAGER') {
      this.getManagerSites();
    } else {
      this.getAllSites();
    } 
  }

  routeToNextTab(btnStatus: string) {
    const addedFormStatus: any = {};
    addedFormStatus.activeTabName = "patient-details";
    addedFormStatus.buttonStatus = btnStatus;
    this.createPatientService.broadcastNewPatient(addedFormStatus);
  }

  getManagerSites() {
    this.siteService.getAllSites(parseInt(this.userId)).subscribe({
      next: (response: any) => {
        
        this.siteList = response.DATA
      },
      error: (err: any) => {

      }
    })
  }

  getAllSites() {
    this.siteService.getAllSites(parseInt(this.userId)).subscribe({
      next: (response: any) => {
        
        this.siteList = response.DATA
      },
      error: (err: any) => {

      }
    })
  }


  filterByDate() {
    this.isDateValid = this.wearerModel.birthDate <= this.maxAllowedDate.toISOString()
  }

  onDateOfBirthChange(event: any) {
    const current_date: Date = new Date()
    this.isDateValid = this.wearerModel.birthDate <= current_date.toISOString() ? true : false
  }

  patientModelChanged(event: any) {
    this.disableSave = false
  }

  saveWearerData(wearerForm: NgForm) {
    if (this.wearerId) {
      this.updateWearerDetails(wearerForm, this.wearerId)
    } else {
      this.createWearerDetails(wearerForm)
    }
  }

  createWearerDetails(wearerForm: NgForm) {
    // this.loading = true
    if (this.btnStatus === 'save') {
      if (wearerForm.invalid) {
        return
      }
      // TODO: 
      /**
       * 1. Integrate create wearer API 
       * 2. Set isSaveAlreadyPressed = true inside next block
       * 3. Store wearer id & wearer data in local storage
       */
      
      this.createPatientService.createWearer(this.wearerModel).subscribe({
        next: (response: any) => {
          this.disableSave = true
          this.isSaveAlreadyPressed = true
          
          this.wearerId = response.DATA.id
          this.wearerData = response.DATA
          localStorage.setItem('wearerId', this.wearerId);
          localStorage.setItem('wearerData', JSON.stringify(this.wearerData))
        }, 
        error: (error: any) => {

        }
      })
    } else {
      if (this.isSaveAlreadyPressed) {
        this.routeToNextTab(this.btnStatus)
      } else {
        if (wearerForm.invalid) {
          return
        }
        if (this.disableSave) {
          this.routeToNextTab(this.btnStatus)
        } else {
          // TODO:
          /**
           * 1. Call add wearer api
           * 2. Store wearer id & wearer data in local storage
           * 3. Call fucntion routeToNextTab()
           */
          this.createPatientService.createWearer(this.wearerModel).subscribe({
            next: (response: any) => {
              
              this.wearerId = response.DATA.id
              this.wearerData = response.DATA
              localStorage.setItem('wearerId', this.wearerId);
              localStorage.setItem('wearerData', JSON.stringify(this.wearerData))
              this.routeToNextTab(this.btnStatus)
              
            }, 
            error: (error: any) => {
    
            }
          })
        }
      }
    }
  }

  updateWearerDetails(wearerForm: NgForm, wearerId: string) {
    // this.loading = true
    if (this.disableSave) {
      this.routeToNextTab(this.btnStatus)
    } else {
      // TODO:
      /**
       * 1. Call update wearer details api
       * 2. Store wearer id & wearer data in local storage
       * 3. Call fucntion routeToNextTab()
       */
      this.createPatientService.updateWearer(parseInt(wearerId), this.wearerModel).subscribe({
        next: (response: any) => {
          
          this.wearerId = response.DATA.id
          this.wearerData = response.DATA
          localStorage.setItem('wearerId', this.wearerId);
          localStorage.setItem('wearerData', JSON.stringify(this.wearerData))
          this.routeToNextTab(this.btnStatus)
        },
        error: (err: any) => {

        }
      })
    }
  }

  getWearerDetails(wearerId: number) {
    this.createPatientService.getWearerById(wearerId).subscribe({
      next: (response: any) => {
        this.loading = false
        let wearerDetails = response.DATA
        this.wearerModel.firstName = wearerDetails.firstName
        this.wearerModel.lastName = wearerDetails.lastName
        this.wearerModel.birthDate = wearerDetails.birthDate
        this.wearerModel.siteId = wearerDetails.siteId
        this.wearerModel.gender = wearerDetails.gender
        this.wearerModel.weight = wearerDetails.weight
        this.wearerModel.height = wearerDetails.height
        this.wearerModel.shoeSize = wearerDetails.shoeSize
      }, 
      error: (err: any) => {
        this.loading = false
      }
    });  
  }

  wearerModelChanged(event: any) {
    this.disableSave = false;
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }
}

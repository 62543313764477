export class Site {
    name: string
    address1: string
    address2: string
    town: string
    postCode: string
    country: string
    timeZone: string
    offSet: string
    contactName: string
    emailAddress: string
    contactMobile: string
    constructor() {
        this.name = ''
        this.address1 = ''
        this.address2 = ''
        this.town = ''
        this.postCode = ''
        this.country = ''
        this.timeZone = ''
        this.offSet = ''
        this.contactName = ''
        this.emailAddress = ''
        this.contactMobile = ''
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityComponent } from './activity.component';
import { ImpactsComponent } from './components/impacts/impacts.component';
import { AlertsComponent } from './components/alerts/alerts.component';
import { GraphComponent } from './components/graph/graph.component';
import { PressureComponent } from './components/pressure/pressure.component';
import { TemperatureComponent } from './components/temperature/temperature.component';
import { HumidityComponent } from './components/humidity/humidity.component';
import { GraphDemoComponent } from './components/graph-demo/graph-demo.component';

export const ACTIVITY_ROUTES: Routes = [
  { 
    path: '', 
    component: ActivityComponent,
    children: [
      {
        path: 'impacts',
        component: ImpactsComponent
      },
      {
        path: 'pressure',
        component: PressureComponent
      },
      {
        path: 'temperature',
        component: TemperatureComponent
      },
      {
        path: 'humidity',
        component: HumidityComponent
      },
      {
        path: 'alerts',
        component: AlertsComponent
      },
      {
        path: 'graph',
        component: GraphComponent
      },
      {
        path: 'graph-demo',
        component: GraphDemoComponent
      },
      {
        path: '',
        redirectTo: 'pressure',
        pathMatch: 'full'
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(ACTIVITY_ROUTES)],
  exports: [RouterModule]
})
export class ActivityRoutingModule { }

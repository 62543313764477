import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment.development'

@Injectable({ providedIn: 'root' })
export class WearerApiBuilderService {

  public serverUrl: string = `${environment.host}/api/v1`

  constructor() { }

  createWearerUrl = `${this.serverUrl}/wearer/addWearer`

  updateWearerUrl = (wearerId: number) => `${this.serverUrl}/wearer/editWearerDetails/${wearerId}`

  getAssignedWearersUrl = (userId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/wearer/getPaginatedAssignedWearers/${userId}/${pageIndex}/${pageSize}`

  getUnassignedWearersUrl = (userId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/wearer/getWearers/${userId}/${pageIndex}/${pageSize}`

  getWearerByIdUrl = (wearerId: number) => `${this.serverUrl}/wearer/getWearer/${wearerId}`

  getWearerPrescriptionByIdUrl = (wearerId: number) => `${this.serverUrl}/wearer/getPrescriptionByWearerId/${wearerId}`

  getUnassignedWearersBySiteUrl = (siteId: number) => `${this.serverUrl}/wearer/getAllUnassignedWearers/${siteId}`

  getAssignedWearersBySiteUrl = (userId: number, siteId: number) => `${this.serverUrl}/wearer/getAllAssignedWearers/${userId}/${siteId}`

  assignWearerAlertRuleProfileUrl = (alertProfileId: number, weareId: number) => `${this.serverUrl}/wearer/associateAlertRuleProfileToWearer/${alertProfileId}/${weareId}`

  editWearerAlertProfileUrl = (wearerId: any) => `${this.serverUrl}/wearer/editAlertProfileAssociatedWithWearer/${wearerId}`

  getWearerAlertProfileUrl = (wearerId: number) => `${this.serverUrl}/wearer/getAlertRuleProfileByWearer/${wearerId}`

  addWearerMessageRoutingUrl = (wearerId: number, sms: boolean, email: boolean) => `${this.serverUrl}/wearer/smsEmailAlert/${wearerId}/${sms}/${email}`

  getWearerMessageRoutingUrl = (wearerId: number) => `${this.serverUrl}/wearer/getNotificationStatusByWearerId/${wearerId}`

  editWearerMessageRoutingUrl = (wearerId: any) => `${this.serverUrl}/wearer/editNotificationChannelForWearer/${wearerId}`

  getWearerMobileDevicesUrl = (wearerId: number) => `${this.serverUrl}/wearer/getMobilesByWearerId/${wearerId}`

  deleteWearerMobilesUrl = (mobileId: number) => `${this.serverUrl}/wearer/deleteMobile/${mobileId}`

  getAssignableDevicesUrl = (userId: number, siteId: number) => `${this.serverUrl}/wearer/getDevicesForAssigning/${userId}/${siteId}`

  assignDeviceToWearerUrl = (wearerId: number, deviceId: string) => `${this.serverUrl}/wearer/add/${deviceId}/${wearerId}`

  editWearerDeviceUrl = (wearerId: number, deviceId: string) => `${this.serverUrl}/wearer/edit/${wearerId}/${deviceId}`

  getWearerDeviceUrl = (wearerId: number) => `${this.serverUrl}/wearer/getAssignedDeviceToWearer/${wearerId}`

  filterWearerData = (filterData: any) => {
    const { isAssigned, userId, pageIndex, pageSize, wearerName, siteId, startDate, endDate, timeZoneName } = filterData
    let filterWearersUrl = `${this.serverUrl}/wearer/filterWearers/${isAssigned}/${userId}/${pageIndex}/${pageSize}?`;

    const queryParams = []
    if (wearerName && wearerName !== '') {
      queryParams.push(`name=${wearerName}`);
    }

    if (siteId && siteId !== '0') {
      queryParams.push(`siteId=${siteId}`);
    }

    if (startDate && endDate) {
      queryParams.push(`dateFrom=${startDate}&dateTo=${endDate}`);
    }

    if (timeZoneName) {
      queryParams.push(`timeZone=${timeZoneName}`)
    }

    filterWearersUrl += queryParams.join('&')
    return filterWearersUrl
  }

  deleteWearerUrl = (wearerId: any) => `${this.serverUrl}/wearer/delete/${wearerId}`

  assignDeviceUrl = (deviceId: String, wearerId: number) => `${this.serverUrl}/wearer/add/${deviceId}/${wearerId}`

  unassignDeviceUrl = (wearerId: number) => `${this.serverUrl}/wearer/unassignDevice/${wearerId}`

  getAllWearersUrl = (siteId: number) => `${this.serverUrl}/wearer/getAllWearers/${siteId}`
}


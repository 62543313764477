export class WearerPrescription {
    id: number | null
    targetPeriodInMinutes: number
    targetStepsPerDay: number
    maximumHoursOfUsage: number
    language: number
    verbosityLevel: number
    wearerId: number | null

    constructor(wearerId: number) {
        this.id = null
        this.wearerId = wearerId
        this.targetPeriodInMinutes = 480
        this.targetStepsPerDay = 5000
        this.maximumHoursOfUsage = 10
        this.language = 0
        this.verbosityLevel = 0
    }
}
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ModalDirective } from 'ngx-bootstrap/modal'
import { CreatePatientService } from 'src/app/iot/services/create-patient.service'
import { AlertRuleProfileService } from 'src/app/iot/services/alert-rule-profile.service'
import { AlertRuleProfile } from 'src/app/shared/models/alert-rule-profile.model'
import { WearerPrescription } from 'src/app/shared/models/wearer-prescription.model'
import { LOADER_CONFIG } from 'src/app/shared/global-constants'
import { LoaderService } from 'src/app/iot/services/loader.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-patient-alert-profile',
  templateUrl: './patient-alert-profile.component.html',
  styleUrls: ['./patient-alert-profile.component.scss']
})
export class PatientAlertProfileComponent implements OnInit, OnDestroy {
  loading: boolean = false
  btnStatus: string = 'save'
  isSaveAlreadyPressed: boolean = false
  alertType: string = ''
  isCustomSelected: boolean = false
  alertRule: string = ''
  isConfirmation: boolean = false
  modalTitle: string = ''
  isModalShown: boolean = false
  wearerId: string = ''
  isEditMode: boolean = false
  alertProfileId!: number
  alertProfileModel: AlertRuleProfile
  preConfiguredAlertProfiles: Array<any> = []
  wearerPrescription!: WearerPrescription
  disableSave: boolean = false
  editPreconfigured: boolean = false
  isPrescriptionPresent: boolean = true
  lowBatteryValue: number = 10
  deviceSyncValue: number = 2
  showError: boolean = false
  invalidDeviceSync: boolean = false
  invalidLowBattery: boolean = false
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG

  @ViewChild("alertModal", { static: false }) alertModal!: ModalDirective
  @ViewChild("confirmationModal", { static: false }) confirmationModal!: ModalDirective

  constructor(private createPatientService: CreatePatientService,
    private loaderService: LoaderService, 
    private alertProfileService: AlertRuleProfileService,
    private activatedRoute: ActivatedRoute) {
      this.alertProfileModel = new AlertRuleProfile()
      // this.wearerPrescription = new WearerPrescription(parseInt(localStorage.getItem('wearerId')!))
      this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
        this.loading = isLoading
      })
  }

  ngOnInit(): void {
    this.wearerId = this.activatedRoute.snapshot.queryParamMap.get("id")!
    if (this.wearerId) {
      this.isEditMode = true
      let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      if (wearerData.alertRuleProfileId) {
        this.getWearerAlerProfile(parseInt(this.wearerId))  
      }
      if (wearerData.prescriptionId) {
        this.getWearerPrescription(parseInt(this.wearerId))
      } else {
        this.isPrescriptionPresent = false
      }
    } else {
      let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      this.getWearerPrescription(parseInt(wearerData.id))
    }
    this.getPreConfiguredAlertProfiles()
  }

  getPreConfiguredAlertProfiles() {
    this.loading = true
    this.alertProfileService.getAllPreconfiguredAlertProfiles().subscribe({
      next: (response: any) => {
        this.loading = false
        
        this.preConfiguredAlertProfiles = response.DATA
      },
      error: (err: any) => {
        this.loading = false
      }
    })
  }

  getWearerAlerProfile(wearerId: number) {
    this.createPatientService.getWearerAlertProfile(wearerId).subscribe({
      next: (response: any) => {
        
        if(response.DATA.alertProfileType === 'CUSTOM') {
          this.isCustomSelected =  true
          this.alertType = 'custom'
          this.alertProfileModel.name = response.DATA.name
          this.alertProfileModel.details = response.DATA.details
          this.alertProfileModel.id = response.DATA.id
        } else {
          this.isCustomSelected = false;
          this.alertType = response.DATA.id.toString()
          this.alertProfileModel.id = response.DATA.id
          this.alertProfileModel.deviceSync = response.DATA.deviceSync
          this.alertProfileModel.lowBattery = response.DATA.lowBattery
        }
      },
      error: (response: any) => {

      }
    })
  }

  getWearerPrescription(wearerId: number) {
    this.createPatientService.getWearerPrescriptionById(wearerId).subscribe({
      next: (response: any) => {
        
        this.wearerPrescription = new WearerPrescription(wearerId)
        this.wearerPrescription.targetPeriodInMinutes = response.DATA.targetPeriodInMinutes
        this.wearerPrescription.targetStepsPerDay = response.DATA.targetStepsPerDay
        this.wearerPrescription.maximumHoursOfUsage = response.DATA.maximumHoursOfUsage
        this.wearerPrescription.verbosityLevel = response.DATA.verbosityLevel
      },
      error: (err: any) => {

      }
    })
  }


  routeToNextTab(btnStatus: string) {
    const addedFormStatus: any = {};
    addedFormStatus.activeTabName = "patient-alert-profile";
    addedFormStatus.buttonStatus = btnStatus;
    this.createPatientService.broadcastNewPatient(addedFormStatus);
  }

  selectAlertRuleProfile(event: any) {
    let alertType = event.target.value
    // alertTpye = '2'
    // if (!isNaN(alertType)) {
    //   return
    // }
    if (alertType == 'custom') {
      this.isCustomSelected = true;
      let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      this.alertProfileModel.name = `Custom alert rule profile for ${wearerData.firstName} ${wearerData.lastName}`
      this.alertProfileModel.details = `Custom alert rule description for ${wearerData.firstName} ${wearerData.lastName}`
      this.alertProfileModel.lowBattery = 10
      this.alertProfileModel.deviceSync = 2
    } else if (!isNaN(alertType)){
      this.isCustomSelected = false
      let alertId = parseInt(event.target.value)
      this.getAlertProfileDetails(alertId)
    }
  }

  getAlertProfileDetails(alertProfileId: number) {
    this.alertProfileService.getAlertProfileById(alertProfileId).subscribe({
      next: (response: any) => {
        
        this.alertProfileModel = response.DATA
      },
      error: (err: any) => {
        
      }
    })
  }

  showModal(alertRule: string) {
    this.alertRule = alertRule
    if (!this.isCustomSelected) {
      this.isConfirmation = true
    } else {
      if (alertRule == 'deviceSync') {
        this.modalTitle = 'Device Sync'
      } else if (alertRule == 'lowBattery') {
        this.modalTitle = 'Low Battery'
      } 
      this.alertModal.show()
    }
  }

  hideModal() {
    this.alertModal.hide()
  }

  updateValues() {
    if (this.alertRule == 'deviceSync') {
      if (!this.deviceSyncValue) {
        this.showError = true
        this.invalidDeviceSync = false
        return
      } else if (this.deviceSyncValue < 2 || this.deviceSyncValue > 10) {
        this.showError = true
        this.invalidDeviceSync = true
        return
      } 
      else {
        this.alertProfileModel.deviceSync = this.deviceSyncValue
      }
    } else if (this.alertRule == 'lowBattery') {
      if (!this.lowBatteryValue) {
        this.showError = true
        this.invalidLowBattery = false
        return
      } else if (this.lowBatteryValue < 10 || this.lowBatteryValue > 20) {
        this.showError = true
        this.invalidLowBattery = true
        return
      } else {
        this.alertProfileModel.lowBattery = this.lowBatteryValue
      }
    }
    this.alertModal.hide()
  }

  onHidden() {
    this.showError = false
    this.invalidDeviceSync = false
    this.invalidLowBattery = false
    this.deviceSyncValue = 2
    this.lowBatteryValue = 10
  }

  editPreCon() {}

  saveAlertProfile() {
    let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
    if (this.isEditMode && wearerData.alertRuleProfileId) {
      this.updateAlertRule(this.wearerId);
    } else {
      this.addAlertRule();
    }
  }

  addAlertRule() {
    this.wearerId = JSON.parse(localStorage.getItem('wearerData')!).id
    if (this.btnStatus === 'save') {
      this.disableSave = true
      this.isSaveAlreadyPressed = true
      if (this.isCustomSelected) {
        this.createCustomAlertRule(this.alertProfileModel)
      } else {
        this.associateAlertRuleProfileWithWearer(this.alertProfileModel, this.wearerId)
      }
    } else {
      if (this.isSaveAlreadyPressed) {
        if(this.disableSave) {
          this.routeToNextTab(this.btnStatus)
        } else {
          if(this.isCustomSelected) {
            this.alertProfileModel.alertProfileType = 'CUSTOM'
          } else {
            this.alertProfileModel.alertProfileType = 'PRE_CONFIGURED'
          }
          this.createPatientService.editWearerAlertRuleProfile(this.alertProfileModel, parseInt(this.wearerId)).subscribe({
            next: (response: any) => {
              if (this.btnStatus == 'saveNext') {
                this.routeToNextTab(this.btnStatus);
              }
            },
            error: (err: any) => {

            }
          })
        }
      } else {
        if (this.isCustomSelected) {
          this.createCustomAlertRule(this.alertProfileModel);
        } else {
          this.associateAlertRuleProfileWithWearer(this.alertProfileModel.id, this.wearerId)
        }
      }
    }
  }

  updateAlertRule(wearerId: any) {
    if (this.disableSave) {
      this.routeToNextTab(this.btnStatus)
    } else {
      if (this.isCustomSelected) {
        this.alertProfileModel.alertProfileType = 'CUSTOM'
      } else {
        this.alertProfileModel.alertProfileType = 'PRE_CONFIGURED'
        this.editPreconfigured = true
        let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
        
      }
      
      // Check if a pre configured profile is being edited
      if (!this.editPreconfigured) {
        // if yes then create a new custom alert profile for that wearer
        this.createCustomAlertRule(this.alertProfileModel);
      } else {
        this.createPatientService.editWearerAlertRuleProfile(this.alertProfileModel, wearerId).subscribe({
          next: (success: any) => {
            this.loading = false
            
            if (this.btnStatus == 'saveNext') {
              this.routeToNextTab(this.btnStatus);
            }
          }, 
          error: (err: any) => {
            this.loading = false
          }
        });
      }
    }
  }

  createCustomAlertRule(alertProfileData: any) {
    this.alertProfileService.createAlertRuleProfile(alertProfileData, 'CUSTOM').subscribe({
      next: (response: any) => {
        this.loading = false
        
        this.alertProfileId = response.DATA.id;
        let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
        //this.alertRuleDTO.id = response.data.id;
        // this.alertRuleDTO.impactRate.id = response.data.impactRate.id;
        //this.wearerId = localStorage.getItem('wearerId')!;
        this.associateAlertRuleProfileWithWearer(this.alertProfileId, wearerData.id);
      }, error: (err: any) => {
        this.loading = false
      }
    });
  }

  associateAlertRuleProfileWithWearer(alertProfileId: any, wearerId: any) {
    this.createPatientService.addWearerAlertRuleProfile(alertProfileId, wearerId).subscribe({
      next: (resposne: any) => {
        this.disableSave = true
        let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
        if(wearerData) {
          wearerData.alertRuleProfileId = resposne.DATA.id
        }
        if (this.btnStatus == 'saveNext') {
          this.routeToNextTab(this.btnStatus)
        }
      },
      error: (err: any) => {

      }
    })
  }
  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }
}

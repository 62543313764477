import { Component, OnInit, ViewChild } from '@angular/core'
import {
  ColumnChartOptions, LineChartOptions,
  PressureBarChartOptions,
  StackedColumnChartOptions,
  PressureLineChartOptions,
  PressureHeatmapOptions,
  TemperatureBarChartOptions,
  TemperatureLineChartOptions,
  StepCountBarChartOptions,
  StepCountLineChartOptions,
  MixedChartOptions
} from './chart-options'
import { pressureLineChartOptions, pressureBarChartOptions, oneWeekPressureBarOptions, oneWeekPressureLineOptions } from './mock-data/pressure'
import {
  temperatureBarChartOptions,
  oneWeekTemperatureBarOptions,
  temperatureLineChartOptions,
  oneWeekTemperatureLineOptions
} from './mock-data/temperature'
import { humidityLineChartOptions, oneWeekHumidityLineOptions, humidityColumnChartOptions, oneWeekHumidityColumnOptions } from './mock-data/humidity'
import { stepCountBarChartOptions, oneWeekSCBarOptions, stepCountLineChartOptions, oneWeekSCLineOptions } from './mock-data/step-count'
import { PressureHeatmap } from './mock-data/heatmap'
import { ChartComponent } from 'ng-apexcharts'
import * as _ from 'lodash'

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit {
  showBar: boolean = true
  showLine: boolean = false
  showHeatmap: boolean = false
  zoneEnabled: boolean = false
  selectedInterval: number = 1
  selectedParameter: string = 'pressure'
  pressureHeatmapOjb = new PressureHeatmap()

  @ViewChild("pressure-bar", { static: true }) pressureBarChart!: ChartComponent;
  pressureBarChartOptions: StackedColumnChartOptions

  @ViewChild("pressure-line", { static: true }) pressureLineChart!: ChartComponent;
  pressureLineChartOptions: PressureLineChartOptions

  @ViewChild("pressure-heatmap", { static: true }) pressureHeatmap!: ChartComponent;
  pressureHeatMapOptions: PressureHeatmapOptions

  @ViewChild("temperature-bar", { static: true }) tempBarChart!: ChartComponent
  temperatureBarChartOptions!: TemperatureBarChartOptions

  @ViewChild("temperature-line", { static: true }) tempLineChart!: ChartComponent
  temperatureLineChartOptions!: TemperatureLineChartOptions

  @ViewChild("pressure-heatmap", { static: true }) tempHeatmap!: ChartComponent;
  tempHeatMapOptions!: PressureHeatmapOptions

  @ViewChild("humidity-bar", { static: true }) humidityBar!: ChartComponent;
  humidityBarChartOptions: ColumnChartOptions

  @ViewChild("humidity-line", { static: true }) humidityLine!: ChartComponent;
  humidityLineChartOptions: LineChartOptions
  @ViewChild("stepCount-bar", { static: true }) stepCountBar!: ChartComponent;
  stepCountBarChartOptions: StepCountBarChartOptions

  @ViewChild("stepCount-line", { static: true }) stepCountLine!: ChartComponent;
  stepCountLineChartOptions: StepCountLineChartOptions

  deepCopy: Array<any>
  oneWeekCopy: Array<any>

  oneDayBarCopy: Array<any>
  oneWeekBarCopy: Array<any>

  constructor() {
    this.deepCopy = _.cloneDeep(pressureLineChartOptions.series)
    this.oneWeekCopy = _.cloneDeep(oneWeekPressureLineOptions.series)
    this.oneDayBarCopy = _.cloneDeep(pressureBarChartOptions.series)
    this.oneWeekBarCopy = _.cloneDeep(oneWeekPressureBarOptions.series)

    this.pressureLineChartOptions = pressureLineChartOptions
    this.pressureBarChartOptions = pressureBarChartOptions
    this.pressureHeatMapOptions = this.pressureHeatmapOjb.prepareData(this.zoneEnabled)

    this.temperatureBarChartOptions = temperatureBarChartOptions
    this.temperatureLineChartOptions = temperatureLineChartOptions

    this.humidityBarChartOptions = humidityColumnChartOptions
    this.humidityLineChartOptions = humidityLineChartOptions

    this.stepCountBarChartOptions = stepCountBarChartOptions
    this.stepCountLineChartOptions = stepCountLineChartOptions
  }

  ngOnInit(): void {
    let fileteredBarData = pressureBarChartOptions.series.filter((item) => {
      return (item.name == 'CH1-Zone 1' || item.name == 'CH1-Zone 2' || item.name == 'CH1-Zone 3' || item.name == 'CH1-Zone 4' || item.name == 'CH1-Zone 5')
    })
    let filteredLineData = pressureLineChartOptions.series.filter((item) => {
      return (item.name == 'CH1-Zone 1' || item.name == 'CH1-Zone 2' || item.name == 'CH1-Zone 3' || item.name == 'CH1-Zone 4' || item.name == 'CH1-Zone 5')
    })
    this.pressureLineChartOptions.series = filteredLineData
    this.pressureBarChartOptions.series = fileteredBarData
    // this.selectChannel({event:{target:{value: '1'}}})
  }

  changeParameter(event: any) {
    this.selectedParameter = event.target.value
  }

  selectChannel(event: any) {
    let value = event.target.value
    let filteredData: Array<any> = []

    console.log('interval: ', this.selectedParameter, this.showLine, this.showBar)
    if (this.showBar) {
      if (this.selectedInterval == 1) {
        pressureBarChartOptions.series = this.oneDayBarCopy
        if (value == '1') {
          filteredData = pressureBarChartOptions.series.filter((item) => {
            return (item.name == 'CH1-Zone 1' || item.name == 'CH1-Zone 2' || item.name == 'CH1-Zone 3' || item.name == 'CH1-Zone 4' || item.name == 'CH1-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '2') {
          filteredData = pressureBarChartOptions.series.filter((item) => {
            return (item.name == 'CH2-Zone 1' || item.name == 'CH2-Zone 2' || item.name == 'CH2-Zone 3' || item.name == 'CH2-Zone 4' || item.name == 'CH2-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '3') {
          filteredData = pressureBarChartOptions.series.filter((item) => {
            return (item.name == 'CH3-Zone 1' || item.name == 'CH3-Zone 2' || item.name == 'CH3-Zone 3' || item.name == 'CH3-Zone 4' || item.name == 'CH3-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '4') {
          filteredData = pressureBarChartOptions.series.filter((item) => {
            return (item.name == 'CH4-Zone 1' || item.name == 'CH4-Zone 2' || item.name == 'CH4-Zone 3' || item.name == 'CH4-Zone 4' || item.name == 'CH4-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '5') {
          filteredData = pressureBarChartOptions.series.filter((item) => {
            return (item.name == 'CH5-Zone 1' || item.name == 'CH5-Zone 2' || item.name == 'CH5-Zone 3' || item.name == 'CH5-Zone 4' || item.name == 'CH5-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '0') {
          this.pressureBarChartOptions.series = this.oneDayBarCopy
        }
      } else if (this.selectedInterval == 7) {
        oneWeekPressureBarOptions.series = this.oneWeekBarCopy
        if (value == '1') {
          filteredData = oneWeekPressureBarOptions.series.filter((item) => {
            return (item.name == 'CH1-Zone 1' || item.name == 'CH1-Zone 2' || item.name == 'CH1-Zone 3' || item.name == 'CH1-Zone 4' || item.name == 'CH1-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '2') {
          filteredData = oneWeekPressureBarOptions.series.filter((item) => {
            return (item.name == 'CH2-Zone 1' || item.name == 'CH2-Zone 2' || item.name == 'CH2-Zone 3' || item.name == 'CH2-Zone 4' || item.name == 'CH2-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '3') {
          filteredData = oneWeekPressureBarOptions.series.filter((item) => {
            return (item.name == 'CH3-Zone 1' || item.name == 'CH3-Zone 2' || item.name == 'CH3-Zone 3' || item.name == 'CH3-Zone 4' || item.name == 'CH3-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '4') {
          filteredData = oneWeekPressureBarOptions.series.filter((item) => {
            return (item.name == 'CH4-Zone 1' || item.name == 'CH4-Zone 2' || item.name == 'CH4-Zone 3' || item.name == 'CH4-Zone 4' || item.name == 'CH4-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '5') {
          filteredData = oneWeekPressureBarOptions.series.filter((item) => {
            return (item.name == 'CH5-Zone 1' || item.name == 'CH5-Zone 2' || item.name == 'CH5-Zone 3' || item.name == 'CH5-Zone 4' || item.name == 'CH5-Zone 5')
          })
          this.pressureBarChartOptions.series = filteredData
        } else if (value == '0') {
          this.pressureBarChartOptions.series = this.oneWeekCopy
        }
      }
    } else if (this.showLine) {
      if (this.selectedInterval == 1) {
        pressureLineChartOptions.series = this.deepCopy
        if (value == '1') {
          filteredData = pressureLineChartOptions.series.filter((item) => {
            return (item.name == 'CH1-Zone 1' || item.name == 'CH1-Zone 2' || item.name == 'CH1-Zone 3' || item.name == 'CH1-Zone 4' || item.name == 'CH1-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '2') {
          filteredData = pressureLineChartOptions.series.filter((item) => {
            return (item.name == 'CH2-Zone 1' || item.name == 'CH2-Zone 2' || item.name == 'CH2-Zone 3' || item.name == 'CH2-Zone 4' || item.name == 'CH2-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '3') {
          filteredData = pressureLineChartOptions.series.filter((item) => {
            return (item.name == 'CH3-Zone 1' || item.name == 'CH3-Zone 2' || item.name == 'CH3-Zone 3' || item.name == 'CH3-Zone 4' || item.name == 'CH3-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '4') {
          filteredData = pressureLineChartOptions.series.filter((item) => {
            return (item.name == 'CH4-Zone 1' || item.name == 'CH4-Zone 2' || item.name == 'CH4-Zone 3' || item.name == 'CH4-Zone 4' || item.name == 'CH4-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '5') {
          filteredData = pressureLineChartOptions.series.filter((item) => {
            return (item.name == 'CH5-Zone 1' || item.name == 'CH5-Zone 2' || item.name == 'CH5-Zone 3' || item.name == 'CH5-Zone 4' || item.name == 'CH5-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '0') {
          this.pressureLineChartOptions.series = this.deepCopy
        }
      } else if (this.selectedInterval == 7) {
        oneWeekPressureLineOptions.series = this.oneWeekCopy
        if (value == '1') {
          filteredData = oneWeekPressureLineOptions.series.filter((item) => {
            return (item.name == 'CH1-Zone 1' || item.name == 'CH1-Zone 2' || item.name == 'CH1-Zone 3' || item.name == 'CH1-Zone 4' || item.name == 'CH1-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '2') {
          filteredData = oneWeekPressureLineOptions.series.filter((item) => {
            return (item.name == 'CH2-Zone 1' || item.name == 'CH2-Zone 2' || item.name == 'CH2-Zone 3' || item.name == 'CH2-Zone 4' || item.name == 'CH2-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '3') {
          filteredData = oneWeekPressureLineOptions.series.filter((item) => {
            return (item.name == 'CH3-Zone 1' || item.name == 'CH3-Zone 2' || item.name == 'CH3-Zone 3' || item.name == 'CH3-Zone 4' || item.name == 'CH3-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '4') {
          filteredData = oneWeekPressureLineOptions.series.filter((item) => {
            return (item.name == 'CH4-Zone 1' || item.name == 'CH4-Zone 2' || item.name == 'CH4-Zone 3' || item.name == 'CH4-Zone 4' || item.name == 'CH4-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '5') {
          filteredData = oneWeekPressureLineOptions.series.filter((item) => {
            return (item.name == 'CH5-Zone 1' || item.name == 'CH5-Zone 2' || item.name == 'CH5-Zone 3' || item.name == 'CH5-Zone 4' || item.name == 'CH5-Zone 5')
          })
          this.pressureLineChartOptions.series = filteredData
        } else if (value == '0') {
          this.pressureLineChartOptions.series = this.oneWeekCopy
        }
      }
      
    }

  }


  switchGraph(event: any) {
    if (event.target.value == 'bar') {
      this.showBar = true
      this.showLine = false
      this.showHeatmap = false
    } else if (event.target.value == 'line') {
      this.showLine = true
      this.showBar = false
      this.showHeatmap = false
    } else {
      this.showHeatmap = true
      this.showLine = false
      this.showBar = false
    }
  }

  toggleHMViews(event: any) {
    console.log(event.target.checked, this.selectedInterval)
    this.zoneEnabled = event.target.checked
    if (this.selectedInterval == 1) {
      this.pressureHeatMapOptions = this.pressureHeatmapOjb.prepareData(this.zoneEnabled)
    } else if (this.selectedInterval == 7) {
      this.pressureHeatMapOptions = this.pressureHeatmapOjb.prepareOneWeekData(this.zoneEnabled)
    }
  }

  selectTimeInterval(value: any) {
    this.selectedInterval = parseInt(value)
    // console.log(this.selectedInterval)
    if (this.selectedInterval == 1) {
      if (this.selectedParameter == 'temperature' && this.showBar) {
        this.temperatureBarChartOptions = temperatureBarChartOptions
      } else if (this.selectedParameter == 'temperature' && this.showLine) {
        this.temperatureLineChartOptions = temperatureLineChartOptions
      } else if (this.selectedParameter == 'humidity' && this.showBar) {
        this.humidityBarChartOptions = humidityColumnChartOptions
      } else if (this.selectedParameter == 'humidity' && this.showLine) {
        this.humidityLineChartOptions = humidityLineChartOptions
      } else if (this.selectedParameter == 'steps' && this.showBar) {
        this.stepCountBarChartOptions = stepCountBarChartOptions
      } else if (this.selectedParameter == 'steps' && this.showLine) {
        this.stepCountLineChartOptions = stepCountLineChartOptions
      } else if (this.selectedParameter == 'pressure' && this.showHeatmap) {
        this.pressureHeatMapOptions = this.pressureHeatmapOjb.prepareData(this.zoneEnabled)
      } else if (this.selectedParameter == 'pressure' && this.showBar) {
        this.pressureBarChartOptions = pressureBarChartOptions
      } else if (this.selectedParameter == 'pressure' && this.showLine) {
        this.pressureLineChartOptions = pressureLineChartOptions
      }
    } else if (this.selectedInterval == 7) {
      if (this.selectedParameter == 'temperature' && this.showBar) {
        this.temperatureBarChartOptions = oneWeekTemperatureBarOptions
      } else if (this.selectedParameter == 'temperature' && this.showLine) {
        this.temperatureLineChartOptions = oneWeekTemperatureLineOptions
      } else if (this.selectedParameter == 'humidity' && this.showBar) {
        this.humidityBarChartOptions = oneWeekHumidityColumnOptions
      } else if (this.selectedParameter == 'humidity' && this.showLine) {
        this.humidityLineChartOptions = oneWeekHumidityLineOptions
      } else if (this.selectedParameter == 'steps' && this.showBar) {
        // this.stepCountBarChartOptions = oneWeekSCBarOptions
      } else if (this.selectedParameter == 'steps' && this.showLine) {
        this.stepCountLineChartOptions = oneWeekSCLineOptions
      } else if (this.selectedParameter == 'pressure' && this.showHeatmap) {
        this.pressureHeatMapOptions = this.pressureHeatmapOjb.prepareOneWeekData(this.zoneEnabled)
      } else if (this.selectedParameter == 'pressure' && this.showBar) {
        this.pressureBarChartOptions = oneWeekPressureBarOptions
        this.pressureBarChartOptions.series = oneWeekPressureBarOptions.series.filter((item) => {
          return (item.name == 'CH1-Zone 1' || item.name == 'CH1-Zone 2' || item.name == 'CH1-Zone 3' || item.name == 'CH1-Zone 4' || item.name == 'CH1-Zone 5')  
        })
      } else if (this.selectedParameter == 'pressure' && this.showLine) {
        this.pressureLineChartOptions = oneWeekPressureLineOptions
        this.pressureLineChartOptions.series = oneWeekPressureLineOptions.series.filter((item) => {
          return (item.name == 'CH1-Zone 1' || item.name == 'CH1-Zone 2' || item.name == 'CH1-Zone 3' || item.name == 'CH1-Zone 4' || item.name == 'CH1-Zone 5')
        })
      }
    }
  }
}

<div class="manage-alerts-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="row">
        <div class="col-md-12">
            <div class="table-wrapper">
                <table class="table table-striped table-bordered text-center">
                    <caption>List of alert rule profiles</caption>
                    <thead>
                        <tr>
                          <th>Name</th>
                          <th>Details</th>
                          <th class="w-25">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let alert of alertProfileList; let i=index">
                            <td>{{alert.name}}</td>
                            <td>{{alert.details}}</td>
                            <td>
                                <select #alertAction class="form-select form-select-sm"
                                    (change)="selectAction($event, alert)">
                                    <option selected value="">Select action</option>
                                    <option value="edit">Edit</option>
                                    <option value="delete">Delete</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="paging">
                    <pagination 
                        [totalItems]="totalItems" 
                        previousText="&lsaquo;" 
                        nextText="&rsaquo;" 
                        firstText="&laquo;"
                        lastText="&raquo;" 
                        [maxSize]="10" 
                        [itemsPerPage]="10" 
                        [boundaryLinks]="true"
                        [(ngModel)]="currentPage" 
                        (pageChanged)="pageChanged($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Delete Modal-->
<div class="modal fade" bsModal #deleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Delete Alert Rule Profile</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="deleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <p>Are you sure you want to delete this alert rule profile ?</p>
                    <h6>Warning:</h6>
                    <p>
                        Deleting this profile will result in any wearers for which the profile has been applied 
                        will have existing settings preserved and their profile type changed to <strong>"Custom"</strong>.
                    </p>
                </div>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Save" icon="pi pi-check" (click)="deleteAlertProfile(alertProfileId)"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Confirmation  -->
<div class="modal fade" bsModal #confirmationModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Edit Alert Rule Profile</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="confirmationModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row mb-3">
                    <p>Are you sure you want to edit this pre-configured alert rule profile ?</p>
                    <h6>Warning:</h6>
                    <p>
                        Editing this profile will result in any wearers for which the profile has been applied 
                        will have existing settings preserved and their profile type changed to <strong>"Custom"</strong>.
                    </p>
                </div>
                <!-- <p style="font-size: 15px;">Are you sure you want to edit a pre-configured alert rule profile ?</p> -->
                <!-- <p style="font-size: 15px;">All associated wearers will be affected by this change</p> -->
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="No" icon="pi pi-times" (click)="hideModal()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Yes" icon="pi pi-check" (click)="editAlertProfile()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>
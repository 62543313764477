import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Subject } from 'rxjs/internal/Subject'
import { WearerApiBuilderService } from 'src/app/shared/api-builders/wearer-api-builder.service'
import { Wearer } from 'src/app/shared/models/wearer.model'
@Injectable()
export class CreatePatientService {

  constructor(private http: HttpClient,
    private wearerApiBuilder: WearerApiBuilderService) {

  }

  private newPatientAddedSource = new Subject<any>
  patientAdded$ = this.newPatientAddedSource.asObservable()

  broadcastNewPatient(newPatientData: any) {
    this.newPatientAddedSource.next(newPatientData)
  }

  createWearer(wearerData: Wearer) {
    return this.http.post(this.wearerApiBuilder.createWearerUrl, wearerData)
  }

  updateWearer(wearerId: number, wearerData: Wearer) {
    return this.http.put(this.wearerApiBuilder.updateWearerUrl(wearerId), wearerData)
  }

  getWearerById(wearerId: number) {
    return this.http.get(this.wearerApiBuilder.getWearerByIdUrl(wearerId))
  }

  getWearerPrescriptionById(wearerId: number) {
    return this.http.get(this.wearerApiBuilder.getWearerPrescriptionByIdUrl(wearerId))
  }

  addWearerAlertRuleProfile(alertProfileId: number, weareId: number) {
    const assignWearerAlertProfileUrl = this.wearerApiBuilder.assignWearerAlertRuleProfileUrl(alertProfileId, weareId);
    return this.http.post(assignWearerAlertProfileUrl, {});
  }

  editWearerAlertRuleProfile(alertProfileData: any, weareId: number) {
    const editWearerAlertProfileUrl = this.wearerApiBuilder.editWearerAlertProfileUrl(weareId);
    return this.http.put(editWearerAlertProfileUrl, alertProfileData);
  }

  getWearerAlertProfile(wearerId: number) {
    return this.http.get(this.wearerApiBuilder.getWearerAlertProfileUrl(wearerId))
  }

  addWearerMessageRouting(wearerId: any, sms: boolean, email: boolean) {
    const wearerNotificationUrl = this.wearerApiBuilder.addWearerMessageRoutingUrl(wearerId, sms, email);
    return this.http.post(wearerNotificationUrl, {});
  }

  editWearerMessageRouting(weareId: any, settingsData: any) {
    const editWearerMessageRoutingUrl = this.wearerApiBuilder.editWearerMessageRoutingUrl(weareId);
    return this.http.put(editWearerMessageRoutingUrl, settingsData);
  }

  getWearerMessageRouting(wearerId: number) {
    return this.http.get(this.wearerApiBuilder.getWearerMessageRoutingUrl(wearerId))
  }

  getWearerMobileDevices(wearerId: number) {
    const getWearerMobileDevicesUrl = this.wearerApiBuilder.getWearerMobileDevicesUrl(wearerId)
    return this.http.get(getWearerMobileDevicesUrl)
  }

  deleteWearerMobile(mobileId: number) {
    const deleteWearerMobilesUrl = this.wearerApiBuilder.deleteWearerMobilesUrl(mobileId)
    return this.http.delete(deleteWearerMobilesUrl)
  }

  getAssignableDevices(userId: number, siteId: number) {
    const getAssignableDevicesUrl = this.wearerApiBuilder.getAssignableDevicesUrl(userId, siteId)
    return this.http.get(getAssignableDevicesUrl)
  }

  assignDeviceToWearer(wearerId: number, deviceId: string) {
    const assignDeviceToWearerUrl = this.wearerApiBuilder.assignDeviceToWearerUrl(wearerId, deviceId)
    return this.http.post(assignDeviceToWearerUrl, {})
  }

  updateWearerDevice(deviceId: string, weareId: number) {
    const editWearerDeviceUrl = this.wearerApiBuilder.editWearerDeviceUrl(weareId, deviceId);
    return this.http.put(editWearerDeviceUrl, {})
  }

  getWearerDevice(wearerId: number) {
    const getWearerDeviceUrl = this.wearerApiBuilder.getWearerDeviceUrl(wearerId)
    return this.http.get(getWearerDeviceUrl)
  }
}

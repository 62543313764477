import { DeviceConfig } from './device-config.model'

export class Device {
    id: number | null
    deviceId: string 
    siteId: number | null
    wearerId: number | null
    controllerId: string | null  
    isActive: boolean
    isAllocated: boolean
    isAssigned: boolean
    isProvisioned: boolean
    isConfigUpdated: boolean
    deviceConfig: DeviceConfig | null

    constructor(configSettings: DeviceConfig) {
        this.id = null
        this.deviceId = ''
        this.siteId = null
        this.wearerId = null
        this.controllerId = null
        this.isActive = true
        this.isAllocated = false
        this.isAssigned = false
        this.isProvisioned = false
        this.isConfigUpdated = false
        this.deviceConfig = configSettings
    }
}
<div class="graph-wrapper">
    <div class="row">
        <!--Graph Filters-->
        <div class="col-md-2">
            <div class="filter-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h6>Filters</h6>
                        </div>
                    </div>
                    <!-- Parameters -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <label for="">Parameters</label>
                            <select class="form-select form-select-sm" (change)="changeParameter($event)">
                                <option value="pressure">Pressure</option>
                                <option value="temperature">Temperatue</option>
                                <option value="humidity">Humidity</option>
                                <option value="steps">Step Count</option>
                            </select>
                        </div>
                    </div>
                    <!-- Channels -->
                    <div class="row mb-3" *ngIf="selectedParameter=='pressure' && (showLine==true || showBar==true)">
                        <div class="col-12">
                            <label for="">Channels</label>
                            <select class="form-select form-select-sm" (change)="selectChannel($event)">
                                <!-- <option value="0" selected>Select channel</option> -->
                                <option value="1" selected>Channel 1</option>
                                <option value="2">Channel 2</option>
                                <option value="3">Channel 3</option>
                                <option value="4">Channel 4</option>
                                <option value="5">Channel 5</option>
                            </select>
                        </div>
                    </div>
                    <!-- Time Intervals -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <button type="button" class="btn btn-outline-primary btn-sm graph-filter-btn"
                                [ngClass]="selectedInterval==1 ? 'active-btn' : ''" (click)="selectTimeInterval('1')">
                                1 day
                            </button>
                            <button type="button" class="btn btn-outline-primary btn-sm graph-filter-btn"
                                [ngClass]="selectedInterval==7 ? 'active-btn' : ''" (click)="selectTimeInterval('7')">
                                1 week
                            </button>
                        </div>
                    </div>
                    
                    <div class="row mb-3">
                        <div class="col-12">
                            <button type="button" class="btn btn-outline-primary btn-sm graph-filter-btn"
                                [ngClass]="selectedInterval==21 ? 'active-btn' : ''" (click)="selectTimeInterval('28')">
                                1 month
                            </button>
                            <button type="button" class="btn btn-outline-primary btn-sm graph-filter-btn"
                                [ngClass]="selectedInterval==30 ? 'active-btn' : ''" (click)="selectTimeInterval('84')">
                                3 months
                            </button>
                        </div>
                    </div>

                    <!-- Site -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <label for="">Site</label>
                            <select class="form-select form-select-sm">
                                <option value="0">Select site</option>
                                <option value="1" selected>Site 1</option>
                                <option value="2">Site 2</option>
                                <option value="3">Site 3</option>
                            </select>
                        </div>
                    </div>
                    <!-- Wearer -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <label for="">Wearer</label>
                            <select class="form-select form-select-sm">
                                <option value="0">Select wearer</option>
                                <option value="1" selected>Wearer 1</option>
                                <option value="2">Wearer 2</option>
                                <option value="3">Wearer 3</option>
                            </select>
                        </div>
                    </div>
                    <!-- Graph Type -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" [checked]="showBar"
                                    (change)="switchGraph($event)" name="graphOptions" id="graph1" value="bar">
                                <label class="form-check-label" for="graph1">
                                    Bar
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" [checked]="showLine"
                                    (change)="switchGraph($event)" name="graphOptions" id="graph2" value="line">
                                <label class="form-check-label" for="graph2">
                                    Line
                                </label>
                            </div>
                            <div class="form-check"
                                *ngIf="selectedParameter == 'pressure'">
                                <input class="form-check-input" type="radio" [checked]="showHeatmap"
                                    (change)="switchGraph($event)" name="graphOptions" id="graph3" value="heatmap">
                                <label class="form-check-label" for="graph3">
                                    Heatmap
                                </label>
                            </div>
                            <!-- <div class="form-check form-switch" *ngIf="showHeatmap">
                                <input class="form-check-input" type="checkbox"
                                    (change)="toggleHMViews($event)" 
                                    [checked]="zoneEnabled" role="switch" id="zoneSwitch">
                                <label class="form-check-label" for="flexSwitchCheckDefault">Switch to zone view</label>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--Graph Wrapper-->
        <div class="col-md-10">
            <div class="" style="width: 100%; height: 20px;" *ngIf="showHeatmap">
                <div class="form-check form-switch" >
                    <input class="form-check-input" type="checkbox"
                        (change)="toggleHMViews($event)" 
                        [checked]="zoneEnabled" role="switch" id="zoneSwitch">
                    <label class="form-check-label" for="flexSwitchCheckDefault">Switch to zone view</label>
                </div>
            </div>
            <!-- {{selectedParameter}} -->
            <div class="graph-container" *ngIf="selectedParameter=='pressure'">
                <!-- <h4>Pressure</h4> -->
                <div class="graph">
                    <div id="pressure-bar" class="bar" *ngIf="selectedParameter=='pressure' && showBar">
                        <!-- Pressure Bar chart -->
                        <apx-chart
                            [series]="pressureBarChartOptions.series"
                            [chart]="pressureBarChartOptions.chart"
                            [colors]="pressureBarChartOptions.options.colors!"
                            [dataLabels]="pressureBarChartOptions.dataLabels"
                            [plotOptions]="pressureBarChartOptions.plotOptions"
                            [xaxis]="pressureBarChartOptions.xaxis">
                            [yaxis]="pressureBarChartOptions.yaxis"
                        </apx-chart>
                    </div>
                    <div id="pressure-line" class="line" *ngIf="selectedParameter=='pressure' && showLine">
                        <!-- Pressure Line chart -->
                        <apx-chart 
                            [series]="pressureLineChartOptions.series" 
                            [chart]="pressureLineChartOptions.chart"
                            [colors]="pressureLineChartOptions.options.colors!"
                            [xaxis]="pressureLineChartOptions.xaxis" 
                            [dataLabels]="pressureLineChartOptions.dataLabels"
                            [grid]="pressureLineChartOptions.grid" 
                            [stroke]="pressureLineChartOptions.stroke"
                            [title]="pressureLineChartOptions.title">
                        </apx-chart>
                    </div>
                    <div id="pressure-heatmap" class="heatmap" *ngIf="selectedParameter=='pressure' && showHeatmap">
                        <!-- Pressure Heatmap -->
                        <apx-chart
                            [series]="pressureHeatMapOptions.series"
                            
                            [chart]="pressureHeatMapOptions.chart"
                            [dataLabels]="pressureHeatMapOptions.dataLabels"
                            [plotOptions]="pressureHeatMapOptions.plotOptions"
                            
                            [title]="pressureHeatMapOptions.title">
                        </apx-chart>
                    </div>
                </div>
            </div>
            <div class="graph-container" *ngIf="selectedParameter=='temperature'">
                <!-- <h4>Temperatue</h4> -->
                <div class="graph">
                    <div id="temperature-bar" class="bar" *ngIf="selectedParameter=='temperature' && showBar">
                        <apx-chart
                            [series]="temperatureBarChartOptions.series"
                            [chart]="temperatureBarChartOptions.chart"
                            [dataLabels]="temperatureBarChartOptions.dataLabels"
                            [plotOptions]="temperatureBarChartOptions.plotOptions"
                            [colors]="temperatureBarChartOptions.options.colors!"
                            [yaxis]="temperatureBarChartOptions.yaxis"
                            [legend]="temperatureBarChartOptions.legend"
                            [fill]="temperatureBarChartOptions.fill"
                            [stroke]="temperatureBarChartOptions.stroke"
                            [tooltip]="temperatureBarChartOptions.tooltip"
                            [xaxis]="temperatureBarChartOptions.xaxis">
                        </apx-chart>
                    </div>
                    <div id="temperature-line" class="line" *ngIf="selectedParameter=='temperature' && showLine">
                        <!-- Temperatue Line chart -->
                        <apx-chart 
                            [series]="temperatureLineChartOptions.series" 
                            [chart]="temperatureLineChartOptions.chart"
                            [colors]="temperatureLineChartOptions.options.colors!"
                            [xaxis]="temperatureLineChartOptions.xaxis" 
                            [dataLabels]="temperatureLineChartOptions.dataLabels"
                            [grid]="temperatureLineChartOptions.grid" 
                            [stroke]="temperatureLineChartOptions.stroke"
                            [title]="temperatureLineChartOptions.title">
                        </apx-chart>
                    </div>
                    <div id="temperature-heatmap" class="heatmap"
                        *ngIf="selectedParameter=='temperature' && showHeatmap">
                        <!-- Temperatue Heatmap -->
                        <apx-chart
                            [series]="pressureHeatMapOptions.series"
                            [chart]="pressureHeatMapOptions.chart"
                            [dataLabels]="pressureHeatMapOptions.dataLabels"
                            [plotOptions]="pressureHeatMapOptions.plotOptions"
                            [title]="pressureHeatMapOptions.title">
                        </apx-chart>
                    </div>
                </div>
            </div>
            <div class="graph-container" *ngIf="selectedParameter=='humidity'">
                <!-- <h4>Humidity</h4> -->
                <div class="graph">
                    <div id="humidity-bar" *ngIf="selectedParameter=='humidity' && showBar">
                        <!-- Humidity Bar chart -->
                        <apx-chart
                            [series]="humidityBarChartOptions.series"
                            [chart]="humidityBarChartOptions.chart"
                            [colors]="humidityBarChartOptions.options.colors!"
                            [dataLabels]="humidityBarChartOptions.dataLabels"
                            [plotOptions]="humidityBarChartOptions.plotOptions"
                            [yaxis]="humidityBarChartOptions.yaxis"
                            [legend]="humidityBarChartOptions.legend"
                            [fill]="humidityBarChartOptions.fill"
                            [stroke]="humidityBarChartOptions.stroke"
                            [tooltip]="humidityBarChartOptions.tooltip"
                            [xaxis]="humidityBarChartOptions.xaxis">
                        </apx-chart>
                    </div>
                    <div id="humidity-line" *ngIf="selectedParameter=='humidity' && showLine">
                        <!-- Humidity Line chart -->
                        <apx-chart 
                            [series]="humidityLineChartOptions.series" 
                            [chart]="humidityLineChartOptions.chart"
                            [colors]="humidityLineChartOptions.options.colors!"
                            [xaxis]="humidityLineChartOptions.xaxis" 
                            [dataLabels]="humidityLineChartOptions.dataLabels"
                            [grid]="humidityLineChartOptions.grid" 
                            [stroke]="humidityLineChartOptions.stroke"
                            [title]="humidityLineChartOptions.title">
                        </apx-chart>
                    </div>
                </div>
            </div>
            <div class="graph-container" *ngIf="selectedParameter=='steps'">
                <!-- <h4>Step Count</h4> -->
                <div class="graph">
                    <div id="stepCount-bar" *ngIf="selectedParameter=='steps' && showBar">
                        <!-- Step count Bar chart -->
                        <apx-chart
                            [series]="stepCountBarChartOptions.series"
                            [chart]="stepCountBarChartOptions.chart"
                            [colors]="stepCountBarChartOptions.options.colors!"
                            [dataLabels]="stepCountBarChartOptions.dataLabels"
                            [plotOptions]="stepCountBarChartOptions.plotOptions"
                            [yaxis]="stepCountBarChartOptions.yaxis"
                            [legend]="stepCountBarChartOptions.legend"
                            [fill]="stepCountBarChartOptions.fill"
                            [stroke]="stepCountBarChartOptions.stroke"
                            [tooltip]="stepCountBarChartOptions.tooltip"
                            [xaxis]="stepCountBarChartOptions.xaxis">
                        </apx-chart>
                    </div>
                    <div id="stepCount-line" *ngIf="selectedParameter=='steps' && showLine">
                        <!-- Step count Line chart -->
                        <apx-chart
                            [series]="stepCountLineChartOptions.series"
                            [chart]="stepCountLineChartOptions.chart"
                            [colors]="stepCountLineChartOptions.options.colors!"
                            [xaxis]="stepCountLineChartOptions.xaxis"
                            [stroke]="stepCountLineChartOptions.stroke"
                            [tooltip]="stepCountLineChartOptions.tooltip"
                            [dataLabels]="stepCountLineChartOptions.dataLabels">
                        </apx-chart>
                    </div>
                </div>
            </div>
            <!-- <div class="graph">
                {{selectedParameter}}
                <div class="bar" *ngIf="showBar">
                    Bar chart
                </div>
                <div class="line" *ngIf="showLine">
                    Line chart
                </div>
                <div class="heatmap" *ngIf="showHeatmap">
                    Heatmap
                </div>
            </div> -->
        </div>
    </div>
</div>
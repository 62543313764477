import { Mobile } from './mobile.model'
import { WearerPrescription } from './wearer-prescription.model'

export class Wearer {
    id: number | null
    firstName: string
    lastName: string
    birthDate: string
    gender: string
    height: number | null
    weight: number | null
    shoeSize: number | string
    siteId: number | string
    mobiles: Array<Mobile>
    prescription: WearerPrescription | null 
    
    constructor() {
        this.id = null
        this.firstName = ''
        this.lastName = ''
        this.birthDate = ''
        this.gender = ''
        this.height = null
        this.weight = null
        this.shoeSize = ''
        this.siteId = ''
        this.mobiles = []
        this.prescription = null
    }   
}

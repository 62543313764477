<div class="pressure-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <div class="row">
        <!-- Pressure Filters -->
        <div class="col-md-3">
            <app-filters [filterType]="'activity'"></app-filters>
        </div>
        <!-- Table -->
        <div class="col-md-9">
            <div class="table-wrapper table-responsive">
                <table class="table table-striped table-bordered text-center">
                    <caption>List of Time Slices</caption>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Site</th>
                            <th>Wearer</th>
                            <th>Time Slice Start</th>
                            <th>Time Slice End</th>
                            <th>SU Active Time</th>
                            <th>Steps</th>
                            <th>Temp</th>
                            <th>Humidity</th>
                            <th>C1</th>
                            <th>C2</th>
                            <th>C3</th>
                            <th>C4</th>
                            <th>C5</th>
                            <th>Foot</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let timeSlice of timeSliceData">
                            <td>{{timeSlice.id}}</td>
                            <td>{{timeSlice.site.name}}</td>
                            <td>{{timeSlice.wearer.firstName}} {{timeSlice.wearer.lastName}}</td>
                            <td>{{timeSlice.timeSliceStart | date:'dd/MM/yyyy, hh:mm a'}}</td>
                            <td>{{timeSlice.timeSliceEnd | date:'dd/MM/yyyy, hh:mm a'}}</td>
                            <td>{{timeSlice.shinUnitOperationTime}}</td>
                            <td>{{timeSlice.stepCount}}</td>
                            <td>{{timeSlice.temperature}}</td>
                            <td>{{timeSlice.humidity}}</td>
                            <td>{{timeSlice.channel1Percentage}}</td>
                            <td>{{timeSlice.channel2Percentage}}</td>
                            <td>{{timeSlice.channel3Percentage}}</td>
                            <td>{{timeSlice.channel4Percentage}}</td>
                            <td>{{timeSlice.channel5Percentage}}</td>
                            <td>{{timeSlice.average}}</td>
                        </tr>
                    </tbody>
                </table>
                <!--Pagination widget-->
                <div class="paging">
                    <button class="login-button p-button-raised p-button-sm" 
                        pButton 
                        pRipple 
                        type="button" 
                        style="float: right;" 
                        label="Export to CSV" 
                        icon="pi pi-download"
                        (click)="exportToCSV()">
                    </button>
                    <pagination [totalItems]="totalItems" 
                        previousText="&lsaquo;" 
                        nextText="&rsaquo;" 
                        firstText="&laquo;" 
                        lastText="&raquo;"
                        [maxSize]="10"
                        [boundaryLinks]="true" 
                        [(ngModel)]="currentPage" 
                        (pageChanged)="handlePageChange($event)">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- CSV Modal -->
<div class="modal fade" bsModal #csvModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="mySmallModalLabel" aria-hidden="true" (onHidden)="onHidden()">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Export to CSV</h4>
                <button type="button" class="btn close pull-right" aria-label="Close" (click)="hideModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Select data to export</p>
                <div class="container">
                    <div class="row mb-3">
                        <div class="form-check">
                            <input class="form-check-input" 
                                type="radio"
                                name="exportCSV"
                                value="all"
                                [(ngModel)]="exportType"
                                (change)="onRadioChange()"
                                id="flexRadioDefault1">
                            <label class="form-check-label" for="flexRadioDefault1">
                                Export all data
                            </label>
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" 
                                type="radio" 
                                name="exportCSV"
                                value="selected" 
                                [(ngModel)]="exportType"
                                (change)="onRadioChange()"
                                id="flexRadioDefault2">
                            <label class="form-check-label" for="flexRadioDefault2">
                                Export data between selected dates
                            </label>
                          </div>
                          
                    </div>
                    <div class="row mb-3" *ngIf="showDatePickers">
                        <!-- Date pickers -->
                        <div class="col-6">
                            <label for="">Start Date</label>
                            <div class="col-6 input-group">
                                <input type="text" 
                                    class="form-control form-control-sm" 
                                    #dp="bsDatepicker" bsDatepicker
                                    [(ngModel)]="dateDto.startDateValue" 
                                    name="startDateValue"
                                    [bsConfig]="{ isAnimated: true , dateInputFormat: 'YYYY-MM-DD', maxDate: maxAllowedDate, showWeekNumbers: false }"
                                    [placeholder]="'YYYY-MM-DD'"  
                                    (ngModelChange)="filterByDate()"  
                                    (bsValueChange)="onValueChangeStart($event)">
                                    <button class="btn btn-sm btn-date text-white" type="button" (click)="dp.toggle()">
                                        <i class="bi bi-calendar-check-fill"></i>
                                    </button>
                            </div>
                        </div>
                        <div class="col-6">
                            <label for="">End Date</label>
                            <div class="col-12 input-group">
                                <input type="text" class="form-control form-control-sm" 
                                    #dp1="bsDatepicker" bsDatepicker
                                    [(ngModel)]="dateDto.endDateValue" 
                                    name="endDateValue"
                                    [bsConfig]="{ isAnimated: true ,  dateInputFormat: 'YYYY-MM-DD' }"
                                    [placeholder]="'YYYY-MM-DD'"
                                    (ngModelChange)="filterByDate()" 
                                    (bsValueChange)="onValueChangeEnd($event)">
                                    <button class="btn btn-sm btn-date text-white" type="button" (click)="dp1.toggle()">
                                        <i class="bi bi-calendar-check-fill"></i>
                                    </button>
                            </div>
                        </div>
                        
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" (click)="hideModal()"
                    class="login-button p-button-raised p-button-secondary p-button-sm">
                </button>
                <button pButton pRipple type="button" label="Export" icon="pi pi-check" (click)="exportToCSV()"
                    class="login-button p-button-raised p-button-sm">
                </button>
            </div>
        </div>
    </div>
</div>
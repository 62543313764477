<div class="patients-wrapper">
    <!--Button group-->
    <div class="btn-group mb-3">
        <button type="button" class="btn btn-outline-primary"
            [routerLink]="['manage-wearers']"
            routerLinkActive="active">
            Manage
        </button>
        <button *ngIf="!editRoute" type="button" class="btn btn-outline-primary"
            [routerLink]="['create-wearer']"
            routerLinkActive="active">
            Create
        </button>
        <button *ngIf="editRoute" type="button" class="btn btn-outline-primary" 
            [ngClass]="editRoute ? 'active' : ''">
            Edit
        </button>
    </div>
    
    <!--Router Outlet-->
    <div class="">
        <router-outlet></router-outlet>
    </div>    
</div>

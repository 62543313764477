import { Injectable } from '@angular/core'
import { Subject } from 'rxjs/internal/Subject'

@Injectable()
export class CreateSiteService {

  constructor() {}

  private newHospitalAddedSource = new Subject<any>
  hospitalAdded$ = this.newHospitalAddedSource.asObservable()
  
  broadcastNewPatient(newHospitalData: any) {
    this.newHospitalAddedSource.next(newHospitalData)
  }
}

import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as _ from 'lodash'
import { DeviceService } from 'src/app/iot/services/device.service';
import { DeviceConfig } from 'src/app/shared/models/device-config.model';
import { Device } from 'src/app/shared/models/device.model';
import { UtilService } from 'src/app/shared/services/util.service';
import { SiteService } from 'src/app/iot/services/site.service';


@Component({
  selector: 'app-unprovisioned-devices',
  templateUrl: './unprovisioned-devices.component.html',
  styleUrls: ['./unprovisioned-devices.component.scss']
})
export class UnprovisionedDevicesComponent implements OnInit {
  totalItems: number = 0
  currentPage: number = 1
  deviceModel: Device
  settings: Array<any> = []
  unprovisionedDevices: Array<any> = []
  isEditConfig: boolean = false
  editIndex: number = 100
  defaultDeviceConfig: DeviceConfig
  deviceConfigModel: any
  invalidConfig: boolean = false
  currentActiveKey: string = ''
  eventThreshold: number = 0
  showDropdown:boolean = false
  userAction: string = ''
  userId: number
  siteId: string = ''
  siteList: Array<any> = []
  userRole: string
  includeDecommissioned: boolean = false
  requestData: any = {}
  errorMessage: string = ''
  editDevice: boolean = false
  
  @ViewChild('updateDeviceModal', { static: false }) updateDeviceModal!: ModalDirective
  @ViewChild('decommissionModal', {static: true}) decommissionModal!: ModalDirective
  @ViewChildren('unprovisionedDropdown') unprovisionedDropdown!: QueryList<ElementRef>
  
  constructor(private deviceService: DeviceService,
    private siteService: SiteService, 
    private utilService: UtilService) {
    this.userId = parseInt(localStorage.getItem("user-id")!)
    this.userRole = localStorage.getItem('role')!

    this.defaultDeviceConfig = new DeviceConfig()
    this.deviceConfigModel = _.cloneDeep(this.defaultDeviceConfig)
    this.deviceModel = new Device(this.deviceConfigModel)
    
    this.initRequestData(this.requestData)
  }

  ngOnInit(): void {
    this.settings = this.deviceService.prepareDeviceConfigList(this.deviceConfigModel)
    if (this.userRole === 'ROLE_SITE_MANAGER') {
      this.getManagerSites()
    } else {
      this.getAllSites(this.userId)
    }
    this.getUnprovisionedDevices(this.requestData)
  }

  initRequestData(requestData: any) {
    requestData.isAllocated = true
    requestData.isAssigned = true
    requestData.isProvisioned = false
    requestData.pageIndex = 1
    requestData.pageSize = 10
    requestData.userId = this.userId
    requestData.type = 'unprovisioned'
  }

  getAllSites(userId: number) {
    this.siteService.getAllSites(userId).subscribe({
      next: (response: any) => {
        this.siteList = response.DATA
      },
      error: (error: any) => { }
    })
  }

  getManagerSites() {
    let managerId = localStorage.getItem('user-id')!
    this.siteService.getManagerSites(parseInt(managerId)).subscribe({
      next: (response: any) => {
        this.siteList = response.DATA
      },
      error: (error: any) => {

      }
    })
  }

  getUnprovisionedDevices(requestData: any) {
    this.deviceService.getAllDevices(requestData).subscribe({
      next: (response: any) => {
        this.unprovisionedDevices = response.DATA
        this.totalItems = response.total
      },
      error: (error: any) => {

      }
    })
  }

  getDecommissionedDevices(requestData: any) {
    this.deviceService.getDecommissionedDevices(requestData).subscribe({
      next: (response: any) => {
        this.unprovisionedDevices = response.DATA
        this.totalItems = response.total
      },
      error: (err: any) => {

      }
    })
  }

  getDeviceData(deviceId: number) {
    this.deviceService.getDeviceById(deviceId).subscribe({
      next: (response: any) => {
        this.settings = this.deviceService.prepareDeviceConfigList(response.DATA.deviceConfig)
        this.deviceModel = response.DATA
        this.deviceConfigModel = response.DATA.deviceConfig
        this.deviceModel.deviceConfig = this.deviceConfigModel
      },
      error: (err: any) => {
      }
    })
  }

  filterBySite(event: any){
    this.siteId = event.target.value;
    if (this.currentPage != 1) {
      this.currentPage = 1
      this.requestData.pageIndex = 1
    }
    if (this.includeDecommissioned) {
      if (event.target.value == '0') {
        this.getDecommissionedDevices(this.requestData)
      } else {
        this.requestData.siteId = event.target.value
        this.getDecommissionedDevices(this.requestData)
      }
    } else {
      if (event.target.value == '0') {
        delete this.requestData.siteId
        this.getUnprovisionedDevices(this.requestData)
      } else {
        this.requestData.siteId = event.target.value
        this.getUnprovisionedDevices(this.requestData)
      }
    }

  }

  includeDecommissionedDevices(value: boolean) {
    if (this.currentPage != 1) {
      this.currentPage = 1
      this.requestData.pageIndex = 1
    }
    if (value === true) {
      this.includeDecommissioned = true
      if (this.siteId && this.siteId != '0') {
        this.getDecommissionedDevices(this.requestData)
      } else {
        this.getDecommissionedDevices(this.requestData)
      }
    } else {
      this.includeDecommissioned = false
      if (this.siteId && this.siteId != '0') {
        this.requestData.siteId = this.siteId
        this.getUnprovisionedDevices(this.requestData)
      } else {
        if (this.requestData.siteId) {
          delete this.requestData.siteId
        }
        this.getUnprovisionedDevices(this.requestData)
      }
    }
  }

  selectAction(event: any, data: any, index: any) {
    this.userAction = event.target.value
    this.getDeviceData(data.id)
    this.showModal()
  }

  showModal() {
    if (this.userAction === 'edit') {
      this.updateDeviceModal.show()
    } else if (this.userAction === 'decommission') {
      this.decommissionModal.show()
    }
  }

  hideModal() {}

  onHidden() {
    this.utilService.resetDropdowns(this.unprovisionedDropdown)
    this.errorMessage = ''
    this.invalidConfig = false
    this.isEditConfig = false
    this.editIndex = 100
    this.currentActiveKey = ''
    this.deviceConfigModel = _.cloneDeep(this.defaultDeviceConfig)
  }

  editConfig(key: any, index: any) {
    this.editIndex = index
    this.invalidConfig = false
    this.errorMessage = ''
    for (let i = 0; i < this.settings.length; i++) {
      if (key == this.settings[i].configKey) {
        this.isEditConfig = true
        this.currentActiveKey = key
        if (this.currentActiveKey == 'sdCardWriteBoolean' || this.currentActiveKey == 'shinUnitAttachedBoolean' || this.currentActiveKey == 'demoMode') {
          this.showDropdown = true
        } else {
          this.showDropdown = false
        }
        // this.currentMin = this.settingList[i].min
        // this.currentMax = this.settingList[i].max
      }
    }
  }

  setConfigValue(form: NgForm) {
    this.isEditConfig = false
    this.editIndex = 100
    for (let i = 0; i < this.settings.length; i++) {
      if (this.settings[i].configKey == this.currentActiveKey) {
        
        this.settings[i].value = this.deviceConfigModel[this.currentActiveKey]
        this.deviceModel.deviceConfig = this.deviceConfigModel
        if (this.currentActiveKey === this.settings[i].configKey && (this.settings[i].value < this.settings[i].min || this.settings[i].value > this.settings[i].max)) {
          this.invalidConfig = true
          let errorCode = (this.editDevice) ? '(63a2.1)' : '(63a3.1)'
          this.errorMessage = `${errorCode} ${this.settings[i].value} outside valid range`
        } else {
          this.invalidConfig = false
          this.errorMessage = ''
        }
      }
    }
  }

  updateDevice(deviceForm: NgForm) {
    let obj = this.deviceConfigModel
    delete obj.isDeleted
    this.errorMessage = this.validateConfigSettings(this.settings, this.deviceConfigModel)
    if (this.errorMessage == '') {
      this.deviceService.updateDevice(this.deviceModel.id!, this.deviceModel).subscribe({
        next: (response: any) => {
          this.updateDeviceModal.hide()
        },
        error: (error: any) => {
  
        }
      })
    }
  }

  validateConfigSettings(settingList: Array<any>, configModel: any): string {
    let errorMessage = ''
    let errorCode = (this.editDevice) ? '(63a2.1)' : '(63a3.1)'
    let arr = Object.keys(configModel).filter(key => key !== 'id')
    .map((key: any) => ({ key: key, value: configModel[key] }))
    for (let index = 0; index < arr.length; index++) {
      settingList[index]['currentValue'] = arr[index]['value']
    }
    
    for (let i = 0; i < settingList.length; i++) {
      let current = settingList[i]['currentValue']
      let min = settingList[i]['min']
      let max = settingList[i]['max']
      if (current < min || current > max) {
        
        this.invalidConfig = true
        errorMessage = `${errorCode} ${settingList[i].currentValue} outside valid range`
        
        return errorMessage
        break
      }
    }
    return ''
  }

  decommissionDevice() {
    this.deviceModel.isActive = false
    const deviceId: number = this.deviceModel.id!
    this.deviceService.updateDevice(deviceId, this.deviceModel).subscribe({
      next: (response: any) => {
        // this.unallocatedDevices = this.unallocatedDevices.filter((item: any) => {
        //   if (this.deviceModel.id != item.id) {
        //     return item
        //   }
        // })
        this.getUnprovisionedDevices(this.requestData)
        this.decommissionModal.hide()
        this.deviceModel = new Device(this.defaultDeviceConfig)
      },
      error: (err: any) => {

      }
    })
  }

  pageChanged(event: any) {
    this.requestData.pageIndex = event.page
    if(!this.includeDecommissioned) {
      this.getUnprovisionedDevices(this.requestData)
    } else {
      if (this.siteId && this.siteId !== '0') {
        this.getDecommissionedDevices(this.requestData)
      } else {
        this.getDecommissionedDevices(this.requestData)
      }
    }    
  }
}

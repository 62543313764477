import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
import { NgForm } from '@angular/forms'
import * as _ from 'lodash'
import { CreatePatientService } from 'src/app/iot/services/create-patient.service'
import { WearerPrescription } from 'src/app/shared/models/wearer-prescription.model';
import { Wearer } from 'src/app/shared/models/wearer.model';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/iot/services/loader.service';
import { LOADER_CONFIG } from 'src/app/shared/global-constants';
@Component({
  selector: 'app-wearer-prescription',
  templateUrl: './wearer-prescription.component.html',
  styleUrls: ['./wearer-prescription.component.scss']
})
export class WearerPrescriptionComponent implements OnInit, OnDestroy {
  loading: boolean = false
  btnStatus: string = 'save'
  isEditMode: boolean = false
  disableSave: boolean = false
  isSaveAlreadyPressed: boolean = false
  pipe = new DatePipe('en-US')
  userRole: string = ''
  userId: string = ''
  wearerId: string = ''
  wearerPrescription!: WearerPrescription
  wearerData!: Wearer
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG

  @ViewChild('prescriptionForm', {static: false}) prescriptionForm!: NgForm

  constructor(private createPatientService: CreatePatientService,
    private loaderService: LoaderService,
    private activatedRoute: ActivatedRoute, 
    private router: Router) {
      this.userId = localStorage.getItem('user-id')!
      this.userRole = localStorage.getItem('role')!
      // this.wearerPrescription = new WearerPrescription(1)
      this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
        this.loading = isLoading
      })
  }

  ngOnInit(): void {
    this.wearerId = this.activatedRoute.snapshot.queryParamMap.get("id")!
    if (this.wearerId) {
      this.isEditMode = true
      let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      this.wearerPrescription = new WearerPrescription(parseInt(this.wearerId))
      if (wearerData.prescriptionId) {
        this.getWearerPrescription(parseInt(this.wearerId))
      } else {
        
      }
    } else {
      let wearerId = localStorage.getItem('wearerId')!
      let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      this.wearerPrescription = new WearerPrescription(parseInt(wearerId))
      if (wearerData.prescriptionId) {
        this.getWearerPrescription(parseInt(wearerId))

      }
    }
  }

  getWearerPrescription(wearerId: number) {
    this.createPatientService.getWearerPrescriptionById(wearerId).subscribe({
      next: (response: any) => {
        this.wearerPrescription.id = response.DATA.id
        this.wearerPrescription.targetPeriodInMinutes = response.DATA.targetPeriodInMinutes
        this.wearerPrescription.targetStepsPerDay = response.DATA.targetStepsPerDay
        this.wearerPrescription.maximumHoursOfUsage = response.DATA.maximumHoursOfUsage
        this.wearerPrescription.verbosityLevel = response.DATA.verbosityLevel 
        this.wearerPrescription.language = response.DATA.language 
      }, 
      error: (err: any) => {
      }
    })
  }

  saveWearerPrescription(form: NgForm) {
    if (this.prescriptionForm.invalid) {
      return
    }
    if (this.wearerId) {
      this.updatePrescription(parseInt(this.wearerId))
    } else {
      let weareId = localStorage.getItem('wearerId')!
      this.createPrescription(parseInt(weareId))
    }
  }

  createPrescription(weareId: number) {
    // delete this.wearerPrescription.id
    this.updatePrescription(weareId)
    
  }

  updatePrescription(wearerId: number) {
    if (this.disableSave) {
      this.routeToNextTab(this.btnStatus)
    } else {
      this.wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      this.wearerData.prescription = this.wearerPrescription
      let wearer_id = (this.wearerId) ? this.wearerId : localStorage.getItem('wearerId')  
      this.createPatientService.updateWearer(wearerId, this.wearerData).subscribe({
        next: (response: any) => {
          this.disableSave = true
          // localStorage.setItem('wearerId', this.wearerId)
          localStorage.setItem('wearerData', JSON.stringify(response.DATA))
          this.routeToNextTab(this.btnStatus)
        },
        error: (err: any) => {

        }
      })
    }
  }

  prescriptionModelChanged() {
    this.disableSave = false
  }

  routeToNextTab(btnStatus: string) {
    const addedFormStatus: any = {};
    addedFormStatus.activeTabName = "wearer-prescription";
    addedFormStatus.buttonStatus = btnStatus;
    this.createPatientService.broadcastNewPatient(addedFormStatus);
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()
  }
}

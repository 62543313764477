import { Component } from '@angular/core'
import { UserService } from '../../services/user.service'
import { Router } from '@angular/router'
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  
  userName: string
  userEmail: string
  userRole: string
  constructor(
    private router: Router,
    private userService: UserService) {
      this.userName = localStorage.getItem('name')!
      this.userEmail = localStorage.getItem('email')!
      this.userRole = localStorage.getItem('user-role')!
  }

  signoutUser() {
    this.userService.setUserLoggedInStatus(false)
    localStorage.clear()
    this.router.navigate(['/'])

  }
}

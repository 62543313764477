import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment.development";

@Injectable({ providedIn: 'root' })
export class AlertRuleApiBuilderService {
    public serverUrl: string = `${environment.host}/api/v1`

    constructor() {

    }

    createAlertRuleProfileUrl = (type: string) => `${this.serverUrl}/alertProfile/add/${type}`

    getAllPreConfiguredAlertProfilesUrl = `${this.serverUrl}/alertProfile/getAlertRuleProfiles`

    getAlertRuleProfilesUrl = (type: string, pageIndex: number, pageSize: number) => `${this.serverUrl}/alertProfile/getAlertRuleProfile/${type}/${pageIndex}/${pageSize}`

    getAlertRuleProfileByIdUrl = (id: number) => `${this.serverUrl}/alertProfile/getAlertRuleProfileById/${id}`

    editAlertRuleProfileUrl = (alertProfileId: number) => `${this.serverUrl}/alertProfile/updateAlertRulePreConfiguredProfile/${alertProfileId}`

    deleteAlertRuleProfileUrl = (alertProfileId: number) => `${this.serverUrl}/alertProfile/delete/${alertProfileId}`

}
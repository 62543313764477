<div class="allocated-wrapper">
    <div class="d-flex flex-row justify-content-start">
        <div class="col-md-2 col-xs-4 px-0 pb-2">
            <select class="form-control form-select form-select-sm custom-form-control" (change)="filterBySite($event)">
                <option value="0" selected> Select Site</option>
                <!-- <option *ngFor="let site of siteList" value="{{ site.id }}">
                    {{site.name}}
                </option> -->
            </select>
        </div>

        <div class="col pt-1 mx-5">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    Include Decommissioned
                </label>
            </div>
            <!-- <label class="container-checkbox align-middle">Include Decomissioned
                <input type="checkbox" name="unAssigned"/>
                <span class="checkmark"></span>
            </label> -->
        </div>
    </div>
    <!--Allocated Device List-->
    <div class="mt-3">
        <div>
            <table class="table table-striped table-bordered text-center">
                <caption>List of allocated devices</caption>
                <thead>
                    <tr>
                        <th>Device Id</th>
                        <th>Site</th>
                        <th>Provisioned Date/Time</th>
                        <th>Last Synch Date/Time</th>
                        <th>Config Upto Date</th>
                        <th>Assigned Wearer</th>
                        <th>Status</th>
                        <th class="">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>HD1</td>
                        <td>Site 1</td>
                        <td>NA</td>
                        <td>NA</td>
                        <td>YES</td>
                        <td>Will Atkinson</td>
                        <td>Active</td>
                        <td>
                            <select class="form-select form-select-sm">
                                <option selected value="">Select an action</option>
                                <option value="edit">Edit</option>
                                <option value="decommission">Decommission</option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="paging mt-3">
        <pagination 
            [totalItems]="totalItems" 
            previousText="&lsaquo;" 
            nextText="&rsaquo;" 
            firstText="&laquo;"
            lastText="&raquo;" 
            [maxSize]="10" 
            [itemsPerPage]="10" 
            [boundaryLinks]="showBoundaryLinks"
            [(ngModel)]="currentPage" 
            (pageChanged)="pageChanged($event)">
        </pagination>
    </div>
</div>
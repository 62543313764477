<div class="activity-wrapper">
    <!--Button group-->
    <div class="btn-group mb-3">
        <button type="button" class="btn btn-outline-primary"
            [routerLink]="['pressure']"
            routerLinkActive="active">
            Timeslices
        </button>
        <button type="button" class="btn btn-outline-primary"
            [routerLink]="['alerts']"
            routerLinkActive="active">
            Alerts
        </button>
        <!-- <button type="button" class="btn btn-outline-primary"
            [routerLink]="['graph']"
            routerLinkActive="active">
            Graph
        </button> -->
    </div>
    
    <!--Router Outlet-->
    <div class="">
        <router-outlet></router-outlet>
    </div>
</div>
import { NgModule } from '@angular/core'
import { CommonModule, DatePipe } from '@angular/common'

import { FormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/shared/shared.module'

import { ModalModule } from 'ngx-bootstrap/modal'
import { ToastrModule } from 'ngx-toastr'
import { NgxLoadingModule } from 'ngx-loading'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { PaginationModule } from 'ngx-bootstrap/pagination'

import { ButtonModule } from 'primeng/button'
import { RippleModule } from 'primeng/ripple'
import { InputTextModule } from 'primeng/inputtext'
import { DynamicDialogModule } from 'primeng/dynamicdialog'

import { PatientsRoutingModule } from './patients-routing.module'
import { PatientsComponent } from './patients.component'
import { PatientHeaderComponent } from './components/patient-header/patient-header.component'
import { ManagePatientsComponent } from './components/manage-patients/manage-patients.component'
import { CreatePatientComponent } from './components/create-patient/create-patient.component'
import { PatientActivityComponent } from './components/patient-activity/patient-activity.component'
import { PatientDetailsComponent } from './components/patient-details/patient-details.component'
import { PatientAlertProfileComponent } from './components/patient-alert-profile/patient-alert-profile.component';
import { MessageRoutingComponent } from './components/message-routing/message-routing.component';
import { AssignDeviceComponent } from './components/assign-device/assign-device.component';

import { CreatePatientService } from '../../services/create-patient.service';
import { ManangePatientsService } from '../../services/manange-patients.service';


import { WearerActivityComponent } from './components/wearer-activity/wearer-activity.component';
import { WearerApiBuilderService } from 'src/app/shared/api-builders/wearer-api-builder.service';
import { WearerService } from '../../services/wearer.service';
import { WearerPrescriptionComponent } from './components/wearer-prescription/wearer-prescription.component';
import { WearerMobileDevicesComponent } from './components/wearer-mobile-devices/wearer-mobile-devices.component';
import { ActivityApiBuilderService } from 'src/app/shared/api-builders/activity-api-builder.service';
import { ActivityService } from '../../services/activity.service';


@NgModule({
  declarations: [
    PatientsComponent,
    PatientHeaderComponent,
    ManagePatientsComponent,
    CreatePatientComponent,
    PatientActivityComponent,
    PatientDetailsComponent,
    PatientAlertProfileComponent,
    MessageRoutingComponent,
    AssignDeviceComponent,
    WearerActivityComponent,
    WearerPrescriptionComponent,
    WearerMobileDevicesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ButtonModule,
    RippleModule,
    InputTextModule,
    DynamicDialogModule,
    PaginationModule.forRoot(),
    ToastrModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    PatientsRoutingModule
  ],
  providers: [
    DatePipe,
    CreatePatientService,
    WearerApiBuilderService, 
    WearerService,
    ManangePatientsService,
    ActivityApiBuilderService,
    ActivityService
  ]
})
export class PatientsModule { }

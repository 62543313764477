<div class="graph-wrapper">
    <div class="row">
        <div class="col-md-2">
            <div class="filter-wrapper">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h6>Filters</h6>
                        </div>
                    </div>
                    <!-- Parameters -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <label for="">Parameters</label>
                            <select class="form-select form-select-sm" (change)="changeParameter($event)">
                                <option value="pressure">Pressure</option>
                                <!-- <option value="temperature">Temperatue</option>
                                <option value="humidity">Humidity</option>
                                <option value="steps">Step Count</option> -->
                            </select>
                        </div>
                    </div>
                    <!-- Time Intervals -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <button type="button" class="btn btn-outline-primary btn-sm graph-filter-btn"
                                [ngClass]="selectedInterval==1 ? 'active-btn' : ''" (click)="selectTimeInterval('1')">
                                1 day
                            </button>
                            <button type="button" class="btn btn-outline-primary btn-sm graph-filter-btn"
                                [ngClass]="selectedInterval==7 ? 'active-btn' : ''" (click)="selectTimeInterval('7')">
                                1 week
                            </button>
                        </div>
                    </div>
                    
                    <!-- <div class="row mb-3">
                        <div class="col-12">
                            <button type="button" class="btn btn-outline-primary btn-sm graph-filter-btn"
                                [ngClass]="selectedInterval==21 ? 'active-btn' : ''" (click)="selectTimeInterval('28')">
                                1 month
                            </button>
                            <button type="button" class="btn btn-outline-primary btn-sm graph-filter-btn"
                                [ngClass]="selectedInterval==30 ? 'active-btn' : ''" (click)="selectTimeInterval('84')">
                                3 months
                            </button>
                        </div>
                    </div> -->

                    <!-- Site -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <label for="">Site</label>
                            <select class="form-select form-select-sm">
                                <option value="0" selected>Select site</option>
                            </select>
                        </div>
                    </div>
                    <!-- Wearer -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <label for="">Wearer</label>
                            <select class="form-select form-select-sm">
                                <option value="0" selected>Select wearer</option>
                            </select>
                        </div>
                    </div>
                    <!-- Graph Type -->
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" [checked]="showBar"
                                    (change)="switchGraph($event)" name="graphOptions" id="graph1" value="bar">
                                <label class="form-check-label" for="graph1">
                                    Bar
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" [checked]="showLine"
                                    (change)="switchGraph($event)" name="graphOptions" id="graph2" value="line">
                                <label class="form-check-label" for="graph2">
                                    Line
                                </label>
                            </div>
                            <div class="form-check"
                                *ngIf="selectedParameter == 'pressure' || selectedParameter=='temperature'">
                                <input class="form-check-input" type="radio" [checked]="showHeatmap"
                                    (change)="switchGraph($event)" name="graphOptions" id="graph3" value="heatmap">
                                <label class="form-check-label" for="graph3">
                                    Heatmap
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-10">
            <div class="row mb-3">
                <div class="col-6">
                    <div id="pie1" style="width: 100%; height: 250px;">
                        
                    </div>
                </div>
                <div class="col-6">
                    <div id="pie2" style="width: 100%; height: 250px;"></div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6">
                    <div id="pie3" style="width: 100%; height: 250px;"></div>
                </div>
                <div class="col-6">
                    <div id="pie4" style="width: 100%; height: 250px;"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <div id="pie5" style="width: 100%; height: 250px;"></div>
                </div>
                <div class="col-6"></div>
            </div>
        </div>
    </div>
</div>

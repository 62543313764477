<div class="patient-header-wrapper">
    <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
    <!-- Create Mode -->
    <ul class="progress2 mb-0" *ngIf="!isEditMode">
        <li [ngClass]="patientPassed ? 'line-pass' : ''">
            <button (click)="currentTab('patient-details')" 
                [ngClass]="patientPassed ? 'pass' : ''" class="activated">
                <div>
                    <i class="bi bi-person-fill-up"></i>
                </div>
                <p>Wearer Details</p>
            </button>
        </li>

        <li [ngClass]="prescriptionPassed ? 'line-pass' : ''">
            <button (click)="currentTab('wearer-prescription')" 
                [ngClass]="prescriptionPassed ? 'pass' : ''"
                [ngClass]="!prescriptionStatus ? 'disabled' : 'activated'">
                <div>
                    <i class="bi bi-journal-text"></i>
                </div>
                <p>Wearer Prescription</p>
            </button>
        </li>

        <li class="" [ngClass]="alertProfilePassed ? 'line-pass' : ''">
            <button (click)="currentTab('patient-alert-profile')" 
                [ngClass]="alertProfilePassed ? 'pass' : ''" 
                [ngClass]="!alertProfileStatus ? 'disabled' : 'activated'">
                <div>
                    <i class="bi bi-exclamation-triangle-fill"></i>
                </div>
                <p>Alert Profile</p>
            </button>
        </li>

        <li class="" [ngClass]="messageRoutingPassed ? 'line-pass' : ''">
            <button (click)="currentTab('message-routing')" 
                [ngClass]="messageRoutingPassed ? 'pass' : ''" 
                [ngClass]="!messageRoutingStatus ? 'disabled' : 'activated'">
                <div>
                    <i class="bi bi-chat-left-text-fill"></i>
                </div>
                <p>Message Routing</p>
            </button>
        </li>
        <li class="" [ngClass]="mobileDevicesPassed ? 'line-pass' : ''">
            <button (click)="currentTab('mobile-devices')" 
                [ngClass]="mobileDevicesPassed ? 'pass' : ''" 
                [ngClass]="!mobileDevicesStatus ? 'disabled' : 'activated'">
                <div>
                    <i class="bi bi-phone-vibrate"></i>
                </div>
                <p>Mobile Devices</p>
            </button>
        </li>
        
        <li class="" [ngClass]="devicePassed ? 'line-pass' : ''">
            <button (click)="currentTab('assign-device')" 
                [ngClass]="!deviceStatus ? 'disabled' : 'activated'">
                <div>
                    <i class="bi bi-cpu-fill"></i>
                </div>
                <p>Assign Device</p>
            </button>
        </li>
    </ul>

    <!-- Edit Mode -->
    <ul class="progress2 mb-0" *ngIf="isEditMode">
        <li class="line-pass">
            <button (click)="currentTab('patient-details')" 
                [ngClass]="patientPassed ? 'pass' : ''" class="activated">
                <div>
                    <i class="bi bi-person-fill-up"></i>
                </div>
                <p>Wearer Details</p>
            </button>
        </li>

        <li class="" [ngClass]="prescriptionPassed ? 'line-pass' : ''">
            <button class="activated" (click)="currentTab('wearer-prescription')" 
                [ngClass]="prescriptionPassed ? 'pass' : ''">
                <div [ngClass]="(!prescriptionPresent) ? 'red' : ''">
                    <i class="bi bi-journal-text"></i>
                </div>
                <p>Wearer Prescription</p>
            </button>
        </li>

        <li class="" [ngClass]="alertProfilePassed ? 'line-pass' : ''">
            <button class="activated" (click)="currentTab('patient-alert-profile')" 
                [ngClass]="alertProfilePassed ? 'pass' : ''">
                <div [ngClass]="(!alertProfilePresent) ? 'red' : ''">
                    <i class="bi bi-exclamation-triangle-fill"></i>
                </div>
                <p>Alert Profile</p>
            </button>
        </li>

        <li class="" [ngClass]="messageRoutingPassed ? 'line-pass' : ''">
            <button class="activated" (click)="currentTab('message-routing')" 
                [ngClass]="messageRoutingPassed ? 'pass' : ''">
                <div [ngClass]="(!messageRoutingPresent) ? 'red' : ''">
                    <i class="bi bi-chat-text-fill"></i>
                </div>
                <p>Message Routing</p>
            </button>
        </li>
        <li class="" [ngClass]="mobileDevicesPassed ? 'line-pass' : ''">
            <button class="activated" (click)="currentTab('mobile-devices')" 
                [ngClass]="mobileDevicesPassed ? 'pass' : ''">
                <div [ngClass]="(!mobileDevicesPresent) ? 'red' : ''">
                    <i class="bi bi-phone-vibrate"></i>
                </div>
                <p>Mobile Devices</p>
            </button>
        </li>
        <li class="line-pass">
            <button [ngClass]="isAssignable ? 'activated' : 'disabled'" [ngClass]="devicePassed ? 'pass':''" class="" (click)="currentTab('assign-device')">
                <div [ngClass]="(!devicePresent) ? 'red' : ''">
                    <i class="bi bi-cpu-fill"></i>
                </div>
                <p>Assign Device</p>
            </button>
        </li>
    </ul>
</div>

<div class="children-wrapper">
    <div class="title mb-2 p-2">
        <h5>{{title}}</h5>
    </div>
    <div class="form-container py-3">
        <app-patient-details *ngIf="currentActiveTab=='patient-details'"></app-patient-details>
        <app-wearer-prescription *ngIf="currentActiveTab=='wearer-prescription'"></app-wearer-prescription>
        <app-patient-alert-profile *ngIf="currentActiveTab=='patient-alert-profile'"></app-patient-alert-profile>
        <app-message-routing *ngIf="currentActiveTab=='message-routing'"></app-message-routing>
        <app-wearer-mobile-devices *ngIf="currentActiveTab=='mobile-devices'"></app-wearer-mobile-devices>
        <app-assign-device *ngIf="currentActiveTab=='assign-device'"></app-assign-device>
    </div>
</div>

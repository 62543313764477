<div class="mobiles-wrapper px-md-5">
    <div class="table-wrapper">
        <table class="table table-striped table-bordered text-center">
            <caption>List of mobile devices</caption>
            <thead>
                <tr>
                    <th>Android Device Id</th>
                    <th>Manufacturer</th>
                    <th>Model</th>
                    <th>OS</th>
                    <th>OS Version</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>54edft67</td>
                    <td>Samsung</td>
                    <td>A03s</td>
                    <td>Android</td>
                    <td>11</td>
                    <td>
                        <select name="" id="" class="form-select form-select-sm">
                            <option value="">Select action</option>
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-5 btn-wrapper">
            <!-- <button type="button" class="btn btn-primary mx-3" (click)="showMobileModal()">Add New Mobile</button> -->
            <!-- <button type="button" class="btn btn-primary mx-3" (click)="addMobilesToSite()">Save</button> -->
            <button pButton pRipple 
              type="button"
              (click)="showMobileModal()"  
              icon="pi pi-mobile" 
              label="Add New Mobile" 
              class="p-button-raised p-button-sm mx-2">
            </button>
            <button pButton pRipple 
              type="button" 
              (click)="addMobilesToSite()"
              icon="pi pi-save" 
              label="Save" 
              class="p-button-raised p-button-sm mx-2">
            </button>
          </div>
    </div>
</div>

<!--Add / Update Mobile Device Modal-->
<div *ngIf="isModalShown" [config]="{ show: true, keyboard: true, backdrop: 'static' }" (onHidden)="onHidden()"
  bsModal #mobileModal="bs-modal" class="modal fade"
  tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-auto-name" class="modal-title pull-left">
          {{!editModileData ? 'Add New Mobile Device' : 'Edit Mobile'}}
        </h4>
        <button
          type="button"
          class="btn close"
          aria-label="Close"
          (click)="hideModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form #mobileForm="ngForm" (ngSubmit)="addMobile(mobileForm)">  
        <div class="modal-body" style="padding-left: 55px; padding-right: 55px;">
        <!--Modal body-->
            <div class="row mb-3">
                <label for="imei" class="col-sm-3 col-form-label text-end">Android Device Id</label>
                <div class="col-sm-6">
                    <input type="text" class="form-control" minlength="15" maxlength="17" required 
                      name="imei"
                      [disabled]="editModileData"
                      [(ngModel)]="mobileDto.androidDeviceId"
                      #imei="ngModel">
                    <div class="error-block p-2" *ngIf="imei.invalid && (imei.dirty || imei.touched)">
                      <span *ngIf="imei.errors?.['required']">Please enter android device id number</span>
                    </div>
                    <div class="error-block p-2" *ngIf="imei.invalid && (imei.dirty || imei.touched)">
                      <span *ngIf="imei.errors?.['minlength']">Android device id must be 15 digits long</span>
                    </div>
                    <div class="error-block p-2" *ngIf="imei.invalid && (imei.dirty || imei.touched)">
                      <span *ngIf="imei.errors?.['maxlength']">Android device id cann't be longer than 17 digits</span>
                    </div>
                </div>
            </div>
            
            <div class="row mb-3">
                <label for="manufacturer" class="col-sm-3 col-form-label text-end">Manufacturer</label>
                <div class="col-sm-4">
                    <select class="form-control form-select" required
                      name="manufacturer"
                      [(ngModel)]="mobileDto.manufacturer"
                      #manufacturer="ngModel">
                        <option value="">Select manufacturer</option>
                        <option value="samsung">Samsung</option>
                    </select>
                    <div class="error-block p-2" *ngIf="manufacturer.invalid && (manufacturer.dirty || manufacturer.touched)">
                      <span *ngIf="manufacturer.errors?.['required']">Please select manufacturer</span>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <label for="model" class="col-sm-3 col-form-label text-end">Model</label>
                <div class="col-sm-4">
                    <input type="text" class="form-control" required
                      name="model"
                      [(ngModel)]="mobileDto.model"
                      #model="ngModel">
                      <div class="error-block p-2" *ngIf="model.invalid && (model.dirty || model.touched)">
                        <span *ngIf="model.errors?.['required']">Please enter model name</span>
                      </div>
                </div>
            </div>
            
            <div class="row mb-3">
              <label for="os" class="col-sm-3 col-form-label text-end">OS</label>
              <div class="col-sm-4">
                <select class="form-control form-select" required
                  name="osName"
                  [(ngModel)]="mobileDto.osName"
                  #osName="ngModel">
                  <option value="">Select os</option>
                  <option value="android">Android</option>
                </select>
                <div class="error-block p-2" *ngIf="osName.invalid && (osName.dirty || osName.touched)">
                  <span *ngIf="osName.errors?.['required']">Please select mobile os</span>
                </div>
              </div>
            </div>
            
            <div class="row mb-3">
              <label for="version" class="col-sm-3 col-form-label text-end">OS Version</label>
              <div class="col-sm-4">
                <select class="form-control form-select" required
                  name="osVersion"
                  [(ngModel)]="mobileDto.osVersion"
                  #osVersion="ngModel">
                  <option value="">Select os version</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
                <div class="error-block p-2" *ngIf="osVersion.invalid && (osVersion.dirty || osVersion.touched)">
                  <span *ngIf="osVersion.errors?.['required']">Please select OS version</span>
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer py-1">
        <div class="form-group m-0">
          <div class="col-md-12 text-right">
            <button class="btn btn-save text-white m-1" [disabled]="!mobileForm.valid" (click)="updateMobile()">
              Save
            </button>
            <button type="button" class="btn btn-cancel text-white" (click)="hideModal()">
              Close
            </button>
          </div>
        </div>
        </div>
      </form>
    </div>
  </div>
</div>
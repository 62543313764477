import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment.development'
import { alertTypes } from '../global-constants'
@Injectable({providedIn: 'root'})
export class ActivityApiBuilderService {
    public serverUrl: string = `${environment.host}/api/v1`
    
    constructor() {}

    getWearerActivityUrl = (wearerId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/time-slice/getTimeSlice/${wearerId}/${pageIndex}/${pageSize}`

    getTimeSliceDataUrl = (userId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/time-slice/getAllTimeSlices/${userId}/${pageIndex}/${pageSize}`

    filterTimeSliceDataUrl = (filterData: any) => {
        const {managerId, pageIndex, pageSize, wearerId, siteId, startDate, endDate, timeZoneName} = filterData
        let filterTimeSliceDataUrl = `${this.serverUrl}/time-slice/filter-timeSlice/${managerId}/${pageIndex}/${pageSize}?`

        const queryParams = []
        if (siteId && siteId !== '0') {
            queryParams.push(`siteId=${siteId}`)
        }

        if (wearerId && wearerId !== '0') {
            queryParams.push(`wearerId=${wearerId}`)
        }

        if (startDate && endDate) {
            queryParams.push(`dateFrom=${startDate}&dateTo=${endDate}`)
        }
        if (timeZoneName) {
            queryParams.push(`timeZone=${timeZoneName}`)
        }

        filterTimeSliceDataUrl += queryParams.join('&')
     
        return filterTimeSliceDataUrl
    }

    getTimeSliceDataForCSVUrl = (requestData: any) => {
        const {userId, siteId, wearerId, startDate, endDate} = requestData
        let timeSliceDataCSVUrl = `${this.serverUrl}/time-slice/filter-timeSlice-non-paginated/${userId}?`
        const queryParams = []

        if (siteId && siteId !== '0') {
            queryParams.push(`siteId=${siteId}`)
        }

        if (wearerId && wearerId !== '0') {
            queryParams.push(`wearerId=${wearerId}`)
        }

        if (startDate && endDate) {
            queryParams.push(`dateFrom=${startDate}&dateTo=${endDate}`)
        }

        timeSliceDataCSVUrl += queryParams.join('&')
     
        return timeSliceDataCSVUrl
    }

    getAlertsDataUrl = (userId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/alerts/getAllAlerts/${userId}/${pageIndex}/${pageSize}`

    getAlertByIdUrl = (userId: number, alertId: number) => `${this.serverUrl}/alerts/getAlertById/${userId}/${alertId}`

    fiterAlertsDataUrl = (filterData: any) => {
        const {userId, pageIndex, pageSize, status, siteId, wearerId, alertType} = filterData
        let filterAlertsUrl = `${this.serverUrl}/alerts/filterAlerts/${userId}/${pageIndex}/${pageSize}?`

        const queryParams = []
        if (status && status !== '') {
            queryParams.push(`alertStatus=${status}`)
        }

        if (siteId && siteId !== '0') {
            queryParams.push(`siteId=${siteId}`)
            if (wearerId && wearerId != '0') {
                queryParams.push(`wearerId=${wearerId}`)
            }
        }

        if (alertType && alertType !== '') {
            queryParams.push(`alertType=${alertType}`)
        }

        filterAlertsUrl += queryParams.join('&')

        return filterAlertsUrl
    }

    getAlertsDataForCSVUrl = (requestData: any) => ``

    addAlertNoteUrl = `${this.serverUrl}/alerts/addAlertNote`

    editAlertUrl = (alertId: any) => `${this.serverUrl}/alerts/editAlert/${alertId}`

    getActivityAlertsUrl = (userId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/alerts/getAllActivityAlertsDashBoard/${userId}/${pageIndex}/${pageSize}`

    getDeviceSyncAlertsUrl = (userId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/alerts/getAllDeviceSyncAlertDashBoard/${userId}/${pageIndex}/${pageSize}`

    getLowBatteryAlertsUrl = (userId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/alerts/getAllLowBatteryAlertDashBoard/${userId}/${pageIndex}/${pageSize}`

    getFaultAlertsUrl = (userId: number, pageIndex: number, pageSize: number) => `${this.serverUrl}/alerts/getAllFaultAlertsDashBoard/${userId}/${pageIndex}/${pageSize}`



}
import { Component } from '@angular/core';

@Component({
  selector: 'app-patient-activity',
  templateUrl: './patient-activity.component.html',
  styleUrls: ['./patient-activity.component.scss']
})
export class PatientActivityComponent {

}

<!-- <div class="sitenav-wrapper">
    <ul class="progress2 px-0 mb-0" *ngIf="!isEditMode">
        <li [ngClass]="sitePassed ? 'line-pass' : ''">
            <button [ngClass]="sitePassed ? 'pass' : ''" class="activated">
                <div>
                    <i class="bi bi-geo-alt-fill"></i>
                </div>
                <p>Site</p>
            </button>
        </li>

        <li class="">
            <button [ngClass]="!mobileStatus ? 'disabled' : 'activated' ">
                <div>
                    <i class="bi bi-phone-fill"></i>
                </div>
                <p>Mobile</p>
            </button>
        </li>
    </ul>

    <ul class="progress2 px-0 mb-0" *ngIf="isEditMode">
        <li class="line-pass">
            <button (click)="currentTab('site-details')" 
                [ngClass]="sitePassed ? 'pass' : ''" class="activated">
                <div>
                    <i class="bi bi-geo-alt-fill"></i>
                </div>
                <p>Site</p>
            </button>
        </li>

        <li class="">
            <button (click)="currentTab('mobiles')" class="activated">
                <div [ngClass]="(!mobilePresent) ? 'red' : ''">
                    <i class="bi bi-phone-fill"></i>
                </div>
                <p>Mobile</p>
            </button>
        </li>
    </ul>
</div> -->

<div class="children-wrapper">
    <div class="title mb-2 p-2">
        <h5>{{title}}</h5>
    </div>
    <div class="form-container py-3">
        <app-hospital-details *ngIf="currentActiveTab=='site-details'"></app-hospital-details>
        <!-- <app-mobile-details *ngIf="currentActiveTab=='mobiles'"></app-mobile-details> -->
    </div>
</div>

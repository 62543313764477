import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CreatePatientService } from 'src/app/iot/services/create-patient.service'
import { LoaderService } from 'src/app/iot/services/loader.service';
import { LOADER_CONFIG } from 'src/app/shared/global-constants';

@Component({
  selector: 'app-message-routing',
  templateUrl: './message-routing.component.html',
  styleUrls: ['./message-routing.component.scss']
})
export class MessageRoutingComponent implements OnInit, OnDestroy {
  btnStatus: string = 'save'
  isSaveAlreadyPressed: boolean = false;
  smsChecked: boolean = false;
  emailChecked: boolean = false;
  valSelected: boolean = false;
  getNotificationValue: boolean = false;
  newNotification: any;
  wearerId: any = '';
  isEditMode: boolean = false;
  notificationsId: number = 0;
  disableSave: boolean = false
  loading: boolean = false
  loadingSubscription!: Subscription
  loaderConfig = LOADER_CONFIG

  constructor(private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private createPatientService: CreatePatientService) {
      this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
        this.loading = isLoading
      })
    }

  ngOnInit(): void {
    this.wearerId = this.activatedRoute.snapshot.queryParamMap.get("id")!;
    if (this.wearerId) {
      this.isEditMode = true;
      let wearerData = JSON.parse(localStorage.getItem('wearerData')!)
      if (wearerData.notificationChannelId) {
        this.getWearerNotificationSettings(this.wearerId)
      }
    } else {
      
    }      
  }

  getWearerNotificationSettings(weareId: any) {
    // this.loading = true
    this.createPatientService.getWearerMessageRouting(weareId).subscribe({
      next: (response: any) => {
        // this.loading = false
        
        this.notificationsId = response.DATA.id;
        this.smsChecked = response.DATA.alertBySMS;
        this.emailChecked = response.DATA.alertByEmail;
      }, 
      error: (err: any) => {
        // this.loading = false
      }
    });
  }

  saveNotifications() {
    if (this.isEditMode && this.notificationsId !== 0) {
      this.updateNotifications(this.wearerId)
    } else {
      this.addNotification()
    }
  }

  addNotification() {
    if (this.btnStatus == 'save') {
      this.isSaveAlreadyPressed = true
      let wearerId = localStorage.getItem('wearerId')!

      this.createPatientService.addWearerMessageRouting(this.wearerId, this.smsChecked, this.emailChecked).subscribe({
        next: (response: any) => {
          // this.loading = false
          this.disableSave = true
          
          this.notificationsId = response.DATA.id;
        }, 
        error: (err: any) => {
          // this.loading = false
        }
      })
    } else {
      if (this.isSaveAlreadyPressed) {
        if (this.disableSave) {
          this.routeToNextTab(this.btnStatus)

        } else {
          let notificationsData: any = {
            id: this.notificationsId,
            alertBySMS: this.smsChecked,
            alertByEmail: this.emailChecked
          }
          let wearerId = localStorage.getItem('wearerId')!
          this.createPatientService.editWearerMessageRouting(this.wearerId, notificationsData).subscribe({
            next: (success: any) => {
              this.loading = false
              if (this.btnStatus == 'saveNext') {
                this.routeToNextTab(this.btnStatus);
              }
            },
            error: (err: any) => {
              this.loading = false
            }
          });
        }
      } else {
        let wearerId = JSON.parse(localStorage.getItem('wearerData')!).id
        this.createPatientService.addWearerMessageRouting(wearerId, this.smsChecked, this.emailChecked).subscribe({
          next: (success: any) => {
            // this.loading = false
            
            this.routeToNextTab(this.btnStatus);
          }, 
          error: (err: any) => {
            // this.loading = false
          }
        })
      }
    }
  }

  updateNotifications(wearerId: number) {
    // this.loading = true
    if (this.disableSave) {
      this.routeToNextTab(this.btnStatus)
    } else {
      let notificationsData: any = {
        id: this.notificationsId,
        alertBySMS: this.smsChecked,
        alertByEmail: this.emailChecked
      }
      
      this.createPatientService.editWearerMessageRouting(wearerId, notificationsData).subscribe({
        next: (success: any) => {
          // this.loading = false
          if (this.btnStatus == 'saveNext') {
            this.routeToNextTab(this.btnStatus);
          }
        },
        error: (err: any) => {
          // this.loading = false
        }
      });
    }
  }

  routeToNextTab(btnStatus: string) {
    const addedFormStatus: any = {};
    addedFormStatus.activeTabName = 'message-routing';
    addedFormStatus.buttonStatus = btnStatus;
    this.createPatientService.broadcastNewPatient(addedFormStatus);
  }

  notificationVal(event: any) {
    
  }

  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe()    
  }
}

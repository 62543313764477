<div class="site-details-wrapper">
    <!-- <ngx-loading [show]="loading"></ngx-loading> -->
    <form #siteDetailsForm="ngForm" (ngSubmit)="saveSite()">
        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Site Name</label>
            <div class="col-sm-6">
                <input type="text" required
                    class="form-control form-control-sm"  
                    (change)="siteModelChanged($event)"
                    placeholder="Site Name"
                    name="siteName"
                    [(ngModel)]="siteModel.name" 
                    #siteName="ngModel">
                <div class="error-block" *ngIf="siteName.invalid && (siteDetailsForm.submitted || siteName.dirty || siteName.touched)">
                    <span *ngIf="siteName.errors?.['required']">Site name is required</span>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Address Line 1</label>
            <div class="col-sm-6">
                <input type="text" required
                    class="form-control form-control-sm"
                    (change)="siteModelChanged($event)"
                    placeholder="Address line 1"
                    name="address1"
                    [(ngModel)]="siteModel.address1"
                    #siteAdress1="ngModel">
                <div class="error-block" *ngIf="siteAdress1.invalid && (siteDetailsForm.submitted || siteAdress1.dirty || siteAdress1.touched)">
                    <span *ngIf="siteAdress1.errors?.['required']">Address line 1 is required</span>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end">Address Line 2</label>
            <div class="col-sm-6">
                <input type="text"
                    class="form-control form-control-sm"
                    (change)="siteModelChanged($event)"
                    placeholder="Address line 2"
                    name="address2"
                    [(ngModel)]="siteModel.address2"
                    #siteAdress2="ngModel">
            </div>
        </div>

        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Town/City</label>
            <div class="col-sm-6">
                <input type="text" 
                    class="form-control form-control-sm" required
                    (change)="siteModelChanged($event)"
                    placeholder="Town/City"
                    name="town"
                    [(ngModel)]="siteModel.town"
                    #town="ngModel">
                <div class="error-block" *ngIf="town.invalid && (siteDetailsForm.submitted || town.dirty || town.touched)">
                    <span *ngIf="town.errors?.['required']">Site location is required</span>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Post Code/Zip Code</label>
            <div class="col-sm-6">
                <input type="text" class="form-control form-control-sm"
                    (change)="siteModelChanged($event)"
                    name="postCode"
                    minlength="5"
                    maxlength="7"
                    placeholder="Post Code"
                    [(ngModel)]="siteModel.postCode"
                    #postCode="ngModel" required>
                <div class="error-block" *ngIf="postCode.invalid && (siteDetailsForm.submitted || postCode.dirty || postCode.touched)">
                    <span *ngIf="postCode.errors?.['required']">Post code is required</span>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Country</label>
            <div class="col-sm-6">
                <select class="form-select form-select-sm"
                    name="country"
                    [(ngModel)]="siteModel.country"
                    #countryName="ngModel"
                    (change)="onCountryChange($event)" required>
                    <option value="" disabled>Select country</option>
                    <option *ngFor="let country of countries" value={{country.name}}>{{country.name}}</option>
                </select>
                <div class="error-block" *ngIf="countryName.invalid && (siteDetailsForm.submitted || countryName.dirty || countryName.touched)">
                    <span *ngIf="countryName.errors?.['required']">Country is mandatory</span>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Time Zone</label>
            <div class="col-sm-6">
                <select class="form-select form-select-sm"
                    name="timezone"
                    [(ngModel)]="siteModel.timeZone"
                    #tz="ngModel"
                    (change)="selectTimeZone($event)" required>
                    <option value="" disabled>Select timezone</option>
                    <option *ngFor="let tz of timeZones" value={{tz.timezone}}>{{tz.timezone}}</option>
                </select>
                <div class="error-block" *ngIf="tz.invalid && (siteDetailsForm.submitted || tz.dirty || tz.touched)">
                    <span *ngIf="tz.errors?.['required']">Timezone is mandatory</span>
                </div>
            </div>
        </div>        

        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Contact Name</label>
            <div class="col-sm-6">
                <input type="text" class="form-control form-control-sm" required
                    (change)="siteModelChanged($event)"
                    name="contactName"
                    placeholder="Contact Name"
                    [(ngModel)]="siteModel.contactName"
                    #contactName="ngModel">
                <div class="error-block" *ngIf="contactName.invalid && (siteDetailsForm.submitted || contactName.dirty || contactName.touched)">
                    <span *ngIf="contactName.errors?.['required']">Contact name is required</span>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Contact Email</label>
            <div class="col-sm-6">
                <input type="text" class="form-control form-control-sm" id="" required
                (change)="siteModelChanged($event)"
                name="emailAddress"
                pattern="[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                placeholder="Contact Email"
                [(ngModel)]="siteModel.emailAddress"
                #emailAddress="ngModel">
                <div class="error-block" *ngIf="emailAddress.invalid && (siteDetailsForm.submitted || emailAddress.dirty || emailAddress.touched)">
                    <span *ngIf="emailAddress.errors?.['required']">
                        Contact email is required
                    </span>
                    <span *ngIf="!emailAddress.errors?.['required'] && emailAddress.errors?.['pattern']">
                        Email is invalid
                    </span>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <label for="" class="col-sm-3 col-form-label text-end required">Contact Mobile</label>
            <div class="col-sm-6">
                <input type="text" class="form-control form-control-sm" id="" required
                    (change)="siteModelChanged($event)"
                    name="contactMobile"
                    placeholder="Contact mobile"
                    pattern="^\+[1-9]\d{1,14}$"
                    [(ngModel)]="siteModel.contactMobile"
                    #contactMobile="ngModel">
                <div class="error-block" *ngIf="contactMobile.invalid && (siteDetailsForm.submitted || contactMobile.dirty || contactMobile.touched)">
                    <span *ngIf="contactMobile.errors?.['required']">Contact number is required</span>
                    <span *ngIf="contactMobile.errors?.['pattern']">Use + with country code</span>
                </div>
            </div>
        </div>

        <div class="btn-wrapper">
            <button pButton pRipple
                [disabled]="false" 
                type="submit" 
                icon="pi pi-save" 
                label="Save" 
                class="p-button-raised p-button-sm mx-2">
            </button>
        </div>
    </form>
</div>


import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Router } from '@angular/router'
import { FilterService } from 'src/app/shared/services/filter.service'
import { SiteService } from 'src/app/iot/services/site.service'
import { Subscription } from 'rxjs'
import { LoaderService } from 'src/app/iot/services/loader.service'
import { LOADER_CONFIG } from 'src/app/shared/global-constants'
import { UtilService } from 'src/app/shared/services/util.service'
import { ModalDirective } from 'ngx-bootstrap/modal'
@Component({
  selector: 'app-manage-hospitals',
  templateUrl: './manage-hospitals.component.html',
  styleUrls: ['./manage-hospitals.component.scss']
})
export class ManageHospitalsComponent implements OnInit, OnDestroy {
  loading: boolean = false
  loaderConfig = LOADER_CONFIG
  dateDto: any = {}
  totalItems: number = 0
  currentPage: number = 1
  siteData: Array<any> = []
  userId: string = ''
  currentUser: string = ''
  requestData: any = {}
  filterData: any = {}
  loadingSubscription!: Subscription
  filterSub!: Subscription
  siteId: string = ''
  userAction: string = ''
  @ViewChildren('siteDropdown') siteDropdown!: QueryList<ElementRef>
  @ViewChild('deleteModal', { static: false }) deleteModal!: ModalDirective

  constructor(private router: Router,
    private utilService: UtilService,
    private siteService: SiteService,
    private loaderService: LoaderService,
    private filterService: FilterService) {

    this.userId = localStorage.getItem('user-id')!
    this.currentUser = localStorage.getItem('role')!

    this.requestData.managerId = this.userId
    this.requestData.pageIndex = 1
    this.requestData.pageSize = 10

    this.loadingSubscription = loaderService.isLoading$().subscribe((isLoading: boolean) => {
      this.loading = isLoading
    })

  }

  ngOnInit(): void {

    // if (this.currentUser == 'ROLE_SITE_MANAGER') {
    //   this.getUserSites();
    // } else {
    //   this.getPaginatedSites(this.requestData);
    // }
    this.getPaginatedSites(this.requestData);
    this.filterSub = this.filterService.selectedFilters$.subscribe({
      next: (filterData: any) => {
        this.filterData = filterData
        console.log('filter data in sites: ', filterData)
        if (filterData.search == '' && filterData.cleared) {
          this.requestData.pageIndex = 1
          
          this.getPaginatedSites(this.requestData)
        } else if (filterData.searchClicked) {
          
          filterData.pageIndex = 1
          filterData.pageSize = 10
          this.filterSites(filterData)
        }
      },
      error: (err: any) => { }
    })
  }

  filterByDate() { }

  onValueChangeStart(event: any) { }

  onValueChangeEnd(event: any) { }

  getUserSites() {
    // this.siteService.getAllUserSites(parseInt(this.userId)).subscribe({
    //   next: (success: any) => {
    //     this.paginatedSites = success.data
    //   },
    //   error: (err: any) => {

    //   }
    // })
  }

  getPaginatedSites(requestData: any) {
    this.siteService.getAllPaginatedSites(requestData).subscribe({
      next: (response: any) => {
        
        this.siteData = response.DATA
        this.totalItems = response.total.toString();
      },
      error: (err: any) => {

      }
    })
  }

  filterSites(filterData: any) {
    this.siteService.filterSites(filterData).subscribe({
      next: (response: any) => {
        this.siteData = response.DATA
        this.totalItems = response.total
      },
      error: () => {

      }
    })
  }

  showModal() {
    if (this.userAction == 'delete') {
      this.deleteModal.show()
    } else {

    }
  }

  hideModal() {

  }

  deleteSite(siteId: string) {
    this.siteService.deleteSite(parseInt(siteId)).subscribe({
      next: (repsonse: any) => {
        this.deleteModal.hide()
        this.siteData = this.siteData.filter((item: any) => {
          return item.id != siteId
        })
        if (this.siteData.length == 0) {
          if(this.currentPage > 1) {
            this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
              this.router.navigate(["iot/sites/manage-sites"]);
            });
            this.deleteModal.hide()
          } else {

          }
        }
      },
      error: (repsonse: any) => {

      }
    })
  }

  onHidden() {
    this.utilService.resetDropdowns(this.siteDropdown)
  }

  pageChanged(event: any) {
    this.filterData.pageIndex = event.page;
    if (this.filterData.searchClicked) {
      this.filterData.pageIndex = event.page
      this.filterSites(this.filterData)
    } else {
      this.requestData.pageIndex = event.page
      this.getPaginatedSites(this.requestData);
    }
  }

  selectAction(siteData: any, event: any) {
    event.stopPropagation()
    let action = event.target.value
    if (action == 'edit') {
      this.router.navigateByUrl("iot/sites/manage-sites", { skipLocationChange: true })
        .then(() => {
          this.router.navigate(["iot/sites/edit-site"], {
            queryParams: { id: siteData.id }
          });
        });
    } else if (action == 'delete') {
      this.siteId = siteData.id
      this.deleteModal.show()
    }
  }

  ngOnDestroy(): void {
    this.filterSub.unsubscribe()
    this.loadingSubscription.unsubscribe()
  }

}

<div class="devices-wrapper">
    <!--Button group-->
    <div class="btn-group mb-3" role="group" aria-label="Basic outlined example">
        <button type="button" class="btn btn-outline-primary"
            *ngIf="userRole != 'ROLE_SITE_MANAGER'"
            [routerLink]="['unallocated']"
            routerLinkActive="active">
            Unallocated
        </button>
        <button type="button" class="btn btn-outline-primary"
            [routerLink]="['unassigned']"
            routerLinkActive="active">
            Unassigned
        </button>
        <button type="button" class="btn btn-outline-primary"
            [routerLink]="['unprovisioned']"
            routerLinkActive="active">
            Unprovisioned
        </button>
        <button type="button" class="btn btn-outline-primary"
            [routerLink]="['provisioned']"
            routerLinkActive="active">
            Provisioned
        </button>
    </div>


    <!--Router Outlet-->
    <div class="">
        <router-outlet></router-outlet>
    </div>

</div>
import { Component } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent {
  totalItems: number = 100
  currentPage: number = 1


  pageChanged(event: any) {

  }

  downloadCSV() {
    
  }
}

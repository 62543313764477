import { Component, OnInit } from '@angular/core'
import { Router, Event, NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss']
})

export class PatientsComponent implements OnInit {
  userRole: string = ''
  editRoute: boolean = false

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.lastIndexOf('edit-wearer') != -1) {
          this.editRoute = true
        } else {
          this.editRoute = false
        }
        
      }
    });
  }

  ngOnInit(): void {
    this.router.navigate(["iot/wearers/manage-wearers"])
  }

  
}

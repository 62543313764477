<div class="settings-wrapper">
    <div class="container">
        <div class="row mb-3">
            <div class="col-md-4">
                <div class="change-password-form">
                    <form #changePasswordForm="ngForm" (ngSubmit)="saveUserSettings()">
                        <div class="form-title mb-30p">
                            <h6>Change Password</h6>
                        </div>
    
                        <div class="form-group mb-30p">
                            <span class="p-float-label">
                                <input autocomplete="new-password" 
                                    type="password"  
                                    class="p-inputtext-sm w-100"
                                    name="password"
                                    #oldPassword="ngModel"
                                    [(ngModel)]="changePasswordModel.oldPassword"
                                    pInputText 
                                    required> 
                                <label for="float-input">Old Password</label>
                            </span>
                            <div class="error-block px-2" *ngIf="oldPassword.invalid && (changePasswordForm.submitted || oldPassword.dirty || oldPassword.touched)">
                                <span *ngIf="oldPassword.errors?.['required']">(61.4) Old password is mandatory</span>
                            </div>
                        </div>
    
                        <div class="form-group mb-30p">
                            <span class="p-float-label">
                                <input autocomplete="new-password" 
                                    type="password"  
                                    pattern="^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$"
                                    minlength="4"
                                    class="p-inputtext-sm w-100"
                                    name="newPassword"
                                    #newPassword="ngModel"
                                    [(ngModel)]="changePasswordModel.newPassword"
                                    pInputText 
                                    required> 
                                <label for="float-input">New Password</label>
                            </span>
                            <div class="error-block" *ngIf="newPassword.invalid && (changePasswordForm.submitted || newPassword.dirty || newPassword.touched)">
                                <span *ngIf="newPassword.errors?.['required']">(61.4) New password is mandatory</span>
                                <!-- <span *ngIf="newPassword.errors?.['minlength']">Password must be 4 characters long</span> -->
                            </div>
                            <div class="error-block" *ngIf="(newPassword.dirty || newPassword.touched) && (changePasswordModel.newPassword === changePasswordModel.oldPassword)">
                                <!-- <span>Old password & new password can't be same</span> -->
                            </div>
                            <div class="error-block"
                                    *ngIf="(changePasswordForm.submitted || newPassword.touched)">
                                    <span *ngIf="newPassword.errors?.['pattern']">
                                        <!-- Password must be 8 characters long and must have at least 1 uppercase character, 1 or more numbers, and 1 or more special character -->
                                        (61.2) Invalid password format
                                    </span>
                            </div>
                        </div>
    
                        <div class="form-group mb-3">
                            <span class="p-float-label">
                                <input autocomplete="new-password" 
                                    type="password"
                                    minlength="4"
                                    class="p-inputtext-sm w-100"
                                    name="conPassword"
                                    #confPassword="ngModel"
                                    [(ngModel)]="changePasswordModel.confirmPassword"
                                    pInputText 
                                    required> 
                                <label for="float-input">Confirm Password</label>
                            </span>
                            <div class="error-block" *ngIf="confPassword.invalid && (changePasswordForm.submitted || confPassword.touched || confPassword.dirty)">
                                <span *ngIf="confPassword.errors?.['required']">(61.4) Confirm password is mandatory</span>
                            </div>
                            <div class="error-block" *ngIf="(confPassword.touched || confPassword.dirty) && (changePasswordForm.submitted && (changePasswordModel.confirmPassword !== '' && changePasswordModel.confirmPassword!=changePasswordModel.newPassword))">
                                <span>(61.3) Passwords do not match</span>
                            </div>
                        </div>
                        
                        <div class="btn-container">
                            <button pButton pRipple type="submit" label="Save" icon="pi pi-save" class="login-button p-button-raised p-button-sm"></button>
                        </div>
                    </form>
                    <div>
                        <div class="">
                            <h6>Toggle Notifications</h6>
                        </div>
                        <div class="mb-3">
                            <p-inputSwitch 
                                name="notifications"
                                (onChange)="toggleNotifications($event)"
                                [(ngModel)]="notificationsEnabled">
                            </p-inputSwitch>
                        </div> 
                    </div>
                    
                </div>
            </div>
            
            <!-- <div class="col-md-4">
                <div class="change-password-form">
                    <form>
                        <div class="form-title mb-3">
                            <h6>Toggle Notifications</h6>
                        </div>
                        <div class="mb-3">
                            <p-inputSwitch></p-inputSwitch>
                        </div>
                        <div class="btn-container">
                            <button pButton pRipple type="submit" label="Save" icon="pi pi-save" class="login-button p-button-raised p-button-sm"></button>
                        </div>
                    </form>
                </div>
            </div> -->
            <!-- <div class="col-md-4"></div> -->
        </div>
    </div>
</div>
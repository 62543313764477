<div class="message-wrapper px-md-5">
  <ngx-loading [show]="loading" [config]="loaderConfig"></ngx-loading>
      <form class="pt-3" #notificationForm="ngForm" (ngSubmit)="saveNotifications()">
          <div class="row mb-3">
              <div class="col-12">
                  <p class="heading">Notification Settings:</p>
              </div>
          </div>
          <div class="row mb-3 px-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="check-sms"  
                name="sms"
                [(ngModel)]="smsChecked"
                #sms="ngModel"
                value="SMS"
                (change)="notificationVal($event)">
              <label class="form-check-label" for="check-sms">
                SMS
              </label>
            </div>
          </div>
  
          <div class="row mb-3 px-3">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="check-email" 
                name="email"
                [(ngModel)]="emailChecked"
                #email="ngModel" 
                value="Email"
                (change)="notificationVal($event)">
              <label class="form-check-label" for="check-email">
                Email
              </label>
            </div>
          </div>
  
          <div class="btn-wrapper mb-3">
            <button pButton pRipple  
                (click)="btnStatus ='save'"
                icon="pi pi-save" 
                label="Save" 
                class="p-button-raised p-button-sm mx-2">
            </button>
            <button pButton pRipple  
                (click)="btnStatus ='saveNext'"
                icon="pi pi-angle-double-right" 
                label="Save & Next" 
                class="p-button-raised p-button-sm mx-2">
            </button>
          </div>
    </form>
  </div>
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AuthApiBuilderService } from 'src/app/shared/api-builders/auth-api-builder.service'

@Injectable()
export class LoginService {
    constructor(public authApiBuilder: AuthApiBuilderService, public httpClient: HttpClient) {
    }

    signIn(loginData: any) {
        return this.httpClient.post(this.authApiBuilder.login, loginData)
    }
}
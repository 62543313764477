import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router'

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['./hospitals.component.scss']
})

export class HospitalsComponent implements OnInit {
  userRole: string = localStorage.getItem('role')!
  editRoute: boolean = false

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.lastIndexOf('edit-site') != -1) {
          this.editRoute = true
        } else {
          this.editRoute = false
        }
      }
    });
  }

  ngOnInit(): void {
    this.router.navigate(['iot/sites/manage-sites'])
  }
}
